<nz-card>
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <!-- <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24"> -->
        <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
            <div class="width-100"> <strong>{{isTestSeriesComponent !== true ? testDetails.name : ''}}</strong> </div>
        </div>
        <div nz-col nzSpan="24" class="mt4">
            <nz-tag *ngIf="testDetails.testType" [nzColor]="'cyan'">
                {{testTypeObject[testDetails.testType].label}}
            </nz-tag>
            <nz-tag *ngIf="testDetails.difficultyLevel" [nzColor]="'magenta'">
                {{difficultyLevelObject[testDetails.difficultyLevel].label}}
            </nz-tag>
            <nz-tag *ngIf="testDetails.examType" [nzColor]="'purple'">
                {{examTypeObject[testDetails.examType].label}}
            </nz-tag>
            <nz-tag [nzColor]="'gold'">{{time}}
            </nz-tag>
            <nz-tag [nzColor]="'green'"> Total Questions- {{totalQuestions}}</nz-tag>
        </div>
        <nz-collapse nzAccordion *ngIf="testDetails.section">
            <nz-collapse-panel *ngFor="let item of testDetails.section; index as i" [nzHeader]="item.sectionName"
                [nzExtra]="extraTpl" [nzActive]="true">
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" *ngFor="let question of item.questions; index as i">
                    <div *ngIf="question.question">
                        <app-question-details  [value]="question.question" [component]="component"
                            [marks]="question.marks" [negativeMarks]="question.negativeMarks" [index]='i'>
                        </app-question-details>
                    </div>

                </div>
                <ng-template #extraTpl>
                    <nz-tag [nzColor]="'magenta'" >Total Questions-
                        {{item.questions.length}}</nz-tag>
                    <nz-tag [nzColor]="'gold'" *ngIf="item.time">Time- {{getHourMinuteFromMinute(item.time)}}</nz-tag>
                </ng-template>
            </nz-collapse-panel>
        </nz-collapse>

    </div>
</nz-card>