import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { HttpRequestService } from 'src/app/core/services';

@Component({
  selector: 'app-rejection-reason',
  templateUrl: './rejection-reason.component.html',
  styleUrls: ['./rejection-reason.component.scss']
})
export class RejectionReasonComponent implements OnInit {

  rejectionReasonForm: FormGroup;
  @Input() updateId: string = '';
  @Input() component: string = '';



  idForUpdate: string;
  buttonLoading = false;
  checkAddPermission = false;
  checkUpdatePermission = false;
  id: string= ''
  apiRoute: string = ''

  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalRef : NzModalRef
  ) {
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.rejectionReasonForm = this.fb.group({
      rejectionReason: [null, [Validators.required]],
      approveStatus: ['rejected'],
    });
  }

  // on page load functions
  ngOnInit(): void {
    this.id = this.updateId,
    this.apiRoute = this.component
  }

  /* Submit subject form */
  submit(): void {
    if (!this.rejectionReasonForm.valid) {
      this.markFormGroupTouched(this.rejectionReasonForm);
    } else {
        this.addOrUpdateAll(
          'put',
          `${this.apiRoute}/approved-rejected/${this.id}`,
          `Data updated successfully.`
        );
    }
  }

  /* Add Or Edit Subject */
  addOrUpdateAll(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    this.buttonLoading = true;
    const finalData:any = this.rejectionReasonForm.value;
    this.httpRequestService
      .request(requestMethod, requestURL, finalData)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          this.buttonLoading = false;
          this.modalRef.close(result);
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          }else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }

  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

}

