import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { mockShalaSubAdminMenu } from 'src/app/core/data/menu.data';
import { Subjects } from 'src/app/core/interfaces/subjects';
import { HttpRequestService } from 'src/app/core/services';
import {
  NzFormatEmitEvent,
  NzTreeComponent,
  NzTreeNode,
  NzTreeNodeOptions,
} from 'ng-zorro-antd/tree';
@Component({
  selector: 'app-add-update-sub-admin',
  templateUrl: './add-update-sub-admin.component.html',
  styleUrls: ['./add-update-sub-admin.component.scss'],
})
export class AddUpdateSubAdminComponent implements OnInit {
  @ViewChild('#nzTreeComponent', { static: false })
  nzTreeComponent!: NzTreeComponent;

  addUpdateSubAdminForm: FormGroup;
  addUpdateSubAdminPasswordForm: FormGroup;
  idForUpdate: string;
  buttonLoading = false;
  checkAddPermission = false;
  checkUpdatePermission = false;
  viewReEnterPassword = false;
  viewPassword = false;
  menuData: NzTreeNodeOptions[] | NzTreeNode[] = [];
  selectedKeys: any[] = [];
  CheckedValue: any[] = [];
  childKeyArray = ['editorials', 'subjects', 'chapters', 'topics', 'exam', 'exam-category', 'success-stories', 'current-affairs', 'resource', 'testimonial', ]

  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.addUpdateSubAdminForm = this.fb.group({
      name: [null, Validators.required],
      allowedRoute: [null],
      email: [
        null,
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      mobile: [null, [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      role: ['subAdmin'],
      status: [true],
    });
    this.addUpdateSubAdminPasswordForm = this.fb.group({
      password: [null, Validators.required],
      reEnterPassword: [
        null,
        [Validators.required, this.confirmationValidator],
      ],
    });
  }

  // on page load functions
  get mobile() {
    return this.addUpdateSubAdminForm.get('mobile');
  }
  ngOnInit(): void {
    this.CheckedValue = ['dashboard']
    if (this.idForUpdate) {
      this.getSubAdminsById();
    }
    this.menuData = mockShalaSubAdminMenu;
    //
  }
  onCheckBoxChange(event: NzFormatEmitEvent): void {
    //;
    event.nodes?.forEach((item: any) => {
      if (item) {
        const keys = item.key;
        const index = this.selectedKeys.indexOf(keys);
        if (index !== -1) {
          this.selectedKeys.splice(index, 1);
        }
        this.selectedKeys.push(keys);

      }
     ;
    });
    if (event.node?.isChecked === false) {
      const deletedKey = event.node?.key;

      // Remove the key from the selectedValues array
      const index = this.selectedKeys.indexOf(deletedKey);
      if (index !== -1) {
        this.selectedKeys.splice(index, 1);
      }
    }
  }
  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (
      control.value !==
      this.addUpdateSubAdminPasswordForm.controls.password.value
      ) {
        return { confirm: true, error: true };
      }
      return {};
    };
    updateConfirmValidator(): void {
      /** wait for refresh value */
      Promise.resolve().then(() =>
      this.addUpdateSubAdminPasswordForm.controls.reEnterPassword.updateValueAndValidity()
      );
    }
    /* Get single subject details by Id */
    getSubAdminsById(): void {
      this.httpRequestService
        .request('get', `auth-admins/${this.idForUpdate}`)
        .subscribe(
          (result: any) => {
            const data: any = result.data;
           ;
            this.CheckedValue = data.allowedRoute;
            this.addUpdateSubAdminForm.patchValue({
              name: data.name,
              email: data.email,
              mobile: data.mobile,
              status: data.status,
            });
          },
          (error: any) => {}
        );
    }
    /* Submit subject form */
    submit(): void {
    if (
      !this.addUpdateSubAdminForm.valid
    ) {
      this.markFormGroupTouched(this.addUpdateSubAdminForm);
      this.markFormGroupTouched(this.addUpdateSubAdminPasswordForm);
      if (!this.selectedKeys) {
        this.notificationService.error('', 'Please select a Route');
      }
      //;
    } else {
      if (this.idForUpdate) {
        //;
        this.addOrUpdateSubAdmin(
          'put',
          `auth-admins/sub-admin-profile/${this.idForUpdate}` ,
          'Sub Admin Successfully Updated'
        );
      } else {
        this.addOrUpdateSubAdmin(
          'post',
          'auth-admins/register',
          'Sub Admin Added Successfully '
        );
      }
    }
  }

  /* Add Or Edit Subject */
  addOrUpdateSubAdmin(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    this.buttonLoading = true;
    if (this.selectedKeys) {
      if(this.selectedKeys.includes('masters')){
        this.selectedKeys.push(...this.childKeyArray)
      }

      this.addUpdateSubAdminForm.patchValue({
        allowedRoute: [...this.selectedKeys],
      });

    }
    const finalData = {
      name: this.addUpdateSubAdminForm.controls.name.value,
      email: this.addUpdateSubAdminForm.controls.email.value,
      password: this.addUpdateSubAdminPasswordForm.controls.password.value,
      mobile: this.addUpdateSubAdminForm.controls.mobile.value,
      role: this.addUpdateSubAdminForm.controls.role.value,
      status: this.addUpdateSubAdminForm.controls.status.value,
      allowedRoute: this.addUpdateSubAdminForm.controls.allowedRoute.value,
    };
   ;
    // return
    this.httpRequestService
      .request(requestMethod, requestURL, finalData)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          this.router.navigateByUrl('/main/sub-admin');
          this.buttonLoading = false;
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          } else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }

  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }
}
