<nz-page-header class="site-page-header" nzBackIcon [nzTitle]="idForUpdate ? 'Update Plan' : 'Add Plan'">
</nz-page-header>
<form nz-form [formGroup]="addUpdatePlanForm ">
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <nz-card nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="name" nzRequired>Plan Name</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                            <input nz-input formControlName="name" type="text" placeholder="Enter Plan name " />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="duration" nzRequired>Plan Duration(days)</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                            <nz-input-number class="width-100" [nzMin]="0" formControlName="duration"
                                placeholder="Enter Plan Duration "></nz-input-number>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="max" >Max Choose </nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                            <nz-input-number class="width-100" [nzDisabled]="isUnlimited" [nzMin]="0"
                                formControlName="max" placeholder="Enter Max test-Series  "></nz-input-number>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="price" nzRequired>Plan Price</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                            <nz-input-number class="width-100" [nzMin]="0" (ngModelChange)="calculateDiscount()" formControlName="mrpPrice"
                                placeholder="Enter Plan Price"></nz-input-number>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="price" >Selling Price</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                            <nz-input-number class="width-100" [nzMin]="0" (ngModelChange)="calculateDiscount()" formControlName="price"
                                placeholder="Enter Selling Price "></nz-input-number>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="5" nzMd="5" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="price">Discount Type</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Please select your discount type!">
                            <nz-select nzAllowClear id="testType" (ngModelChange)="calculateDiscount()" formControlName="discountType"
                                nzPlaceHolder="Select discount type">
                                <nz-option *ngFor="let item of allDiscountType" nzValue="{{ item.value }}"
                                    nzLabel="{{ item.label }}"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="3" nzMd="3" nzSm="24" nzXs="24" *ngIf="this.addUpdatePlanForm.value.discountType === 'FLAT' && this.addUpdatePlanForm.value.mrpPrice && this.addUpdatePlanForm.value.price">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="price">Discount Amount</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                            <!-- <input nz-input placeholder="hi" formControlName="flatDiscount" [disabled]="true"/> -->

                            <nz-input-group nzAddOnAfter="₹">
                                <input type="text" nz-input formControlName="flatDiscount" />
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                
                <div nz-col nzLg="3" nzMd="3" nzSm="24" nzXs="24" *ngIf="this.addUpdatePlanForm.value.discountType === 'PERCENTAGE' && this.addUpdatePlanForm.value.mrpPrice && this.addUpdatePlanForm.value.price">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="price">Discount Percentage</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                                <!-- <input nz-input placeholder="hello" formControlName="discountInPercent" [disabled]="true" /> -->

                                    <nz-input-group nzAddOnAfter="%">
                                        <input type="text" nz-input formControlName="discountInPercent" />
                                    </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                <div nz-col nzLg="2" nzMd="2" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="exam-category">is Unlimited?
                        </nz-form-label>
                        <nz-form-control>
                            <nz-switch (ngModelChange)="clickChange($event)" formControlName="isUnlimited"></nz-switch>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="22" nzMd="22" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="exam-category" >Description
                        </nz-form-label>
                        <nz-form-control>
                            <ckeditor formControlName="description" name="editor" [editor]="Editor" [config]="ckeConfig" debounce="500"
                                (ngModelChange)="onChange($event)"></ckeditor>
                        </nz-form-control>
                            <!-- <nz-textarea-count [nzMaxCharacterCount]="100">
                                <textarea rows="4" formControlName="description" nz-input></textarea>
                            </nz-textarea-count> -->
                            
                        
                    </nz-form-item>
                </div>
            </div>
            <!------------------------ Table Of Test-Series --------------------------------------->
            <div *ngIf="!isUnlimited" nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                <nz-divider></nz-divider>
                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                    <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24" nzFor="subject" >Exam name </nz-form-label>
                            <nz-form-control [nzSpan]="24" nzErrorTip="Please select your exam!">
                                <nz-select nzAllowClear nzShowSearch nzServerSearch id="testType" formControlName="exam"
                                    nzPlaceHolder="Select a option" (ngModelChange)="onChangeExam($event)"
                                    (nzOnSearch)="searchExamForDropdown($event)">
                                    <nz-option *ngFor="let exam of allExams" nzValue="{{ exam._id }}"
                                        nzLabel="{{ exam.examName }}"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24" nzFor="exam-category" >Exam-Category name
                            </nz-form-label>
                            <nz-form-control [nzSpan]="24" nzErrorTip="Please select your exam-category!">
                                <nz-select nzAllowClear nzShowSearch nzServerSearch id="testType"
                                    formControlName="examCategory" (ngModelChange)="onChangeExamCategory($event)"
                                    nzPlaceHolder="Select a option"
                                    (nzOnSearch)="searchExamCategoryForDropdown($event)">
                                    <nz-option *ngFor="let exam of allExamCategories" nzValue="{{ exam._id }}"
                                        nzLabel="{{ exam.categoryName }}"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <!-- </div> -->
                </div>
            </div>

            <div *ngIf="!isUnlimited" nz-row [nzGutter]="16">
                <div nz-col class="gutter-row" [nzMd]="12" [nzSm]="24">
                    <nz-list nzBordered nzSize="small" class="samelistheight questionlistbox">
                        <nz-list-header>
                            <h4>
                                Total Tests:
                                {{ allTestSeries.length - newSelectedTestSeries.length }}
                            </h4>

                            <div nz-row>
                                <button style="text-align: right" nz-button nzType="primary" nzSize="small"
                                    (click)="addTestSeries()">
                                    Add
                                </button>
                            </div>
                        </nz-list-header>
                        <div class="myqlistitems">
                            <nz-list-item *ngFor="let item of filterTestSeries; index as i" class="qlist flex-wrapneed">
                                <label nz-checkbox [(ngModel)]="item.isChecked"
                                    [ngModelOptions]="{ standalone: true }"></label>
                                <span class="mr2">
                                    {{i+1}}.
                                </span>
                                <div class="inquestionlistonform">
                                    {{item.name}}
                                </div>
                            </nz-list-item>
                            <div class="loader" *ngIf="loading">
                                <nz-spin nzSimple></nz-spin>
                            </div>
                        </div>
                        <div class="text-center mt3 " *ngIf="!loading">
                            <button [disabled]="limit" nz-button nzType="primary" nzSize="small"
                                (click)="testPagination()"> Load More
                            </button>
                        </div>
                    </nz-list>
                </div>
                <div nz-col class="gutter-row" [nzMd]="12" [nzSm]="24">
                    <nz-list nzBordered nzSize="small" class="samelistheight questionlistbox">
                        <nz-list-header>
                            <h4>Total Selected Tests: {{ newSelectedTestSeries.length }}</h4>

                            <div nz-row>
                                <button style="text-align: right" nz-button nzDanger nzSize="small"
                                    (click)="removeTestSeries ()">
                                    Remove
                                </button>
                            </div>
                        </nz-list-header>

                        <div class="myqlistitems">
                            <nz-table #basicTable [nzData]="newSelectedTestSeries" [nzShowPagination]="false"
                                class="valigntop">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Tests</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody cdkDropList (cdkDropListDropped)="drop($event)" class="drag-css">
                                    <tr *ngFor="let data of basicTable.data;  index as i" cdkDrag>
                                        <td>
                                            <label nz-checkbox [(ngModel)]="data.isChecked"
                                                [ngModelOptions]="{ standalone: true }"></label>
                                            <span class="ml4">{{i+1}}.</span>
                                        </td>
                                        <td>
                                            {{data.name}}
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </nz-list>
                </div>
            </div>
            <div nz-row nzJustify="end" class="mt3">
                <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()"
                    class="submitoraddbutton">
                    {{idForUpdate? 'Update':'Submit'}}
                </button>
            </div>
        </nz-card>
    </div>
</form>