import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { CommonArrayService } from 'src/app/core/services/common-array.service';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';
import { AddUpdateQuestionComponent } from '../add-update-question/add-update-question.component';
import { Question } from 'src/app/core/interfaces/question';
import { HttpRequestService } from 'src/app/core/services';
import { RejectionReasonComponent } from 'src/app/shared/components/rejection-reason/rejection-reason.component';

@Component({
  selector: 'app-question-details',
  templateUrl: './question-details.component.html',
  styleUrls: ['./question-details.component.scss'],
})
export class QuestionDetailsComponent implements OnInit {
  @Input() value = {};
  @Input() bulkApprove = false;
  @Input() marks: number = 0;
  @Input() negativeMarks: number = 0;
  @Input() index: number = 0;
  @Input() isShowRejectOption: boolean = false;
  @Input() isShowMarkNagativeMark: boolean = true;
  @Input() component: string = '';

  questionDetails: any;
  mediaUploadUrl = environment.mediaBaseUrl;
  difficultyLevelObject: any = {};
  questionTypeObject: any = {};
  id: string = '';
  drawerRef!: NzDrawerRef<string>;
  modalForNormalQuestion: NzModalRef | null = null;
  questionMarks: number = 0;
  questionNegativeMarks: number = 0;
  questionIndex: number = 0;
  // questionGroups: any[] = [];
  showMore: boolean = false;
  paragraphId: any;
  componentFrom: string = '';

  constructor(
    private commonArrayService: CommonArrayService,
    private drawerReference: NzDrawerRef,
    private modalService: NzModalService,
    private commonService: CommonService,
    private httpRequestService: HttpRequestService
  ) {}

  ngOnInit(): void {
    this.questionMarks = this.marks;
    this.questionNegativeMarks = this.negativeMarks;
    this.questionIndex = this.index;
    this.difficultyLevelObject =
      this.commonArrayService.difficultyLevelObjectByValue;
    this.questionTypeObject = this.commonArrayService.questionTypeObjectByValue;
    this.componentFrom = this.component;
    this.questionDetails = this.value;
    console.log('questionDetails----', this.questionDetails);
    this.id = this.questionDetails._id ? this.questionDetails._id : '';
    this.paragraphId = this.questionDetails.paragraph
      ? this.questionDetails.paragraph
      : '';
    if (this.paragraphId) {
      const type = typeof this.paragraphId;
      type === 'string' ? this.getParagraph(this.paragraphId) : '';
    }
  }
  getParagraph(id: string) {
    this.httpRequestService.request('get', `paragraphs/${id}`).subscribe(
      (result: any) => {
        const data = result.data;

        this.questionDetails.paragraph = data;
      },
      (error: any) => {}
    );
  }

  openQuestion(element: any) {
    this.modalForNormalQuestion = this.modalService.create({
      nzTitle: 'Update Question',
      nzContent: AddUpdateQuestionComponent,
      nzWidth: '90%',
      nzFooter: null,
      nzComponentParams: {
        isFromOtherComponent: true,
        idForUpdate: element,
      },
      nzCancelText: null,
      nzOkText: null,
      nzCentered: true,
    });
    const questionService = this.commonService.$addQuestion.subscribe(
      (data: any) => {
        if (this.componentFrom === 'isFromQuestion') {
          this.drawerReference.close(data);
        }
        this.modalForNormalQuestion?.close();
        if (this.questionDetails._id === data._id) {
          this.questionDetails = data;
        }
        questionService.unsubscribe();
      }
    );
    const paragraphQuestionService =
      this.commonService.$addParagraphQuestion.subscribe((data: any) => {
        if (this.componentFrom === 'isFromQuestion') {
          this.drawerReference.close(data);
        }
        this.modalForNormalQuestion?.destroy();
        if (this.questionDetails._id === data._id) {
          this.questionDetails = data;
        }

        paragraphQuestionService.unsubscribe();
      });
  }
  onShow() {
    this.showMore = !this.showMore;
  }

  rejectQuestion(questionId: string): void {
    const pendingStatusData = 'rejected';
    const rejectionReason = this.modalService.create({
      nzTitle: 'Add A Reason',
      nzContent: RejectionReasonComponent,
      nzWidth: '800px',
      nzFooter: null,
      nzComponentParams: {
        updateId: questionId,
        component: 'questions',
      },
      nzCancelText: null,
      nzOkText: null,
    });

    rejectionReason.afterClose.subscribe((data: any) => {
      // delete data from the list
      this.questionDetails.approveStatus = pendingStatusData;
    });
  }
}
