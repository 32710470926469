import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExamsComponent } from './exams/exams.component';
import { AddUpdateExamsComponent } from './add-update-exams/add-update-exams.component';

const routes: Routes = [
  {
    path: '',
    component: ExamsComponent
  },
  {
    path: 'add',
    component: AddUpdateExamsComponent
  },
  {
    path: 'update/:id',
    component: AddUpdateExamsComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExamRoutingModule { }
