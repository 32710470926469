import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Chapter } from 'src/app/core/interfaces/chapter';
import { Subjects } from 'src/app/core/interfaces/subjects';
import { HttpRequestService } from 'src/app/core/services';

@Component({
  selector: 'app-add-update-chapter',
  templateUrl: './add-update-chapter.component.html',
  styleUrls: ['./add-update-chapter.component.scss'],
})
export class AddUpdateChapterComponent implements OnInit {
  addUpdateChapterForm: FormGroup;
  idForUpdate: string;
  buttonLoading = false;
  checkAddPermission = false;
  checkUpdatePermission = false;
  allSubjects: Subjects[] = [];
  search = '';
  pageSize = 50;
  pageIndex = 1;
  totalDataCount: any;
  searchSubject: Subject<string> = new Subject<string>();
  tabIndex: number = 0
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.tabIndex = this.activatedRoute.snapshot.queryParams.index;
    this.addUpdateChapterForm = this.fb.group({
      chapterName: [null, [Validators.required]],
      description: [null],
      subject: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    if (this.idForUpdate) {
      this.getChapterById();
    }
    this.searchSubject.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.search = success;
      this.getAllSubjects();
    });
  }
  loadMore() {
    // calculate skip
    this.pageIndex = this.pageIndex + 1;
    const skip = this.pageSize * (this.pageIndex - 1);
    if (this.totalDataCount !== this.allSubjects.length) {
      this.getAllSubjects(skip);
    } else {
    }
  }
  /* Get single chapter details by Id */
  getChapterById(): void {
    this.httpRequestService
      .request('get', `chapters/${this.idForUpdate}`)
      .subscribe(
        (result: any) => {
          const data: Chapter = result.data;
          this.addUpdateChapterForm.patchValue({
            chapterName: data.chapterName,
            subject: data.subject._id,
            description: data.description,
          });
        },
        (error: any) => {}
      );
  }

  /* Get all Subjects */
  getAllSubjects(skip = 0): void {
    let params: any;
    params = {
      skip,
      limit: this.pageSize,
      status: true,
      approveStatus: 'approved',
    };
    if (this.search) {
      params.search = this.search;
    }
    this.httpRequestService.request('get', 'subjects', params).subscribe(
      (result: any) => {
        if(this.search){
          this.allSubjects = result.data;
        }
        this.allSubjects.push(...result.data);
        this.totalDataCount = result.totalCount;
      },
      (err: any) => {}
    );
  }

  /* Submit chapter form */
  submit(): void {
    if (!this.addUpdateChapterForm.valid) {
      this.markFormGroupTouched(this.addUpdateChapterForm);
    } else {
      if (this.idForUpdate) {
        this.addOrUpdateChapter(
          'put',
          `chapters/${this.idForUpdate}`,
          'Chapter Successfully Updated'
        );
      } else {
        this.addOrUpdateChapter(
          'post',
          'chapters',
          'Chapter Added Successfully '
        );
      }
    }
  }

  /* Add Or Edit Chapter */
  addOrUpdateChapter(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    this.buttonLoading = true;
    const finalData: Chapter = this.addUpdateChapterForm.value;
    this.httpRequestService
      .request(requestMethod, requestURL, finalData)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          this.router.navigate(['/main/chapters'], { queryParams: {index: this.tabIndex } });
          this.buttonLoading = false;
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          } else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }

  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

  // seach Examination
  searchSubjectForDropdown(event: any): void {
    this.allSubjects = [
      
    ]
    this.searchSubject.next(event);
  }
}
