import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SunEditorOptions } from 'suneditor/src/options';
// Import katex
import katex from 'katex';
import {
  align,
  audio,
  blockquote,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  image,
  imageGallery,
  lineHeight,
  link,
  list,
  math,
  paragraphStyle,
  table,
  textStyle,
  video,
} from 'suneditor/src/plugins';

@Injectable({
  providedIn: 'root',
})
export class CkEditorConfigService {
  apiBaseUrl: any = environment.apiBaseUrl;
  mediaBaseUrl: any = environment.mediaBaseUrl;
  constructor() {}
  token = `${'Bearer ' + localStorage.getItem('token')}`;
  //=------------------------development--------------------//

  editorOptions: SunEditorOptions = {
    minWidth: '100%',
    height: '100',
    minHeight: '20px',
    katex: katex,
    plugins: [
      align,
      blockquote,
      font,
      fontColor,
      fontSize,
      formatBlock,
      hiliteColor,
      horizontalRule,
      image,
      imageGallery,
      lineHeight,
      link,
      list,
      math,
      paragraphStyle,
      table,
      textStyle,
    ],
    buttonList: [
      // default
      ['undo', 'redo'],
      [
        ':p-More Paragraph-default.more_paragraph',
        'font',
        'fontSize',
        'formatBlock',
        'paragraphStyle',
        'blockquote',
      ],
      ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
      ['fontColor', 'hiliteColor', 'textStyle'],
      ['removeFormat'],
      ['outdent', 'indent'],
      ['align', 'horizontalRule', 'list', 'lineHeight'],
      ['table', 'math'],
      ['image', 'link'],
      [
        ':i-More Misc-default.more_vertical',
        'fullScreen',
        'showBlocks',
        'codeView',
        'preview',
        'print',
        'save',
      ],
      // (min-width: 992)
      [
        '%992',
        [
          ['undo', 'redo'],
          [
            ':p-More Paragraph-default.more_paragraph',
            'font',
            'fontSize',
            'formatBlock',
            'paragraphStyle',
            'blockquote',
          ],
          ['bold', 'underline', 'italic', 'strike'],
          [
            ':t-More Text-default.more_text',
            'subscript',
            'superscript',
            'fontColor',
            'hiliteColor',
            'textStyle',
          ],
          ['removeFormat'],
          ['outdent', 'indent'],
          ['align', 'horizontalRule', 'list', 'lineHeight'],
          [
            ':i-More Misc-default.more_vertical',
            'fullScreen',
            'showBlocks',
            'codeView',
            'preview',
            'print',
            'save',
          ],
          [':r-More Rich-default.more_plus', 'table', 'link', 'image', 'math'],
        ],
      ],
      // (min-width: 767)
      [
        '%767',
        [
          ['undo', 'redo'],
          [
            ':p-More Paragraph-default.more_paragraph',
            'font',
            'fontSize',
            'formatBlock',
            'paragraphStyle',
            'blockquote',
          ],
          [
            ':t-More Text-default.more_text',
            'bold',
            'underline',
            'italic',
            'strike',
            'subscript',
            'superscript',
            'fontColor',
            'hiliteColor',
            'textStyle',
          ],
          ['removeFormat'],
          ['outdent', 'indent'],
          [
            ':e-More Line-default.more_horizontal',
            'align',
            'horizontalRule',
            'list',
            'lineHeight',
          ],
          [':r-More Rich-default.more_plus', 'table', 'link', 'image', 'math'],
          [
            '-right',
            ':i-More Misc-default.more_vertical',
            'fullScreen',
            'showBlocks',
            'codeView',
            'preview',
            'print',
            'save',
          ],
        ],
      ],
      // (min-width: 480)
      [
        '%480',
        [
          ['undo', 'redo'],
          [
            ':p-More Paragraph-default.more_paragraph',
            'font',
            'fontSize',
            'formatBlock',
            'paragraphStyle',
            'blockquote',
          ],
          [
            ':t-More Text-default.more_text',
            'bold',
            'underline',
            'italic',
            'strike',
            'subscript',
            'superscript',
            'fontColor',
            'hiliteColor',
            'textStyle',
            'removeFormat',
          ],
          [
            ':e-More Line-default.more_horizontal',
            'outdent',
            'indent',
            'align',
            'horizontalRule',
            'list',
            'lineHeight',
          ],
          [':r-More Rich-default.more_plus', 'table', 'link', 'image', 'math'],
          [
            '-right',
            ':i-More Misc-default.more_vertical',
            'fullScreen',
            'showBlocks',
            'codeView',
            'preview',
            'print',
            'save',
          ],
        ],
      ],
    ],
    charCounter : true,
    // value: 'Type here...',
    imageUploadHeader: {
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: this.token,
    },
    imageUploadUrl: `${this.apiBaseUrl}/api/media/from-editor`,
    imageMultipleFile: false,
    imageAccept: '*', // Accept file type
    imageUploadSizeLimit: 5000000, // 5MB
    imageAlignShow: true,
    imageFileInput: true,
    imageUrlInput: true,
  };
}
