import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonArrayService {
  difficultyLevel = [
    { label: 'Beginner', value: 'BEGINNER' },
    { label: 'Intermediate', value: 'INTERMEDIATE' },
    { label: 'Pro', value: 'PRO' },
  ];
  publishedStatus = [
    { label: 'Published', value: 'PUBLISHED' },
    { label: 'Up Coming', value: 'UPCOMING' },
  ];
  testsTypes = [
    { value: 'FULL_LENGTH_MOCK', label: 'Full Length Mock' },
    { value: 'PRACTICE_MOCK', label: 'Practice Mock' },
    { value: 'MEMORY_BASED_MOCK', label: 'Memory Based Mock' },
    { value: 'PREVIOUS_YEAR_PAPER', label: 'Previous Year Paper' },
    { value: 'SUBJECT_WISE', label: 'Subject Wise ' },
    { value: 'TOPIC_WISE', label: 'Topic Wise' },
  ];
  examsType = [
    { value: 'NORMAL', label: 'Normal' },
    { value: 'SEQUENTIAL', label: 'Sequential' },
  ];
  questionType = [
    { value: 'NORMAL', label: 'Normal' },
    { value: 'PARAGRAPH', label: 'Paragraph' },
  ];
  paidOrUnpaid = [
    { value: '', label: 'All' },
    { value: 'true', label: 'Paid' },
    { value: 'false', label: 'UnPaid' },
  ];

  difficultyLevelObjectByValue: any = {};
  testsTypesObjectByValue: any = {};
  examTypeObjectByValue: any = {};
  questionTypeObjectByValue: any = {};
  paidOrUnpaidObjectByValue: any = {};
  publishedStatusObjectByValue: any = {};

  constructor() {
    this.difficultyLevel.forEach((item: any) => {
      this.difficultyLevelObjectByValue[item.value] = item;
    });
    this.testsTypes.forEach((item: any) => {
      this.testsTypesObjectByValue[item.value] = item;
    });
    this.examsType.forEach((item: any) => {
      this.examTypeObjectByValue[item.value] = item;
    });
    this.questionType.forEach((item: any) => {
      this.questionTypeObjectByValue[item.value] = item;
    });
    this.publishedStatus.forEach((item: any) => {
      this.publishedStatusObjectByValue[item.value] = item;
    });

  }
}
