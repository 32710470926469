import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Exam } from 'src/app/core/interfaces/exam';
import { HttpRequestService } from 'src/app/core/services';

@Component({
  selector: 'app-add-update-exams',
  templateUrl: './add-update-exams.component.html',
  styleUrls: ['./add-update-exams.component.scss']
})
export class AddUpdateExamsComponent implements OnInit {
  addUpdateExamForm: FormGroup;
  idForUpdate: string;
  buttonLoading = false;
  allChapters: any;
  allExamCategories: any;
  checkAddPermission = false;
  checkUpdatePermission = false;
  search = '';
  searchChapter: Subject<any> = new Subject<any>();
  searchExamCategory: Subject<any> = new Subject<any>();
  tabIndex: number = 0

  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.tabIndex = this.activatedRoute.snapshot.queryParams.index;
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.addUpdateExamForm = this.fb.group({
      examName: [null, [Validators.required]],
      examCategory: [null, [Validators.required]],
      description: [null],
      status: ['true', [Validators.required]],

    });
  }

  ngOnInit(): void {
    // this.getAllChapters();
    this.getAllExamCategory();

    if (this.idForUpdate) {
      this.getExamById();
    }
    this.searchExamCategory.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.search = success;
      // this.getAllChapters();
    });
  }



  /* Get all Subjects */
  getAllExamCategory(): void {
    const params: any = {
      skip: 0, limit: 100, approveStatus: 'approved'
    };
    if (this.search) {
      params.search = this.search;
    }
    this.httpRequestService.request('get', 'exam-categories', params).subscribe(
      (result: any) => {
        this.allExamCategories = result.data;
      },
      (err: any) => { }
    );
  }

  /* Get single Exam details by Id */
  getExamById(): void {
    this.httpRequestService
      .request('get', `exams/${this.idForUpdate}`)
      .subscribe(
        (result: any) => {
          const data :Exam = result.data;
          this.addUpdateExamForm.patchValue({
            examName: data.examName,
            description: data.description,
            examCategory: data.examCategory._id,
          });
        },
        (error: any) => { }
      );
  }

  /* Submit Exam form */
  submit(): void {
    if (!this.addUpdateExamForm.valid) {
      this.markFormGroupTouched(this.addUpdateExamForm);
    } else {
      if (this.idForUpdate) {
        this.addOrUpdateExam(
          'put',
          `exams/${this.idForUpdate}`,
          'Exams Successfully Updated'
        );
      } else {
        this.addOrUpdateExam(
          'post',
          'exams',
          'Exams Added Successfully '
        );
      }
    }
  }

  /* Add Or Exam Concept */
  addOrUpdateExam(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    this.buttonLoading = true;
    const finalData : Exam = this.addUpdateExamForm.value;
    this.httpRequestService
      .request(requestMethod, requestURL, finalData)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          this.router.navigate(['/main/exam'], { queryParams: {index: this.tabIndex } });
          this.buttonLoading = false;
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          } else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }

  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

  // search ExamCategory
  searchExamCategoryForDropdown(event: any): void {
    this.searchExamCategory.next(event);
  }


}
