import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubjectRoutingModule } from './subject-routing.module';
import { SubjectsComponent } from './subjects/subjects.component';
import { AddUpdateSubjectComponent } from './add-update-subject/add-update-subject.component';
import { SubjectDetailsComponent } from './subject-details/subject-details.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    SubjectsComponent,
    AddUpdateSubjectComponent,
    SubjectDetailsComponent
  ],
  imports: [
    CommonModule,
    SubjectRoutingModule,
    SharedModule
  ]
})
export class SubjectModule { }
