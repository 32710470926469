import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { toNumber } from 'ng-zorro-antd/core/util';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Stories } from 'src/app/core/interfaces/stories';
import { HttpRequestService, LocalStorageService } from 'src/app/core/services';
import { RejectionReasonComponent } from 'src/app/shared/components/rejection-reason/rejection-reason.component';

@Component({
  selector: 'app-success-stories',
  templateUrl: './success-stories.component.html',
  styleUrls: ['./success-stories.component.scss']
})
export class SuccessStoriesComponent implements OnInit {
  allStories: Stories[] = [];
  allApprovedStories: Stories[] = [];
  isAddOrUpdate:boolean = true;
  totalDataCount = 0;
  totalApprovedDataCount = 0;
  pageSize = 12;
  pageIndex = 1;
  loading = false;
  searchStory: Subject<string> = new Subject<string>();
  search = '';
  userRole: string = ''
  subAdminAddedStories: Stories[] = [];
  index : number = 0;
  skipIndex : number = 0;
  snapShootIndex:number = 0;
  // listView = true;
  // isOpenDetails = false;
  // active: boolean = false;
  // deActive: boolean = true;
  // isSubjectComponent: boolean = true;

  constructor(
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private localStorageService: LocalStorageService,
    private modalService: NzModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.snapShootIndex = toNumber(this.activatedRoute.snapshot.queryParams.index) ;
    if(this.snapShootIndex){
      this.changeIndex(this.snapShootIndex)
    }
    this.userRole = this.localStorageService.getItem('user').role;
  }

  // on page load functions
  ngOnInit(): void {
    this.searchStory.pipe(debounceTime(1000)).subscribe((success: string) => {
      this.search = success;
      this.getAllStories(this.index);
    });
  }
  changeIndex(data: any) {
    this.router.navigate([], { queryParams: {index: data } });
    this.pageIndex = 1;
    this.pageSize = 12;
    this.allApprovedStories = []
    this.allStories = []
    this.index = data
      this.getAllStories();
  }

  /* Get all subjects */
  getAllStories(index: number = 0, skip = 0, sortBy?: any): void {
    index = index? index : this.index
    let params: any;
    params = { skip: this.skipIndex, limit: this.pageSize }
    if (this.search) {
      params.search = this.search;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if(index === 0){
      params.approveStatus = 'approved'
    }else if(index === 1 && this.userRole === 'admin'){
      params.approveStatus = "pending"
    }else if( index === 1 && this.userRole === 'subAdmin' ){
      params.approveStatus = ["pending", "rejected"]
    }else{
      delete params.approvedStatus
    }
    this.loading = true;
    this.httpRequestService.request('get', 'success-stories', params).subscribe(
      (result) => {
        this.loading = false;
        const data: Stories[] = result.data;
        if(index === 0){
          this.allApprovedStories = data;
          this.totalApprovedDataCount = result.totalCount;
        }else{
          this.allStories = data
          this.totalDataCount = result.totalCount;
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  /* For Pagination / Sending skip */
  onQueryParamsChange(params: NzTableQueryParams): void {
    this.allApprovedStories = []
    this.allStories = []
    const { pageSize, pageIndex} = params;
    this.skipIndex = pageSize * (pageIndex - 1)
    this.getAllStories(this.index, pageSize * (pageIndex - 1));
  }
  /* For Pagination / Sending skip for grid view*/
  onQueryParamsChangeForGridView(index: any): void {
    this.allApprovedStories = []
    this.allStories = []
    this.pageIndex = index;
    this.skipIndex = this.pageSize * (this.pageIndex - 1)
    this.getAllStories(this.index, this.pageSize * (this.pageIndex - 1));
  }
  onQueryPageSizeChangeForGridView(size: any) {
    this.allApprovedStories = []
    this.allStories = []
    this.pageSize = size;
    this.skipIndex = this.pageSize * (this.pageIndex - 1)
    this.getAllStories(this.index, this.pageSize * (this.pageIndex - 1));
  }

  /* Status change*/
  updateStatus(id: string, status: boolean): void {
    this.httpRequestService
      .request('put', `success-stories/active-inactive/${id}`, { status: !status })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllStories(this.index);
      });
  }
  updatePendingStatus(id: string, pendingStatus: string) {
    this.httpRequestService
      .request('put', `success-stories/approved-rejected/${id}`, {
        approveStatus: 'approved',
      })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllStories(this.index);
      });
  }
  openRejectionReason(id: string, pendingStatus: string) {
      const pendingStatusData = 'rejected';
      const rejectionReason = this.modalService.create({
        nzTitle: 'Add A Reason',
        nzContent: RejectionReasonComponent,
        nzWidth: '800px',
        nzFooter: null,
        nzComponentParams: {
          updateId: id,
          component: 'success-stories',
        },
        nzCancelText: null,
        nzOkText: null,
      });

    const questionService = rejectionReason.afterClose.subscribe(
      (data: any) => {
        this.getAllStories(this.index);
       ;
      }
    );
  }
  // Seach subject
  searchStorys(event: any): void {
    this.searchStory.next(event);
  }
}
