import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TestComponent } from './test/test.component';
import { AddUpdateTestComponent } from './add-update-test/add-update-test.component';
import { TestDetailsComponent } from './test-details/test-details.component';

const routes: Routes = [
  {
    path: '',
    component: TestComponent
  },
  {
    path: 'add',
    component: AddUpdateTestComponent
  },
  {
    path: 'update/:id',
    component: AddUpdateTestComponent
  },
  {
    path: 'detail/:id',
    component: TestDetailsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TestRoutingModule { }
