import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-sidebar-layout',
  templateUrl: './no-sidebar-layout.component.html',
  styleUrls: ['./no-sidebar-layout.component.scss'],
})
export class NoSidebarLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
