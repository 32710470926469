<div class="site-page-header-ghost-wrapper">
    <nz-page-header [nzGhost]="false">
        <nz-page-header-title class="ml4">Success Stories </nz-page-header-title>
        <nz-page-header-extra class="mr4">
            <button nz-button nzType="primary" [routerLink]="['add']" [queryParams]="{index: index}" routerLinkActive="router-link-active"
                class="submitoraddbutton">
                Add Success Story
            </button>
        </nz-page-header-extra>
    </nz-page-header>
</div>
<div nz-row nzJustify="end" class="mt4">
    <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24" class="mb5">
        <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input placeholder="Search by story name" ngModel
                (ngModelChange)="searchStorys($event)" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <i nz-icon nzType="search"></i>
        </ng-template>
    </div>
</div>
<nz-tabset (nzSelectedIndexChange)="changeIndex($event)" [nzSelectedIndex]="index">
    <nz-tab nzTitle="Approved Stories">
        <nz-table #storyTbl [nzData]="allApprovedStories" [nzLoading]="loading" [nzTotal]="totalApprovedDataCount"
            [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex" [nzFrontPagination]="false"
            (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger [nzScroll]="{ x: '900px' }">
            <thead>
                <tr>
                    <th nzColumnKey="name" [nzSortFn]="true">User Name</th>
                    <th>Description</th>
                    <th>ExamCategory</th>
                    <th>Rating</th>
                    <th>Created On</th>
                    <th nzWidth="100px">Status</th>
                    <th class="text-right" nzWidth="150px">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of storyTbl.data; index as i">
                    <td><span *ngIf="data.name"> {{ data.name }} </span></td>
                    <td> <app-mathjax *ngIf="data.description" [content]=" data.description "
                            class="{{data.description? 'ellipsis' : ''}}"></app-mathjax></td>
                    <td><span *ngIf="data.examCategory "> {{ data.examCategory.categoryName }}</span></td>
                    <td><span *ngIf="data.starCount "> {{ data.starCount }}<span nz-icon nzType="star"
                                nzTheme="fill"></span></span></td>
                    <td><span *ngIf="data.createdAt">{{ data.createdAt | date : "mediumDate" }}</span></td>

                    <td><span class="{{data.status? 'active' : 'inactive' }} status"></span></td>
                    <td class="action-buttons">
                        <a nz-button  nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
                            [routerLink]="['update', data._id]" [queryParams]="{index: index}" routerLinkActive="router-link-active"><i nz-icon
                                nzType="form" nzTheme="outline"></i></a>
                        <a *ngIf="userRole !== 'subAdmin'" class="action-button" nz-button nz-popconfirm
                            nzPopconfirmTitle="Are you sure {{data.status ? 'deActive' : 'active'}} this task?"
                            (nzOnConfirm)="updateStatus(data._id, data.status)"
                            nzTooltipTitle="{{data.status ? 'Deactive' : 'Active'}}" nzTooltipPlacement="topRight"
                            nz-tooltip><i nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </nz-tab>

    <nz-tab nzTitle="Un-approved Stories ">
        <nz-table #storyTbl [nzData]="allStories" [nzLoading]="loading" [nzTotal]="totalDataCount"
            [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex" [nzFrontPagination]="false"
            (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger [nzScroll]="{ x: '900px' }">
            <thead>
                <tr>
                    <th nzColumnKey="name" [nzSortFn]="true">User Name</th>
                    <th>Description</th>
                    <th>ExamCategory</th>
                    <th>Rating</th>
                    <th>Created On</th>
                    <th>Created By</th>
                    <th nzWidth="200px">Created By</th>
                    <th nzWidth="100px">Status</th>
                    <th class="text-right" nzWidth="150px">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of allStories; index as i">
                    <td><span *ngIf="data.name"> {{ data.name }} </span></td>
                    <td> <app-mathjax *ngIf="data.description" [content]=" data.description "
                            class="{{data.description? 'ellipsis' : ''}}"></app-mathjax></td>
                    <td><span *ngIf="data.examCategory "> {{ data.examCategory.categoryName }}</span></td>
                    <td><span *ngIf="data.starCount "> {{ data.starCount }}<span nz-icon nzType="star"
                                nzTheme="fill"></span></span></td>
                    <td><span *ngIf="data.createdAt">{{ data.createdAt | date : "mediumDate" }}</span></td>
                    <td><span *ngIf="data.createdBy?.role === 'subAdmin'">
                            {{ data?.createdBy?.name }}
                        </span></td>
                        <td><span *ngIf="data.rejectionReason" class="ellipsis">{{data.rejectionReason}}</span></td>
                    <td>
                        <nz-tag
                            [nzColor]="data.approveStatus === 'approved' ? 'green':  data.approveStatus === 'pending'? 'lime':'volcano' ">
                            {{data.approveStatus}}

                        </nz-tag>
                    </td>
                    <!-- <td><span class="{{data.status? 'active' : 'inactive' }} status"></span></td> -->
                    <td class="action-buttons">
                        <a nz-button  nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
                            [routerLink]="['update', data._id]" [queryParams]="{index: index}" routerLinkActive="router-link-active"><i nz-icon
                                nzType="form" nzTheme="outline"></i></a>
                        <a *ngIf="userRole !== 'subAdmin'" class="action-button" nz-button nz-popconfirm
                            nzPopconfirmTitle="Are you sure {{data.status ? 'deActive' : 'active'}} this Story?"
                            (nzOnConfirm)="updateStatus(data._id, data.status)"
                            nzTooltipTitle="{{data.status ? 'Deactive' : 'Active'}}" nzTooltipPlacement="topRight"
                            nz-tooltip><i nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>
                        <a *ngIf="userRole !== 'subAdmin' " class="action-button top-margin" nz-button nz-popconfirm
                            nzPopconfirmTitle="Are you sure Approved this Story?"
                            (nzOnConfirm)="updatePendingStatus(data._id, data.approveStatus)"
                            nzTooltipTitle="{{'Approve'}}" nzTooltipPlacement="topRight" nz-tooltip><i nz-icon nzType="file-done" nzTheme="outline"></i></a>
                        <a *ngIf="userRole !== 'subAdmin' && data.approveStatus !== 'rejected'"
                            class="action-button top-margin delete" nz-button nz-popconfirm
                            nzPopconfirmTitle="Are you sure Reject this Story?"
                            (nzOnConfirm)="openRejectionReason(data._id, data.approveStatus)" nzTooltipTitle='Reject'
                            nzTooltipPlacement="topRight" nz-tooltip><i nz-icon [nzType]="'close-circle'"
                                nzTheme="fill"></i></a>

                    </td>
                </tr>
            </tbody>
        </nz-table>
    </nz-tab>
</nz-tabset>

<!-- <div *ngIf=" !listView">
    <app-common-grid-view [list]="allSubjects" [isAddOrUpdate]="isAddOrUpdate" [isSubjectComponent]="isSubjectComponent"
        [isOpenDetails]="isOpenDetails" [loading]="loading"></app-common-grid-view>
    <div class="pagination">
        <nz-pagination [nzPageIndex]="pageIndex" [nzTotal]="totalDataCount" nzShowSizeChanger
            (nzPageSizeChange)="onQueryPageSizeChangeForGridView($event)" [(nzPageSize)]="pageSize"
            (nzPageIndexChange)="onQueryParamsChangeForGridView($event)"></nz-pagination>
    </div>
</div> -->