import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HttpRequestService } from 'src/app/core/services';
import { CommonArrayService } from 'src/app/core/services/common-array.service';
import { environment } from 'src/environments/environment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Test } from 'src/app/core/interfaces/test';
import { ExamCategory } from 'src/app/core/interfaces/exam-category';
import { LocalArray } from 'src/app/core/interfaces/local-array';
import { Exam } from 'src/app/core/interfaces/exam';
import { TestSeries } from 'src/app/core/interfaces/test-series';
@Component({
  selector: 'app-add-update-dashboard-config',
  templateUrl: './add-update-dashboard-config.component.html',
  styleUrls: ['./add-update-dashboard-config.component.scss']
})
export class AddUpdateDashboardConfigComponent implements OnInit  {
  addUpdateFeatureCategoriesForm: FormGroup;
  idForUpdate: string;
  buttonLoading = false;
  allExamCategories: ExamCategory[] = [];
  checkAddPermission = false;
  checkUpdatePermission = false;
  search = '';
  searchExam: Subject<string> = new Subject<string>();
  searchExamCategory: Subject<string> = new Subject<string>();
  allTestTypes: LocalArray[] = [];
  allLevels: LocalArray[] = [];
  time: Date | null = null;
  defaultOpenValue: any;
  selectedExamCategory: any[] = [];
  loading:boolean = false;
  filterExamCategory: ExamCategory[] = [];
  totalDataCount: number = 0 ;
  switchValue: boolean = false;
  mediaBaseUrl = environment.mediaBaseUrl;
  mediaUploadUrl = environment.apiBaseUrl + '/api/media';
  // searchExamCategoryValue: string='';
  examSearch:string = '';
  examCategorySearch:string = '';
  topicSearch:string = '';
  newSelectedExamCategories: ExamCategory[] = [];
  pageIndex:number = 1;
  pageSize:number = 100;
  limit:boolean = false
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private msg: NzMessageService,
    private commonArrayService: CommonArrayService
  ) {
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.addUpdateFeatureCategoriesForm = this.fb.group({
      featureCategory: [''],
    });
  }

  ngOnInit(): void {
    this.getAllFeatureCategory();
    this.getAllExamsCategory();

    // if (this.idForUpdate) {
    //   this.getCategoryById();
    // }
  }
  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.selectedExamCategory, event.previousIndex, event.currentIndex)
    this.newSelectedExamCategories = [...this.selectedExamCategory]
  }
  /* Get all exam-category */
  getAllExamsCategory(skip = 0, sortBy?: any): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
    };
    if (this.examCategorySearch) {
      params.search = this.examCategorySearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'exam-categories', params).subscribe(
      (result: any) => {
        let data:ExamCategory[] = result.data
        this.loading = false;
        this.allExamCategories.push(...data);
        this.totalDataCount = result.totalCount;
        if(this.allExamCategories.length === this.totalDataCount){
          this.limit = true
        }
        this.filterSectionValues();
      },
      (err: any) => {}
    );
  }

  // Question remove
  removeCategories(): void {
    const removedItems = this.selectedExamCategory.filter((x) => x.isChecked);
    let allItemsToBeRemoved: any[] = [...removedItems];
    this.selectedExamCategory = this.selectedExamCategory.filter(
      (item: any) =>
        !allItemsToBeRemoved.map((rmItem: any) => rmItem._id).includes(item._id)
    );
    this.newSelectedExamCategories = [...this.selectedExamCategory]
    this.filterSectionValues();
  }
  // Add Normal question
  addCategories(): void {
    this.selectedExamCategory = [
      ...this.selectedExamCategory,
      ...this.filterExamCategory
        .filter((x: any) => x.isChecked)
        .map((x: any, index:number) => ({ ...x, isChecked: false, key: index  })),
    ];
    this.newSelectedExamCategories = [...this.selectedExamCategory];
  
    this.filterSectionValues();
  }
  filterSectionValues() {
    this.filterExamCategory = this.allExamCategories
      .filter((item: any) => {
        return !this.selectedExamCategory.map((q: any) => q._id).includes(item._id);
      })
      .map((item: any) => ({ ...item, isChecked: false }));
  }

  // /* Get single concept details by Id */
  getAllFeatureCategory(): void {
    this.httpRequestService
      .request('get', 'dashboards/feature-categories')
      .subscribe(
        (result: any) => {
          const data = result.data[0].featureCategory
          this.selectedExamCategory.push(...data);
          this.totalDataCount = result.totalCount;
          if(this.selectedExamCategory.length === this.totalDataCount){
            this.limit = true
          }
          this.filterSectionValues()
          this.newSelectedExamCategories = [...this.selectedExamCategory]
        },
        (error: any) => {}
      );
      
  }

  /* Submit concept form */
  submit(): void {
    if (!this.addUpdateFeatureCategoriesForm.valid) {
      this.markFormGroupTouched(this.addUpdateFeatureCategoriesForm);
    } else {
      if (this.idForUpdate) {
        this.addOrUpdateCategories(
          'put',
          `dashboards/feature-categories/${this.idForUpdate}`,
          'Feature Category Successfully Updated'
        );
      } else {
        this.addOrUpdateCategories(
          'put',
          'dashboards/feature-categories',
          'Feature Category Added Successfully '
        );
      }
    }
  }

  /* Add Or Edit Concept */
  addOrUpdateCategories(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    this.addUpdateFeatureCategoriesForm.patchValue({
      featureCategory: this.selectedExamCategory.map((data: any) => data._id),
    });
    if (this.selectedExamCategory && !this.selectedExamCategory.length) {
      this.notificationService.error('', 'Add some Category');
      return;
    }
    this.buttonLoading = true;
    const finalData :TestSeries = this.addUpdateFeatureCategoriesForm.value
    this.httpRequestService
      .request(requestMethod, requestURL, finalData)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          // this.router.navigateByUrl('/main/test-series');
          this.buttonLoading = false;
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          } else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }

  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }
      /* For Pagination / Sending skip */
      examCategoryPagination(): void {
        this.pageIndex = this.pageIndex + 1
        this.getAllExamsCategory(this.pageSize * (this.pageIndex - 1));
      }
}

