<form [formGroup]="rejectionReasonForm">
    <div nz-row>
        <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
            <nz-form-item>
                <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                    <nz-form-label [nzSpan]="24" nzFor="description">Reject Reason</nz-form-label>
                    <!-- <ckeditor #editor id="description" name="description" formControlName="description"
                            type="divarea">
                        </ckeditor> -->
                    <textarea nz-input placeholder="Add a Reason for Rejection" formControlName="rejectionReason"
                        [nzAutosize]="{ minRows: 3 }"></textarea>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <div nz-row nzJustify="end">
        <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()" class="submitoraddbutton">
            {{idForUpdate? 'Update':'Submit'}}
        </button>
    </div>
</form>