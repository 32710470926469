import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Chapter } from 'src/app/core/interfaces/chapter';
import { LocalArray } from 'src/app/core/interfaces/local-array';
import { Question } from 'src/app/core/interfaces/question';
import { Subjects } from 'src/app/core/interfaces/subjects';
import { Topic } from 'src/app/core/interfaces/topic';
import { HttpRequestService } from 'src/app/core/services';
import { CkEditorConfigService } from 'src/app/core/services/ck-editor-config.service';
import { CommonArrayService } from 'src/app/core/services/common-array.service';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';
import { ClassicEditor } from '../../../../../node_modules/ckeditor5';
import {
  Bold,
  SelectAll,
  Essentials,
  Clipboard,
  FindAndReplace,
  Highlight,
  CodeBlock,
  Heading,
  TableCaption,
  Alignment,
  SourceEditing,
  TableColumnResize,
  Undo,
  Image,
  ImageUpload,
  ImageTextAlternative,
  ImageCaption,
  ImageResize,
  ImageToolbar,
  ImageStyle,
  ImageResizeEditing,
  ImageResizeButtons,
  TableToolbar,
  SimpleUploadAdapter,
  HorizontalLine,
  Mention,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Font,
} from '../../../../../node_modules/ckeditor5';

@Component({
  selector: 'app-single-question',
  templateUrl: './single-question.component.html',
  styleUrls: ['./single-question.component.scss']
})
export class SingleQuestionComponent implements OnInit  {
  editorContent = '';
  token = 'Bearer ' + localStorage.getItem('token');
  @Input() isFromOtherComponent: boolean = false
  @Input() addUpdateSingleQuestionForm: FormGroup | null = null;
  fileList: NzUploadFile[] = [];
  @Input() idForUpdate: string;
  buttonLoading = false;
  allSubjects: Subjects[] = [];
  allChapters: Chapter[] = [];
  allTopics: Topic[] = [];
  allLevels: LocalArray[] = [];
  checkAddPermission = false;
  checkUpdatePermission = false;
  mediaBaseUrl = environment.mediaBaseUrl;
  mediaUploadUrl = environment.apiBaseUrl + '/api/media';
  searchChapter: Subject<string> = new Subject<string>();
  searchSubject: Subject<string> = new Subject<string>();
  searchTopic: Subject<string> = new Subject<string>();
  chaptarId = '';
  subjectId = '';
  checked: boolean = false;
  paragraphDetails: any;
  subjectSearch = '';
  chapterSearch = '';
  topicSearch = '';
  search = '';
  typeChange: boolean = false;
  apiBaseUrl: any = environment.apiBaseUrl;
  // mediaBaseUrl: any = environment.mediaBaseUrl; 
  // ck editor 
  name = 'test';
  public ckeConfig: any;
  log: string = '';
  testForm: boolean = false;
  public Editor: any = ClassicEditor;
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private msg: NzMessageService,
    private router: Router,
    private commonArrayService: CommonArrayService,
    private commonService: CommonService,
    private ckEditorService: CkEditorConfigService
  ) {
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
  }
  onChange(data: any){

  }
  ngOnInit(): void {
    // config of ck editor
    this.testForm = this.isFromOtherComponent
    const token = `${'Bearer ' + localStorage.getItem('token')}`;
    this.ckeConfig = this.ckEditorService.getConfig()
    this.ckeConfig.height = 40;

    this.allLevels = this.commonArrayService.difficultyLevel;
    this.getAllChapters();
    this.getAllSubjects();
    this.getAllTopics();

    if (this.idForUpdate) {
      this.getQuestionsById();
    } else {
      this.addOption();
      this.addOption();
      this.addOption();
      this.addOption();
    }
    this.searchChapter.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.chapterSearch = success;
      this.getAllChapters()
    });
    this.searchSubject.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.subjectSearch = success;
      this.getAllSubjects()
    });
    this.searchTopic.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.topicSearch = success;
      this.getAllTopics();
    });
  }
  ngOnChanges(){
  }
  // send auth in headers //
  customRequestHeaders = () => {
    return { Authorization: `Bearer ${localStorage.getItem('token')}` };
  };
  /* Get all Subjects */
  getAllSubjects(): void {
    const params: any = {
      skip: 0,
      limit: 100, status:true,
      approveStatus: 'approved'
    };
    if (this.subjectSearch) {
      params.search = this.subjectSearch;
    }else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'subjects', params).subscribe(
      (result: any) => {
        this.allSubjects = result.data;
      },
      (err: any) => { }
    );
  }
  /* On change subject get chapter */
  onChangeSubject(value: string, skip = 0): void {
    if (this.addUpdateSingleQuestionForm?.value && this.addUpdateSingleQuestionForm?.value.subject) {
      this.allChapters = [];
      this.allTopics = [];
      this.addUpdateSingleQuestionForm?.patchValue({
        chapter: null,
        topic: null, 
      });
      if (this.addUpdateSingleQuestionForm?.value.subject) {
        this.getAllChapters();
        this.getAllTopics();
      }
    }
  }

  /* Get all chapters */
  getAllChapters(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved'
    };
    if (this.addUpdateSingleQuestionForm?.value.subject) {
      params['subject'] = this.addUpdateSingleQuestionForm?.value.subject;
    } else {
      delete params.subject;
    }
    if (this.chapterSearch) {
      params.search = this.chapterSearch;
    }else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'chapters', params).subscribe(
      (result: any) => {
        //;

        this.allChapters = result.data;
      },
      (err: any) => { }
    );
  }

  /* On change chapter get course */
  onChangeChapter(value: string, skip = 0): void {
    if (this.addUpdateSingleQuestionForm?.value && this.addUpdateSingleQuestionForm?.value.chapter) {

      this.allTopics = [];
      this.addUpdateSingleQuestionForm?.patchValue({
        topic: null,
      });
      if (this.addUpdateSingleQuestionForm?.value.chapter) {
        this.getAllTopics();
      }
    }
  }

  /* Get all topics */
  getAllTopics(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved'
    };
    if (this.addUpdateSingleQuestionForm?.value.subject) {
      params['subject'] = this.addUpdateSingleQuestionForm?.value.subject;
    } else {
      delete params.subject;
    }
    if (this.addUpdateSingleQuestionForm?.value.chapter) {
      params['chapter'] = this.addUpdateSingleQuestionForm?.value.chapter;
    } else {
      delete params.search;
    }
    if (this.topicSearch) {
      params.search = this.topicSearch;
    }else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'topics', params).subscribe(
      (result: any) => {
        this.allTopics = result.data;
      },
      (err: any) => { }
    );
  }

  /* Get single concept details by Id */
  getQuestionsById(): void {
    this.httpRequestService
      .request('get', `questions/${this.idForUpdate}`)
      .subscribe(
        (result: any) => {
          const questionData = result.data;
          this.paragraphDetails = questionData.paragraph;
          if (questionData.questionType === 'PARAGRAPH') {
            this.typeChange = true
           
          }
          this.addUpdateSingleQuestionForm?.patchValue({
            subject: questionData.subject._id? questionData.subject._id : '',
            questionType: questionData.questionType ? questionData.questionType : '',
            difficultyLevel: questionData.difficultyLevel ? questionData.difficultyLevel : '',
            questionText: questionData.questionText ? questionData.questionText : '',
            solution: questionData.solution ? questionData.solution : '',
            

            // questionText: result.data.questionText,
            // examCategory: result.data.examCategory._id,
          });
          if (questionData.chapter != null) {
            this.addUpdateSingleQuestionForm?.patchValue({
              chapter: questionData.chapter._id,
            });
          }
          if (questionData.topic != null) {
            this.addUpdateSingleQuestionForm?.patchValue({
              topic: questionData.topic._id,
            });
          }
          if (questionData.serialNo != null) {
            this.addUpdateSingleQuestionForm?.patchValue({
              serialNo: questionData.serialNo,
            });
          }
          questionData.options.forEach((element: any) => {
            this.optionsFormArray.push(this.createOption(element));
          });
        },
        (error: any) => { }
      );
  }
  // options Form Array
  createOption(item?: any): FormGroup {
    if (item) {
      return this.fb.group({
        optionText: [item.optionText ||  ''],
        isCorrectOption: [item.isCorrectOption],
        loadingButton: [false],
      });
    }
    return this.fb.group({
      optionText: [null],
      isCorrectOption: [null],
      loadingButton: [false],
    });
  }

  // for tutor form array
  get optionsFormArray(): FormArray<any> {
    return this.addUpdateSingleQuestionForm?.get('options') as FormArray;
  }

  // Add question
  addOption(): void {
    this.optionsFormArray.push(this.createOption());
  }

  // Remove
  removeOption(index: number): void {
    this.optionsFormArray.removeAt(index);
  }
  //Update time (single get)

  

  // seach Chapter
  searchChapterForDropdown(event: any): void {
      this.searchChapter.next(event);
  }
  // seach Subject
  searchSubjectForDropdown(event: any): void {
      this.searchSubject.next(event);
  }
  // seach Topic
  searchTopicForDropdown(event: any): void {
      this.searchTopic.next(event);
  }
}
