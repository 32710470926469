<nz-page-header *ngIf="!isFromOtherComponent" class="site-page-header" nzBackIcon
  [nzTitle]="idForUpdate ? 'Update Paragraph' : 'Add Paragraph'">
</nz-page-header>
<nz-card class="minumum-space-form">
  <nz-steps [nzCurrent]="current">
    <nz-step nzTitle="Add Paragraph"></nz-step>
    <nz-step nzTitle="Add Question"></nz-step>
    <!-- <nz-step nzTitle="Done"></nz-step> -->
  </nz-steps>
  <form nz-form [formGroup]="addUpdateParagraphTextForm" *ngIf="current === 0">
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
      <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-control [nzSpan]="24" nzErrorTip="Please select your subject!">
            <nz-form-label nzFor="chapter">Subject</nz-form-label>
            <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" formControlName="subject"
              nzPlaceHolder="Select a subject" (nzOnSearch)="searchSubjectForDropdown($event)"
              (ngModelChange)="onChangeSubjectInParagraph()">
              <nz-option *ngFor="let item of allSubjects" nzValue="{{ item._id }}"
                nzLabel="{{ item.subjectName }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-control [nzSpan]="24" nzErrorTip="Please select your chapter!">
            <nz-form-label nzFor="chapter">Chapter</nz-form-label>
            <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" formControlName="chapter"
              nzPlaceHolder="Select a Chapter" (nzOnSearch)="searchChapterForDropdown($event)"
              (ngModelChange)="onChangeChapterInParagraph()">
              <nz-option *ngFor="let item of allChapters" nzValue="{{ item._id }}"
                nzLabel="{{ item.chapterName }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-control [nzSpan]="24" nzErrorTip="Please select your subject!">
            <nz-form-label nzFor="chapter">Topic</nz-form-label>
            <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" formControlName="topic"
              nzPlaceHolder="Select a Topic" (nzOnSearch)="searchTopicForDropdown($event)">
              <nz-option *ngFor="let item of allTopics" nzValue="{{ item._id }}"
                nzLabel="{{ item.topicName }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
            <nz-form-label [nzSpan]="24" nzFor="description">Paragraph Text</nz-form-label>
            <!-- <ckeditor #editor id="paragraphText" name="paragraphText" formControlName="name" type="divarea">
            </ckeditor> -->
            <div *ngIf="isEditorVisible">
              <ngx-suneditor [options]="editorOptions" [editorID]="'paragraphName'" (onChange)="onParagraphNameChange($event)">
              </ngx-suneditor>
            </div>
          </nz-form-control>
        </nz-form-item>
      </div>

    </div>

  </form>

  <div *ngIf="current === 1">
    <app-bulk-question [questionType]="'PARAGRAPH'" [subject]="subjectId" [chapter]="chapterId" [topic]="topicId"
      [paragraph]="paragraphId" [isFromOtherComponent]='isFromOtherComponent'></app-bulk-question>
  </div>
</nz-card>

<div class="steps-action">
  <button nz-button nzType="default" (click)="pre()" *ngIf="current > 0">
    <span>Previous</span>
  </button>
  <button nz-button nzType="default" (click)="next()" *ngIf="current < 1" [nzLoading]="Loading">
    <span>Next</span>
  </button>
</div>