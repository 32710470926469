import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { toNumber } from 'ng-zorro-antd/core/util';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ExamCategory } from 'src/app/core/interfaces/exam-category';
import { HttpRequestService, LocalStorageService } from 'src/app/core/services';
import { RejectionReasonComponent } from 'src/app/shared/components/rejection-reason/rejection-reason.component';

@Component({
  selector: 'app-exams-categories',
  templateUrl: './exams-categories.component.html',
  styleUrls: ['./exams-categories.component.scss']
})
export class ExamsCategoriesComponent implements OnInit {
  allExamCategories: ExamCategory[] = [];
  allApprovedExamCategories: ExamCategory[] = [];
  totalDataCount = 0;
  totalApprovedDataCount = 0;
  isAddOrUpdate:boolean = true;
  pageSize = 12;
  pageIndex = 1;
  loading = false;
  searchConcept: Subject<string> = new Subject<string>();
  search = '';
  checkAddPermission = false;
  checkUpdatePermission = false;
  isOpenDetails = false;
  listView = true;
  active:boolean = false;
  deActive: boolean = true;
  isExamCategoryComponent: boolean = true;
  userRole: string = ''
  subAdminAddedCategories:ExamCategory[] = [];
  index : number = 0 ;
  skipIndex : number = 0 ;
  snapShootIndex : number = 0 ;
  constructor(
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private localStorageService: LocalStorageService,
    private modalService: NzModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { 
    this.snapShootIndex = toNumber(this.activatedRoute.snapshot.queryParams.index) ;
    if(this.snapShootIndex){
      this.changeIndex(this.snapShootIndex)
    }
    this.userRole = this.localStorageService.getItem('user').role;
  }

  ngOnInit(): void {
    this.searchConcept.pipe(debounceTime(1000)).subscribe((success) => {
      this.search = success;
      this.getAllExamCategory(this.index);
    });
  }
  changeIndex(data: any) {
    this.router.navigate([], { queryParams: {index: data } });
    this.pageSize = 12;
    this.pageIndex = 1;
    this.allApprovedExamCategories = [];
    this.allExamCategories = [];
    this.index = data
      this.getAllExamCategory();
  }
  changeGridView() {
    this.listView = false;
    this.active = true;
    this.deActive = false;
  }
  changeListView() {
    this.listView = true;
    this.active = false;
    this.deActive = true;
  }
    /* Get all concepts */
    getAllExamCategory(index: number = 0, skip = 0, sortBy?: any): void {
      index = index? index : this.index
      let params: any;
      params = { skip: this.skipIndex, limit: this.pageSize };
      if (this.search) {
        params.search = this.search;
      }
      if (sortBy) {
        params.sortBy = JSON.stringify(sortBy);
      }
      if(index === 0){
        params.approveStatus = 'approved'
      }else if(index === 1 && this.userRole === 'admin'){
        params.approveStatus = "pending"
      }else if( index === 1 && this.userRole === 'subAdmin' ){
        params.approveStatus = ["pending", "rejected"]
      }else{
        delete params.approvedStatus
      }
      this.loading = true;
      this.httpRequestService.request('get', 'exam-categories', params).subscribe(
        (result) => {
          this.loading = false;
          const data : ExamCategory[] = result.data;
          if(index === 0 ){
            this.allApprovedExamCategories = data;
            this.totalApprovedDataCount = result.totalCount;
          }else{
            this.allExamCategories = data
            this.totalDataCount = result.totalCount;
          }
        },
        (err) => {
          this.loading = false;
        }
      );
    }
  
    /* For Pagination / Sending skip */
    onQueryParamsChange(params: NzTableQueryParams): void {
      this.allApprovedExamCategories = [];
      this.allExamCategories = [];
      const { pageSize, pageIndex } = params;
      this.skipIndex = pageSize * (pageIndex - 1)
      this.getAllExamCategory(this.index, pageSize * (pageIndex - 1));
    }
     /* For Pagination / Sending skip */
  onQueryParamsChangeForGridView(index: any): void {
    this.allApprovedExamCategories = [];
    this.allExamCategories = [];
    // const { pageSize, pageIndex} = params;
    this.pageIndex = index;
    this.getAllExamCategory(this.index, this.pageSize * (this.pageIndex - 1));
  }
  onQueryPageSizeChangeForGridView(size: any){
    this.allApprovedExamCategories = [];
    this.allExamCategories = [];
    this.pageSize = size;
    this.getAllExamCategory(this.index, this.pageSize * (this.pageIndex - 1));
  }
  
    /* status change*/
    updateStatus(id: string, status: boolean): void {
      this.httpRequestService
        .request('put', `exam-categories/active-inactive/${id}`, { status: !status })
        .subscribe((result: any) => {
          this.notificationService.success('', 'Status Updated Successfully');
          this.getAllExamCategory(this.index);
        });
    }
    updatePendingStatus(id: string, pendingStatus: string) {
      this.httpRequestService
        .request('put', `exam-categories/approved-rejected/${id}`, {
          approveStatus: 'approved',
        })
        .subscribe((result: any) => {
          this.notificationService.success('', 'Status Updated Successfully');
          this.getAllExamCategory(this.index);
        });
    }
    openRejectionReason(id: string, pendingStatus: string) {
        const pendingStatusData = 'rejected';
        const rejectionReason = this.modalService.create({
          nzTitle: 'Add A Reason',
          nzContent: RejectionReasonComponent,
          nzWidth: '800px',
          nzFooter: null,
          nzComponentParams: {
            updateId: id,
            component: 'exam-categories',
          },
          nzCancelText: null,
          nzOkText: null,
        });
  
      const questionService = rejectionReason.afterClose.subscribe(
        (data: any) => {
          this.getAllExamCategory(this.index);
        }
      );
    }
    // search concept
    searchConcepts(event: any): void {
      this.searchConcept.next(event);
    }


}
