<div class="price-height">
    <nz-card class="min-height card-color ">
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
            <div nz-col nzLg="16" nzMd="16" nzSm="24" nzXs="24" class="details-height">
                <h2 class="text-center color">{{planDetails.name}}</h2>
                <br>

                <h5 [innerHTML]=" planDetails.description ">{{planDetails.description}}</h5>
            </div>
            <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24" class="price-height ">
                <nz-card class="plan-card">
                    <div class="plan-color plan-padding">
                        <h1 class="text-center"><b>₹{{planDetails.price}}</b></h1>
                        <p class="text-center">
                            <b>Per Plan</b>
                        </p>
                    </div>
                    <div class="padding-ul">
                        <ul>
                            <li>
                                <h5>
                                    <b>Plan Duration</b> :<p *ngIf="planDetails.duration">{{planDetails.duration}}</p>
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    <b>Total Test-Series</b> :{{planDetails.testSeries && planDetails.testSeries.length ? planDetails.testSeries.length : 'All'}}
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    <b>Max Selected Test-Series Per Plan</b> :{{planDetails.max ? planDetails.max : 'All'}}
                                </h5>
                            </li>
                        </ul>
                    </div>
                    <div nz-row nzJustify="center" class="mt3 mb3 text-center">
                        <button nz-button nzType="primary" class="submitoraddbutton" (click)="updatePlan()">
                            Update Plan<span nz-icon nzType="arrow-right" nzTheme="outline"></span> 
                        </button>
                    </div>
                </nz-card>
            </div>
        </div>

    </nz-card>
</div>