<div class="site-page-header-ghost-wrapper">
    <nz-page-header [nzGhost]="false">
        <nz-page-header-title class="ml4">Activity Logs </nz-page-header-title>
    </nz-page-header>
</div>
<div nz-row nzJustify="end" class="mt4">
    <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24" class="mb5 mr3">
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="Date">Date</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <nz-range-picker class="width-100" [(ngModel)]="dateRange"
                    (ngModelChange)="onChangeDateRange($event)"></nz-range-picker>
            </nz-form-control>
        </nz-form-item>
    </div>
    <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24" class="mb5">
        <nz-form-item>
            <nz-form-label nzFor="name" [nzSpan]="24">Admins</nz-form-label>
            <nz-form-control [nzSpan]="24" >
                <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" [(ngModel)]="selectedAdmin"
                    nzPlaceHolder="Select a Admin" (nzOnSearch)="searchAdminForDropdown($event)"
                    (ngModelChange)="onChangeAdmin()">
                    <nz-option *ngFor="let item of allAdmins" nzValue="{{ item._id }}"
                        nzLabel="{{ item.name }}"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
    </div>
</div>

<nz-table #subjectTbl [nzData]="allSubAdmins" [nzLoading]="loading" [nzTotal]="totalDataCount" [(nzPageSize)]="pageSize"
    [nzPageIndex]="pageIndex" [nzFrontPagination]="false" (nzQueryParams)="onQueryParamsChange($event)"
    nzShowSizeChanger [nzScroll]="{ x: '900px' }">
    <thead>
        <tr>
            <th nzColumnKey="name" [nzSortFn]="true">Admin Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th nzWidth="150px">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of subjectTbl.data; index as i">
            <td><span *ngIf="data.name  "> {{ data.name }} </span></td>
            <td><span *ngIf="data.email "> {{ data.email }}</span></td>
            <td><span *ngIf="data.mobile "> {{ data.mobile }}</span></td>
            <td> 
                    <a class="action-button" nz-button [nzType]="'link'" [nzSize]="'small'" (click)="openActivityDrawer(data._id)"
                    nzTooltipPlacement="topRight" nz-tooltip>Activity</a>
            </td>
        </tr>
    </tbody>
</nz-table>