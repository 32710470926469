import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exam-category-details',
  templateUrl: './exam-category-details.component.html',
  styleUrls: ['./exam-category-details.component.scss']
})
export class ExamCategoryDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
