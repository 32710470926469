<nz-page-header class="site-page-header" nzBackIcon [nzTitle]="'Upload Question'">
</nz-page-header>
<div>
    <form nz-form [formGroup]="addUpdateBulkQuestionForm">
        <nz-card>
            <div nz-row>
                <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
                    Upload Excel: <nz-upload class="upload-list-inline" nzName="avatar"
                        (nzChange)="UploadImageFile($event, 'image', 0)" [nzName]="'file'" [nzShowUploadList]="false"
                        [nzBeforeUpload]="beforeRelatedMediaUpload" [nzAction]="mediaUploadUrl"
                        [nzHeaders]="customRequestHeaders">
                        <button nz-button *ngIf="!addUpdateBulkQuestionForm.value.image" nzType="primary"
                            [nzLoading]="buttonLoading">
                            <span nz-icon nzType="upload"></span>
                            Upload
                        </button>
                    </nz-upload>
                </div>
                <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
                    Download Demo Question Format: <button nz-button nzType="link" (click)="downloadExcel()">
                        Download
                    </button>
                    <br>
                    <span *ngIf="errorDataList && errorDataList.length">

                        Invalid Data List: <button nz-button nzType="link" (click)="downloadExcel(false)">
                            Download
                        </button>
                    </span>
                </div>

            </div>


        </nz-card>

        <nz-card class="mt3" *ngIf="duplicateDataList && duplicateDataList.length">
            <p>Duplicate Data List : </p>
            <div *ngIf="duplicateDataList && duplicateDataList.length">
                <nz-table [nzData]="duplicateDataList" [nzLoading]="loading" [nzFrontPagination]="false"
                    [nzShowPagination]="false">
                    <thead>
                        <tr>
                            <!-- <th>Concept ID</th> -->
                            <!-- <th nzColumnKey="topic" [nzSortFn]="true">Topic</th> -->
                            <th>Question Name</th>
                            <th>Question Type</th>
                            <th nzWidth="130px">Subject (slug)</th>
                            <th nzWidth="130px">Chapter (slug)</th>
                            <th nzWidth="130px">Topic (slug)</th>
                            <th nzWidth="130px">difficulty Level</th>
                            <!-- <th nzWidth="150px">Paragraph</th> -->
                            <th nzWidth="150px">option1</th>
                            <th nzWidth="150px">option2</th>
                            <th nzWidth="150px">option3</th>
                            <th nzWidth="150px">option4</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of duplicateDataList; index as i">
                            <td class="{{ data.questionText ? 'ellipsis' : '' }}">
                                <span *ngIf="data.questionText">
                                    <app-mathjax [content]="data.questionText"></app-mathjax>
                                </span>
                                <p class="text-center" *ngIf="!data.questionText">----</p>
                            </td>
                            <td class="{{ data.questionType ? 'description' : '' }}">
                                <p *ngIf="data.questionType">
                                    {{ data?.questionType }}
                                </p>
                                <p class="text-center" *ngIf="!data.questionType">
                                    ----
                                </p>
                            </td>
                            <td class="{{ data.subject ? 'description' : '' }}">
                                <p *ngIf="data.subject">
                                    {{ data?.subject }}
                                </p>
                                <p class="text-center text-white" *ngIf="!data.subject">
                                    ----
                                </p>
                            </td>
                            <td class="{{ data.chapter ? 'description' : '' }}">
                                <p *ngIf="data.chapter">
                                    {{ data.chapter}}
                                </p>
                                <p class="text-center text-white" *ngIf="!data.chapter">
                                    ----
                                </p>
                            </td>
                            <td class="{{ data.topic ? 'description' : '' }}">
                                <p *ngIf="data.topic">
                                    {{ data.topic }}
                                </p>
                                <p class="text-center text-white" *ngIf="!data.topic">
                                    ----
                                </p>
                            </td>
                            <td>
                                <p *ngIf="data.difficultyLevel">
                                    {{ difficultyLevelObject[data.difficultyLevel].label }}
                                </p>
                                <p class="text-center text-white" *ngIf="!data.difficultyLevel">
                                    ----
                                </p>
                            </td>
                            <!-- <td >
                                <p *ngIf="data.paragraph" class="{{ data.paragraph ? 'ellipsis' : '' }}">
                                    {{ data.paragraph }}
                                </p>
                                <p class="text-center text-white" *ngIf="!data.paragraph">
                                    ----
                                </p>
                            </td> -->
                            <td *ngFor="let item of data.options" class="{{ data.optionText ? 'ellipsis ' : '' }}">
                                <span *ngIf="item.optionText"><app-mathjax [content]="item.optionText"> </app-mathjax>
                                </span> <nz-tag class="ml3" [nzColor]="'green' "
                                    *ngIf="item.isCorrectOption === true">Correct</nz-tag>
                            </td>

                        </tr>
                    </tbody>
                </nz-table>

            </div>
        </nz-card>
    </form>
</div>