<nz-page-header class="site-page-header" nzBackIcon [nzTitle]="idForUpdate ? 'Update Sub Admin' : 'Add Sub Admin'">
</nz-page-header>
<nz-card>
    <div nz-row>
            <div nz-col nzLg="16" nzMd="16" nzSm="24" nzXs="24">
                <form nz-form [formGroup]="addUpdateSubAdminForm">
                    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                        <div nz-col nzLg="10" nzMd="10" nzSm="24" nzXs="24">
                            <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                                <nz-form-label [nzSpan]="24" nzFor="reEnterPassword" nzRequired>Access Routes</nz-form-label>
                                <nz-form-control [nzSpan]="24">
                                    <nz-tree #nzTreeComponent [nzCheckedKeys]="CheckedValue" [nzExpandAll]="true" [nzData]="menuData"
                                        nzCheckable formControlName="allowedRoute"
                                        (nzCheckBoxChange)="onCheckBoxChange($event)"></nz-tree>
                                </nz-form-control>
                            </div>
                        </div>
                        <div nz-col nzLg="14" nzMd="14" nzSm="24" nzXs="24"> 
                            <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                                <nz-form-item>
                                    <nz-form-label [nzSpan]="24" nzFor="subjectName" nzRequired>Sub Admin Name</nz-form-label>
                                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                                        <input nz-input formControlName="name" type="text" placeholder="Enter subject name " />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                                <nz-form-item>
                                    <nz-form-label [nzSpan]="24" nzFor="subjectName" nzRequired>Email Address</nz-form-label>
                                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                                        <input nz-input formControlName="email" type="text" placeholder="Enter subject name " />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                                <nz-form-item>
                                    <nz-form-label [nzSpan]="24" nzFor="mobile" nzRequired>Mobile No</nz-form-label>
                                    <nz-form-control [nzSpan]="24">
                                        <input nz-input id="mobile" formControlName="mobile" type="text"
                                            placeholder="Enter mobile number" />
                                    </nz-form-control>
                                    <div *ngIf="mobile?.invalid && (mobile?.dirty || mobile?.touched)" class="error-msg">
                                        <div *ngIf="mobile?.errors?.required">Mobile number is required.</div>
                                        <div *ngIf="mobile?.errors?.pattern">Invalid mobile number format.</div>
                                    </div>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
                <form [formGroup]="addUpdateSubAdminPasswordForm" class="width-100">
                    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                        <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" *ngIf="!idForUpdate">
                            <nz-form-item>
                                <nz-form-label [nzSpan]="24" nzFor="password" nzRequired>Password</nz-form-label>
                                <nz-form-control nzErrorTip="Please input your Password!">
                                    <nz-input-group [nzAddOnAfter]="passwordIconTpl">
                                        <input [type]="!viewPassword ? 'password' : 'text'" nz-input formControlName="password"
                                            placeholder="Password" (ngModelChange)="updateConfirmValidator()" />
                                    </nz-input-group>
                                    <ng-template #passwordIconTpl>
                                        <i nz-icon [nzType]="!viewPassword ? 'eye' : 'eye-invisible'" nzTheme="outline"
                                            (click)="viewPassword = !viewPassword"></i>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" *ngIf="!idForUpdate">
                            <nz-form-item>
                                <nz-form-label [nzSpan]="24" nzFor="reEnterPassword" nzRequired>Confirm Password</nz-form-label>
                                <nz-form-control [nzSpan]="24" [nzErrorTip]="errorTpl">
                                    <nz-input-group [nzAddOnAfter]="reEnterpasswordIconTpl">
                                        <input [type]="!viewReEnterPassword ? 'password' : 'text'" nz-input
                                            formControlName="reEnterPassword" placeholder="Re-Enter Password" />
                                        <ng-template #reEnterpasswordIconTpl>
                                            <i nz-icon [nzType]="!viewReEnterPassword ? 'eye' : 'eye-invisible'" nzTheme="outline"
                                                (click)="viewReEnterPassword = !viewReEnterPassword"></i>
                                        </ng-template>
                                    </nz-input-group>
                                    <ng-template #errorTpl let-control>
                                        <ng-container *ngIf="control.hasError('required')">
                                            Please confirm your password
                                        </ng-container>
                                        <ng-container *ngIf="control.hasError('confirm')">
                                            Password and Confirm Password must be match!
                                        </ng-container>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                </form>
            </div>
    </div>
    <div nz-row nzJustify="end">
        <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()" class="submitoraddbutton">
            {{idForUpdate? 'Update':'Submit'}}
        </button>
    </div>
</nz-card>