import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TopicRoutingModule } from './topic-routing.module';
import { TopicsComponent } from './topics/topics.component';
import { AddUpdateTopicComponent } from './add-update-topic/add-update-topic.component';
import { TopicDetailsComponent } from './topic-details/topic-details.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    TopicsComponent,
    AddUpdateTopicComponent,
    TopicDetailsComponent
  ],
  imports: [
    CommonModule,
    TopicRoutingModule,
    SharedModule
  ]
})
export class TopicModule { }
