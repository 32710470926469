import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NotificationDrawerComponent } from './notification-drawer/notification-drawer.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MathjaxComponent } from './components/mathjax/mathjax.component';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { SectionQuestionComponent } from './components/section-question/section-question.component';
import { AddUpdateQuestionComponent } from '../main/question/add-update-question/add-update-question.component';
import { BulkQuestionComponent } from './components/bulk-question/bulk-question.component';
import { SingleQuestionComponent } from './components/single-question/single-question.component';
import { GridViewComponent } from './components/grid-view/grid-view.component';
import { RouterModule } from '@angular/router';
import { CommonGridViewComponent } from './components/common-grid-view/common-grid-view.component';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { UpdateParagraphComponent } from './components/update-paragraph/update-paragraph.component';
import { ExcelUploadComponent } from './components/excel-upload/excel-upload.component';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { RejectionReasonComponent } from './components/rejection-reason/rejection-reason.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { RejectionReasonModalComponent } from './components/rejection-reason-modal/rejection-reason-modal.component';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxSuneditorModule } from 'ngx-suneditor';

const imports = [
  FormsModule,
  ReactiveFormsModule,
  NzResultModule,
  NzButtonModule,
  NzIconModule,
  NzTypographyModule,
  NzGridModule,
  NzLayoutModule,
  NzDropDownModule,
  NzBreadCrumbModule,
  NzMenuModule,
  NzPageHeaderModule,
  NzPaginationModule,
  NzStepsModule,
  NzAutocompleteModule,
  NzCascaderModule,
  NzCheckboxModule,
  NzDatePickerModule,
  NzFormModule,
  NzInputModule,
  NzInputNumberModule,
  NzRadioModule,
  NzSelectModule,
  NzUploadModule,
  NzAvatarModule,
  NzBadgeModule,
  NzCalendarModule,
  NzCardModule,
  NzCollapseModule,
  NzDescriptionsModule,
  NzEmptyModule,
  NzListModule,
  NzPopoverModule,
  NzStatisticModule,
  NzTableModule,
  NzTabsModule,
  NzTagModule,
  NzToolTipModule,
  NzAlertModule,
  NzDrawerModule,
  NzMessageModule,
  NzModalModule,
  NzNotificationModule,
  NzPopconfirmModule,
  NzSkeletonModule,
  NzSpinModule,
  NzDividerModule,
  NzSwitchModule,
  NzBackTopModule,
  NzImageModule,
  NzProgressModule,
  DragDropModule,
  NzTransferModule,
  NzTreeModule,
  NzTimelineModule,
  SlickCarouselModule,
  NgxSuneditorModule

];

@NgModule({
  declarations: [
    NotificationDrawerComponent,
    MathjaxComponent,
    SectionQuestionComponent,
    BulkQuestionComponent,
    SingleQuestionComponent,
    GridViewComponent,
    CommonGridViewComponent,
    UpdateParagraphComponent,
    ExcelUploadComponent,
    RejectionReasonComponent,
    ChangePasswordComponent,
    RejectionReasonModalComponent
  ],
  imports: [CommonModule, RouterModule, ...imports],
  exports: [...imports, MathjaxComponent, SectionQuestionComponent, BulkQuestionComponent, GridViewComponent, CommonGridViewComponent, ChangePasswordComponent],
})
export class SharedModule {}
