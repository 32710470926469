import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChapterRoutingModule } from './chapter-routing.module';
import { ChaptersComponent } from './chapters/chapters.component';
import { AddUpdateChapterComponent } from './add-update-chapter/add-update-chapter.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    ChaptersComponent,
    AddUpdateChapterComponent
  ],
  imports: [
    CommonModule,
    ChapterRoutingModule,
    SharedModule
  ]
})
export class ChapterModule { }
