<form nz-form [formGroup]="addUpdateParagraphTextForm">
  <nz-card>
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
      <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-control [nzSpan]="24" nzErrorTip="Please select your subject!">
            <nz-form-label nzFor="chapter">Subject</nz-form-label>
            <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" formControlName="subject"
              nzPlaceHolder="Select a subject" (nzOnSearch)="searchSubjectForDropdown($event)"
              (ngModelChange)="onChangeSubjectInParagraph()">
              <nz-option *ngFor="let item of allSubjects" nzValue="{{ item._id }}"
                nzLabel="{{ item.subjectName }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-control [nzSpan]="24" nzErrorTip="Please select your chapter!">
            <nz-form-label nzFor="chapter">Chapter</nz-form-label>
            <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" formControlName="chapter"
              nzPlaceHolder="Select a Chapter" (nzOnSearch)="searchChapterForDropdown($event)"
              (ngModelChange)="onChangeChapterInParagraph()">
              <nz-option *ngFor="let item of allChapters" nzValue="{{ item._id }}"
                nzLabel="{{ item.chapterName }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-control [nzSpan]="24" nzErrorTip="Please select your topic!">
            <nz-form-label nzFor="topic">Topic</nz-form-label>
            <nz-select nzAllowClear nzShowSearch nzServerSearch id="topic" formControlName="topic"
              nzPlaceHolder="Select a Topic" (nzOnSearch)="searchTopicForDropdown($event)">
              <nz-option *ngFor="let item of allTopics" nzValue="{{ item._id }}"
                nzLabel="{{ item.topicName }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
            <nz-form-label [nzSpan]="24" nzFor="description">Paragraph Text</nz-form-label>
            <!-- <ckeditor #editor id="paragraphText" name="paragraphText" formControlName="name" type="divarea">
            </ckeditor> -->
            <ckeditor formControlName="name"  name="editor" [editor]="Editor" [config]="ckeConfig" debounce="500"
                            (ngModelChange)="onChange($event)">
                        </ckeditor>
          </nz-form-control>
        </nz-form-item>
      </div>

    </div>
  </nz-card>
    <div  class="width-100">
      <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="text-right mt4">
          <button nz-button nzType="primary" [nzLoading]="Loading" (click)="submitParagraph()">
              {{ idForUpdate ? "Update" : "Submit" }}
          </button>
      </div>
  </div>

  </form>