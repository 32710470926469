<div class="outerContainer lgrgbg">
    <nz-card class="loginCard">
        <div nz-row nzAlign="middle">
            <div nz-col nzXl="24" nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                <div class="reg-log-form-inner">
                    <!-- <div class="logo-area mb2">
            <img src="/assets/images/logo.jpg" alt="" />
            </div> -->
                    <h1>Mockshala</h1>
                    <h2 *ngIf="isOtpSend" class="loginHeader">Reset Password</h2>
                    <form nz-form [formGroup]="forgotPasswordForm" class="login-form">
                        <nz-form-item class="loginfld">
                            <nz-form-label [nzSpan]="24" nzFor="password" nzRequired >Email</nz-form-label>
                            <nz-form-control nzErrorTip="Please input your email!" [nzErrorTip]="errorTplEmail">
                                <input type="text" nz-input formControlName="email" [readOnly]="isOtpSend ? true: false" placeholder="Email" />
                                <ng-template #errorTplEmail let-control>
                                    <ng-container *ngIf="control.hasError('required')">
                                        Please input your email address
                                    </ng-container>
                                    <ng-container *ngIf="control.hasError('pattern')">
                                        Please input a valid email address
                                    </ng-container>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                        <!-- <nz-form-item class="loginfld">
                            <nz-form-control nzErrorTip="Please input your Password!">
                                <input type="password" nz-input formControlName="password" placeholder="Password" />
                            </nz-form-control>
                        </nz-form-item> -->
                        <nz-form-item *ngIf="isOtpSend">
                            <nz-form-label [nzSpan]="24" nzFor="password" nzRequired>OTP</nz-form-label>
                            <nz-form-control nzErrorTip="Please input OTP!">
                                <nz-input-group nzPrefixIcon="lock">
                                    <input type="text" nz-input formControlName="otp" placeholder="OTP" />
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                        <div class="text-right">
                            <P class="top-margin" *ngIf="!showButton && isOtpSend"> Resend OTP in: {{ countdownInMinute }}:{{countdownInSec}} sec</P>
                            <button nz-button class="top-margin" [nzType]="'link'"
                                    *ngIf="showButton" [nzLoading]="isLoading" (click)="resendOTP()">Resend OTP</button>
                        </div>
                        <nz-form-item *ngIf="isOtpSend">
                            <nz-form-label [nzSpan]="24" nzFor="password" nzRequired>Password</nz-form-label>
                            <nz-form-control nzErrorTip="Please input your Password!">
                                <nz-input-group [nzAddOnAfter]="passwordIconTpl">
                                    <input [type]="!viewPassword ? 'password' : 'text'" nz-input formControlName="password"
                                        placeholder="Password" (ngModelChange)="updateConfirmValidator()" />
                                </nz-input-group>
                                <ng-template #passwordIconTpl>
                                    <i nz-icon [nzType]="!viewPassword ? 'eye' : 'eye-invisible'" nzTheme="outline"
                                        (click)="viewPassword = !viewPassword"></i>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item *ngIf="isOtpSend">
                            <nz-form-label [nzSpan]="24" nzFor="reEnterPassword" nzRequired>Confirm Password</nz-form-label>
                            <nz-form-control [nzSpan]="24" [nzErrorTip]="errorTpl">
                                <nz-input-group [nzAddOnAfter]="reEnterpasswordIconTpl">
                                    <input [type]="!viewReEnterPassword ? 'password' : 'text'" nz-input
                                        formControlName="reEnterPassword" placeholder="Re-Enter Password" />
                                    <ng-template #reEnterpasswordIconTpl>
                                        <i nz-icon [nzType]="!viewReEnterPassword ? 'eye' : 'eye-invisible'" nzTheme="outline"
                                            (click)="viewReEnterPassword = !viewReEnterPassword"></i>
                                    </ng-template>
                                </nz-input-group>
                                <ng-template #errorTpl let-control>
                                    <ng-container *ngIf="control.hasError('required')">
                                        Please confirm your password
                                    </ng-container>
                                    <ng-container *ngIf="control.hasError('confirm')">
                                        Password and Confirm Password must be match!
                                    </ng-container>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                        
                        <div nz-col [nzSpan]="24" class="login-button">
                            <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'"
                                *ngIf="!isOtpSend" [nzLoading]="isLoading" (click)="sendOTP()">Next</button>
                            <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'"
                                *ngIf="isOtpSend" [nzLoading]="isLoading" (click)="submitForResetPassword()">Submit</button>
                        </div>
                        <!-- <div nz-row class="login-form-margin">
                            <div nz-col [nzSpan]="24" class="login-form-forgot">
                                Forgot Password? <a>Reset Password</a>.
                            </div>
                        </div> -->
                    </form>
                    <!-- <app-change-password></app-change-password> -->
                </div>
            </div>
        </div>
    </nz-card>
</div>