import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { toNumber } from 'ng-zorro-antd/core/util';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
  import { Exam } from 'src/app/core/interfaces/exam';
import { ExamCategory } from 'src/app/core/interfaces/exam-category';
import { HttpRequestService, LocalStorageService } from 'src/app/core/services';
import { RejectionReasonComponent } from 'src/app/shared/components/rejection-reason/rejection-reason.component';

@Component({
  selector: 'app-exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.scss'],
})
export class ExamsComponent implements OnInit {
  allExams: Exam[] = [];
  allApprovedExams: Exam[] = [];
  isAddOrUpdate:boolean = true
  totalDataCount = 0;
  totalApprovedDataCount = 0;
  pageSize = 12;
  pageIndex = 1;
  loading = false;
  searchExam: Subject<string> = new Subject<string>();
  searchExamCategory: Subject<string> = new Subject<string>();
  search = '';
  examCategorySearch: string = '';
  allExamCategoryList: ExamCategory[] = [];
  checkAddPermission = false;
  checkUpdatePermission = false;
  selectedExamCategory: ExamCategory | undefined;
  isOpenDetails = false;
  listView = true;
  active: boolean = false;
  deActive: boolean = true;
  isExamComponent: boolean = true;
  userRole: string = ''
  subAdminAddedExams: Exam[] = [];
  index: number = 0;
  skipIndex: number = 0;
  snapShootIndex: number = 0
  constructor(
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private localStorageService: LocalStorageService,
    private modalService : NzModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.snapShootIndex = toNumber(this.activatedRoute.snapshot.queryParams.index) ;
    if(this.snapShootIndex){
      this.changeIndex(this.snapShootIndex)
    }
    this.userRole = this.localStorageService.getItem('user').role;
  }

  ngOnInit(): void {
    this.searchExam.pipe(debounceTime(1000)).subscribe((success) => {
      this.search = success;
      this.getAllExams(this.index);
    });
    this.searchExamCategory.pipe(debounceTime(1000)).subscribe((success) => {
      this.examCategorySearch = success;
      this.getAllExamCategory();
    });
  }
  changeIndex(data: any) {
    this.router.navigate([], { queryParams: {index: data } });
    this.allApprovedExams = [];
    this.allExams = [];
    this.pageIndex = 1;
    this.pageSize = 12;
    this.index = data
      this.getAllExams();
  }
  changeGridView() {
    this.listView = false;
    this.active = true;
    this.deActive = false;
  }
  changeListView() {
    this.listView = true;
    this.active = false;
    this.deActive = true;
  }
  /* Get allExams */
  getAllExams(index:number = 0, skip = 0, sortBy?: any): void {
    index = index? index : this.index
    let params: any;
    params = { skip: this.skipIndex, limit: this.pageSize };

    if (this.search) {
      params.search = this.search;
    }
    if (this.selectedExamCategory) {
      params.examCategory = this.selectedExamCategory;
    } else {
      delete params.examCategory;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if(index === 0){
      params.approveStatus = 'approved'
    }else if(index === 1 && this.userRole === 'admin'){
      params.approveStatus = "pending"
    }else if( index === 1 && this.userRole === 'subAdmin' ){
      params.approveStatus = ["pending", "rejected"]
    }else{
      delete params.approvedStatus
    }
    this.loading = true;
    this.httpRequestService.request('get', 'exams', params).subscribe(
      (result) => {
        this.loading = false;
        const data: Exam[] = result.data
        if(index === 0){
          this.allApprovedExams = data;
          this.totalApprovedDataCount = result.totalCount;
        }else{
          this.allExams = data
          this.totalDataCount = result.totalCount;
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }
  // get allExamCategory
  getAllExamCategory(skip = 0, sortBy?: any): void {
    let params: any;
    params = { skip, limit: this.pageSize, approveStatus: 'approved' };
    if (this.examCategorySearch) {
      params.search = this.examCategorySearch;
    } else {
      delete params.search;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    this.loading = true;
    this.httpRequestService.request('get', 'exam-categories', params).subscribe(
      (result) => {
        this.loading = false;
        this.allExamCategoryList = result.data;
        this.totalDataCount = result.totalCount;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  /* For Pagination / Sending skip */
  onQueryParamsChange(params: NzTableQueryParams): void {
    this.allApprovedExams = [];
    this.allExams = [];
    const { pageSize, pageIndex } = params;
    this.skipIndex = pageSize * (pageIndex - 1)
    this.getAllExams(this.index, pageSize * (pageIndex - 1));
  }
  /* For Pagination / Sending skip for grid view*/
  onQueryParamsChangeForGridView(index: any): void {
    this.pageIndex = index;
    this.getAllExams(this.index, this.pageSize * (this.pageIndex - 1));
  }
  onQueryPageSizeChangeForGridView(size: any) {
    this.pageSize = size;
    this.getAllExams(this.index, this.pageSize * (this.pageIndex - 1));
  }

  /* status change*/
  updateStatus(id: string, status: boolean): void {
    this.httpRequestService
      .request('put', `exams/active-inactive/${id}`, { status: !status })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllExams(this.index);
      });
  }
  updatePendingStatus(id: string, pendingStatus: string) {
    this.httpRequestService
      .request('put', `exams/approved-rejected/${id}`, {
        approveStatus: 'approved',
      })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllExams(this.index);
      });
  }
  openRejectionReason(id: string, pendingStatus: string) {
      const pendingStatusData = 'rejected';
      const rejectionReason = this.modalService.create({
        nzTitle: 'Add A Reason',
        nzContent: RejectionReasonComponent,
        nzWidth: '800px',
        nzFooter: null,
        nzComponentParams: {
          updateId: id,
          component: 'exams',
        },
        nzCancelText: null,
        nzOkText: null,
      });

    const questionService = rejectionReason.afterClose.subscribe(
      (data: any) => {
        this.getAllExams(this.index);
      }
    );
  }
  onChangeExamCategory() {
    this.getAllExams(this.index);
  }
  // search concept
  searchExams(event: any): void {
    this.searchExam.next(event);
  }
  searchExamCategoryForDropDown(event: any): void {
    this.searchExamCategory.next(event);
  }
}
