  <nz-page-header
    class="site-page-header"
    nzBackIcon
    [nzTitle]="idForUpdate ? 'Update Subject' : 'Add Subject'"
  >
  </nz-page-header>
<form nz-form [formGroup]="addUpdateSubjectForm">
  <div nz-row>
    <nz-card nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="subjectName" nzRequired
          >Subject Name</nz-form-label
        >
        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
          <input
            nz-input
            formControlName="subjectName"
            type="text"
            placeholder="Enter subject name "
          />
        </nz-form-control>
      </nz-form-item>
      <div nz-row nzJustify="end">
        <button
          nz-button
          nzType="primary"
          [nzLoading]="buttonLoading"
          (click)="submit()"
          class="submitoraddbutton"
        >
        {{idForUpdate? 'Update':'Submit'}}
        </button>
      </div>
    </nz-card>
  </div>
</form>
