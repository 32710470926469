import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzFormatEmitEvent, NzTreeComponent, NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { mockShalaSubAdminMenu } from 'src/app/core/data/menu.data';
import { HttpRequestService } from 'src/app/core/services';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @Input() updateId: string = '';
  @Input() component: string = '';

  addUpdateSubAdminPasswordForm: FormGroup;
  idForUpdate: string;
  buttonLoading = false;
  checkAddPermission = false;
  checkUpdatePermission = false;
  viewReEnterPassword = false;
  viewPassword = false;
  menuData: NzTreeNodeOptions[] | NzTreeNode[] = [];
  selectedKeys: any[] = [];
  CheckedValue: any[] = [];
  id: string = '';
  apiRoute: string = '';
 
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalRef : NzModalRef
  ) {
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.addUpdateSubAdminPasswordForm = this.fb.group({
      password: [null, Validators.required],
      reEnterPassword: [
        null,
        [Validators.required, this.confirmationValidator],
      ],
    });
  }


  ngOnInit(): void {
    this.id = this.updateId,
    this.apiRoute = this.component
    //
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (
      control.value !==
      this.addUpdateSubAdminPasswordForm.controls.password.value
      ) {
        return { confirm: true, error: true };
      }
      return {};
    };
    updateConfirmValidator(): void {
      /** wait for refresh value */
      Promise.resolve().then(() =>
      this.addUpdateSubAdminPasswordForm.controls.reEnterPassword.updateValueAndValidity()
      );
    }
    submit(): void {
      if (!this.addUpdateSubAdminPasswordForm.valid) {
        this.markFormGroupTouched(this.addUpdateSubAdminPasswordForm);
      } else {
          this.addOrUpdateSubject(
            'put',
            `${this.apiRoute}/sub-admin-change-password/${this.id}`,
            `${this.apiRoute} Password Update SuccessFully `
          );
      }
    }
  
    /* Add Or Edit Subject */
    addOrUpdateSubject(
      requestMethod: string,
      requestURL: string,
      successMessage: string
    ): void {
      this.buttonLoading = true;
      const finalData:any = {};
      finalData.password = this.addUpdateSubAdminPasswordForm.controls.password.value;
      this.httpRequestService
        .request(requestMethod, requestURL, finalData)
        .subscribe(
          (result: any) => {
            this.notificationService.success('', successMessage);
            // this.router.navigateByUrl('/main/subjects');
            this.buttonLoading = false;
            this.modalRef.close(result);
          },
          (error: any) => {
            if (error.error.errors) {
              const allErrors: string[] = Object.values(error.error.errors);
              for (const err of allErrors) {
                this.notificationService.error('', err);
              }
            }else {
              this.notificationService.error('', error.error.message);
            }
            this.buttonLoading = false;
          }
        );
    }
  


  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }
}
