import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer, Subject } from 'rxjs';
import { HttpRequestService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
})
export class BannersComponent implements OnInit {
  addUpdateBannerForm: FormGroup;
  idForUpdate: string;
  buttonLoading = false;
  imageLoading = false;
  pageSize = 10;
  pageIndex = 1;
  totalTagDataCount: number = 0;
  thumbImageLoading = false;
  checkAddPermission = false;
  checkUpdatePermission = false;
  mediaBaseUrl = environment.mediaBaseUrl;
  mediaUploadUrl = environment.apiBaseUrl + '/api/media';
  urlRegexPattern = /^https:\/\/(mockshala-user-web\.pages\.dev|mockshala\.com)/;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  bannerData: any = [];
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private msg: NzMessageService
  ) {
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.addUpdateBannerForm = this.fb.group({
      slides: this.fb.array([]),
    });
  }

  // on page load functions
  ngOnInit(): void {
    this.getBanners();
    if(this.slideFormArray.length  < 1){
      this.addSlides();
    }
    console.log(this.slideFormArray.length, this.bannerData.length)

  }
  // if (this.idForUpdate) {
  // }

  // banners Form Array
  createSlide(item?: any): FormGroup {
    if (item) {
      // console.log('item', item);
      return this.fb.group({
        _id: [item._id],
        title: [item.title],
        position: [item.position, [Validators.required]],
        image: [item.image, [Validators.required]],
        imageForMobile: [item.imageForMobile, [Validators.required]],
        redirectUrl: [
          item.redirectUrl,
          [Validators.required, Validators.pattern(this.urlRegexPattern)],
        ],
        status: [item.status || 'active', [Validators.required]],
      });
    }
    return this.fb.group({
      _id: [null],
      title: [null],
      position: [null, [Validators.required]],
      image: [null, [Validators.required]],
      imageForMobile: [null, [Validators.required]],
      redirectUrl: [
        null,
        [Validators.required, Validators.pattern(this.urlRegexPattern)],
      ],
      status: ['active', [Validators.required]],
    });
  }

  get slideFormArray(): FormArray<any> {
    return this.addUpdateBannerForm.get('slides') as FormArray;
  }
  addSlides(): void {
    // Position is set based on the current length of the array
    const newPosition = this.slideFormArray.length + 1;
    // Create a new slide with position set
  const newSlide = this.createSlide({ position: newPosition });
  console.log('newSlide', newSlide);
  // Push the new slide to the form array
  this.slideFormArray.push(newSlide);
  console.log('this.addUpdateForm', this.addUpdateBannerForm.value.slides);
  }
  removeSlide(index: number, id?: any): void {
    console.log('this.addUpdateForm', id);
    if (id) {
        this.httpRequestService
            .request('delete', `banners/${id}`)
            .subscribe((result: any) => {
                if (result) {
                    this.notificationService.success('', 'Banner Deleted Successfully');
                    this.slideFormArray.removeAt(index);
                    this.updateSlidePositions();
                }
            });
    } else {
        this.slideFormArray.removeAt(index);
        this.updateSlidePositions();
    }
}
private updateSlidePositions(): void {
  const formArray = this.slideFormArray;
  formArray.controls.forEach((control, index) => {
    control.patchValue({ position: index + 1 });
  });
}

  /* Get single banner details by Id */
  getBanners(): void {
    this.bannerData = [];
    this.httpRequestService.request('get', 'banners').subscribe(
      (result: any) => {
        const data = result.data;
        this.bannerData = data.sort((a: any, b: any) => a.position - b.position);
        this.slideFormArray.clear();
        data.forEach((element: any) => {
          this.slideFormArray.push(this.createSlide(element));
        });
        console.log(this.slideFormArray.length, this.bannerData.length)
        if(!this.slideFormArray.length) {
          this.addSlides();
        }
      },
      (error: any) => {}
    );
  }
  // //  send auth in headers //
  customRequestHeaders = () => {
    return { Authorization: `Bearer ${localStorage.getItem('token')}` };
  };
  drop(event: CdkDragDrop<AbstractControl[]>): void {
    // Rearrange the FormArray controls based on drag-and-drop
    const previousIndex = event.previousIndex;
    const currentIndex = event.currentIndex;

    if (previousIndex !== currentIndex) {
      // Move the form control in the FormArray
      const formArray = this.slideFormArray;
      const movedItem = formArray.at(previousIndex);
      formArray.removeAt(previousIndex);
      formArray.insert(currentIndex, movedItem);
      formArray.controls.forEach((control, index) => {
        control.patchValue({ position: index + 1 });
      });
      console.log('this.addUpdateForm', this.addUpdateBannerForm.value);
    }
  }


  /* Submit banner form */
  submit(): void {
    if (!this.addUpdateBannerForm.valid) {
      this.markFormGroupTouched(this.addUpdateBannerForm);
    } else {
      if (this.idForUpdate) {
        this.addOrUpdateBanners(
          'put',
          `banners/${this.idForUpdate}`,
          'Banners Successfully Updated'
        );
      } else {
        this.addOrUpdateBanners(
          'post',
          'banners',
          'Banner Added Successfully '
        );
      }
    }
  }

  /* Add Or Edit banner */
  addOrUpdateBanners(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    // return
    this.buttonLoading = true;
    const finalData = this.addUpdateBannerForm.value.slides;
    console.log('this.addUpdateBannerForm', finalData);
    this.httpRequestService
      .request(requestMethod, requestURL, finalData)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          this.buttonLoading = false;
          this.getBanners();
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          } else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }

  // image Upload
  UploadImageFile(
    info: { file: NzUploadFile },
    FormControl: string,
    index: number
  ) {
    switch (info.file.status) {
      case 'uploading':
        if (FormControl === 'imageForMobile') {
          this.thumbImageLoading = true;
        } else {
          this.imageLoading = true;
        }
        break;
      case 'done':
        if (FormControl === 'imageForMobile') {
          this.slideFormArray.controls[index].patchValue({
            imageForMobile: info.file.response.data.path,
          });
          this.thumbImageLoading = false;
        } else {
          this.imageLoading = false;
          this.slideFormArray.controls[index].patchValue({
            image: info.file.response.data.path,
          });
        }
        console.log(
          'this.addUpdateBannerForm',
          this.addUpdateBannerForm.value.slides[index].image
        );
        break;
      case 'error':
        this.msg.error('Network error');
        if (FormControl === 'imageForMobile') {
          this.thumbImageLoading = false;
        } else {
          this.imageLoading = false;
        }
        break;
    }
  }

  beforeRelatedMediaUpload = (
    file: NzUploadFile,
    fileList: NzUploadFile[]
  ): Observable<any> => {
    return new Observable((observer: Observer<boolean>) => {
      const isImage =
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/JPG' ||
        file.type === 'image/JPEG' ||
        file.type === 'image/PNG' ||
        file.type === 'image/webp';
      if (!isImage) {
        this.msg.error('You can only upload image file!');
        observer.complete();
        return;
      }
      const isLt20M = file.size ? file.size / 1024 / 1024 < 20 : false;
      if (!isLt20M) {
        this.msg.error('Image must smaller than 10MB!');
        observer.complete();
        return;
      }
      observer.next(isImage && isLt20M);
      observer.complete();
    });
  };
  removeImage(path: string, index: number): void {
    let params: any;
    params = { path: path };
    this.slideFormArray.controls[index].patchValue({
      image: null,
    });
    this.httpRequestService
      .request('post', 'media/delete-media', params)
      .subscribe((result: any) => {
        if (result) {
          this.notificationService.success('', 'Image Deleted Successfully');
        }
      });
  }
  removeThumbImage(path: string, index: number): void {
    let params: any;
    params = { path: path };
    this.slideFormArray.controls[index].patchValue({
      imageForMobile: null,
    });
    this.httpRequestService
      .request('post', 'media/delete-media', params)
      .subscribe((result: any) => {
        if (result && result.data) {
          this.notificationService.success('', 'Image Deleted Successfully');
        }
      });
  }

  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
        // nested formgroup
        if (formGroup.controls[i] instanceof FormGroup) {
          this.markFormGroupTouched(formGroup.controls[i] as FormGroup);
        }

        // nested form array
        if (formGroup.controls[i] instanceof FormArray) {
          const formArray = formGroup.controls[i] as FormArray;
          for (const j in formArray.controls) {
            if (formArray.controls.hasOwnProperty(j)) {
              formArray.controls[j].markAsDirty();
              formArray.controls[j].updateValueAndValidity();
              if (formArray.controls[j] instanceof FormGroup) {
                this.markFormGroupTouched(formArray.controls[j] as FormGroup);
              }
            }
          }
        }
      }
    }
  }
}
