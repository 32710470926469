import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Chapter } from 'src/app/core/interfaces/chapter';
import { Subjects } from 'src/app/core/interfaces/subjects';
import { Topic } from 'src/app/core/interfaces/topic';
import { HttpRequestService } from 'src/app/core/services';
import { AddExcelService } from 'src/app/core/services/add-excel.service';
import { CommonArrayService } from 'src/app/core/services/common-array.service';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-excel-upload',
  templateUrl: './excel-upload.component.html',
  styleUrls: ['./excel-upload.component.scss']
})
export class ExcelUploadComponent implements OnInit {
  addBulkQuestionForm: FormGroup
  allSubjects: Subjects[] = [];
  allChapters: Chapter[] = [];
  allTopics: Topic[] = [];
  searchChapter: Subject<string> = new Subject<string>();
  searchSubject: Subject<string> = new Subject<string>();
  searchTopic: Subject<string> = new Subject<string>();
  subjectSearch = '';
  chapterSearch = '';
  topicSearch = '';
  // mediaBaseUrl = environment.mediaBaseUrl;
  // mediaUploadUrl = environment.apiBaseUrl+ '/api/questions/upload-excel';
  pageSize = 12;
  pageIndex = 1;
  loading: boolean = false;
  buttonLoading: boolean = false;
  excel: any[] = [];
  fileItem: NzUploadFile[] = [];;
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private msg: NzMessageService,
    private router: Router,
    private commonArrayService: CommonArrayService,
    private commonService: CommonService,
    private excelService: AddExcelService,
    private modalRef : NzModalRef
  ) {
    this.addBulkQuestionForm = this.fb.group({
      subject: [null, Validators.required],
      chapter: [''],
      topic: [''],
      excel: [null],
    });
  }

  ngOnInit(): void {

    this.searchChapter.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.chapterSearch = success;
      this.getAllChapters()
    });
    this.searchSubject.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.subjectSearch = success;
      this.getAllSubjects()
    });
    this.searchTopic.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.topicSearch = success;
      this.getAllTopics();
    });
  }
  //  //  send auth in headers //
  // customRequestHeaders = () => {
  //   return { Authorization: `Bearer ${localStorage.getItem('token')}` };
  // };

  /* Get all Subjects */
  getAllSubjects(): void {
    const params: any = {
      skip: 0,
      limit: 100, status: true
    };
    if (this.subjectSearch) {
      params.search = this.subjectSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'subjects', params).subscribe(
      (result: any) => {
        this.allSubjects = result.data;
      },
      (err: any) => { }
    );
  }
  /* On change subject get chapter */
  onChangeSubject(value: string, skip = 0): void {
    if (this.addBulkQuestionForm?.value && this.addBulkQuestionForm?.value.subject) {
      this.allChapters = [];
      this.allTopics = [];
      this.addBulkQuestionForm?.patchValue({
        chapter: null,
        topic: null,
      });
      if (this.addBulkQuestionForm?.value.subject) {
        this.getAllChapters();
        this.getAllTopics();
      }
    }
  }

  /* Get all chapters */
  getAllChapters(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true
    };
    if (this.addBulkQuestionForm?.value.subject) {
      params['subject'] = this.addBulkQuestionForm?.value.subject;
    } else {
      delete params.subject;
    }
    if (this.chapterSearch) {
      params.search = this.chapterSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'chapters', params).subscribe(
      (result: any) => {
        //;

        this.allChapters = result.data;
      },
      (err: any) => { }
    );
  }

  /* On change chapter get course */
  onChangeChapter(value: string, skip = 0): void {
    if (this.addBulkQuestionForm?.value && this.addBulkQuestionForm?.value.chapter) {

      this.allTopics = [];
      this.addBulkQuestionForm?.patchValue({
        topic: null,
      });
      if (this.addBulkQuestionForm?.value.chapter) {
        this.getAllTopics();
      }
    }
  }

  /* Get all topics */
  getAllTopics(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true
    };
    if (this.addBulkQuestionForm?.value.subject) {
      params['subject'] = this.addBulkQuestionForm?.value.subject;
    } else {
      delete params.subject;
    }
    if (this.addBulkQuestionForm?.value.chapter) {
      params['chapter'] = this.addBulkQuestionForm?.value.chapter;
    } else {
      delete params.search;
    }
    if (this.topicSearch) {
      params.search = this.topicSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'topics', params).subscribe(
      (result: any) => {
        this.allTopics = result.data;
      },
      (err: any) => { }
    );
  }

  beforeRelatedMediaUpload = (file: NzUploadFile): boolean => {
    if (typeof file?.type === 'string') {
      const findType = file.type.split('.')[file.type.split('.').length - 1];

      if (findType === 'sheet') {
        this.fileItem = this.fileItem.concat(file);
        return false;
      } else {
        this.fileItem = []
        this.msg.error('You can only upload Excel file!');
        return false;
      }
    }
    return false;
  };

  submit(){
    if (!this.addBulkQuestionForm.valid) {
      this.markFormGroupTouched(this.addBulkQuestionForm);
    } else {
    this.buttonLoading = true

    const formData = new FormData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if(this.fileItem){
      this.fileItem.forEach((file: any) => {
        formData.append('file', file);
      });
      if(this.addBulkQuestionForm.value.subject ){
        formData.append('subject', this.addBulkQuestionForm.value.subject);
      }
      if( this.addBulkQuestionForm.value.chapter){
        formData.append('chapter', this.addBulkQuestionForm.value.chapter);
      }
      if( this.addBulkQuestionForm.value.topic){
        formData.append('topic', this.addBulkQuestionForm.value.topic);
      }
        //
          this.httpRequestService
            .fileRequest('questions/upload-excel', formData)
            .subscribe(
              (result: any) => {
                this.buttonLoading = false
                const data = result.data.data;

                if ( data.duplicateData && data.duplicateData.length > 0 ) {
                  data.duplicateData.forEach((element: any) => {
                    this.commonService.$addBulkQuestion.next(data);
                    // if( element.questionType === "NORMAL"){
                    //   this.commonService.$addQuestion.next(data);
                    // }else if(element.questionType === "PARAGRAPH"){
                    //   this.commonService.$addParagraphQuestion.next(data);
                    // }
                  });
                }
                if(data.successData && data.successData.length > 0){
                  data.successData.forEach((element: any) => {
                    this.commonService.$addBulkQuestion.next(data);
                    // if( element.questionType === "NORMAL"){
                    //   this.commonService.$addQuestion.next(data);
                    // }else if(element.questionType === "PARAGRAPH"){
                    //   this.commonService.$addParagraphQuestion.next(data);
                    // }
                  });
                }
                if(data.errorData && data.errorData.length > 0){
                  data.errorData.forEach((element: any) => {
                    this.commonService.$addBulkQuestion.next(data);
                    // if( element.questionType === "NORMAL"){
                    //   this.commonService.$addQuestion.next(data);
                    // }else if(element.questionType === "PARAGRAPH"){
                    //   this.commonService.$addParagraphQuestion.next(data);
                    // }
                  });
                }
                this.modalRef.close(data);
              },(error: any) => {
                if (error.error.errors) {
                  const allErrors: string[] = Object.values(error.error.errors);
                  for (const err of allErrors) {
                    this.notificationService.error('', err);
                  }
                }else {
                  this.notificationService.error('', error.error.message);
                }
                this.buttonLoading = false;
              })
        // }
    }
  }
  }
  // addOrUpdateQuestion(
  //   requestMethod: string,
  //   requestURL: string,
  //   successMessage: string
  // ): void {
  //   this.buttonLoading = true;
  //   const finalData = this.addBulkQuestionForm.value;
  //     this.httpRequestService
  //       .request(requestMethod, requestURL, finalData)
  //       .subscribe(
  //         (result: any) => {
  //
  //         })
  // }
  downloadExcel() {
    this.excelService.downloadExcel();
  }
  removeImage(): void {
    this.addBulkQuestionForm.value.image = null;
  }
  // seach Chapter
  searchChapterForDropdown(event: any): void {
    this.searchChapter.next(event);
  }
  // seach Subject
  searchSubjectForDropdown(event: any): void {
    this.searchSubject.next(event);
  }
  // seach Topic
  searchTopicForDropdown(event: any): void {
    this.searchTopic.next(event);
  }
   /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }
}
