import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-layout',
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.scss'],
})
export class SidebarLayoutComponent implements OnInit {
  isCollapsed = false;
  constructor() {}

  ngOnInit(): void {}
  onCollapseChange(value: any): void {
    this.isCollapsed = value;
  }
}
