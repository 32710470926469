import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CurrentAffair } from 'src/app/core/interfaces/current-affair';
import { Testimonial } from 'src/app/core/interfaces/testimonial';
import { HttpRequestService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-update-testimonials',
  templateUrl: './add-update-testimonials.component.html',
  styleUrls: ['./add-update-testimonials.component.scss']
})
export class AddUpdateTestimonialsComponent implements OnInit  {

  addUpdateTestimonialForm: FormGroup;
  idForUpdate: string;
  buttonLoading = false;
  imageLoading = false;
  checkAddPermission = false;
  checkUpdatePermission = false;
  mediaBaseUrl = environment.mediaBaseUrl;
  mediaUploadUrl = environment.apiBaseUrl + '/api/media';
  tabIndex: number = 0
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private msg: NzMessageService,
  ) {
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.tabIndex = this.activatedRoute.snapshot.queryParams.index;
    this.addUpdateTestimonialForm = this.fb.group({
      name: [null, [Validators.required]],
      comment: [null, [Validators.required]],
      image: [null, [Validators.required]],
      reviewStar: [null, [Validators.required]],
      status:[true]
    });
  }

  // on page load functions
  ngOnInit(): void {
    if (this.idForUpdate) {
      this.getTestimonialsById();
    }

  }

   //  send auth in headers //
  customRequestHeaders = () => {
    return { Authorization: `Bearer ${localStorage.getItem('token')}` };
  };
  /* Get single subject details by Id */
  getTestimonialsById(): void {
    this.httpRequestService
      .request('get', `testimonials/${this.idForUpdate}`)
      .subscribe(
        (result: any) => {
          const data:Testimonial = result.data;
          this.addUpdateTestimonialForm.patchValue({
            name: data.name,
            comment: data.comment,
            reviewStar: data.reviewStar,
            image: data.image,

          });
        },
        (error: any) => {}
      );
  }



  /* Submit subject form */
  submit(): void {
    if (!this.addUpdateTestimonialForm.valid) {
      this.markFormGroupTouched(this.addUpdateTestimonialForm);
    } else {
      if (this.idForUpdate) {
        this.addOrUpdateTestimonials(
          'put',
          `testimonials/${this.idForUpdate}`,
          'Testimonial Successfully Updated'
        );
      } else {
        this.addOrUpdateTestimonials(
          'post',
          'testimonials',
          'Testimonial Added Successfully '
        );
      }
    }
  }

  /* Add Or Edit Subject */
  addOrUpdateTestimonials(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    this.buttonLoading = true;
    const finalData:Testimonial = this.addUpdateTestimonialForm.value;
    this.httpRequestService
      .request(requestMethod, requestURL, finalData)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          this.router.navigate(['/main/testimonial'], { queryParams: {index: this.tabIndex } });
          this.buttonLoading = false;
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          }else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }

  // image Upload
  UploadImageFile(
    info: { file: NzUploadFile },
    FormControl: string,
    index: number
  ) {
    switch (info.file.status) {
      case 'uploading':
          this.imageLoading = true;
        break;
      case 'done':
        this.imageLoading = false;
          this.addUpdateTestimonialForm.patchValue({
            image: info.file.response.data.path,
          });
        break;
      case 'error':
        this.msg.error('Network error');
        this.imageLoading = false;
        break;
    }
  }

  beforeRelatedMediaUpload = (
    file: NzUploadFile,
    fileList: NzUploadFile[]
  ): Observable<any> => {
    return new Observable((observer: Observer<boolean>) => {
     ;

      const isImage =
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/JPG' ||
        file.type === 'image/JPEG' ||
        file.type === 'image/PNG';
      if (!isImage) {
        this.msg.error('You can only upload image file!');
        observer.complete();
        return;
      }
      const isLt3M = file.size ? file.size / 1024 / 1024 < 5 : false;
      if (!isLt3M) {
        this.msg.error('Image must smaller than 5MB!');
        observer.complete();
        return;
      }
      observer.next(isImage && isLt3M);
      observer.complete();
    });
  };
  removeImage(path: string): void {
    let params: any;
    params = { path: path };
    this.addUpdateTestimonialForm.patchValue({
      image: null,
    });
    this.httpRequestService.request( 'post', 'media/delete-media', params ).subscribe((result: any)=>{
      if(result){
        this.notificationService.success('', 'Image Deleted Successfully');
      }
    });
  }

  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

}

