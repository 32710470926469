import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TestimonialRoutingModule } from './testimonial-routing.module';
import { AddUpdateTestimonialsComponent } from './add-update-testimonials/add-update-testimonials.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { TestimonialDetailsComponent } from './testimonial-details/testimonial-details.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    AddUpdateTestimonialsComponent,
    TestimonialsComponent,
    TestimonialDetailsComponent
  ],
  imports: [
    CommonModule,
    TestimonialRoutingModule,
    SharedModule
  ]
})
export class TestimonialModule { }
