import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  authLogout: Subject<any> = new Subject<any>();
  //queation add from test page
  // questionAddFromOutside = new BehaviorSubject()<any>;
  constructor() {}

  public logout(): void {
    this.authLogout.next();
  }
}
