<nz-tabset (nzSelectedIndexChange)="changeIndex($event)">
    <nz-tab nzTitle="Approved">
        <nz-card *ngIf="list" [nzLoading]="loading">
            <div *ngIf=" list.length === 0">
                <nz-empty nzNotFoundImage="simple"></nz-empty>
            </div>
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
                <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24" class="mt4" *ngFor="let item of list">
                    <nz-card class="height-100  gridView-card">

                        <img class="coverImage-size" *ngIf="item.image" alt="example"
                            [src]="mediaBaseUrl + item.image " />
                        <img class="coverImage-size" *ngIf="!item.image" alt="example"
                            src="../../../../assets/images/no-image.jpeg" />
                        <!-- <nz-divider></nz-divider> -->
                        <div class="fixed-titleDescription">
                            <b class="title-ellipsis mt3"> <app-mathjax [content]='item.name'></app-mathjax></b>
                            <b class="" *ngIf="item.categoryName"> <app-mathjax
                                    [content]='item.categoryName'></app-mathjax></b>
                            <!-- <p class="ellipsis fixed-height"> <app-mathjax [content]='item.description'></app-mathjax></p> -->
                        </div>
                        <div nz-row nzJustify="space-around" class="actions-button ">
                            <a nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
                                [routerLink]="['update', item._id]" routerLinkActive="router-link-active"><i nz-icon
                                    nzType="form" nzTheme="outline"></i></a>
                            <a *ngIf="isOpenDetails" nzTooltipTitle="Details" nzTooltipPlacement="topCenter" nz-tooltip
                                (click)="openDetails(item)" routerLinkActive="router-link-active"><i nz-icon
                                    nzType="eye" nzTheme="outline"></i>
                            </a>
                            <a *ngIf="isTestComponent && userRole !== 'subAdmin'" class="action-button" nz-popconfirm
                                nzPopconfirmTitle="Are you sure {{
                                item.status ? 'deactive' : 'active'
                            }} this task?" (nzOnConfirm)="updateStatus(item._id, item.status, 'tests' )"
                                nzTooltipTitle="{{ item.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                                nz-tooltip><i nz-icon [nzType]="item.status ? 'stop' : 'check'" nzTheme="outline"></i>
                            </a>
                            <a *ngIf="!isTestComponent && !isExamCategoryComponent && userRole !== 'subAdmin'"
                                class="action-button" nz-popconfirm nzPopconfirmTitle="Are you sure {{
                                item.status ? 'deactive' : 'active'
                            }} this task?" (nzOnConfirm)="updateStatus(item._id, item.status, 'test-series')"
                                nzTooltipTitle="{{ item.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                                nz-tooltip><i nz-icon [nzType]="item.status ? 'stop' : 'check'" nzTheme="outline"></i>
                            </a>
                            <a *ngIf="isExamCategoryComponent && userRole !== 'subAdmin'" class="action-button"
                                nz-popconfirm nzPopconfirmTitle="Are you sure {{
                                        item.status ? 'deactive' : 'active'
                                    }} this task?"
                                (nzOnConfirm)="updateStatus(item._id, item.status, 'exam-categories')"
                                nzTooltipTitle="{{ item.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                                nz-tooltip><i nz-icon [nzType]="item.status ? 'stop' : 'check'" nzTheme="outline"></i>
                            </a>
                            <a *ngIf="isTestComponent && userRole !== 'subAdmin' " nz-button nzType="link" nzTooltipTitle="Delete"
                                nzTooltipPlacement="topCenter" nz-tooltip nz-popconfirm
                                nzPopconfirmTitle="Are you sure delete this Test?"
                                (nzOnConfirm)="removeTest(item._id)"><i nz-icon nzType="delete"
                                    nzTheme="outline"></i></a>
                        </div>
                    </nz-card>
                    <ng-template #extraTemplate>
                    </ng-template>
                </div>
            </div>
        </nz-card>
    </nz-tab>
    <nz-tab nzTitle="UnApproved">
        <nz-card *ngIf="unApprovedList" [nzLoading]="loading">
            <div *ngIf=" unApprovedList.length === 0">
                <nz-empty nzNotFoundImage="simple"></nz-empty>
            </div>
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
                <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24" class="mt4" *ngFor="let item of unApprovedList">
                    <nz-card class="height-100  gridView-card">
                        <nz-tag
                            [nzColor]="item.approveStatus === 'approved' ? 'green':  item.approveStatus === 'pending'? 'lime':'volcano' "
                            class="status-position">
                            {{item.approveStatus}} </nz-tag>
                        <img class="coverImage-size" *ngIf="item.image" alt="example"
                            [src]="mediaBaseUrl + item.image " />
                        <img class="coverImage-size" *ngIf="!item.image" alt="example"
                            src="../../../../assets/images/no-image.jpeg" />
                        <!-- <nz-divider></nz-divider> -->
                        <div class="fixed-titleDescription">
                            <b class="title-ellipsis mt3"> <app-mathjax [content]='item.name'></app-mathjax></b>
                            <b class="" *ngIf="item.categoryName"> <app-mathjax
                                    [content]='item.categoryName'></app-mathjax></b>
                            <!-- <p class="ellipsis fixed-height"> <app-mathjax [content]='item.description'></app-mathjax></p> -->
                        </div>
                        <div nz-row nzJustify="space-around" class="actions-button ">
                            <a nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
                                [routerLink]="['update', item._id]" routerLinkActive="router-link-active"><i nz-icon
                                    nzType="form" nzTheme="outline"></i></a>
                            <a *ngIf="isOpenDetails" nzTooltipTitle="Details" nzTooltipPlacement="topCenter" nz-tooltip
                                (click)="openDetails(item)" routerLinkActive="router-link-active"><i nz-icon
                                    nzType="eye" nzTheme="outline"></i>
                            </a>
                            <!-- test action buttons -->
                            <a *ngIf="isTestComponent && userRole !== 'subAdmin'" class="action-button" nz-popconfirm
                                nzPopconfirmTitle="Are you sure {{
                                item.status ? 'deactive' : 'active'
                            }} this task?" (nzOnConfirm)="updateStatus(item._id, item.status, 'tests')"
                                nzTooltipTitle="{{ item.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                                nz-tooltip><i nz-icon [nzType]="item.status ? 'stop' : 'check'" nzTheme="outline"></i>
                            </a>
                            <a *ngIf="isTestComponent && userRole !== 'subAdmin' " class="action-button top-margin"
                                nz-popconfirm nzPopconfirmTitle="Are you sure Approved this task?"
                                (nzOnConfirm)="updatePendingStatus(item._id, item.approveStatus , 'tests')"
                                nzTooltipTitle="{{'Approve'}}" nzTooltipPlacement="topRight" nz-tooltip><i nz-icon
                                    nzType="file-done" nzTheme="outline"></i></a>

                            <a *ngIf="isTestComponent && userRole !== 'subAdmin' && item.approveStatus !== 'rejected'"
                                class="action-button top-margin" nz-popconfirm
                                nzPopconfirmTitle="Are you sure Reject this task?"
                                (nzOnConfirm)="openRejectionReason(item._id, item.approveStatus, 'tests')"
                                nzTooltipTitle='Reject' nzTooltipPlacement="topRight" nz-tooltip><i nz-icon
                                    [nzType]="'close-circle'" nzTheme="outline"></i></a>

                            <!-- test-series action button -->
                            <a *ngIf="isTestSeriesComponent && userRole !== 'subAdmin'" class="action-button"
                                nz-popconfirm nzPopconfirmTitle="Are you sure {{
                                item.status ? 'deactive' : 'active'
                            }} this task?" (nzOnConfirm)="updateStatus(item._id, item.status, 'test-series')"
                                nzTooltipTitle="{{ item.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                                nz-tooltip><i nz-icon [nzType]="item.status ? 'stop' : 'check'" nzTheme="outline"></i>
                            </a>
                            <a *ngIf="isTestSeriesComponent && userRole !== 'subAdmin' "
                                class="action-button top-margin" nz-popconfirm
                                nzPopconfirmTitle="Are you sure Approved this task?"
                                (nzOnConfirm)="updatePendingStatus(item._id, item.approveStatus , 'test-series')"
                                nzTooltipTitle="{{'Approve'}}" nzTooltipPlacement="topRight" nz-tooltip><i nz-icon
                                    nzType="file-done" nzTheme="outline"></i></a>

                            <a *ngIf="isTestSeriesComponent && userRole !== 'subAdmin' && item.approveStatus !== 'rejected'"
                                class="action-button top-margin" nz-popconfirm
                                nzPopconfirmTitle="Are you sure Reject this task?"
                                (nzOnConfirm)="openRejectionReason(item._id, item.approveStatus, 'test-series')"
                                nzTooltipTitle='Reject' nzTooltipPlacement="topRight" nz-tooltip><i nz-icon
                                    [nzType]="'close-circle'" nzTheme="outline"></i></a>

                            <!-- exam-categories action button -->
                            <a *ngIf="isExamCategoryComponent && userRole !== 'subAdmin'" class="action-button"
                                nz-popconfirm nzPopconfirmTitle="Are you sure {{
                                        item.status ? 'deactive' : 'active'
                                    }} this task?"
                                (nzOnConfirm)="updateStatus(item._id, item.status, 'exam-categories')"
                                nzTooltipTitle="{{ item.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                                nz-tooltip><i nz-icon [nzType]="item.status ? 'stop' : 'check'" nzTheme="outline"></i>
                            </a>
                            <a *ngIf="isExamCategoryComponent && userRole !== 'subAdmin' "
                                class="action-button top-margin" nz-popconfirm
                                nzPopconfirmTitle="Are you sure Approved this task?"
                                (nzOnConfirm)="updatePendingStatus(item._id, item.approveStatus , 'exam-categories')"
                                nzTooltipTitle="{{'Approve'}}" nzTooltipPlacement="topRight" nz-tooltip><i nz-icon
                                    nzType="file-done" nzTheme="outline"></i></a>

                            <a *ngIf="isExamCategoryComponent && userRole !== 'subAdmin' && item.approveStatus !== 'rejected'"
                                class="action-button top-margin" nz-popconfirm
                                nzPopconfirmTitle="Are you sure Reject this task?"
                                (nzOnConfirm)="openRejectionReason(item._id, item.approveStatus, 'exam-categories')"
                                nzTooltipTitle='Reject' nzTooltipPlacement="topRight" nz-tooltip><i nz-icon
                                    [nzType]="'close-circle'" nzTheme="outline"></i></a>
                            <a *ngIf="isTestComponent " nz-button nzType="link" nzTooltipTitle="Delete"
                                nzTooltipPlacement="topCenter" nz-tooltip nz-popconfirm
                                nzPopconfirmTitle="Are you sure delete this Test?"
                                (nzOnConfirm)="removeTest(item._id)"><i nz-icon nzType="delete"
                                    nzTheme="outline"></i></a>


                        </div>
                    </nz-card>
                    <ng-template #extraTemplate>
                    </ng-template>
                </div>
            </div>
        </nz-card>
    </nz-tab>
</nz-tabset>