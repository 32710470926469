<nz-page-header class="site-page-header" nzBackIcon [nzTitle]="idForUpdate ? 'Update Resource' : 'Add Resource'">
</nz-page-header>
<div>
    <form nz-form [formGroup]="addUpdateResourceForm">
        <nz-card>
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                <div nz-col nzLg="16" nzMd="16" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="subject" nzRequired>Title</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                            <input nz-input formControlName="title" type="text" placeholder="Enter a Title " />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="" nzRequired>Published Date
                        </nz-form-label>
                        <nz-form-control>
                            <nz-date-picker class="width-100" formControlName="publishedDate"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="16" nzMd="16" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="subject" >Exam Category</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Please select your subject!">
                            <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory"
                                formControlName="examCategory" nzPlaceHolder="Select a option"
                                (nzOnSearch)="searchExamCategoryForDropdown($event)">
                                <nz-option *ngFor="let exam of allExamCategories" nzValue="{{ exam._id }}"
                                    nzLabel="{{ exam.categoryName }}"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="subject">PDF Upload</nz-form-label>
                        <nz-upload class="upload-list-inline" nzName="avatar"
                            (nzChange)="UploadImageFile($event, 'file', 0)" [nzName]="'file'" [nzShowUploadList]="false"
                            [nzBeforeUpload]="beforeRelatedMediaUpload" [nzAction]="mediaUploadUrl"
                            [nzHeaders]="customRequestHeaders" [nzAccept]="'.pdf'">
                            <button nz-button *ngIf="!pdfName" [nzLoading]="imageLoading">
                                <span nz-icon nzType="upload"></span>
                                Upload
                            </button>
                        </nz-upload>
                        <nz-card *ngIf="pdfName" class="question-image-card">
                            <span><span nz-icon nzType="file-pdf" nzTheme="outline"></span> {{pdfName}}</span>
                            <button nz-button nzDanger class="ml3" nzType="primary"  nzTooltipTitle="Delete" nz-tooltip nz-popconfirm
                            nzPopconfirmTitle="Are you sure delete this?"
                            (nzOnConfirm)="removeImage(pdfId)">
                                <span nz-icon nzType="delete" nzTheme="outline" ></span>
                            </button>
                        </nz-card>
                    </nz-form-item>
                </div>
            </div>
            <div class="right-aline">
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                    <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()">
                        {{idForUpdate? 'Update':'Submit'}}
                    </button>
                </div>
            </div>
        </nz-card>
    </form>
</div>
