<nz-page-header class="site-page-header" nzBackIcon [nzTitle]="idForUpdate ? 'Update Topic' : 'Add Topic'">
</nz-page-header>
<form nz-form [formGroup]="addUpdateTopicForm">
  <div nz-row>
    <nz-card nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="topicName" nzRequired>Topic</nz-form-label>
        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
          <input nz-input formControlName="topicName" type="text" placeholder="Enter topic " />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="subject" nzRequired>Subject</nz-form-label>
        <nz-form-control [nzSpan]="24" nzErrorTip="Please select your subject!">
          <nz-select (nzScrollToBottom)="loadMore('subject')"  nzAllowClear nzShowSearch nzServerSearch id="subject" formControlName="subject" nzPlaceHolder="Select a option"
            (nzOnSearch)="searchSubjectForDropdown($event)" (ngModelChange)="onChangeSubject($event)">
            <nz-option *ngFor="let subject of allSubjects" nzValue="{{ subject._id }}"
              nzLabel="{{ subject.subjectName }}"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="chapter">Chapter</nz-form-label>
        <nz-form-control [nzSpan]="24" nzErrorTip="Please select your chapter!">
          <nz-select (nzScrollToBottom)="loadMore('chapter')" nzAllowClear nzShowSearch nzServerSearch id="chapter" formControlName="chapter" nzPlaceHolder="Select a option"
            (nzOnSearch)="searchChapterForDropdown($event)">
            <nz-option *ngFor="let chapter of allChapters" nzValue="{{ chapter._id }}"
              nzLabel="{{ chapter.chapterName }}"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="description">Topic Description</nz-form-label>
        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
          <textarea formControlName="description" nz-input rows="4" placeholder="Enter Topic description"></textarea>
        </nz-form-control>
      </nz-form-item>
      <div nz-row nzJustify="end">
        <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()" class="submitoraddbutton">
          {{idForUpdate? 'Update':'Submit'}}
        </button>
      </div>
    </nz-card>
  </div>
</form>