import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { RoleGuard } from '../core/guards/role.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'subjects',
    canActivate: [RoleGuard],
  data: { key: 'subjects' },
    loadChildren: () =>
      import('./subject/subject.module').then((m) => m.SubjectModule),
  },
  {
    path: 'chapters',
    canActivate: [RoleGuard],
  data: { key: 'chapters' },
    loadChildren: () =>
      import('./chapter/chapter.module').then((m) => m.ChapterModule),
  },
  {
    path: 'topics',
    canActivate: [RoleGuard],
  data: { key: 'topics' },
    loadChildren: () =>
      import('./topic/topic.module').then((m) => m.TopicModule),
  },
  {
    path: 'exam',
    canActivate: [RoleGuard],
  data: { key: 'exam' },
    loadChildren: () =>
      import('./exam/exam.module').then((m) => m.ExamModule),
  },
  {
    path: 'exam-category',
    canActivate: [RoleGuard],
  data: { key: 'exam-category' },
    loadChildren: () =>
      import('./exam-category/exam-category.module').then((m) => m.ExamCategoryModule),
  },
  {
    path: 'question',
    canActivate: [RoleGuard],
  data: { key: 'question' },
    loadChildren: () =>
      import('./question/question.module').then((m) => m.QuestionModule),
  },
  {
    path: 'test-series',
    canActivate: [RoleGuard],
  data: { key: 'test-series' },
    loadChildren: () =>
      import('./test-series/test-series.module').then((m) => m.TestSeriesModule),
  },
  {
    path: 'test',
    canActivate: [RoleGuard],
  data: { key: 'test' },
    loadChildren: () =>
      import('./test/test.module').then((m) => m.TestModule),
  },
  {
    path: 'profile',
    component: MyProfileComponent,
  },{
    path: 'user',
    canActivate: [RoleGuard],
    data: { key: 'user' },
    loadChildren: () =>
      import('./user/user.module').then((m) => m.UserModule),
  },
  {
  path: 'plan',
  canActivate: [RoleGuard],
  data: { key: 'plan' },
    loadChildren: () =>
      import('./plan/plan.module').then((m) => m.PlanModule),
  },
  {
  path: 'success-stories',
  canActivate: [RoleGuard],
  data: { key: 'success-stories' },
    loadChildren: () =>
      import('./success-stories/success-stories.module').then((m) => m.SuccessStoriesModule),
  },
  {
  path: 'current-affairs',
  canActivate: [RoleGuard],
  data: { key: 'current-affairs' },
    loadChildren: () =>
      import('./current-affair/current-affair.module').then((m) => m.CurrentAffairModule),
  },
  {
  path: 'resource',
  canActivate: [RoleGuard],
  data: { key: 'resource' },
    loadChildren: () =>
      import('./resource/resource.module').then((m) => m.ResourceModule),
  },
  {
  path: 'dashboard-config',
  canActivate: [RoleGuard],
  data: { key: 'dashboard-config' },
    loadChildren: () =>
      import('./dashboard-config/dashboard-config.module').then((m) => m.DashboardConfigModule),
  },{
  path: 'sub-admin',
  canActivate: [RoleGuard],
  data: { key: 'sub-admin' },
    loadChildren: () =>
      import('./sub-admin/sub-admin.module').then((m) => m.SubAdminModule),
  },
  {
  path: 'testimonial',
  canActivate: [RoleGuard],
  data: { key: 'testimonial' },
    loadChildren: () =>
      import('./testimonial/testimonial.module').then((m) => m.TestimonialModule),
  },
  {
    path: 'editorials',
    canActivate: [RoleGuard],
    data: { key: 'editorials' },                                                  // for routeKey
      loadChildren: () =>
        import('./editorial/editorial.module').then((m) => m.EditorialModule),
    },
    {
    path: 'banners',
    canActivate: [RoleGuard],
    data: { key: 'banners' },                                                  // for routeKey
      loadChildren: () =>
        import('./banner/banner.module').then((m) => m.BannerModule),
    },
  // only access for admin
  {
  path: 'login-logs',
  canActivate: [RoleGuard],
    loadChildren: () =>
      import('./login-logs/login-logs.module').then((m) => m.LoginLogsModule),
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
