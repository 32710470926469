<div class="site-page-header-ghost-wrapper">
  <nz-page-header [nzGhost]="false">
    <nz-page-header-title class="ml4">Resources </nz-page-header-title>
    <nz-page-header-extra class="mr4">
      <button nz-button nzType="primary" [routerLink]="['add']" [queryParams]="{index: index}" routerLinkActive="router-link-active"
        class="submitoraddbutton">
        Add Resource
      </button>
    </nz-page-header-extra>
  </nz-page-header>
</div>
<div nz-row nzJustify="end" [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }" class="mt4">
  <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzErrorTip="Please select your subject!">
        <nz-form-label nzFor="chapter">Search By Exam-Category</nz-form-label>
        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" [(ngModel)]="selectedExamCategory"
          nzPlaceHolder="Select exam-category" (nzOnSearch)="searchExamCategoryDropdown($event)"
          (ngModelChange)="onChangeExamCategory()">
          <nz-option *ngFor="let item of allExamCategories" nzValue="{{ item._id }}"
            nzLabel="{{ item.categoryName }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <!-- <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control>
          <nz-form-label nzFor="exam-category">Published Date
          </nz-form-label>
            <nz-date-picker class="width-100" formControlName="publishedDate"></nz-date-picker>
        </nz-form-control>
    </nz-form-item>
</div> -->
<div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24" >
  <nz-form-label nzFor="Date">Published Date</nz-form-label>
  <nz-form-item>
    <nz-form-control [nzSpan]="24" nzErrorTip="Please select a Date!">
      <nz-range-picker class="width-100" [(ngModel)]="dateRange" (ngModelChange)="onChangeDateRange($event)"></nz-range-picker>
    </nz-form-control>
  </nz-form-item>
</div>
  <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24" class="mb5">
    <nz-form-label nzFor="chapter">Search</nz-form-label>
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input type="text" nz-input placeholder="Search by Resource name" ngModel
        (ngModelChange)="searchResources($event)" />
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div>
</div>
<!-- <div nz-row nzJustify="end" class="mb2">
  <nz-button-group class="ml3">
    <button nz-button [nzType]="!active && deActive?'primary':'link'" (click)="changeListView()"> <span class="icon-size" nz-icon nzType="ordered-list"
        nzTheme="outline"></span></button>
    <button nz-button [nzType]="active && !deActive?'primary':'link'"  (click)="changeGridView() "><span class="icon-size action-button" nz-icon
        nzType="appstore" nzTheme="outline"></span></button>
  </nz-button-group>
</div> -->

<nz-tabset (nzSelectedIndexChange)="changeIndex($event)" [nzSelectedIndex]="index">
  <nz-tab nzTitle="Approved Resources">
    <nz-table #resourceTbl [nzData]="allApprovedResources" [nzLoading]="loading" [nzTotal]="totalApprovedDataCount"
      [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex" [nzFrontPagination]="false"
      (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger [nzScroll]="{ x: '900px' }">
      <thead>
        <tr>
          <th nzColumnKey="name" [nzSortFn]="true">Resource Name</th>
          <th>Exam Category</th>
          <th>Published Date</th>
          <th>Created By</th>
          <th>Status</th>
          <th class="text-right" nzWidth="150px">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of allApprovedResources; index as i">
          <td><span *ngIf="data.title ">{{ data.title }}</span></td>
          <td><span *ngIf="data.examCategory && data.examCategory.categoryName">{{
              data.examCategory.categoryName}}</span></td>
          <td><span *ngIf="data.publishedDate">{{ data.publishedDate | date : "mediumDate" }}</span></td>
          <td><span *ngIf="data.createdBy?.role === 'subAdmin'">{{ data?.createdBy?.name }}</span></td>
          <td>
            <span class="{{ data.status ? 'active' : 'inactive' }} status"></span>
          </td>
          <td class="action-buttons">
            <a nz-button  nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
              [routerLink]="['update', data._id]" [queryParams]="{index: index}" routerLinkActive="router-link-active"><i nz-icon nzType="form"
                nzTheme="outline"></i></a>

            <a *ngIf="userRole !== 'subAdmin'" class="action-button" nz-button nz-popconfirm
              nzPopconfirmTitle="Are you sure {{data.status ? 'deActive' : 'active'}} this task?"
              (nzOnConfirm)="updateStatus(data._id, data.status)"
              nzTooltipTitle="{{data.status ? 'Deactive' : 'Active'}}" nzTooltipPlacement="topRight" nz-tooltip><i
                nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>

                <a  *ngIf="userRole !== 'subAdmin'" class="action-button delete" nz-button nz-popconfirm
                            nzPopconfirmTitle="Are you sure delete this resource?"
                            (nzOnConfirm)="deleteResource(data._id)"
                            z><i nz-icon nzType="delete" nzTheme="fill"></i></a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
  <nz-tab nzTitle="Un-approved Resources">
    <nz-table #resourceTbl [nzData]="allResources" [nzLoading]="loading" [nzTotal]="totalDataCount"
      [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex" [nzFrontPagination]="false"
      (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger [nzScroll]="{ x: '900px' }">
      <thead>
        <tr>
          <th nzColumnKey="name" [nzSortFn]="true">Resource Name</th>
          <th>Exam Category</th>
          <th>Published Date</th>
          <th>Created By</th>
          <th>Rejected Reason</th>
          <th>Status</th>
          <th class="text-right" nzWidth="150px">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of allResources; index as i">
          <td><span *ngIf="data.title ">{{ data.title }}</span></td>
          <td><span *ngIf="data.examCategory && data.examCategory.categoryName">{{
              data.examCategory.categoryName}}</span></td>
          <td><span *ngIf="data.publishedDate">{{ data.publishedDate | date : "mediumDate" }}</span></td>
          <td><span *ngIf="data.createdBy?.role === 'subAdmin'">
              {{ data?.createdBy?.name }}
            </span></td>
            <td><span *ngIf="data.rejectionReason" class="ellipsis">{{data.rejectionReason}}</span></td>
          <td>
            <nz-tag
              [nzColor]="data.approveStatus === 'approved' ? 'green':  data.approveStatus === 'pending'? 'lime':'volcano' ">
              {{data.approveStatus}}

            </nz-tag>
          </td>
          <!-- <td><span class="{{data.status? 'active' : 'inactive' }} status"></span></td> -->
          <td class="action-buttons">
            <a nz-button  nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
              [routerLink]="['update', data._id]" [queryParams]="{index: index}" routerLinkActive="router-link-active"><i nz-icon nzType="form"
                nzTheme="outline"></i></a>

            <a *ngIf="userRole !== 'subAdmin'" class="action-button" nz-button nz-popconfirm
              nzPopconfirmTitle="Are you sure {{data.status ? 'deActive' : 'active'}} this Resource?"
              (nzOnConfirm)="updateStatus(data._id, data.status)"
              nzTooltipTitle="{{data.status ? 'Deactive' : 'Active'}}" nzTooltipPlacement="topRight" nz-tooltip><i
                nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>

            <a *ngIf="userRole !== 'subAdmin' " class="action-button top-margin" nz-button nz-popconfirm
              nzPopconfirmTitle="Are you sure Approved this Resource?"
              (nzOnConfirm)="updatePendingStatus(data._id, data.approveStatus)" nzTooltipTitle="{{'Approve'}}"
              nzTooltipPlacement="topRight" nz-tooltip><i nz-icon nzType="file-done" nzTheme="outline"></i></a>

            <a *ngIf="userRole !== 'subAdmin' && data.approveStatus !== 'rejected'" class="action-button delete top-margin"
              nz-button nz-popconfirm nzPopconfirmTitle="Are you sure Reject this Resource?"
              (nzOnConfirm)="openRejectionReason(data._id, data.approveStatus)" nzTooltipTitle='Reject'
              nzTooltipPlacement="topRight" nz-tooltip><i nz-icon [nzType]="'close-circle'" nzTheme="fill"></i></a>

          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
</nz-tabset>



<!-- <div *ngIf=" !listView">
  <app-common-grid-view [list]="allResources" [isChapterComponent]="isChapterComponent" [isAddOrUpdate]="isAddOrUpdate" [isOpenDetails]="isOpenDetails" [loading]="loading"></app-common-grid-view>
  <div class="pagination">
    <nz-pagination [nzPageIndex]="pageIndex" [nzTotal]="totalDataCount" nzShowSizeChanger
      (nzPageSizeChange)="onQueryPageSizeChangeForGridView($event)" [(nzPageSize)]="pageSize"
      (nzPageIndexChange)="onQueryParamsChangeForGridView($event)"></nz-pagination>
  </div>
</div> -->
