<div style="background: #ECECEC; padding: 30px;">

  <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
    <nz-card nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
      <nz-row [nzGutter]="16">
        <nz-col [nzSpan]="4">
          <nz-statistic class="count-Color"  [nzValue]="(totalTestSeriesCount | number)!" [nzTitle]="'Total Test-Series'"></nz-statistic>
        </nz-col>
        <nz-col [nzSpan]="4">
          <nz-statistic class="count-Color" [nzValue]="( totalPaidTestSeriesCount| number)!" [nzTitle]="'Total Paid Series'"></nz-statistic>
        </nz-col>
        <nz-col [nzSpan]="4">
          <nz-statistic class="count-Color" [nzValue]="(totalUnpaidTestSeriesCount| number)!" [nzTitle]="'Total Unpaid Series'"></nz-statistic>
        </nz-col>
        <nz-col [nzSpan]="4">
          <nz-statistic class="count-Color" [nzValue]="(totalTestCount | number)!" [nzTitle]="'Total Tests'"></nz-statistic>
        </nz-col>
        <nz-col [nzSpan]="4">
          <nz-statistic class="count-Color" [nzValue]="( totalQuestionCount| number)!" [nzTitle]="'Total Questions'"></nz-statistic>
        </nz-col>
        <nz-col [nzSpan]="4">
          <nz-statistic class="count-Color" [nzValue]="(userCount| number)!" [nzTitle]="'Total User'"></nz-statistic>
        </nz-col>
      </nz-row>

    </nz-card>
    <nz-card  class="mt3"  nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" >
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
          <div class="end-button" nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
            <nz-range-picker  [nzRanges]="ranges" ngModel (ngModelChange)="onChange($event)"></nz-range-picker>
          </div>
          <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
            <nz-card class="mt4">
              <div id="chart">
                <apx-chart
                [series]="chartOptions.series!"
                [labels]="chartOptions.labels!"
                  [chart]="chartOptions.chart!"
                  [dataLabels]="chartOptions.dataLabels!"
                  [plotOptions]="chartOptions.plotOptions!"
                  [yaxis]="chartOptions.yaxis!"
                  [xaxis]="chartOptions.xaxis!"
                  [fill]="chartOptions.fill!"
                  [title]="chartOptions.title!"
                ></apx-chart>
              </div>
            </nz-card>
          </div>
          <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
            <!-- <nz-card class="mt4">
              <div id="chart">
                <apx-chart
                [series]="chartOptions.series!"
                [labels]="chartOptions.labels!"
                  [chart]="chartOptions.chart!"
                  [dataLabels]="chartOptions.dataLabels!"
                  [plotOptions]="chartOptions.plotOptions!"
                  [yaxis]="chartOptions.yaxis!"
                  [xaxis]="chartOptions.xaxis!"
                  [fill]="chartOptions.fill!"
                  [title]="chartOptions.title!"
                ></apx-chart>
              </div>
            </nz-card> -->
          </div>
        </div>
    </nz-card>
  </div>
</div>

