import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tr } from 'date-fns/locale';
import { toNumber } from 'ng-zorro-antd/core/util';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Chapter } from 'src/app/core/interfaces/chapter';
import { ExamCategory } from 'src/app/core/interfaces/exam-category';
import { Resource } from 'src/app/core/interfaces/resource';
import { Subjects } from 'src/app/core/interfaces/subjects';
import { HttpRequestService, LocalStorageService } from 'src/app/core/services';
import { RejectionReasonComponent } from 'src/app/shared/components/rejection-reason/rejection-reason.component';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  allResources: Resource[] = [];
  allApprovedResources: Resource[] = [];
  isAddOrUpdate:boolean = true;
  totalDataCount = 0;
  totalApprovedDataCount = 0;
  pageSize = 12;
  pageIndex = 1;
  loading = false;
  searchResource: Subject<string> = new Subject<string>();
  searchExamCategory: Subject<string> = new Subject<string>();
  search = '';
  examCategorySearch:string = '';
  allExamCategories: ExamCategory[] = [];
  selectedExamCategory: string = '';
  userRole: string = ''
  subAdminAddedResources: Resource[] = [];
  dateRange: any;
  startDate: any
  endDate: any;
  index : number = 0;
  skipIndex : number = 0;
  snapShootIndex : number = 0;

  constructor(
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private localStorageService: LocalStorageService,
    private modalService : NzModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.snapShootIndex = toNumber(this.activatedRoute.snapshot.queryParams.index) ;
    if(this.snapShootIndex){
      this.changeIndex(this.snapShootIndex)
    }
    this.userRole = this.localStorageService.getItem('user').role;
  }
  /* on page load function */
  ngOnInit(): void {
    this.getAllExamsCategory();
    this.searchResource.pipe(debounceTime(1000)).subscribe((success) => {
      this.search = success;
      this.getAllResources(this.index);
    });
    this.searchExamCategory.pipe(debounceTime(1000)).subscribe((success) => {
      this.examCategorySearch = success;
      this.getAllExamsCategory();
      this.getAllResources(this.index);
    });
  }
  onChangeDateRange(date: any){
    this.startDate = date[0]
    this.endDate = date[1]
    this.getAllResources(this.index)

  }
  changeIndex(data: any) {
    this.router.navigate([], { queryParams: {index: data } });
    this.pageIndex = 1;
    this.pageSize = 12
    this.allApprovedResources = [];
    this.allResources = [];
    this.index = data
      this.getAllResources();
  }
   /* Get all exam-category */
  getAllExamsCategory(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved'
    };
    if (this.examCategorySearch) {
      params.search = this.examCategorySearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'exam-categories', params).subscribe(
      (result: any) => {
        this.allExamCategories = result.data;
      },
      (err: any) => {}
    );
  }

  /* Get all chapters */
  getAllResources(index: number = 0, skip = 0, sortBy?: any): void {
    index = index? index : this.index
    let params: any;
    params = { skip: this.skipIndex, limit: this.pageSize };
    if (this.search) {
      params.search = this.search;
    }else{
      delete params.search
    }
    if (this.selectedExamCategory) {
      params.examCategory = this.selectedExamCategory;
    }else{
      delete params.subject
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if(this.startDate && this.endDate){
      params.startDate = this.startDate;
      params.endDate = this.endDate   ;
    }else{
      delete params.startDate;
      delete params.endDate;
    }
    if(index === 0){
      params.approveStatus = 'approved'
    }else if(index === 1 && this.userRole === 'admin'){
      params.approveStatus = "pending"
    }else if( index === 1 && this.userRole === 'subAdmin' ){
      params.approveStatus = ["pending", "rejected"]
    }else{
      delete params.approvedStatus
    }
    this.loading = true;
    this.httpRequestService.request('get', 'resources', params).subscribe(
      (result) => {
        this.loading = false;
        // const data : Resource[] = result.data;
        const data: Resource[] = result.data;
        if(index === 0){
          this.allApprovedResources = data;
          this.totalApprovedDataCount = result.totalCount;
        }else{
          this.allResources = data
          this.totalDataCount = result.totalCount;
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  onChangeExamCategory():void{
    this.getAllResources(this.index);
    }
  /* For Pagination / Sending skip */
  onQueryParamsChange(params: NzTableQueryParams): void {
    this.allApprovedResources = [];
    this.allResources = [];

    const { pageSize, pageIndex, sort } = params;
    let sortBy: any = {};
    sortBy[sort[0].key] = sort[0].value === 'ascend' ? 1 : -1;

    if (!sort[0].value) {
      sortBy = null;
    }
    this.skipIndex = pageSize * (pageIndex - 1)
    this.getAllResources(this.index, pageSize * (pageIndex - 1), sortBy);
  }

  /* status change*/
  updateStatus(id: string, status: boolean): void {
    this.httpRequestService
      .request('put', `resources/active-inactive/${id}`, { status: !status })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllResources(this.index);
      });
  }
  updatePendingStatus(id: string, pendingStatus: string) {
    this.httpRequestService
      .request('put', `resources/approved-rejected/${id}`, {
        approveStatus: 'approved',
      })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllResources(this.index);
      });
  }
  openRejectionReason(id: string, pendingStatus: string) {
      const pendingStatusData = 'rejected';
      const rejectionReason = this.modalService.create({
        nzTitle: 'Add A Reason',
        nzContent: RejectionReasonComponent,
        nzWidth: '800px',
        nzFooter: null,
        nzComponentParams: {
          updateId: id,
          component: 'resources',
        },
        nzCancelText: null,
        nzOkText: null,
      });

    const questionService = rejectionReason.afterClose.subscribe(
      (data: any) => {
        this.getAllResources(this.index);
      }
    );
  }
  deleteResource(id: any){
    this.httpRequestService
    .request('delete', `resources/${id}`)
    .subscribe((result: any) => {
      this.notificationService.success('', 'Resource Deleted Successfully');
      this.getAllResources(this.index);
    });
  }
  // search chapter
  searchResources(event: any): void {
    this.searchResource.next(event);
  }
  searchExamCategoryDropdown(event: any): void {
    this.searchExamCategory.next(event);
  }

}

