import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup } from '@angular/forms';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { TransferItem } from 'ng-zorro-antd/transfer';
import { Subject } from 'rxjs';
import { debounceTime, filter, pairwise } from 'rxjs/operators';
import {
  ConfigurationService,
  HttpRequestService,
} from 'src/app/core/services';
import { CommonArrayService } from 'src/app/core/services/common-array.service';
import { CommonService } from 'src/app/core/services/common.service';
import { AddUpdateParagraphQuestionComponent } from 'src/app/main/question/add-update-paragraph-question/add-update-paragraph-question.component';
import { AddUpdateQuestionComponent } from 'src/app/main/question/add-update-question/add-update-question.component';
import { QuestionDetailsComponent } from 'src/app/main/question/question-details/question-details.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Question } from 'src/app/core/interfaces/question';
import { Subjects } from 'src/app/core/interfaces/subjects';
import { Chapter } from 'src/app/core/interfaces/chapter';
import { Topic } from 'src/app/core/interfaces/topic';
import { LocalArray } from 'src/app/core/interfaces/local-array';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ExcelUploadComponent } from '../excel-upload/excel-upload.component';
import { ActivatedRoute } from '@angular/router';
import { AddExcelService } from 'src/app/core/services/add-excel.service';
@Component({
  selector: 'app-section-question',
  templateUrl: './section-question.component.html',
  styleUrls: ['./section-question.component.scss'],
})
export class SectionQuestionComponent implements OnInit {
  // sectionForm: FormGroup;

  @Input() totalQuestions = 1;
  @Input() totalMarks = 0;
  @Input() isNegativeMarking = false;
  @Input() examType = 'NORMAL';
  @Input() sectionForm: FormGroup | null = null;
  @Output() sectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() allAvailableQuestions: any[] = [];
  @Input() addUpdateTestForm: FormGroup | null = null;
  @Input() idForUpdate: string | null = null;
  selectedQuestions: any[] = [];
  excel: any[] = [];
  errorDataList: any[] = [];
  mediaBasePath = '';
  searchChapter: Subject<string> = new Subject<string>();
  searchSubject: Subject<string> = new Subject<string>();
  searchTopic: Subject<string> = new Subject<string>();
  pageSize: number = 100;
  pageIndexNormalQuestion: number = 1;
  pageIndexParagraphQuestion: number = 1;
  loading = false;
  allSubjects: Subjects[] = [];
  allChapters: Chapter[] = [];
  allTopics: Topic[] = [];
  search: string = '';
  allQuestions: TransferItem[] = [];
  $asTransferItems = (data: unknown): TransferItem[] => data as TransferItem[];
  allLevels: LocalArray[] = [];
  totalDataCountNormalQuestion: number = 0;
  totalDataCountParagraphQuestion: number = 0;
  allNormalQuestions: Question[] = [];
  allParagraphQuestions: Question[] = [];
  normalFilterData: Question[] = [];
  paragraphFilterData: Question[] = [];
  modalForNormalQuestion: NzModalRef | null = null;
  modalForExcelQuestion: NzModalRef | null = null;
  modalForParagraphQuestion: NzModalRef | null = null;
  index: number = 0;
  switchValue: boolean = false;
  bulkSwitchValue: boolean = false;
  normalQuestionLimit: boolean = false;
  paragraphQuestionLimit: boolean = false;
  subjectSearch: string = '';
  chapterSearch: string = '';
  topicSearch: string = '';
  newSelectedQuestions: any = [];
  order: number = 1;
  isIndexChange: boolean = false;
  indexNotChange: number = 0;
  remove: boolean = false;
  startDate: any;
  endDate: any;
  dateRange: any;
  targetIndex: any;
  currentIndex: any;
  selectedSubAdmin = '';
  allSubAdmins: any[] = [];
  searchSubAdmin: Subject<string> = new Subject<string>();
  subAdminSearch = '';
  constructor(
    private excelService: AddExcelService,
    private fb: FormBuilder,
    private configurationService: ConfigurationService,
    private commonArrayService: CommonArrayService,
    private httpRequestService: HttpRequestService,
    private modalService: NzModalService,
    private commonService: CommonService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private drawerService: NzDrawerService // private modalReference : NzModalRef,
  ) {
    this.mediaBasePath = configurationService.mediaBaseUrl;
  }

  // on page load functions
  ngOnInit(): void {
    // this.modalReference.afterClose.subscribe(result =>);
    const bulkValue = this.activatedRoute.snapshot.queryParams.bulk;

    if (bulkValue) {
      this.bulkSwitchValue = true;
    }
    this.allLevels = this.commonArrayService.difficultyLevel;
    this.searchChapter.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.chapterSearch = success;
      this.getAllChapters();
    });
    this.searchSubject.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.subjectSearch = success;
      this.getAllSubjects();
    });
    this.searchTopic.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.topicSearch = success;
      this.getAllTopics();
    });
    this.searchSubAdmin.pipe(debounceTime(1000)).subscribe((success) => {
      this.subAdminSearch = success;
      this.getAllSubAdmins();
    });

    this.getAllChapters();
    this.getAllSubjects();
    this.getAllTopics();
    this.getAllParagraphQuestions();
    this.getAllNormalQuestions();
    //
    if (this.addUpdateTestForm) {
      this.addUpdateTestForm
        ?.get('negativeMarks')
        ?.valueChanges.pipe(pairwise())
        .subscribe((data: any) => {
          //;
          if (data && data.length) {
            let lastData = data[data.length - 1];
            // this.newSelectedQuestions = [...this.selectedQuestions]
            this.selectedQuestions.forEach((data: any) => {
              data.negativeMarks = lastData;
            });
          }
        });
    }
    const formValue = this.sectionForm?.value;

    /* if(this.negetiveMarks){
      this.sectionForm?.patchValue({
        negativeMarks:this.negetiveMarks
      })
    } */

    if (formValue && formValue.questions && formValue.questions.length) {
      // remove object from array
      this.selectedQuestions = formValue.questions.map(
        (data: any, index: number) => {
          return {
            ...data,
            ...data.question,
            isChecked: false,
            index: index + 1,
          };
        }
      );
      this.newSelectedQuestions = [...this.selectedQuestions];
      this.filterSectionValues();
    }
  }
  onChangeDateRange(date: any) {
    this.startDate = date[0];
    this.endDate = date[1];

    this.getAllFilteredNormalQuestions();
    this.getAllFilteredParagraphQuestions();
  }

  clickChange() {
    this.switchValue = !this.switchValue;
  }
  tabChange(data: any) {
    this.index = data;
  }

  ngAfterViewInit(): void {
    this.getAllChapters();
  }

  // Get alert type based with different color
  getAlertType(a: number, b: number): 'success' | 'info' | 'warning' | 'error' {
    if (a === b) {
      return 'success';
    } else if (a > b) {
      return 'warning';
    } else {
      return 'error';
    }
  }

  // Calculation of total marks
  getTotalMarks(): number {
    return this.selectedQuestions.reduce(
      (a: number, b: any) => Number(a) + Number(b.marks),
      0
    );
  }

  // Question remove
  removeQuestion(): void {
    const removedItems = this.selectedQuestions.filter((x) => x.isChecked);
    let allItemsToBeRemoved: any[] = [...removedItems];
    removedItems.forEach((data: any) => {
      if (data.paragraph) {
        // get all paragraph question from selectedQuestions
        const allParagraphQuestion = this.selectedQuestions.filter(
          (x) => x.paragraph === data.paragraph
        );
        allItemsToBeRemoved = [
          ...new Set([...allItemsToBeRemoved, ...allParagraphQuestion]),
        ];
      }
    });

    /* -------------------------------------------------------------------------- */
    /* this.selectedQuestions.filter(
        (item: any) =>
          allItemsToBeRemoved.map((rmItem: any) => rmItem._id).includes(item._id)
      ).forEach((item:any) => {
        this.allNormalQuestions.push(item);
      }) */
    /* -------------------------------------------------------------------------- */

    this.selectedQuestions = this.selectedQuestions.filter(
      (item: any) =>
        !allItemsToBeRemoved.map((rmItem: any) => rmItem._id).includes(item._id)
    );
    this.newSelectedQuestions = [...this.selectedQuestions];
    this.patchSectionValue();
    this.filterSectionValues();
    this.getAllFilteredNormalQuestions();
    this.getAllFilteredParagraphQuestions();
  }
  marksChangeInSection(data: any) {
    this.selectedQuestions = [
      ...this.selectedQuestions.map((x) => ({
        ...x,
        marks:
          this.sectionForm?.value && this.sectionForm?.value.isFixedNegativeMark
            ? data
            : x.marks,
      })),
    ];
    this.newSelectedQuestions = [...this.selectedQuestions];
    this.patchSectionValue();
  }
  negativeMarksChangeInSection(data: any) {
    this.selectedQuestions = [
      ...this.selectedQuestions.map((x) => ({
        ...x,
        negativeMarks:
          this.sectionForm?.value && this.sectionForm?.value.isFixedNegativeMark
            ? data
            : x.negativeMarks,
      })),
    ];
    this.newSelectedQuestions = [...this.selectedQuestions];
    this.patchSectionValue();
  }
  // Add Normal question
  addQuestion(): void {
    this.selectedQuestions = [
      ...this.selectedQuestions,
      ...this.normalFilterData
        .filter((x) => x.isChecked)
        .map((x) => ({
          ...x,
          isChecked: false,
          questionType: 'NORMAL',
          negativeMarks:
            this.sectionForm?.value &&
            this.sectionForm?.value.isFixedNegativeMark
              ? this.sectionForm?.value.negativeMarks
              : x.negativeMarks,
          marks:
            this.sectionForm?.value &&
            this.sectionForm?.value.isFixedNegativeMark
              ? this.sectionForm?.value.marks
              : x.marks,
        })),
    ];
    const selectedParagraphQuestions: any[] = this.paragraphFilterData
      .filter((x) => x.isChecked)
      .map((x) => ({
        ...x,
        isChecked: false,
        questionType: 'PARAGRAPH',
        negativeMarks:
          this.sectionForm?.value && this.sectionForm?.value.isFixedNegativeMark
            ? this.sectionForm?.value.negativeMarks
            : x.negativeMarks || 0,
        marks:
          this.sectionForm?.value && this.sectionForm?.value.isFixedNegativeMark
            ? this.sectionForm?.value.marks
            : x.marks || 0,
      }));

    // marge with selected question when seleceted paragraph question length > 0
    if (selectedParagraphQuestions && selectedParagraphQuestions.length) {
      selectedParagraphQuestions.forEach((data: any) => {
        data.questions.forEach((element: any) => {
          element['paragraph'] = data.paragraph;
        });

        if (
          this.sectionForm &&
          this.sectionForm?.value &&
          this.sectionForm?.value.isFixedNegativeMark
        ) {
          data.questions.forEach((item: any) => {
            item['negativeMarks'] =
              this.sectionForm?.value &&
              this.sectionForm?.value.isFixedNegativeMark
                ? this.sectionForm?.value.negativeMarks
                : item.negativeMarks || 0;
            item['marks'] =
              this.sectionForm?.value &&
              this.sectionForm?.value.isFixedNegativeMark
                ? this.sectionForm?.value.marks
                : item.marks || 0;
          });
        }
        this.selectedQuestions.push(...data.questions);
      });
    }
    this.selectedQuestions.forEach((x, index) => {
      (x.index = index + 1), (x.isChangeToEditIndex = false);
    });
    this.newSelectedQuestions = [...this.selectedQuestions];
    //
    this.patchSectionValue();
    this.filterSectionValues();
  }

  // Patch complete question section box
  patchSectionValue(): void {
    this.sectionForm?.patchValue({
      questions: this.selectedQuestions.map((x, index) => ({
        question: x._id,
        order: index + 1,
        negativeMarks: x.negativeMarks || 0,
        marks: x.marks || 0,
        paragraphId: x.paragraph,
      })),
    });
  }
  changeIndex(item: any, index: any, currentIndex: any) {
    const changeItemList = this.selectedQuestions;
    const id = item._id; // get item id
    const itemIndex = index - 1; // get item index
    const itemTargetIndex = currentIndex - 1; // get item target index
    // target index check
    if (
      itemTargetIndex > this.selectedQuestions.length ||
      itemTargetIndex === -1
    ) {
      this.newSelectedQuestions = [...changeItemList];
    } else {
      const removeTargetItem = changeItemList[itemTargetIndex]; // get item target element
      const removeItem = changeItemList[itemIndex]; // get item element
      // paragraph id  and question type check
      if (
        removeTargetItem.paragraph === removeItem.paragraph &&
        removeTargetItem.questionType === removeItem.questionType
      ) {
        changeItemList.splice(itemIndex, 1, removeTargetItem); // remove item and paste target item
        changeItemList.splice(itemTargetIndex, 1, removeItem); // remove target item and paste item
        // change swap items index
        changeItemList.forEach((item, index) => {
          if (item._id === id || item._id === removeTargetItem._id) {
            item.index = index + 1;
          }
        });
        this.newSelectedQuestions = [...changeItemList];
      } else {
        this.notificationService.error(
          '',
          "You can't shift the location of this Question! "
        );
        //;
      }

      removeItem.isChangeToEditIndex = false;
    }
    this.targetIndex = null;
    // this.selectedQuestions.splice()
  }
  /* Get all chapters */
  getAllChapters(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved',
    };
    if (this.sectionForm?.value.subject) {
      params['subject'] = this.sectionForm?.value.subject;
    } else {
      delete params.subject;
    }
    if (this.chapterSearch) {
      params.search = this.chapterSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'chapters', params).subscribe(
      (result: any) => {
        this.allChapters = result.data;
      },
      (err: any) => {}
    );
  }

  /* On change chapter get course */
  onChangeChapter(value: string, skip = 0): void {
    this.allTopics = [];
    this.sectionForm?.patchValue({
      topic: '',
    });
    this.getAllTopics();
    this.getAllFilteredNormalQuestions();
    this.getAllFilteredParagraphQuestions();
  }
  /* Get all Subjects */
  getAllSubjects(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved',
    };
    if (this.subjectSearch) {
      params.search = this.subjectSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'subjects', params).subscribe(
      (result: any) => {
        this.allSubjects = result.data;
      },
      (err: any) => {}
    );
  }

  /* On change subject get chapter */
  onChangeSubject(value: string, skip = 0): void {
    this.allTopics = [];
    this.allChapters = [];
    this.sectionForm?.patchValue({
      chapter: '',
    });
    this.getAllChapters();
    this.getAllTopics();
    this.getAllFilteredNormalQuestions();
    this.getAllFilteredParagraphQuestions();
    // this.getAllNormalQuestions();
    // this.getAllParagraphQuestions();
  }
  /* Get all topics */
  getAllTopics(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved',
    };
    if (this.sectionForm?.value.subject) {
      params['subject'] = this.sectionForm?.value.subject;
    } else {
      delete params.subject;
    }
    if (this.sectionForm?.value.chapter) {
      params['chapter'] = this.sectionForm?.value.chapter;
    } else {
      delete params.chapter;
    }
    if (this.topicSearch) {
      params.search = this.topicSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'topics', params).subscribe(
      (result: any) => {
        this.allTopics = result.data;
      },
      (err: any) => {}
    );
  }
  /* On change topic get questions */
  onChangeTopic(value: string, skip = 0): void {
    this.getAllFilteredNormalQuestions();
    this.getAllFilteredParagraphQuestions();
  }
  onChangeDifficultyLevel(value: string, skip = 0): void {
    this.getAllFilteredNormalQuestions();
    this.getAllFilteredParagraphQuestions();
  }
  getAllFilteredNormalQuestions(): void {
    this.allNormalQuestions = [];
    this.getAllNormalQuestions(0);
  }
  getAllFilteredParagraphQuestions(): void {
    this.allParagraphQuestions = [];
    this.getAllParagraphQuestions(0);
  }
  /* Get all paragraph Questions */
  getAllParagraphQuestions(skip = 0, sortBy?: any): void {
    this.allParagraphQuestions = [];
    let params: any;
    params = {
      skip,
      limit: this.pageSize,
      status: true,
      approveStatus: 'approved',
    };
    if (this.sectionForm?.value.subject) {
      params['subject'] = this.sectionForm?.value.subject;
    } else {
      delete params.subject;
    }
    if (this.sectionForm?.value.chapter) {
      params['chapter'] = this.sectionForm?.value.chapter;
    } else {
      delete params.chapter;
    }
    if (this.sectionForm?.value.topic) {
      params['topic'] = this.sectionForm?.value.topic;
    } else {
      delete params.topic;
    }
    if (this.sectionForm?.value.difficultyLevel) {
      params['difficultyLevel'] = this.sectionForm?.value.difficultyLevel;
    } else {
      delete params.difficultyLevel;
    }
    if (this.sectionForm?.value.adminId) {
      params['adminId'] = this.sectionForm?.value.adminId;
      this.allParagraphQuestions = [];
    } else {
      delete params.adminId;
    }
    if (this.search) {
      params.search = this.search;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if (this.startDate && this.endDate) {
      params.startDate = this.startDate;
      params.endDate = this.endDate;
    } else {
      delete params.startDate;
      delete params.endDate;
    }
    this.loading = true;
    this.httpRequestService
      .request('get', 'questions/group-by-paragraph', params)
      .subscribe(
        (result) => {
          this.loading = false;
          this.allParagraphQuestions.push(...result.data);

          this.totalDataCountParagraphQuestion = result.totalCount;
          if (
            this.allParagraphQuestions.length ===
            this.totalDataCountParagraphQuestion
          ) {
            this.paragraphQuestionLimit = true;
          }
          if (this.sectionForm?.value) {
            this.filterSectionValues();
          }
        },
        (err) => {
          this.loading = false;
        }
      );
    // this.filterSectionValues();
  }
  // get all normalQuestion
  getAllNormalQuestions(skip = 0, sortBy?: any): void {
    let params: any;
    params = {
      skip,
      limit: this.pageSize,
      status: true,
      approveStatus: 'approved',
    };
    if (this.sectionForm?.value.subject) {
      params['subject'] = this.sectionForm?.value.subject;
    } else {
      delete params.subject;
    }
    if (this.sectionForm?.value.chapter) {
      params['chapter'] = this.sectionForm?.value.chapter;
    } else {
      delete params.chapter;
    }
    if (this.sectionForm?.value.topic) {
      params['topic'] = this.sectionForm?.value.topic;
    } else {
      delete params.topic;
    }
    if (this.sectionForm?.value.difficultyLevel) {
      params['difficultyLevel'] = this.sectionForm?.value.difficultyLevel;
    } else {
      delete params.difficultyLevel;
    }
    if (this.sectionForm?.value.adminId) {
      this.allNormalQuestions = [];
      params['adminId'] = this.sectionForm?.value.adminId;
    } else {
      delete params.adminId;
    }
    if (this.search) {
      params.search = this.search;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if (this.startDate && this.endDate) {
      params.startDate = this.startDate;
      params.endDate = this.endDate;
    } else {
      delete params.startDate;
      delete params.endDate;
    }
    this.loading = true;

    this.httpRequestService
      .request('get', 'questions/paragraph-not-exists-questions', params)
      .subscribe(
        (result) => {
          const allData = result.data;

          this.loading = false;
          this.allNormalQuestions.push(...allData);
          this.totalDataCountNormalQuestion = result.totalCount;
          if (
            this.allNormalQuestions.length === this.totalDataCountNormalQuestion
          ) {
            this.normalQuestionLimit = true;
          }

          this.filterSectionValues();
        },
        (err) => {
          this.loading = false;
        }
      );
  }
  filterSectionValues() {
    // normal question filter
    this.normalFilterData = this.allNormalQuestions
      .filter((item: any) => {
        return !this.newSelectedQuestions
          .map((q: any) => q._id)
          .includes(item._id);
      })
      .map((item: any) => ({ ...item, isChecked: false }));
    // paragraph question filter
    this.paragraphFilterData = this.allParagraphQuestions
      .filter(
        (item: any) =>
          !this.newSelectedQuestions
            .map((q: any) => q.paragraph)
            .filter((x: any) => x)
            .map((x: any) => x._id)
            .includes(item.paragraph['_id'])
      )
      .map((item: any) => ({ ...item, isChecked: false }));
    this.loading = false;

    // selected question value filter
    this.selectedQuestions = Array.from(
      new Set(this.selectedQuestions.map((item) => item._id))
    ).map((id) => this.selectedQuestions.find((obj) => obj._id === id));
    this.newSelectedQuestions = [...this.selectedQuestions];
  }

  // search Chapter
  searchChapterForDropdown(event: any): void {
    if (event) {
      this.searchChapter.next(event);
    }
  }
  // search Subject
  searchSubjectForDropdown(event: any): void {
    if (event) {
      this.searchSubject.next(event);
    }
  }
  // search Topic
  searchTopicForDropdown(event: any): void {
    if (event) {
      this.searchTopic.next(event);
    }
  }
  /* For Pagination / Sending skip */
  paginationForNormalQuestions(): void {
    this.pageIndexNormalQuestion = this.pageIndexNormalQuestion + 1;
    this.getAllNormalQuestions(
      this.pageSize * (this.pageIndexNormalQuestion - 1)
    );
  } /* For Pagination / Sending skip */
  paginationForParagraphQuestions(): void {
    this.pageIndexParagraphQuestion = this.pageIndexParagraphQuestion + 1;

    this.getAllParagraphQuestions(
      this.pageSize * (this.pageIndexParagraphQuestion - 1)
    );
  }
  //---------- pagination ----------------//
  pageChange(data: number) {
    this.getAllParagraphQuestions(this.pageSize * (data - 1));
    this.getAllNormalQuestions(this.pageSize * (data - 1));
  }
  dataListChange(data: number) {
    this.pageSize = data;
    this.getAllParagraphQuestions();
    this.getAllNormalQuestions();
  }

  openQuestion() {
    this.modalForNormalQuestion = this.modalService.create({
      nzTitle: '',
      nzContent: AddUpdateQuestionComponent,
      nzWidth: '95%',
      nzFooter: null,
      nzComponentParams: {
        isFromOtherComponent: true,
      },
      nzCancelText: null,
      nzOkText: null,
      nzCentered: true,
    });

    const questionService = this.commonService.$addQuestion.subscribe(
      (data: any) => {
        // data.forEach((item:any) => {
        //   this.allNormalQuestions.push(item);
        // })
        this.selectedQuestions = [...this.selectedQuestions, ...data].sort(
          (a, b) => a.serialNo - b.serialNo
        );
        this.selectedQuestions = this.selectedQuestions.map((x) => ({
          ...x,
          negativeMarks:
            this.sectionForm?.value &&
            this.sectionForm?.value.isFixedNegativeMark
              ? this.sectionForm?.value.negativeMarks
              : x.negativeMarks || 0,
          marks:
            this.sectionForm?.value &&
            this.sectionForm?.value.isFixedNegativeMark
              ? this.sectionForm?.value.marks
              : x.marks || 0,
        }));
        this.indexChangeInSelectedQuestion();
        this.newSelectedQuestions = [...this.selectedQuestions];
        this.modalForNormalQuestion?.destroy();
        questionService.unsubscribe();
        this.filterSectionValues();
        this.patchSectionValue();
      }
    );
  }
  openBulkQuestion() {
    this.bulkSwitchValue = true;
    //
    this.modalForExcelQuestion = this.modalService.create({
      nzTitle: 'Add Question',
      nzContent: ExcelUploadComponent,
      nzWidth: '1300px',
      nzFooter: null,
      nzComponentParams: {},
      nzCancelText: null,
      nzOkText: null,
    });

    const excelQuestionService = this.commonService.$addBulkQuestion.subscribe(
      (data: any) => {
        const excelData = [...data.successData, ...data.duplicateData];
        this.errorDataList = [...data.errorData];
        this.selectedQuestions = [...this.selectedQuestions, ...excelData].sort(
          (a, b) => a.serialNo - b.serialNo
        );
        this.selectedQuestions = this.selectedQuestions.map((x) => ({
          ...x,
          negativeMarks:
            this.sectionForm?.value &&
            this.sectionForm?.value.isFixedNegativeMark
              ? this.sectionForm?.value.negativeMarks
              : x.negativeMarks || 0,
          marks:
            this.sectionForm?.value &&
            this.sectionForm?.value.isFixedNegativeMark
              ? this.sectionForm?.value.marks
              : x.marks || 0,
        }));
        this.selectedQuestions.forEach((x, index) => {
          (x.question = x._id),
            (x.index = index + 1),
            (x.isChangeToEditIndex = false),
            (x.isChecked = false);
        });
        this.selectedQuestions = this.selectedQuestions.filter(
          (obj, index) =>
            this.selectedQuestions.findIndex(
              (item) => item.questionHash === obj.questionHash
            ) === index
        );
        this.newSelectedQuestions = [...this.selectedQuestions];

        this.modalForNormalQuestion?.destroy();
        excelQuestionService.unsubscribe();
        this.filterSectionValues();
        this.patchSectionValue();
      }
    );
    // paragraph Question Add
    const excelParagraphQuestionService =
      this.commonService.$addParagraphQuestion.subscribe((data: any) => {
        const excelData = [...data.successData, ...data.duplicateData];
        this.errorDataList = [...data.errorData];
        this.selectedQuestions = [...this.selectedQuestions, ...excelData].map(
          (x) => ({
            ...x,
            negativeMarks:
              this.sectionForm?.value &&
              this.sectionForm?.value.isFixedNegativeMark
                ? this.sectionForm?.value.negativeMarks
                : x.negativeMarks || 0,
            marks:
              this.sectionForm?.value &&
              this.sectionForm?.value.isFixedNegativeMark
                ? this.sectionForm?.value.marks
                : x.marks || 0,
          })
        );
        this.selectedQuestions.forEach((x, index) => {
          (x.question = x._id),
            (x.index = index + 1),
            (x.isChangeToEditIndex = false),
            (x.isChecked = false);
        });
        this.selectedQuestions = this.selectedQuestions.filter(
          (obj, index) =>
            this.selectedQuestions.findIndex(
              (item) => item.questionHash === obj.questionHash
            ) === index
        );
        this.newSelectedQuestions = [...this.selectedQuestions];

        this.newSelectedQuestions = [...this.selectedQuestions];
        this.modalForParagraphQuestion?.destroy();
        excelParagraphQuestionService.unsubscribe();
        this.filterSectionValues();
        this.patchSectionValue();
      });
  }
  openParagraphQuestion() {
    this.modalForParagraphQuestion = this.modalService.create({
      nzTitle: 'Add Question',
      nzContent: AddUpdateParagraphQuestionComponent,
      nzWidth: '95%',
      nzComponentParams: {
        isFromOtherComponent: true,
      },
      nzFooter: null,
      nzCancelText: null,
      nzOkText: null,
      nzCentered: true,
    });
    const paragraphQuestionService =
      this.commonService.$addParagraphQuestion.subscribe((data: any) => {
        this.selectedQuestions = [...this.selectedQuestions, ...data].sort(
          (a, b) => a.serialNo - b.serialNo
        );
        this.selectedQuestions = this.selectedQuestions.map((x) => ({
          ...x,
          negativeMarks:
            this.sectionForm?.value &&
            this.sectionForm?.value.isFixedNegativeMark
              ? this.sectionForm?.value.negativeMarks
              : x.negativeMarks || 0,
          marks:
            this.sectionForm?.value &&
            this.sectionForm?.value.isFixedNegativeMark
              ? this.sectionForm?.value.marks
              : x.marks || 0,
        }));
        this.selectedQuestions.forEach((x, index) => {
          (x.index = index + 1), (x.isChangeToEditIndex = false);
        });
        this.newSelectedQuestions = [...this.selectedQuestions];
        this.modalForParagraphQuestion?.destroy();
        paragraphQuestionService.unsubscribe();
        this.filterSectionValues();
        this.patchSectionValue();
      });
  }
  openQuestionDetails(item: any) {
    const drawerRef = this.drawerService.create({
      nzTitle: 'Question Details',
      nzFooter: '',
      nzWidth: '80%',
      nzContent: QuestionDetailsComponent,
      nzContentParams: {
        value: item,
        isShowMarkNagativeMark: true,
        marks: item.marks,
        negativeMarks: item.negativeMarks,
        component: 'isFromQuestion',
      },
    });
    drawerRef.afterClose.subscribe((result: any) => {
      this.allNormalQuestions.forEach((item: any) => {
        if (result) {
          if (item._id === result._id) {
            item.questionText = result.questionText;
            item.options = result.options;
            item.subject = result.subject;
            item.chapter = result.chapter;
            item.topic = result.topic;
          }
        }
      });
      this.selectedQuestions.forEach((item: any) => {
        if (result) {
          if (item._id === result._id) {
            item.questionText = result.questionText;
            item.options = result.options;
            item.subject = result.subject;
            item.chapter = result.chapter;
            item.topic = result.topic;
            // item = JSON.parse( JSON.stringify( result ) )
          }
        }
      });
      this.newSelectedQuestions = [...this.selectedQuestions];
      this.filterSectionValues();
      // this.getAllNormalQuestions();
    });
  }
  deleteQuestions(id: any) {
    this.httpRequestService
      .request('delete', `questions/${id}`)
      .subscribe((result: any) => {
        this.notificationService.success('', 'Question Deleted Successfully');
        this.getAllFilteredNormalQuestions();
        this.getAllFilteredParagraphQuestions();
        this.selectedQuestions.filter((item: any, index: any) => {
          if (item._id === id) {
            this.selectedQuestions.splice(index, 1);
          }
        });
        this.indexChangeInSelectedQuestion();
        this.newSelectedQuestions = [...this.selectedQuestions];
      });
  }
  indexChangeInSelectedQuestion() {
    this.selectedQuestions.forEach((x, index) => {
      (x.index = index + 1), (x.isChangeToEditIndex = false);
    });
  }
  inputValueChanged(index: any, targetIndex: any) {
    this.currentIndex = index;
    this.targetIndex = targetIndex;
  }
  downloadExcel(isBlank: any) {
    if (this.errorDataList && this.errorDataList.length) {
      this.excel = this.errorDataList;
    }
    this.excelService.exportAsExcelFile(this.excel, 'Error-Sheet', isBlank);
  }
  getAllSubAdmins(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      role :'subAdmin'
    };
    if (this.selectedSubAdmin) {
      params.search = this.selectedSubAdmin;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'auth-admins', params).subscribe(
      (result: any) => {
        this.allSubAdmins = result.data;
      },
      (err: any) => {}
    );
  }
  onChangeSubAdmin(): void {
    console.log('index------',this.index)
    if (this.index === 0) {
      this.getAllNormalQuestions();
    } else {
      this.getAllParagraphQuestions();
    }
  }
  searchSubAdminForDropdown(event: any): void {
    this.searchSubAdmin.next(event);
  }
}
