import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CurrentAffairRoutingModule } from './current-affair-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CurrentAffairsComponent } from './current-affairs/current-affairs.component';
import { CurrentAffairDetailsComponent } from './current-affair-details/current-affair-details.component';
import { AddUpdateCurrentAffairsComponent } from './add-update-current-affairs/add-update-current-affairs.component';

@NgModule({
  declarations: [
    CurrentAffairsComponent,
    CurrentAffairDetailsComponent,
    AddUpdateCurrentAffairsComponent
  ],
  imports: [
    CommonModule,
    CurrentAffairRoutingModule,
    SharedModule
  ]
})
export class CurrentAffairModule { }
