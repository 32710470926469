import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainRoutingModule } from './main-routing.module';
import { SharedModule } from '../shared/shared.module';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { NgApexchartsModule } from 'ng-apexcharts';
@NgModule({
  declarations: [MyProfileComponent],
  imports: [CommonModule, MainRoutingModule, SharedModule, NgApexchartsModule],
})
export class MainModule {}
