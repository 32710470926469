import { Component, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Exam } from 'src/app/core/interfaces/exam';
import { ExamCategory } from 'src/app/core/interfaces/exam-category';
import { TestSeries } from 'src/app/core/interfaces/test-series';
import { HttpRequestService, LocalStorageService } from 'src/app/core/services';
import { CommonArrayService } from 'src/app/core/services/common-array.service';
import { RejectionReasonComponent } from 'src/app/shared/components/rejection-reason/rejection-reason.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';
import { TestSeriesDetailsComponent } from '../test-series-details/test-series-details.component';
import { toNumber } from 'ng-zorro-antd/core/util';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-test-series',
  templateUrl: './test-series.component.html',
  styleUrls: ['./test-series.component.scss'],
})
export class TestSeriesComponent implements OnInit {
  listView = true;
  active: boolean = false;
  deActive: boolean = true;
  gridView = false;
  totalApprovedDataCount = 0;
  totalDataCount = 0;
  isOpenDetails = false;
  pageSize: number = 20;
  pageIndex: number = 1;
  loading = false;
  searchExamCategory: Subject<string> = new Subject<string>();
  searchExam: Subject<string> = new Subject<string>();
  searchQuestion: Subject<string> = new Subject<string>();
  search: string = '';
  examSearch: string = '';
  examCategorySearch: string = '';
  checkAddPermission: boolean = false;
  checkUpdatePermission: boolean = false;
  visible: boolean = false;
  mediaUploadUrl = environment.mediaBaseUrl;
  allTestSeries: TestSeries[] = [];
  allApprovedTestSeries: TestSeries[] = [];
  subAdminAddedTestSeries: TestSeries[] = [];
  allExamCategories: ExamCategory[] = [];
  allExams: Exam[] = [];
  selectedExam: string = '';
  selectedExamCategory: string = '';
  isTestSeriesComponent: boolean = true;
  selectedPaidOrUnPaid: string = '';
  allPaidOrUnPaid: any[] = [];
  userRole: string = '';
  index: number = 0;
  skipIndex: number = 0;
  approvedTestSeriesCount: number = 0;
  unApprovedTestSeriesCount: number = 0;
  snapShootIndex: number = 0;
  constructor(
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private drawerService: NzDrawerService,
    private commonArrayService: CommonArrayService,
    private localStorageService: LocalStorageService,
    private modalService: NzModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.snapShootIndex = toNumber(
      this.activatedRoute.snapshot.queryParams.index
    );
    this.userRole = this.localStorageService.getItem('user').role;
    if (this.snapShootIndex) {
      this.changeIndex(this.snapShootIndex);
    }
  }

  ngOnInit(): void {
    // this.getAllExams();
    // this.getAllExamsCategory();
    this.getApprovedUnapprovedTestSeriesCount();
    this.allPaidOrUnPaid = this.commonArrayService.paidOrUnpaid;
    this.searchQuestion.pipe(debounceTime(1000)).subscribe((success) => {
      this.search = success;
      this.getAllTestSeries();
    });
    this.searchExam.pipe(debounceTime(1000)).subscribe((success) => {
      this.examSearch = success;
      this.getAllExams();
      this.getAllTestSeries();
    });
    this.searchExamCategory.pipe(debounceTime(1000)).subscribe((success) => {
      this.examCategorySearch = success;
      this.getAllExamsCategory();
      this.getAllTestSeries();
    });
  }
  changeIndex(data: any) {
    this.router.navigate([], { queryParams: { index: data } });
    this.allApprovedTestSeries = [];
    this.allTestSeries = [];
    this.pageIndex = 1;
    this.pageSize = 20;
    this.index = data;
    this.getAllTestSeries(this.index);
  }
  onChangeExam() {
    this.getAllTestSeries(this.index);
  }
  onChangeExamCategory() {
    this.getAllTestSeries(this.index);
  }
  /* Get all concepts */
  getAllTestSeries(index: number = 0, skip = 0, sortBy?: any): void {
    this.getApprovedUnapprovedTestSeriesCount();
    index = index ? index : this.index;
    let params: any;
    params = { skip: this.skipIndex, limit: this.pageSize };
    if (this.search) {
      params.search = this.search;
    }
    if (this.selectedExamCategory) {
      params.examCategory = this.selectedExamCategory;
    } else {
      delete params.examCategory;
    }
    if (this.selectedExam) {
      params.exam = this.selectedExam;
    } else {
      delete params.exam;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if (this.selectedPaidOrUnPaid) {
      params.isPaid = this.selectedPaidOrUnPaid;
    } else {
      delete params.isPaid;
      // params.isPaid = false;
    }
    if (index === 0) {
     
      params.approveStatus = 'approved';
    } else if (index === 1 && this.userRole === 'admin') {
     
      params.approveStatus = 'pending';
    } else if (index === 1 && this.userRole === 'subAdmin') {
      params.approveStatus = ['pending', 'rejected'];
    } else {
      delete params.approvedStatus;
    }

    this.loading = true;
    this.httpRequestService.request('get', 'test-series', params).subscribe(
      (result) => {
        this.loading = false;
        const data: TestSeries[] = result.data;
        if (index === 0) {
          this.allApprovedTestSeries = data;
          this.totalApprovedDataCount = result.totalCount;
        } else {
          this.allTestSeries = data;
          this.totalDataCount = result.totalCount;
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }
  /* Get all exam */
  getAllExams(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved',
    };
    if (this.examSearch) {
      params.search = this.examSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'exams', params).subscribe(
      (result: any) => {
        this.allExams = result.data;
      },
      (err: any) => {}
    );
  }
  /* Get all exam-category */
  getAllExamsCategory(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved',
    };
    if (this.examCategorySearch) {
      params.search = this.examCategorySearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'exam-categories', params).subscribe(
      (result: any) => {
        this.allExamCategories = result.data;
      },
      (err: any) => {}
    );
  }

  /* For Pagination / Sending skip */
  onQueryParamsChange(params: NzTableQueryParams): void {
    this.allApprovedTestSeries = [];
    this.allTestSeries = [];
    const { pageSize, pageIndex } = params;
    this.skipIndex = pageSize * (pageIndex - 1);
      this.getAllTestSeries(this.index, pageSize * (pageIndex - 1));
  }
  /* For Pagination / Sending skip */
  onQueryParamsChangeForGridView(index: any): void {
    this.allApprovedTestSeries = [];
    this.allTestSeries = [];
    // const { pageSize, pageIndex} = params;
    this.skipIndex = this.pageSize * (this.pageIndex - 1);
    this.pageIndex = index;
    this.getAllTestSeries(this.index, this.pageSize * (this.pageIndex - 1));
  }
  onQueryPageSizeChangeForGridView(size: any) {
    this.pageSize = size;
    this.skipIndex = this.pageSize * (this.pageIndex - 1);
    this.getAllTestSeries(this.index, this.pageSize * (this.pageIndex - 1));
  }

  /* status change*/
  updateStatus(id: string, status: boolean): void {
    this.httpRequestService
      .request('put', `test-series/active-inactive/${id}`, { status: !status })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllTestSeries(this.index);
      });
  }
  updatePendingStatus(id: string, pendingStatus: string) {
    this.httpRequestService
      .request('put', `test-series/approved-rejected/${id}`, {
        approveStatus: 'approved',
      })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllTestSeries(this.index);
      });
  }
  openRejectionReason(id: string, pendingStatus: string) {
    const pendingStatusData = 'rejected';
    const rejectionReason = this.modalService.create({
      nzTitle: 'Add A Reason',
      nzContent: RejectionReasonComponent,
      nzWidth: '800px',
      nzFooter: null,
      nzComponentParams: {
        updateId: id,
        component: 'test-series',
      },
      nzCancelText: null,
      nzOkText: null,
    });

    const questionService = rejectionReason.afterClose.subscribe(
      (data: any) => {
        this.getAllTestSeries(this.index);
      }
    );
  }
  changeGridView() {
    this.listView = false;
    this.active = true;
    this.deActive = false;
  }
  changeListView() {
    this.listView = true;
    this.active = false;
    this.deActive = true;
  }
  // search concept
  searchQuestions(event: any): void {
    this.searchQuestion.next(event);
  }
  searchExamDropdown(event: any): void {
    this.searchExam.next(event);
  }
  searchExamCategoryDropdown(event: any): void {
    this.searchExamCategory.next(event);
  }

  getApprovedUnapprovedTestSeriesCount(): void {
    let params: any;
    params = {};
    if (this.search) {
      params.search = this.search;
    }
    if (this.selectedExamCategory) {
      params.examCategory = this.selectedExamCategory;
    } else {
      delete params.examCategory;
    }
    if (this.selectedExam) {
      params.exam = this.selectedExam;
    } else {
      delete params.exam;
    }
    if (this.selectedPaidOrUnPaid) {
      params.isPaid = this.selectedPaidOrUnPaid;
    } else {
      delete params.isPaid;
      // params.isPaid = false;
    }
    this.httpRequestService
      .request('get', 'test-series/approved-unapproved-count', params)
      .subscribe(
        (result: any) => {
          const data = result.data;
          this.approvedTestSeriesCount = data.approved;
          this.unApprovedTestSeriesCount = data.unapproved;
        },
        (err: any) => {}
        // for loop
      );
  }
  openTestSeriesDetails(item: any) {
    item['loading'] = true;
    this.httpRequestService.request('get', `test-series/${item._id}`).subscribe(
      (result: any) => {
        item['loading'] = false;
        const data = result;
        const drawerRef = this.drawerService.create({
          nzTitle: 'Test Series Details',
          nzFooter: '',
          nzWidth: '70%',
          nzContent: TestSeriesDetailsComponent,
          nzContentParams: {
            value: data.data,
          },
        });
      },
      (err) => {
        item['loading'] = false;
      }
    );
  }
}
