import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { set } from 'date-fns';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NgxSuneditorComponent } from 'ngx-suneditor';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Chapter } from 'src/app/core/interfaces/chapter';
import { Question } from 'src/app/core/interfaces/question';
import { Subjects } from 'src/app/core/interfaces/subjects';
import { Topic } from 'src/app/core/interfaces/topic';
import { HttpRequestService } from 'src/app/core/services';
import { CkEditorConfigService } from 'src/app/core/services/ck-editor-config.service';
import { CommonArrayService } from 'src/app/core/services/common-array.service';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-update-paragraph',
  templateUrl: './update-paragraph.component.html',
  styleUrls: ['./update-paragraph.component.scss'],
})
export class UpdateParagraphComponent implements OnInit {
  @ViewChildren(NgxSuneditorComponent)
  editors!: QueryList<NgxSuneditorComponent>;
  @Input() paragraphDetails: any;
  addUpdateParagraphTextForm: FormGroup;
  current = 0;
  index = 'First-content';
  Loading = false;
  loadingButton = false;
  buttonLoading = false;
  active: boolean = true;
  paragraphId: string = '';
  activate: boolean = true;
  activateTopic: boolean = true;
  idForUpdate: string;
  name: string = '';
  mediaBaseUrl = environment.mediaBaseUrl;
  mediaUploadUrl = environment.apiBaseUrl + '/api/media';
  search = '';
  searchChapter: Subject<string> = new Subject<string>();
  searchSubject: Subject<string> = new Subject<string>();
  searchTopic: Subject<string> = new Subject<string>();
  // allLevels: LocalArray[] = [];
  allSubjects: Subjects[] = [];
  allChapters: Chapter[] = [];
  allTopics: Topic[] = [];
  subjectSearch = '';
  chapterSearch = '';
  topicSearch = '';
  subjectId: string = '';
  chapterId: string = '';
  topicId: string = '';
  idForParagraphUpdate: string = '';

  // ck editor
  // name = 'test';
  public editorOptions: any;
  log: string = '';
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private msg: NzMessageService,
    private router: Router,
    private commonArrayService: CommonArrayService,
    private commonService: CommonService,
    private ckEditorService: CkEditorConfigService
  ) {
    this.editorOptions = this.ckEditorService.editorOptions;
    this.editorOptions.height = '100';
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.addUpdateParagraphTextForm = this.fb.group({
      name: ['', Validators.required],
      subject: [null, Validators.required],
      chapter: [null],
      topic: [null],
    });
  }

  onNameChange(content: any) {
    this.addUpdateParagraphTextForm.patchValue(
      {
        name: content.content,
      },
      { emitEvent: false }
    );
  }

  ngOnChanges(): void {
    if (this.paragraphDetails) {
      this.idForParagraphUpdate = this.paragraphDetails._id;
      this.addUpdateParagraphTextForm?.patchValue({
        // name: this.paragraphDetails.name,
        subject: this.paragraphDetails.subject,
        chapter: this.paragraphDetails.chapter,
        topic: this.paragraphDetails.topic,
      });
    }
  }
  ngOnInit(): void {
    // config of ck editor

    this.getAllChaptersInParagraph();
    this.getAllSubjectsInParagraph();
    this.getAllTopicsInParagraph();

    this.searchChapter.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.chapterSearch = success;
      this.getAllChaptersInParagraph();
    });
    this.searchSubject.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.subjectSearch = success;
      this.getAllSubjectsInParagraph();
    });
    this.searchTopic.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.topicSearch = success;
      this.getAllTopicsInParagraph();
    });
  }

  ngAfterViewInit(): void {
    this.editors.forEach((editor) => {
      if (editor.editorID === 'name') {
        editor.setContents(this.paragraphDetails.name);
      }
    });
  }

  customRequestHeaders = () => {
    return { Authorization: `Bearer ${localStorage.getItem('token')}` };
  };

  /* Get all Subjects */
  getAllSubjectsInParagraph(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
    };
    if (this.subjectSearch) {
      params.search = this.subjectSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'subjects', params).subscribe(
      (result: any) => {
        this.allSubjects = result.data;
      },
      (err: any) => {}
    );
  }
  /* On change subject get chapter */
  onChangeSubjectInParagraph(): void {
    if (
      this.addUpdateParagraphTextForm.value &&
      this.addUpdateParagraphTextForm.value.subject
    ) {
      this.allChapters = [];
      this.allTopics = [];
      this.addUpdateParagraphTextForm.patchValue({
        chapter: null,
        topic: null,
      });
      if (this.addUpdateParagraphTextForm.value.subject) {
        this.getAllChaptersInParagraph();
        this.getAllTopicsInParagraph();
      }
    }
  }

  /* Get all chapters */
  getAllChaptersInParagraph(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
    };
    if (this.addUpdateParagraphTextForm.value.subject) {
      params['subject'] = this.addUpdateParagraphTextForm.value.subject;
    } else {
      delete params.subject;
    }
    if (this.chapterSearch) {
      params.search = this.chapterSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'chapters', params).subscribe(
      (result: any) => {
        //;

        this.allChapters = result.data;
      },
      (err: any) => {}
    );
  }

  /* On change chapter get course */
  onChangeChapterInParagraph(): void {
    if (
      this.addUpdateParagraphTextForm.value &&
      this.addUpdateParagraphTextForm.value.chapter
    ) {
      this.allTopics = [];
      this.addUpdateParagraphTextForm.patchValue({
        topic: null,
      });
      if (this.addUpdateParagraphTextForm.value.chapter) {
        this.getAllTopicsInParagraph();
      }
    }
  }

  /* Get all topics */
  getAllTopicsInParagraph(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
    };
    if (this.addUpdateParagraphTextForm.value.subject) {
      params['subject'] = this.addUpdateParagraphTextForm.value.subject;
    } else {
      delete params.subject;
    }
    if (this.addUpdateParagraphTextForm.value.chapter) {
      params['chapter'] = this.addUpdateParagraphTextForm.value.chapter;
    } else {
      delete params.search;
    }
    if (this.topicSearch) {
      params.search = this.topicSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'topics', params).subscribe(
      (result: any) => {
        this.allTopics = result.data;
      },
      (err: any) => {}
    );
  }

  submitParagraph(): void {
    if (!this.addUpdateParagraphTextForm.valid) {
      this.markFormGroupTouched(this.addUpdateParagraphTextForm);
    } else {
      if (this.idForParagraphUpdate) {
        this.addOrUpdateParagraph(
          'put',
          `paragraphs/${this.idForParagraphUpdate}`,
          'Paragraph Successfully Updated'
        );
      } else {
        this.addOrUpdateParagraph(
          'post',
          'paragraphs',
          'Paragraph Added Successfully '
        );
      }
    }
  }
  /* Add Or Edit paragraph Text */
  addOrUpdateParagraph(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    // const submitData = {
    //   name: this.name,
    // };
    this.Loading = true;
    const finalData: Question = this.addUpdateParagraphTextForm.value;
    this.httpRequestService
      .request(requestMethod, requestURL, finalData)
      .subscribe(
        (result: any) => {
          this.paragraphId = result.data._id;
          this.subjectId = result.data.subject;
          this.chapterId = result.data.chapter;
          this.topicId = result.data.topic;
          this.notificationService.success('', successMessage);
          // this.router.navigateByUrl('/main/question');
          if (this.current == 0) {
            this.current += 1;
          }
          this.Loading = false;
          this.getAllSubjectsInParagraph();
          this.getAllChaptersInParagraph();
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          } else {
            this.notificationService.error('', error.error.message);
          }
          this.Loading = false;
        }
      );
  }
  //  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

  // seach Chapter
  searchChapterForDropdown(event: any): void {
    if (event) {
      this.searchChapter.next(event);
    }
  }
  // seach Subject
  searchSubjectForDropdown(event: any): void {
    if (event) {
      this.searchSubject.next(event);
    }
  }
  // seach Topic
  searchTopicForDropdown(event: any): void {
    if (event) {
      this.searchTopic.next(event);
    }
  }
}
