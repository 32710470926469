<div class="site-page-header-ghost-wrapper">
    <nz-page-header [nzGhost]="false">
        <nz-page-header-title class="ml4">Dashboard Config </nz-page-header-title>
        <nz-page-header-extra class="mr4">
            <button nz-button nzType="primary" [routerLink]="['add']" routerLinkActive="router-link-active"
                class="submitoraddbutton">
                Add Feature Category
            </button>
        </nz-page-header-extra>
    </nz-page-header>
</div>
<div nz-row nzJustify="end" [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }" class="mt4">
    <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24" class="mb5">
        <nz-form-label nzFor="chapter">Search</nz-form-label>
        <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input placeholder="Search by exam name" ngModel
                (ngModelChange)="searchByExamCategory($event)" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <i nz-icon nzType="search"></i>
        </ng-template>
    </div>
</div>
<!-- <nz-table #examTbl [nzData]="allExamCategoryList" [nzLoading]="loading" [nzTotal]="totalDataCount" [(nzPageSize)]="pageSize"
    [nzPageIndex]="pageIndex" [nzFrontPagination]="false" (nzQueryParams)="onQueryParamsChange($event)"
    nzShowSizeChanger [nzScroll]="{ x: '900px' }" *ngIf="listView">
    <thead>
        <tr>
            <th>Exam Name</th>
            <th>Exam Category</th>
            <th>Description</th>
            <th>Status</th>
            <th class="text-right">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of examTbl.data; index as i">
            <td><span *ngIf="data.examName">{{ data.examName }}</span></td>
            <td><span *ngIf="data.examCategory && data.examCategory.categoryName">{{ data.examCategory.categoryName
                    }}</span>
            </td>
            <td class="description"><span *ngIf="data.description">{{ data.description }}</span></td>
            <td>
                <span class="{{ data.status ? 'active' : 'inactive' }} status"></span>
            </td>

            <td class="action-buttons">
                <a nz-button nzType="primary" nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
                    [routerLink]="['update', data._id]" routerLinkActive="router-link-active"><i nz-icon nzType="form"
                        nzTheme="outline"></i></a>
                <a class="action-button" nz-button nz-popconfirm nzPopconfirmTitle="Are you sure {{
                data.status ? 'deActive' : 'active'}} this exam?" (nzOnConfirm)="updateStatus(data._id, data.status)"
                    nzTooltipTitle="{{ data.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                    nz-tooltip><i nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>
            </td>
        </tr>
    </tbody>
</nz-table> -->



<!-- <div *ngIf=" !listView">
    <app-common-grid-view [list]="allExams" [isExamComponent]="isExamComponent" [isAddOrUpdate]="isAddOrUpdate"
        [isOpenDetails]="isOpenDetails" [loading]="loading"></app-common-grid-view>
    <div class="pagination">
        <nz-pagination [nzPageIndex]="pageIndex" [nzTotal]="totalDataCount" nzShowSizeChanger
            (nzPageSizeChange)="onQueryPageSizeChangeForGridView($event)" [(nzPageSize)]="pageSize"
            (nzPageIndexChange)="onQueryParamsChangeForGridView($event)"></nz-pagination>
    </div>
</div> -->