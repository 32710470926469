<nz-page-header class="site-page-header" nzBackIcon [nzTitle]="idForUpdate ? 'Update Test' : 'Add Test'">
</nz-page-header>
<form nz-form [formGroup]="addUpdateTestForm">
  <nz-card>
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="topicName" nzRequired>Test Name</nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
            <input nz-input formControlName="name" type="text" placeholder="Enter Test Name " />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="subject" nzRequired>Test Type
          </nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Please select your test type!">
            <nz-select nzAllowClear nzShowSearch nzServerSearch id="testType" formControlName="testType"
              nzPlaceHolder="Select a option" (nzOnSearch)="searchTestTypeForDropdown($event)">
              <nz-option *ngFor="let testType of allTestTypes" nzValue="{{ testType.value }}"
                nzLabel="{{ testType.label }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="subject" nzRequired>Exam Name </nz-form-label>
            <nz-form-control [nzSpan]="24" nzErrorTip="Please select your exam!">
                <nz-select nzAllowClear nzShowSearch nzServerSearch id="testType" formControlName="exam"
                    nzPlaceHolder="Select a option" (nzOnSearch)="searchExamForDropdown($event)">
                    <nz-option *ngFor="let exam of allExams" nzValue="{{ exam._id }}"
                        nzLabel="{{ exam.examName }}"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
    </div>
      <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="subject" nzRequired>Published Status </nz-form-label>
            <nz-form-control [nzSpan]="24" nzErrorTip="Please select your exam!">
                <nz-select nzAllowClear nzShowSearch nzServerSearch id="testType" formControlName="publishedStatus"
                    nzPlaceHolder="Select a option">
                    <nz-option *ngFor="let item of allPublishedStatus" nzValue="{{ item.value }}"
                        nzLabel="{{ item.label }}"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
    </div>
      <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="subject" nzRequired>
            Difficulty Level</nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Please select your Difficulty Level!">
            <nz-select nzAllowClear nzShowSearch nzServerSearch id="testLevel" formControlName="difficultyLevel"
              nzPlaceHolder="Select a option" (nzOnSearch)="searchTestTypeForDropdown($event)">
              <nz-option *ngFor="let item of allLevels" nzValue="{{ item.value }}"
                nzLabel="{{ item.label }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="subject" nzRequired>
            Exam Type</nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Please select your Exam Type!">
            <nz-select nzAllowClear nzShowSearch nzServerSearch id="testLevel" formControlName="examType"
              nzPlaceHolder="Select a option" (nzOnSearch)="searchTestTypeForDropdown($event)">
              <nz-option *ngFor="let item of allExamType" nzValue="{{ item.value }}"
                nzLabel="{{ item.label }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="description">Exam Time&nbsp;<small>(in Minutes)</small></nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
            <nz-input-number class='width-100' formControlName="time" type="number" placeholder="Enter test time " [nzMin]="0"[nzDisabled]="addUpdateTestForm.value.examType === 'SEQUENTIAL'" ></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
              <nz-form-label [nzSpan]="24" nzFor="description">Instruction In Hindi</nz-form-label>
              <!-- <ckeditor #editor id="instruction" name="instruction" formControlName="instructionInHindi" type="divarea">
              </ckeditor> -->
              <ckeditor formControlName="instructionInHindi" name="editor" [editor]="Editor" [config]="ckeConfig" debounce="500"
              (ngModelChange)="onChange($event)">
              </ckeditor>
            </nz-form-control>
          </nz-form-item>
      </div>
      <div nz-col nzLg="18" nzMd="18" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
              <nz-form-label [nzSpan]="24" nzFor="description">Instruction</nz-form-label>
              <!-- <ckeditor #editor id="instruction" name="instruction" formControlName="instruction" type="divarea">
              </ckeditor> -->
              <ckeditor formControlName="instruction" name="editor" [editor]="Editor" [config]="ckeConfig" debounce="500"
              (ngModelChange)="onChange($event)">
              </ckeditor>
            </nz-form-control>
          </nz-form-item>
      </div>
      <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="subject">Image</nz-form-label>
            <nz-upload class="upload-list-inline" nzName="avatar"
                (nzChange)="UploadImageFile($event, 'image', 0)" [nzName]="'file'"
                [nzShowUploadList]="false" [nzBeforeUpload]="beforeRelatedMediaUpload"
                [nzAction]="mediaUploadUrl" [nzHeaders]="customRequestHeaders">
                <button nz-button *ngIf="!addUpdateTestForm.value.image"
                    [nzLoading]="imageLoading">
                    <span nz-icon nzType="upload"></span>
                    Upload
                </button>
            </nz-upload>
            <nz-card *ngIf="addUpdateTestForm.value.image" class="question-image-card">
                <img nz-image width="auto" max-height="150px" class="img-responsive"
                    [nzSrc]="mediaBaseUrl + addUpdateTestForm.value.image" />

                <button nz-button nzDanger class="remove-image image-card-del-icon"  nzTooltipTitle="Delete" nz-tooltip nz-popconfirm
                nzPopconfirmTitle="Are you sure delete this?"
                (nzOnConfirm)="removeImage(addUpdateTestForm.value.image)"
                    nzType="primary">
                    <span nz-icon nzType="delete" nzTheme="outline"></span>
                </button>
            </nz-card>
        </nz-form-item>
    </div>
      <!-- <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="description">isFixed Negative Marks &nbsp;<small>(Per questions)</small></nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
            <nz-switch formControlName="isFixedNegativeMark" (click)="clickChange()" ></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24" *ngIf="addUpdateTestForm.value.isFixedNegativeMark">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="description">Negative Marks &nbsp;<small>(Per questions)</small></nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
            <span  nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" >
              <input nz-input  formControlName="negativeMarks" type="number" placeholder="Enter Negative Marks" />
            </span>
          </nz-form-control>
        </nz-form-item>
      </div> -->
      <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
        <nz-collapse>
          <nz-collapse-panel class="mt5 custoncollaps"
            *ngFor="let sectionTemp of sectionsFormArray.controls; index as i" [nzActive]="true"
            [nzHeader]="'Section' + (i + 1)">
            <div *ngIf="sectionsFormArray.length > 0" class="deletesect" nz-popconfirm nzPopconfirmTitle="Are you sure delete this task?"
              nzPopconfirmPlacement="bottom" (nzOnConfirm)="removeSection(i)">
              <i nz-icon nzType="delete" nzTheme="outline"></i>
            </div>
            <app-section-question [sectionForm]="sectionsFormArray.controls[i]"
              [totalQuestions]="addUpdateTestForm.value.totalQuestions"
              [totalMarks]="addUpdateTestForm.value.totalMarks"
              [isNegativeMarking]="addUpdateTestForm.value.isNegativeMarking"
              [allAvailableQuestions]="availableQuestionsTemp[i]"
              [examType]="addUpdateTestForm.value.examType"
              [addUpdateTestForm]="addUpdateTestForm" [idForUpdate]="idForUpdate"></app-section-question>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
      <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="center">
        <button nz-button nzType="primary" (click)="addSection()">
          Add Section
        </button>
      </div>
    </div>
    <div nz-row nzGutter="16" nzJustify="end">
      <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="right-aline" style="margin-top: 60px">
        <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()">
          {{ idForUpdate ? "Update" : "Submit" }}
        </button>
      </div>
    </div>
  </nz-card>
</form>
