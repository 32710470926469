<div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" style="background-color: #eee;">
    <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24" class="mt3" style="background-color: #eee;">
        <nz-card class="header text-center mb3">
            <div nz-row>
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="userDetail"
                    *ngIf="!userDetails && !userDetails.profilePicture">
                    <img src="../../assets/images/image.png" alt="Admin" class="rounded-circle " width="150">
                </div>
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="userDetail"
                    *ngIf="userDetails && userDetails.profilePicture">
                    <img nz-image [nzSrc]="mediaBaseUrl+userDetails.profilePicture.path" alt="Admin" class="rounded-circle "
                        width="150">
                </div>
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="mt-3 p3 userDetails-text">
                    <h2 class="user-color"><b>{{userDetails.name}}</b></h2>
                </div>
            </div>
        </nz-card>
    </div>
    <div nz-col nzLg="20" nzMd="20" nzSm="24" nzXs="24">
        <nz-card class="mt3 mb3 details-height">
            <!-- <h1 class="text-center">Details</h1> -->
            <div nz-row>
                <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
                    <nz-form-label>
                        Mail
                    </nz-form-label>
                    <p *ngIf="userDetails.email" class="details"><span nz-icon nzType="mail"
                            nzTheme="outline"></span>{{userDetails.email}}</p>
                    <!-- <nz-divider *ngIf="!userDetails.email && !userDetails.mobile"></nz-divider> -->
                    <nz-form-label>
                        Phone No
                    </nz-form-label>
                    <p *ngIf="userDetails.mobile" class="details"><span nz-icon nzType="phone"
                            nzTheme="outline"></span>{{userDetails.mobile}}</p>
                    <br *ngIf="!userDetails.mobile">
                    <br *ngIf="!userDetails.mobile">
                    <!-- <nz-divider *ngIf="!userDetails.mobile && !userDetails.dob"></nz-divider> -->
                    <nz-form-label>
                        Date Of Birth
                    </nz-form-label>
                    <p *ngIf="userDetails.dob" class="details"><span nz-icon nzType="calendar"
                            nzTheme="outline"></span>{{userDetails.dob | date: 'dd/MM/yyyy'}}</p>
                </div>
                <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
                    <nz-form-label>
                        Gender
                    </nz-form-label>
                    <p *ngIf="userDetails.gender" class="details"><span nz-icon nzType="user"
                            nzTheme="outline"></span>{{userDetails.gender}}</p><br *ngIf="!userDetails.gender"><br
                        *ngIf="!userDetails.gender">
                    <!-- <nz-divider
                        *ngIf="!userDetails.gender && !userDetails.line1 && !userDetails.line2 && !userDetails.city && !userDetails.state && !userDetails.pinCode"></nz-divider> -->
                    <nz-form-label>
                        Address
                    </nz-form-label>
                    <p *ngIf="userDetails.line1 && userDetails.line2 && userDetails.city && userDetails.state && userDetails.pinCode"
                        class="details"><span nz-icon nzType="home" nzTheme="outline"></span>{{userDetails.line1}},
                        {{userDetails.line2}}, {{userDetails.city}}, {{userDetails.state}}, India, <br><span>Pin
                            Code:</span>
                        {{userDetails.pinCode}}</p>
                </div>
            </div>
        </nz-card>
    </div>
    <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
        <div nz-row>
            <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="mt3 mb3">
                <!-- <nz-label><h2>Subscribe Test Series</h2></nz-label> -->
                <nz-form-label [nzSpan]="24" nzFor="description">
                    <h3>Test conduct Details:</h3>
                </nz-form-label>
                <nz-table #userTbl [nzData]="allReport" [nzLoading]="loading" [nzTotal]="totalDataCount"
                    [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex" [nzFrontPagination]="false"
                    (nzQueryParams)="onQueryParamsChange($event)" #nzTable nzTableLayout="fixed">
                    <thead>
                        <tr>
                            <th nzWidth="60px"></th>
                            <th>Test Name</th>
                            <th>Correct Answers</th>
                            <!-- <th nzColumnKey="gender">Incorrect</th> -->
                            <th nzWidth="">Attempted</th>
                            <th nzWidth="">Time Spent (in Minutes)</th>
                            <th nzWidth="">Skipped</th>
                            <th nzWidth="">Not Visited</th>
                            <th nzWidth="">percentile</th>
                            <th nzWidth="">Total Question</th>
                            <th nzWidth="">Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let data of allReport; let i = index">
                            <tr>
                                <td [nzExpand]="isExpand && index === i"
                                    (nzExpandChange)="onExpandChange(data._id, i , $event)"></td>
                                <td><span *ngIf="data.testName">{{ data.testName }}</span></td>
                                <td>
                                    {{ data.correct ||0 }} / {{data.totalQuestion ||0}}
                                </td>
                                <td>
                                    {{ data.attempted ||0 }} / {{data.totalQuestion ||0}}
                                </td>
                                <td>
                                    {{ data.completedTime ||0 }} / {{ data.time ||0 }}

                                </td>
                                <td>
                                    {{ data.skipped || 0 }}
                                </td>
                                <td>
                                    {{ data.unseenCount || 0 }}
                                </td>
                                <td>
                                    {{ data.percentile || 0 }}%
                                </td>
                                <td>
                                    {{ data.totalQuestion|| 0 }}
                                </td>
                                <td>
                                    {{ data.finalMarks||0 }} / {{ data.examTotalMarks || 0 }}
                                </td>
                            </tr>
                            <tr [nzExpand]="isExpand && index === i">
                                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }" >
                                    <nz-card class="ml3 mt2" nz-col  nzLg="7" nzMd="7" nzSm="24" nzXs="24" *ngFor="let item of data.extraData" >
                                        <h3 class="text-center"><b>{{item.name}}</b></h3>
                                        <!-- <span class="text-center"><nz-tag [nzColor]="'magenta'"></nz-tag></span> -->
                                        <br>
                                        <span class="">
                                            Total Marks : <nz-tag [nzColor]="'green'"><b>{{item.finalmarks}}</b></nz-tag>
                                        </span>
                                        <span class="ml2">
                                            Total Questions : <nz-tag [nzColor]="'green'"><b>{{item.attempted + item.unseenCount}}</b></nz-tag>
                                        </span>
                                        <br>
                                        <span class="mt3">
                                            Total Correct : <nz-tag [nzColor]="'green'"><b>{{item.correct}}</b></nz-tag>
                                        </span>
                                        <span class="mt3 ml2">
                                            Total Wrong : <nz-tag [nzColor]="'red'"><b>{{item.inCorrect}}</b></nz-tag>
                                        </span>
                                        <br>
                                        <span class="mt3">
                                            Attempted : <nz-tag [nzColor]="'green'"><b>{{item.attempted}}</b></nz-tag>
                                        </span>
                                        <span class="mt3 ml2">
                                            Unattempted : <nz-tag [nzColor]="'red'"><b>{{item.unseenCount}}</b></nz-tag>
                                        </span>
                                        <br>
                                        <span class="mt3">
                                            Marks Obtained : <nz-tag [nzColor]="'green'"><b>{{item.totalMarksObtained}}</b></nz-tag>
                                        </span>
                                    <span class="mt3 ml2" *ngIf="item.time">
                                        Time Spent : <nz-tag [nzColor]="'volcano'"><b>{{item.time ? item.time: '0'}}</b></nz-tag>
                                    </span>


                                    </nz-card>
                                </div>
                            </tr>
                        </ng-container>
                    </tbody>
                </nz-table>
            </div>
            <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="mb3">
                <nz-form-label [nzSpan]="24" nzFor="description">
                    <h3>Subscribe Test Series Details: </h3>
                </nz-form-label>
                <nz-table #userTbl [nzData]="allSUbscribeTestSeries" [nzLoading]="loading" [nzTotal]="totalDataCount"
                    [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex" [nzFrontPagination]="false" nzShowSizeChanger
                    (nzQueryParams)="onQueryParamsChange($event)">
                    <thead>
                        <tr>
                            <th>Test-Series Name</th>
                            <th>Plans Name</th>
                            <th nzWidth="350px">Price (in ₹)</th>
                            <!-- <th nzColumnKey="gender">Incorrect</th> -->
                            <th nzWidth="350px">Time Duration(days)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of allSUbscribeTestSeries">
                            <td *ngIf="data.testSeries">
                                <nz-tag *ngFor="let item of data.testSeries" [nzColor]="getColorForItem(item)">{{
                                    item.name }}</nz-tag>
                            </td>
                            <td><span *ngIf="data.planName">{{ data.planName }}</span></td>
                            <td>
                                <span *ngIf="data.totalAmount">
                                    {{'₹'+ data.totalAmount ||'₹'+ 0}}
                                </span>
                            </td>
                            <td>
                                <span>
                                    {{ data.startDate | date: 'dd/MM/yyyy' }} - {{data.endDate | date: 'dd/MM/yyyy' }}
                                </span>
                            </td>

                        </tr>
                    </tbody>
                </nz-table>
            </div>

        </div>
    </div>
</div>