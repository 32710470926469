<div class="site-page-header-ghost-wrapper">
    <nz-page-header [nzGhost]="false">
        <nz-page-header-title class="ml4">Editorials </nz-page-header-title>
        <nz-page-header-extra class="mr4">
            <button nz-button nzType="primary" [routerLink]="['add']" [queryParams]="{index: index}" routerLinkActive="router-link-active"
                class="submitoraddbutton">
                Add Editorial
            </button>
        </nz-page-header-extra>
    </nz-page-header>
</div>
<div nz-row nzJustify="end" class="mt4">
    <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
        <nz-form-label nzFor="Date">Published Date</nz-form-label>
        <nz-form-item>
            <nz-form-control [nzSpan]="24" nzErrorTip="Please select a Date!">
                <nz-range-picker class="width-100" [(ngModel)]="dateRange"
                    (ngModelChange)="onChangeDateRange($event)"></nz-range-picker>
            </nz-form-control>
        </nz-form-item>
    </div>
    <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24" class="mb5 ml3">
        <nz-form-label nzFor="chapter"> Search</nz-form-label>
        <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input placeholder="Search by Editorials name" ngModel
                (ngModelChange)="searchEditorials($event)" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <i nz-icon nzType="search"></i>
        </ng-template>
    </div>
</div>


<!-- <div nz-row nzJustify="end" class="mb2">
    <nz-button-group class="ml3">
        <button nz-button [nzType]="!active && deActive?'primary':'link'" (click)="changeListView()"> <span
                class="icon-size" nz-icon nzType="ordered-list" nzTheme="outline"></span></button>
        <button nz-button [nzType]="active && !deActive?'primary':'link'" (click)="changeGridView() "><span
                class="icon-size action-button" nz-icon nzType="appstore" nzTheme="outline"></span></button>
    </nz-button-group>
</div> -->
<nz-tabset (nzSelectedIndexChange)="changeIndex($event)" [nzSelectedIndex]="index">
    <nz-tab nzTitle="Approved Editorials">
        <nz-table #storyTbl [nzData]="allApprovedEditorials" [nzLoading]="loading" [nzTotal]="totalApprovedDataCount"
            [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex" [nzFrontPagination]="false"
            (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger [nzScroll]="{ x: '900px' }">
            <thead>
                <tr>
                    <th nzColumnKey="name" [nzSortFn]="true">Title Name</th>
                    <th>Description</th>
                    <th nzWidth="300px">tags</th>
                    <th nzWidth="90px">Status</th>
                    <th class="text-right" nzWidth="150px">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of storyTbl.data; index as i">
                    <td><span *ngIf="data.title" class="{{data.description? 'ellipsis' : ''}}"> {{ data.title }} </span>
                    </td>
                    <td> <app-mathjax *ngIf="data.description" [content]=" data.description "
                            class="{{data.description? 'ellipsis' : ''}}"></app-mathjax></td>
                    <td>
                        <span>
                            <nz-tag [nzColor]="getRandomColor()" *ngFor="let item of data.tags">{{ item}}</nz-tag>
                        </span>
                    </td>
                    <td><span class="{{data.status? 'active' : 'inactive' }} status"></span></td>
                    <td class="action-buttons">
                        <a nz-button nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
                            [routerLink]="['update', data._id]" [queryParams]="{index: index}" routerLinkActive="router-link-active"><i nz-icon
                                nzType="form" nzTheme="outline"></i></a>

                        <a *ngIf="userRole !== 'subAdmin'" class="action-button" nz-button nz-popconfirm
                            nzPopconfirmTitle="Are you sure {{data.status ? 'deActive' : 'active'}} this task?"
                            (nzOnConfirm)="updateStatus(data._id, data.status)"
                            nzTooltipTitle="{{data.status ? 'Deactive' : 'Active'}}" nzTooltipPlacement="topRight"
                            nz-tooltip><i nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>

                        <!-- <a *ngIf="userRole !== 'subAdmin'"  class="action-button delete" nz-button nz-popconfirm
                            nzPopconfirmTitle="Are you sure delete this Current-Affair?"
                            (nzOnConfirm)="deleteAffair(data._id)" z><i nz-icon nzType="delete"
                                nzTheme="fill"></i></a> -->
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </nz-tab>

    <nz-tab nzTitle="Un-approved Editorials">
        <nz-table #storyTbl [nzData]="allEditorials" [nzLoading]="loading" [nzTotal]="totalDataCount"
            [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex" [nzFrontPagination]="false"
            (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger [nzScroll]="{ x: '900px' }">
            <thead>
                <tr>
                    <th nzColumnKey="name" [nzSortFn]="true">Title Name</th>
                    <th>Description</th>
                    <th nzWidth="300px">tags</th>
                    <th nzWidth="150px">Created By</th>
                    <th nzWidth="150px">Rejected Reason</th>
                    <th nzWidth="90px">Status</th>
                    <th class="text-right" nzWidth="150px">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of allEditorials; index as i">
                    <td><span *ngIf="data.title" class="{{data.description? 'ellipsis' : ''}}"> {{ data.title }} </span>
                    </td>
                    <td> <app-mathjax *ngIf="data.description" [content]=" data.description "
                            class="{{data.description? 'ellipsis' : ''}}"></app-mathjax></td>
                    <td>
                        <span>
                            <nz-tag [nzColor]="getRandomColor()" *ngFor="let item of data.tags">{{ item}}</nz-tag>
                        </span>
                    </td>
                    <td><span *ngIf="data.createdBy?.role === 'subAdmin'">
                            {{ data?.createdBy?.name }}
                        </span></td>
                    <td><span *ngIf="data.rejectionReason" class="ellipsis">{{data.rejectionReason}}</span></td>
                    <td>
                        <nz-tag
                            [nzColor]="data.approveStatus === 'approved' ? 'green':  data.approveStatus === 'pending'? 'lime':'volcano' ">
                            {{data.approveStatus}}

                        </nz-tag>
                    </td>
                    <td class="action-buttons">
                        <a nz-button nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
                            [routerLink]="['update', data._id]" [queryParams]="{index: index}" routerLinkActive="router-link-active"><i nz-icon
                                nzType="form" nzTheme="outline"></i></a>

                        <a *ngIf="userRole !== 'subAdmin'" class="action-button" nz-button nz-popconfirm
                            nzPopconfirmTitle="Are you sure {{data.status ? 'deActive' : 'active'}} this Editorial"
                            (nzOnConfirm)="updateStatus(data._id, data.status)"
                            nzTooltipTitle="{{data.status ? 'Deactive' : 'Active'}}" nzTooltipPlacement="topRight"
                            nz-tooltip><i nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>

                        <a *ngIf="userRole !== 'subAdmin' " class="action-button top-margin" nz-button nz-popconfirm
                            nzPopconfirmTitle="Are you sure Approved this Editorial?"
                            (nzOnConfirm)="updatePendingStatus(data._id, data.approveStatus)"
                            nzTooltipTitle="{{'Approve'}}" nzTooltipPlacement="topRight" nz-tooltip><i nz-icon
                                nzType="file-done" nzTheme="outline"></i></a>

                        <a *ngIf="userRole !== 'subAdmin' && data.approveStatus !== 'rejected'"
                            class="action-button top-margin delete" nz-button nz-popconfirm
                            nzPopconfirmTitle="Are you sure Reject this Editorial?"
                            (nzOnConfirm)="openRejectionReason(data._id, data.approveStatus)" nzTooltipTitle='Reject'
                            nzTooltipPlacement="topRight" nz-tooltip><i nz-icon [nzType]="'close-circle'"
                                nzTheme="fill"></i></a>

                    </td>
                </tr>
            </tbody>
        </nz-table>
    </nz-tab>
</nz-tabset>



<!-- <div *ngIf=" !listView">
    <app-common-grid-view [list]="allSubjects" [isAddOrUpdate]="isAddOrUpdate" [isSubjectComponent]="isSubjectComponent"
        [isOpenDetails]="isOpenDetails" [loading]="loading"></app-common-grid-view>
    <div class="pagination">
        <nz-pagination [nzPageIndex]="pageIndex" [nzTotal]="totalDataCount" nzShowSizeChanger
            (nzPageSizeChange)="onQueryPageSizeChangeForGridView($event)" [(nzPageSize)]="pageSize"
            (nzPageIndexChange)="onQueryParamsChangeForGridView($event)"></nz-pagination>
    </div>
</div> -->