import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { HttpRequestService, LocalStorageService } from 'src/app/core/services';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  profileForm: FormGroup;
  buttonLoading = false;
  changePasswordForm: FormGroup;
  siteConfigureFrom: FormGroup;
  isLoading = false;
  passwordChangeLoading = false;
  viewPassword = false;
  viewReEnterPassword = false;
  shipData!: any[];
  shipDetails!: any;
  designationData!: any[];
  userRole: any;
  emailRegex = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
  mobileRegex = '^[6-9]\\d{9}$';
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this.profileForm = this.fb.group({
      name: [null, Validators.required],
      email: [
        null,
        [
          Validators.required,
          Validators.pattern(this.emailRegex),
        ],
      ],
    });
    this.changePasswordForm = this.fb.group({
      password: [null, Validators.required],
      reEnterPassword: [
        null,
        [Validators.required, this.confirmationValidator],
      ],
    });
    this.siteConfigureFrom = this.fb.group({
      isShowNavigation: [true, Validators.required],
      isShowPagination: [true, Validators.required],
      slideDuration: [2, Validators.required],
      facebook: [null ],
      twitter: [null ],
      instagram: [null ],
      youtube: [null ],
      linkedIn: [null ],
      contactAddress: [null],
      contactEmail: [null, [Validators.pattern(this.emailRegex)]], // Standard email validation
      contactWhatsapp: [null, [Validators.pattern(this.mobileRegex)]],
      contactNumber: [null, [Validators.pattern(this.mobileRegex)]],
    });
  }

  ngOnInit(): void {
    this.userRole =  this.localStorageService.getItem('user').role;
    this.getProfile();
    this.getConfig();
  }

  /* Get  profile details by Id */
  getProfile(): void {
    this.httpRequestService.request('get', `auth-admins/profile`).subscribe(
      (result: any) => {
        this.profileForm.patchValue({
          name: result.data?.name,
          email: result.data?.email,
        });
      },
      (error: any) => {}
    );
  }

  /* Submit UpdateProfileForm */
  submit(): void {
    if (!this.profileForm.valid) {
      this.markFormGroupTouched(this.profileForm);
    } else {
      this.UpdateProfile('put', `auth-admins/profile`, 'Profile Successfully Updated');
    }
  }

  //skip update
  cancel(): void {}

  /* update profile */
  UpdateProfile(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    this.buttonLoading = true;
    const sendData = { ...this.profileForm.value };
    delete sendData.email;

    this.httpRequestService
      .request(requestMethod, requestURL, sendData)
      .subscribe(
        (result: any) => {
          this.notificationService.success('Success', successMessage);
          this.buttonLoading = false;
        },
        (error: any) => {
          this.notificationService.error('', error.error.message);
          this.buttonLoading = false;
        }
      );
  }
  /* Get  profile details by Id */
  getConfig(): void {
    this.httpRequestService.request('get', 'site-configs').subscribe(
      (result: any) => {
        const data = result.data;
        this.siteConfigureFrom.patchValue({
          isShowNavigation: data.isShowNavigation,
          isShowPagination: data.isShowPagination,
          slideDuration: data.slideDuration,
          facebook: data.facebook,
          twitter: data.twitter,
          instagram: data.instagram,
          youtube: data.youtube,
          linkedIn: data.linkedIn,
          contactAddress: data.contactAddress,
          contactEmail: data.contactEmail,
          contactWhatsapp: data.contactWhatsapp,
          contactNumber: data.contactNumber,
        });
      },
      (error: any) => {}
    );
  }

  /* Submit UpdateProfileForm */
  submitConfig(): void {
    if (!this.siteConfigureFrom.valid) {
      this.markFormGroupTouched(this.siteConfigureFrom);
    } else {
      this.changeConfig('post', 'site-configs', 'Configuration Successfully Changed');
    }
  }

  /* update profile */
  changeConfig(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    this.buttonLoading = true;
    this.httpRequestService
      .request(requestMethod, requestURL, this.siteConfigureFrom.value)
      .subscribe(
        (result: any) => {
          this.notificationService.success('Success', successMessage);
          this.buttonLoading = false;
        },
        (error: any) => {
          this.notificationService.error('', error.error.message);
          this.buttonLoading = false;
        }
      );
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (
      control.value !== this.changePasswordForm.controls.password.value
    ) {
      return { confirm: true, error: true };
    }
    return {};
  };

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() =>
      this.changePasswordForm.controls.reEnterPassword.updateValueAndValidity()
    );
  }

  updatePassword(): void {
    if (!this.changePasswordForm.valid) {
      this.markFormGroupTouched(this.changePasswordForm);
    } else {
      this.passwordChangeLoading = true;
      this.httpRequestService
        .request('post', 'auth-admins/change-password', {
          password: this.changePasswordForm.value.password,
        })
        .subscribe(
          (success) => {
            this.notificationService.success(
              'Success',
              'Successfully Password Updated '
            );
            this.changePasswordForm.reset();
            this.passwordChangeLoading = false;
          },
          (error) => {
            this.passwordChangeLoading = false;
            this.notificationService.error('error', error.error.message);
          }
        );
    }
  }

  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }
}
