<nz-page-header class="site-page-header" nzBackIcon [nzTitle]="idForUpdate ? 'Update Editorial' : 'Add Editorial'">
</nz-page-header>
<form nz-form [formGroup]="addUpdateEditorialForm">
    <nz-card nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
            <div nz-col nzLg="18" nzMd="18" nzSm="24" nzXs="24">
                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">

                    <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24" nzFor="subjectName" nzRequired>Title</nz-form-label>
                            <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                                <input nz-input formControlName="title" type="text" placeholder="Enter Title " />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    
                    <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24" nzFor="exam-category">Tags
                            </nz-form-label>
                            <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                                <!-- <input nz-input  type="text" placeholder="Enter Tags " /> -->
                                <nz-select (nzOnSearch)="onSearch($event)" nzAllowClear nzShowSearch nzServerSearch
                                    (nzScrollToBottom)="loadMore()" [nzMaxTagCount]="5" nzMode="tags"
                                    nzPlaceHolder="Please select" formControlName="tags">
                                    <nz-option *ngFor="let item of listOfTags" [nzLabel]="item.name"
                                        [nzValue]="item.name"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                                <nz-form-label [nzSpan]="24" nzRequired nzFor="description">Description</nz-form-label>
                                <!-- <ckeditor #editor id="description" name="description" formControlName="description"
                                    type="divarea">
                                </ckeditor> -->
                                <ngx-suneditor [options]="editorOptions" [editorID]="'description'" (onChange)="onDescriptionChange($event)"></ngx-suneditor>
                            </nz-form-control>
                        </nz-form-item>

                    </div>
                    <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24" nzFor="subjectName" nzRequired>Meta Title</nz-form-label>
                            <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                                <input nz-input formControlName="metaTitle" type="text" placeholder="Enter Meta Title " />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                                <nz-form-label [nzSpan]="24" nzRequired nzFor="description">Meta Description</nz-form-label>
                                <!-- <ckeditor #editor id="description" name="description" formControlName="description"
                                    type="divarea">
                                </ckeditor> -->
                                <ngx-suneditor [options]="editorOptions" (onChange)="onMetaDescriptionChange($event)" [editorID]="'metaDescription'"> </ngx-suneditor>
                            </nz-form-control>
                        </nz-form-item>

                    </div>

                </div>
            </div>
            <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="subject">Banner Image (1920px * 700px)</nz-form-label>
                        <nz-upload class="upload-list-inline" nzName="avatar"
                            (nzChange)="UploadImageFile($event, 'image', 0)" [nzName]="'file'"
                            [nzShowUploadList]="false" [nzBeforeUpload]="beforeRelatedMediaUpload"
                            [nzAction]="mediaUploadUrl" [nzHeaders]="customRequestHeaders">
                            <button nz-button *ngIf="!addUpdateEditorialForm.value.image" [nzLoading]="imageLoading">
                                <span nz-icon nzType="upload"></span>
                                Upload
                            </button>
                        </nz-upload>
                        <nz-card *ngIf="addUpdateEditorialForm.value.image" class="question-image-card">
                            <img nz-image width="auto" max-height="150px" class="img-responsive"
                                [nzSrc]="mediaBaseUrl + addUpdateEditorialForm.value.image" />

                            <button nz-button nzDanger nzTooltipTitle="Delete" nz-tooltip nz-popconfirm
                                nzPopconfirmTitle="Are you sure delete this?" (nzOnConfirm)="removeImage(addUpdateEditorialForm.value.thumbnailImage)"
                                class="remove-image image-card-del-icon" nzType="primary">
                                <span nz-icon nzType="delete" nzTheme="outline"></span>
                            </button>
                        </nz-card>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="subject" nzRequired>Thumbnail Image (1280px *
                            800px)</nz-form-label>
                        <nz-upload class="upload-list-inline" nzName="avatar"
                            (nzChange)="UploadImageFile($event, 'thumbnailImage', 0)" [nzName]="'file'"
                            [nzShowUploadList]="false" [nzBeforeUpload]="beforeRelatedMediaUpload"
                            [nzAction]="mediaUploadUrl" [nzHeaders]="customRequestHeaders">
                            <button nz-button *ngIf="!addUpdateEditorialForm.value.thumbnailImage"
                                [nzLoading]="thumbImageLoading">
                                <span nz-icon nzType="upload"></span>
                                Upload
                            </button>
                        </nz-upload>
                        <nz-card *ngIf="addUpdateEditorialForm.value.thumbnailImage" class="question-image-card">
                            <img nz-image width="auto" max-height="150px" class="img-responsive"
                                [nzSrc]="mediaBaseUrl + addUpdateEditorialForm.value.thumbnailImage" />

                            <button nz-button nzDanger nzTooltipTitle="Delete" nz-tooltip nz-popconfirm
                                nzPopconfirmTitle="Are you sure delete this?" (nzOnConfirm)="removeThumbImage(addUpdateEditorialForm.value.thumbnailImage)"
                                class="remove-image image-card-del-icon" nzType="primary">
                                <span nz-icon nzType="delete" nzTheme="outline"></span>
                            </button>
                        </nz-card>
                    </nz-form-item>
                </div>
            </div>
        </div>

        <div nz-row nzJustify="end">
            <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()" class="submitoraddbutton">
                {{idForUpdate? 'Update':'Submit'}}
            </button>
        </div>
    </nz-card>
</form>