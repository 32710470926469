<div [formGroup]="addUpdateFeatureCategoriesForm">
    <nz-page-header class="site-page-header" nzBackIcon
        [nzTitle]="idForUpdate ? 'Update Feature Categories' : 'Add Feature Categories'">
    </nz-page-header>
    <nz-card>
        <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
            <div nz-row>
            </div>
            <div nz-row nzType="flex" nzJustify="center" class="mb4" [nzGutter]="16"></div>
            <div nz-row [nzGutter]="16">
                <div nz-col class="gutter-row" [nzMd]="12" [nzSm]="24">
                    <nz-list nzBordered nzSize="small" class="samelistheight questionlistbox">
                        <nz-list-header>
                            <h4>
                                Total Categories:
                                {{ allExamCategories.length - newSelectedExamCategories.length }}
                            </h4>

                            <div nz-row>
                                <button style="text-align: right" nz-button nzType="primary" nzSize="small"
                                    (click)="addCategories()">
                                    Add
                                </button>
                            </div>
                        </nz-list-header>
                        <div>
                            <div class="myqlistitems">
                                <nz-list-item *ngFor="let item of filterExamCategory; index as i"
                                    class="qlist flex-wrapneed">
                                    <label nz-checkbox [(ngModel)]="item.isChecked"
                                        [ngModelOptions]="{ standalone: true }"></label>
                                    <span class="mr2">
                                        {{i+1}}.
                                    </span>
                                    <div class="inquestionlistonform">
                                        {{item.categoryName}}
                                    </div>
                                </nz-list-item>
                            </div>
                            <div class="loader" *ngIf="loading">
                                <nz-spin nzSimple class="loader-span"></nz-spin>
                            </div>
                        </div>


                        <div class=" loadMore-button text-center mt3 " *ngIf="!loading">
                            <button [disabled]="limit" nz-button nzType="primary" nzSize="small"
                                (click)="examCategoryPagination()"> Load More
                            </button>
                        </div>
                    </nz-list>
                </div>
                <div nz-col class="gutter-row" [nzMd]="12" [nzSm]="24">
                    <nz-list nzBordered nzSize="small" class="samelistheight questionlistbox">
                        <nz-list-header>
                            <h4>Total Selected Categories: {{ newSelectedExamCategories.length }}</h4>
                            <div nz-row>
                                <button style="text-align: right" nz-button nzDanger nzSize="small"
                                    (click)="removeCategories()">
                                    Remove
                                </button>
                            </div>
                        </nz-list-header>

                        <div class="myqlistitems">
                            <nz-table #basicTable [nzData]="newSelectedExamCategories" [nzShowPagination]="false"
                                class="valigntop">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Categories</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody cdkDropList (cdkDropListDropped)="drop($event)" class="drag-css">
                                    <tr *ngFor="let data of basicTable.data;  index as i" cdkDrag>
                                        <td>
                                            <label nz-checkbox [(ngModel)]="data.isChecked"
                                                [ngModelOptions]="{ standalone: true }"></label>
                                            <span class="ml4">{{i+1}}.</span>
                                        </td>
                                        <td>
                                            {{data.categoryName}}
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </nz-table>
                            <!-- <div class="loader" *ngIf="loading">
                                <nz-spin nzSimple></nz-spin>
                            </div> -->
                        </div>
                    </nz-list>
                </div>
            </div>
        </div>
        <div nz-row nzGutter="16" nzJustify="end">
            <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="right-aline">
                <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()">
                    {{idForUpdate? 'Update':'Submit'}}
                </button>
            </div>
        </div>
    </nz-card>
</div>