import { Component, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HttpRequestService } from 'src/app/core/services';
import { UserDetailsComponent } from '../user-details/user-details.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  allUsers: any[] = [];
  totalDataCount = 0;
  pageSize = 12;
  pageIndex = 1;
  loading = false;
  searchUser: Subject<any> = new Subject<any>();
  search = '';
  listView = true;
  isOpenDetails = true;
  isAddOrUpdate = false;
  isViewDetailsOfUser:boolean = true;
  active: boolean = false;
  deActive: boolean = true;
  dateRange : any;
  startDate: any;
  endDate: any;
  isVisible:boolean = false;
  apiUrl = environment.apiBaseUrl
  type: any
  skipIndex : number = 0
  constructor(
    private route : Router,
    private drawerService: NzDrawerService,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService
  ) {}

  // on page load functions
  ngOnInit(): void {
    this.searchUser.pipe(debounceTime(1000)).subscribe((success) => {
      this.search = success;
      this.getAllUsers();
    });
  }
  exportFile(type: string): void {
    window.open(
      this.apiUrl + `/api/users/export/${type}?token=${localStorage.getItem('token')}`,
      '_blank'
    );
    this.isVisible = false;
    this.type = '';
    
  }
  onChangeDateRange(date: any){
    this.startDate = date[0]
    this.endDate = date[1]
   
    this.getAllUsers();
  }
  /* Get all subjects */
  getAllUsers(skip = 0, sortBy?: any): void {
    let params: any;
    params = { skip: this. skipIndex, limit: this.pageSize };
    if (this.search) {
      params.search = this.search;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if( this.startDate &&  this.endDate){
      params.startDate = this.startDate;
      params.endDate = this.endDate   ;
    }else{
      delete params.startDate;
      delete params.endDate;
    }
    this.loading = true;
    this.httpRequestService.request('get', 'users', params).subscribe(
      (result) => {
        this.loading = false;
        this.allUsers = result.data;
        this.totalDataCount = result.totalCount;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  /* For Pagination / Sending skip */
  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex} = params;
    this.skipIndex = pageSize * (pageIndex - 1)
    this.getAllUsers(pageSize * (pageIndex - 1));
  }
  /* For Pagination / Sending skip for grid view*/
  onQueryParamsChangeForGridView(index: any): void {
    this.pageIndex = index;
    this.skipIndex = this.pageSize * (this.pageIndex - 1)
    this.getAllUsers(this.pageSize * (this.pageIndex - 1));
  }
  onQueryPageSizeChangeForGridView(size: any) {
    this.pageSize = size;
    this.skipIndex = this.pageSize * (this.pageIndex - 1)
    this.getAllUsers(this.pageSize * (this.pageIndex - 1));
  }

  /* Status change*/
  updateStatus(id: string, status: boolean): void {
    this.httpRequestService
      .request('put', `users/active-inactive/${id}`, { status: !status })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllUsers();
      });
  }
  openUserDetails(item: any) {
    this.route.navigate(['/','main', 'user', 'detail', item._id]);
    // const drawerRef = this.drawerService.create({
    //   nzTitle: 'User Details',
    //   nzFooter: '',
    //   nzWidth: '50%',
    //   nzContent: UserDetailsComponent,
    //   nzContentParams: {
    //     value: item,
    //   },
    // });
  }  openSubscribePack(item: any) {
    
  }
  changeGridView() {
    this.listView = false;
    this.active = true;
    this.deActive = false;
  }
  changeListView() {
    this.listView = true;
    this.active = false;
    this.deActive = true;
  }
  // Seach subject
  searchUsers(event: any): void {
    this.searchUser.next(event);
  }
}
