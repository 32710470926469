import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlanDetailsComponent } from './plan-details/plan-details.component';
import { PlansComponent } from './plans/plans.component';
import { AddUpdatePlanComponent } from './add-update-plan/add-update-plan.component';

const routes: Routes = [
  {
    path: '',
    component: PlansComponent
  },
  {
    path: 'add',
    component: AddUpdatePlanComponent
  },
  {
    path: 'update/:id',
    component: AddUpdatePlanComponent
  },
  {
    path: 'details/:id',
    component: PlanDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlanRoutingModule { }
