import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRequestService } from 'src/app/core/services';
import { endOfMonth } from 'date-fns';@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  totalQuestionCount: number = 0;
  totalTestCount: number = 0;
  totalTestSeriesCount: number = 0;
  totalPaidTestSeriesCount: number = 0;
  totalUnpaidTestSeriesCount: number = 0;
  totalUserCount: number = 0;
  selectedStartDate:any;
  selectedEndDate:any;
  dashboardData: any;
  userCount: number = 0
  chartOptions:any;
  ranges = { Today: [new Date(), new Date()], 'This Month': [new Date(), endOfMonth(new Date())] };
  constructor(
    private httpRequestService: HttpRequestService,
    private router: Router,
    private route: ActivatedRoute
  ) { 
    this.chartOptions = {
      series: [{
        name: 'User',
        data: []
      }],
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false
        }
      },
      labels:[],
      plotOptions: {
        bar: {
          columnWidth: '30%',
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      fill: {
        colors: ['#F44336']
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },

      xaxis: {
        type: "category",
        position: "bottom",
        // labels: {
        //   offsetY: -18
        // },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: true,
          offsetY: -35
        }
      },
      title: {
        text: "User Engagement",
        // offsetY: 320,
        align: "center",
        margin: 25,
        style: {
          position: "top",
          color: "#444"
        }
      }
    };
  }
  ngOnInit(): void {
    this.getAllCounts();
    this.getAllUserCount();
    
  }

  onChange(result: Date[]): void {
    this.selectedStartDate = new Date (result[0]);
    this.selectedEndDate = new Date(result[1]);
    this.getAllUserCount();
  }


  getAllCounts(){
    this.httpRequestService
      .request('get', 'dashboards')
      .subscribe(
        (result: any) => {
          const countData = result.data;
          this.totalQuestionCount = countData.questionCount;
          this.totalTestCount = countData.testCount;
          this.totalTestSeriesCount = countData.TestSeriesCount[0].totalCount ;
          this.userCount = countData.userCount
          this.totalPaidTestSeriesCount = countData.TestSeriesCount[0].paidSeriesCount;
          this.totalUnpaidTestSeriesCount = countData.TestSeriesCount[0].unPaidSeriesCount;
        },
        (error: any) => {}
      );
  }
  getAllUserCount(){
    let params:any = {};
    if(this.selectedStartDate && this.selectedEndDate){
      params['startDate'] = this.selectedStartDate,
      params['endDate'] = this.selectedEndDate
    }
    this.httpRequestService.request('get', 'dashboards/users-count', params).subscribe((result: any)=>{
          const data = result.data;
          let dataArr:any[] = [];
            let levelArr: string[] = [];
            for(let key in data){
                dataArr.push(data[key]);
                const date = new Date(key);
            const formattedDate = date.toLocaleString('default', { day: 'numeric', month: 'short' });
                levelArr.push(formattedDate);
            };
                this.chartOptions.series[0].data =[...dataArr];
                this.chartOptions.labels =levelArr;
          
    },
    (error: any) => {}
    )
  }
}
