import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor() {}
  get apiUrl(): string {
    return environment.apiBaseUrl
  }

  // get mediaBaseUrl(): string {
  //   return environment.production
  //     ? 'https://bt-prod-ijss.s3.ap-south-1.amazonaws.com/'
  //     : 'https://bt-dev-ijss.s3.ap-south-1.amazonaws.com/';
  // }
  get mediaBaseUrl(): string {
    return environment.production ? '/' : '/';
  }
}
