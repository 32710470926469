<nz-page-header class="site-page-header" nzBackIcon [nzTitle]="idForUpdate ? 'Update Story' : 'Add Story'">
</nz-page-header>
<form nz-form [formGroup]="addUpdateSuccessStoriesForm">
    <nz-card nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
            <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="subjectName" nzRequired>Name</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                        <input nz-input formControlName="name" type="text" placeholder="Enter user name " />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="exam-category" nzRequired>Exam-Category name
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Please select your exam-category!">
                        <nz-select nzAllowClear nzShowSearch nzServerSearch id="testType"
                            formControlName="examCategory" nzPlaceHolder="Select a option"
                            (nzOnSearch)="searchExamCategoryForDropdown($event)">
                            <nz-option *ngFor="let exam of allExamCategories" nzValue="{{ exam._id }}"
                                nzLabel="{{ exam.categoryName }}"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="exam-category" nzRequired>Star Count
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                        <nz-input-number class="width-100"  formControlName="starCount" [nzMin]="1" [nzMax]="5" [nzStep]="1"></nz-input-number>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzLg="18" nzMd="18" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                        <nz-form-label [nzSpan]="24" nzFor="description">Description</nz-form-label>
                        <!-- <ckeditor #editor id="description" name="description" formControlName="description"
                            type="divarea">
                        </ckeditor> -->
                        <textarea nz-input placeholder="Add a Description" formControlName="description" [nzAutosize]="{ minRows: 3 }"></textarea>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="subject">Image</nz-form-label>
                    <nz-upload class="upload-list-inline" nzName="avatar"
                        (nzChange)="UploadImageFile($event, 'image', 0)" [nzName]="'file'" [nzShowUploadList]="false"
                        [nzBeforeUpload]="beforeRelatedMediaUpload" [nzAction]="mediaUploadUrl"
                        [nzHeaders]="customRequestHeaders">
                        <button nz-button *ngIf="!addUpdateSuccessStoriesForm.value.image" [nzLoading]="imageLoading">
                            <span nz-icon nzType="upload"></span>
                            Upload
                        </button>
                    </nz-upload>
                    <nz-card *ngIf="addUpdateSuccessStoriesForm.value.image" class="question-image-card">
                        <img nz-image width="auto" max-height="150px" class="img-responsive"
                            [nzSrc]="mediaBaseUrl + addUpdateSuccessStoriesForm.value.image" />

                        <button nz-button nzDanger class="remove-image image-card-del-icon"
                        nzTooltipTitle="Delete" nz-tooltip nz-popconfirm
                        nzPopconfirmTitle="Are you sure delete this?"
                        (nzOnConfirm)="removeImage(addUpdateSuccessStoriesForm.value.image)"
                            nzType="primary">
                            <span nz-icon nzType="delete" nzTheme="outline"></span>
                        </button>
                    </nz-card>
                </nz-form-item>
            </div>
        </div>
        <div nz-row nzJustify="end">
            <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()" class="submitoraddbutton">
                {{idForUpdate? 'Update':'Submit'}}
            </button>
        </div>
    </nz-card>
</form>
