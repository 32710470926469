<nz-page-header class="site-page-header" nzBackIcon
  [nzTitle]="idForUpdate ? 'Update Exam Category' : 'Add Exam Category'">
</nz-page-header>
<form nz-form [formGroup]="addUpdateExamsCategoryForm">
  <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
    <nz-card nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="topicName" nzRequired>Exam Category Name</nz-form-label>
        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
          <input nz-input formControlName="categoryName" type="text" placeholder="Enter Category Name " />
        </nz-form-control>
      </nz-form-item>
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <div nz-col nzLg="18" nzMd="18" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="description">Exam Description</nz-form-label>
            <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
              <textarea formControlName="description" nz-input rows="4"
                placeholder="Enter Category description"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="subject">Image</nz-form-label>
            <nz-upload class="upload-list-inline" nzName="avatar" (nzChange)="UploadImageFile($event, 'image', 0)"
              [nzName]="'file'" [nzShowUploadList]="false" [nzBeforeUpload]="beforeRelatedMediaUpload"
              [nzAction]="mediaUploadUrl" [nzHeaders]="customRequestHeaders">
              <button nz-button *ngIf="!addUpdateExamsCategoryForm.value.image" [nzLoading]="loading">
                <span nz-icon nzType="upload"></span>
                Upload
              </button>
            </nz-upload>
            <nz-card *ngIf="addUpdateExamsCategoryForm.value.image" class="question-image-card">
              <img nz-image width="auto" max-height="150px" class="img-responsive"
                [nzSrc]="mediaBaseUrl + addUpdateExamsCategoryForm.value.image" />

              <button nz-button nzDanger class="remove-image image-card-del-icon"
                nzType="primary"  nzTooltipTitle="Delete" nz-tooltip nz-popconfirm
                nzPopconfirmTitle="Are you sure delete this?"
                (nzOnConfirm)="removeImage(addUpdateExamsCategoryForm.value.image)">
                <span nz-icon nzType="delete" nzTheme="outline"></span>
              </button>
            </nz-card>
          </nz-form-item>
        </div>
      </div>
      <div nz-row nzJustify="end">
        <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()" class="submitoraddbutton">
          {{idForUpdate? 'Update':'Submit'}}
        </button>
      </div>
    </nz-card>
  </div>
</form>
