import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExamCategoryRoutingModule } from './exam-category-routing.module';
import { ExamCategoryDetailsComponent } from './exam-category-details/exam-category-details.component';
import { AddUpdateExamsCategoryComponent } from './add-update-exams-category/add-update-exams-category.component';
import { ExamsCategoriesComponent } from './exams-categories/exams-categories.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    ExamCategoryDetailsComponent,
    AddUpdateExamsCategoryComponent,
    ExamsCategoriesComponent,

  ],
  imports: [
    CommonModule,
    ExamCategoryRoutingModule,
    SharedModule
  ]
})
export class ExamCategoryModule { }
