<nz-page-header class="site-page-header" nzBackIcon [nzTitle]="idForUpdate ? 'Update Test-series' : 'Add Test-series'">
</nz-page-header>
<form nz-form [formGroup]="addUpdateTestSeriesForm">
    <div nz-row>
        <nz-card nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="topicName" nzRequired>Test Series Name</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                            <input nz-input formControlName="name" type="text" placeholder="Enter Test Name " />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="exam-category" nzRequired>Exam Category Name
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Please select your exam-category!">
                            <nz-select nzAllowClear nzShowSearch nzServerSearch id="testType"
                                formControlName="examCategory" nzPlaceHolder="Select a option"
                                (nzOnSearch)="searchExamCategoryForDropdown($event)">
                                <nz-option *ngFor="let exam of allExamCategories" nzValue="{{ exam._id }}"
                                    nzLabel="{{ exam.categoryName }}"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="subject" nzRequired>Exam Name </nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Please select your exam!">
                            <nz-select nzAllowClear nzShowSearch nzServerSearch id="testType" formControlName="exam"
                                nzPlaceHolder="Select a option" (nzOnSearch)="searchExamForDropdown($event)" (ngModelChange)="examChange($event)">
                                <nz-option *ngFor="let exam of allExams" nzValue="{{ exam._id }}"
                                    nzLabel="{{ exam.examName }}"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">

                    <nz-form-item>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                            <nz-form-label [nzSpan]="24" nzFor="description">Description</nz-form-label>
                            <!-- <ckeditor #editor id="description" name="description" formControlName="description"
                                type="divarea">
                            </ckeditor> -->
                            <ckeditor formControlName="description" name="editor" [editor]="Editor" [config]="ckeConfig" debounce="500">
                            </ckeditor>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="18" nzMd="18" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                            <nz-form-label [nzSpan]="24" nzFor="description">Meta Description</nz-form-label>
                            <!-- <ckeditor #editor id="extraDescription" name="extraDescription"
                                formControlName="extraDescription" type="divarea">
                            </ckeditor> -->
                            <ckeditor formControlName="extraDescription" name="editor"  [editor]="Editor" [config]="ckeConfig" debounce="500">
                        </ckeditor>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="subject">Image</nz-form-label>
                        <nz-upload class="upload-list-inline" nzName="avatar"
                            (nzChange)="UploadImageFile($event, 'image', 0)" [nzName]="'file'"
                            [nzShowUploadList]="false" [nzBeforeUpload]="beforeRelatedMediaUpload"
                            [nzAction]="mediaUploadUrl" [nzHeaders]="customRequestHeaders">
                            <button nz-button *ngIf="!addUpdateTestSeriesForm.value.image" [nzLoading]="imageLoading">
                                <span nz-icon nzType="upload"></span>
                                Upload
                            </button>
                        </nz-upload>
                        <nz-card *ngIf="addUpdateTestSeriesForm.value.image" class="question-image-card">
                            <img nz-image width="auto" max-height="150px" class="img-responsive"
                                [nzSrc]="mediaBaseUrl + addUpdateTestSeriesForm.value.image" />

                            <button nz-button nzDanger class="remove-image image-card-del-icon"
                                nzType="primary"
                                nzTooltipTitle="Delete" nz-tooltip nz-popconfirm
                                nzPopconfirmTitle="Are you sure delete this?"
                                (nzOnConfirm)="removeImage(addUpdateTestSeriesForm.value.image)"
                                >
                                <span nz-icon nzType="delete" nzTheme="outline"></span>
                            </button>
                        </nz-card>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="2" nzMd="2" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="description">Is Paid ?</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                            <nz-switch formControlName="isPaid" (ngModelChange)="clickChange($event)"></nz-switch>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="11" nzMd="11" nzSm="24" nzXs="24" *ngIf="addUpdateTestSeriesForm.value.isPaid">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="description">Price&nbsp;<small>(in
                                ₹)</small></nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                            <span nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                                <nz-input-number nz-input formControlName="price" class="width-100" type="number"
                                    [nzMin]="0" placeholder="Enter Negative Marks"></nz-input-number>
                            </span>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <!-- <div nz-col nzLg="11" nzMd="11" nzSm="24" nzXs="24" *ngIf="addUpdateTestSeriesForm.value.isPaid">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="subject">Duration Time &nbsp;<small>(in Month)</small></nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Please select your Duration Time!">
                            <nz-select nzAllowClear nzShowSearch nzServerSearch id="durationTime"
                                formControlName="durationTime" nzPlaceHolder="Select a option">
                                <nz-option *ngFor="let item of allDurationTime" [nzValue]="item.value"
                                    [nzLabel]="item.label"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div> -->
                <div nz-col nzLg="11" nzMd="11" nzSm="24" nzXs="24" *ngIf="addUpdateTestSeriesForm.value.isPaid">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="subject">Duration Time &nbsp;<small>(in
                                Days)</small></nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Please select your Duration Time!">
                            <span nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                                <nz-input-number nz-input formControlName="durationTime" type="number" class="width-100"
                                    [nzMin]="0" placeholder="Enter Negative Marks"></nz-input-number>
                            </span>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <!-------------------------- Test Add Section -------------------------------------->
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                    <nz-divider></nz-divider>
                    <div nz-row>
                        <!-- <div nz-row [nzGutter]="24"> -->
                        <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
                            <nz-form-item>
                                <nz-form-label [nzSpan]="24" nzFor="subject">Difficulty Level</nz-form-label>
                                <nz-form-control [nzSpan]="24" nzErrorTip="Please select your Difficulty Level!">
                                    <nz-select nzShowSearch nzServerSearch id="difficultyLevel" nzAllowClear
                                        formControlName="difficultyLevel" nzPlaceHolder="Select a option"
                                        (ngModelChange)="onChangeDifficultyLevel($event)">
                                        <nz-option *ngFor="let item of allLevels" nzValue="{{ item.value }}"
                                            nzLabel="{{ item.label }}"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <!-- <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
                          <nz-form-item>
                            <nz-form-control [nzSpan]="24" nzErrorTip="Please select your exam!">
                              <nz-form-label nzFor="exam">Search By Exam</nz-form-label>
                              <nz-select nzAllowClear nzShowSearch nzServerSearch id="exam" [(ngModel)]="selectedExam"
                                nzPlaceHolder="Select exam" (nzOnSearch)="searchExamDropdown($event)" (ngModelChange)="onChangeExam()">
                                <nz-option *ngFor="let item of allExams" nzValue="{{ item._id }}" nzLabel="{{ item.examName }}"></nz-option>
                              </nz-select>
                            </nz-form-control>
                          </nz-form-item>
                        </div> -->
                        <!-- </div> -->
                    </div>
                    <div nz-row nzType="flex" nzJustify="center" class="mb4" [nzGutter]="16"></div>
                    <div nz-row [nzGutter]="16">
                        <div nz-col class="gutter-row" [nzMd]="12" [nzSm]="24">
                            <nz-list nzBordered nzSize="small" class="samelistheight questionlistbox">
                                <nz-list-header>
                                    <h4>
                                        Total Test Count:
                                        <!-- {{ allTests.length - newSelectedTests.length > 0? allTests.length - newSelectedTests.length : 0 }} -->
                                        {{totalDataCount}}
                                    </h4>

                                    <div nz-row>
                                        <button style="text-align: right" nz-button nzSize="small"
                                            (click)="addTests()">
                                            Add
                                        </button>
                                    </div>
                                </nz-list-header>
                                <div>
                                    <div class="myqlistitems">
                                        <nz-list-item *ngFor="let item of filterTests; index as i"
                                            class="qlist flex-wrapneed">
                                            <label nz-checkbox [(ngModel)]="item.isChecked"
                                                [ngModelOptions]="{ standalone: true }"></label>
                                            <span class="mr2">
                                                {{i+1}}.
                                            </span>
                                            <div class="inquestionlistonform">
                                                {{item.name}}
                                            </div>
                                        </nz-list-item>
                                    </div>
                                    <div class="loader" *ngIf="loading" >
                                        <nz-spin nzSimple class="loader-span"></nz-spin>
                                    </div>
                                </div>


                                <div class=" loadMore-button text-center mt3 " *ngIf="hasNextPage">
                                    <button [disabled]="limit" nz-button nzType="primary" nzSize="small"
                                        (click)="testPagination()"> Load More
                                    </button>
                                </div>
                            </nz-list>
                        </div>
                        <div nz-col class="gutter-row" [nzMd]="12" [nzSm]="24">
                            <nz-list nzBordered nzSize="small" class="samelistheight questionlistbox">
                                <nz-list-header>
                                    <h4>Total Selected Tests: {{ newSelectedTests.length }}</h4>

                                    <div nz-row>
                                        <button style="text-align: right" nz-button nzDanger nzSize="small"
                                            (click)="removeTests()">
                                            Remove
                                        </button>
                                    </div>
                                </nz-list-header>

                                <div class="myqlistitems">
                                    <nz-table #basicTable [nzData]="newSelectedTests" [nzShowPagination]="false"
                                        class="valigntop">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Tests</th>
                                                <th>Is Free</th>
                                            </tr>
                                        </thead>
                                        <tbody cdkDropList (cdkDropListDropped)="drop($event)" class="drag-css">
                                            <tr *ngFor="let data of newSelectedTests;  index as i" cdkDrag>
                                                <td>
                                                    <label nz-checkbox [(ngModel)]="data.isChecked"
                                                        [ngModelOptions]="{ standalone: true }"></label>
                                                    <span class="ml4">{{i+1}}.</span>
                                                </td>
                                                <td>
                                                    {{data.name}}
                                                </td>
                                                <td>
                                                    <nz-switch [(ngModel)]="data.isOpen" [ngModelOptions]="{ standalone: true }" [nzDisabled]="!addUpdateTestSeriesForm.value.isPaid"></nz-switch>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </nz-table>
                                    <!-- <div class="loader" *ngIf="loading">
                                        <nz-spin nzSimple></nz-spin>
                                    </div> -->
                                </div>
                            </nz-list>
                        </div>
                    </div>
                </div>
            </div>
            <div nz-row nzGutter="16" nzJustify="end">
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="right-aline">
                    <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()">
                        {{idForUpdate? 'Update':'Submit'}}
                    </button>
                </div>
            </div>
        </nz-card>
    </div>
</form>
