import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editorial-details',
  templateUrl: './editorial-details.component.html',
  styleUrls: ['./editorial-details.component.scss']
})
export class EditorialDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
