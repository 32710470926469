<div class="site-page-header-ghost-wrapper">
  <nz-page-header [nzGhost]="false">
    <nz-page-header-title class="ml4">Questions </nz-page-header-title>
    <nz-page-header-extra class="mr4">
      <button nz-button nzType="primary" [routerLink]="['add-bulk-question']"
        [queryParams]="{index: index, statusIndex: statusIndex}" routerLinkActive="router-link-active"
        class="submitoraddbutton" style="margin-right: 5px">
        Add Bulk Question
      </button>
      <button nz-button nzType="primary" [routerLink]="['add-paragraph-question']"
        [queryParams]="{index: index, statusIndex: statusIndex}" routerLinkActive="router-link-active"
        class="submitoraddbutton" style="margin-right: 5px">
        Add Paragraph Question
      </button>
      <button nz-button nzType="primary" [routerLink]="['add']" [queryParams]="{index: index, statusIndex: statusIndex}"
        routerLinkActive="router-link-active" class="submitoraddbutton">
        Add Question
      </button>
    </nz-page-header-extra>
  </nz-page-header>
</div>
<div nz-row nzJustify="end" [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }" class="mt4">
  <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzErrorTip="Please select your subject!">
        <nz-form-label nzFor="chapter">Subject</nz-form-label>
        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" [(ngModel)]="selectedSubject"
          nzPlaceHolder="Select a subject" (nzOnSearch)="searchSubjectForDropdown($event)"
          (ngModelChange)="onChangeSubject()">
          <nz-option *ngFor="let item of allSubjects" nzValue="{{ item._id }}"
            nzLabel="{{ item.subjectName }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzErrorTip="Please select your chapter!">
        <nz-form-label nzFor="chapter">Chapter</nz-form-label>
        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" [(ngModel)]="selectedChapter"
          nzPlaceHolder="Select a Chapter" (nzOnSearch)="searchChapterForDropdown($event)"
          (ngModelChange)="onChangeChapter()">
          <nz-option *ngFor="let item of allChapters" nzValue="{{ item._id }}"
            nzLabel="{{ item.chapterName }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzErrorTip="Please select your Topic!">
        <nz-form-label nzFor="chapter">Topic</nz-form-label>
        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" [(ngModel)]="selectedTopic"
          nzPlaceHolder="Select a Topic" (nzOnSearch)="searchTopicForDropdown($event)"
          (ngModelChange)="onChangeTopics()">
          <nz-option *ngFor="let item of allTopics" nzValue="{{ item._id }}" nzLabel="{{ item.topicName }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzErrorTip="Please select your Difficulty Level!">
        <nz-form-label nzFor="chapter"> Difficulty Level</nz-form-label>
        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" [(ngModel)]="difficultyLevelForQuestion"
          nzPlaceHolder="Select Difficulty Level" (ngModelChange)="onChangeDifficultyLevel()">
          <nz-option *ngFor="let item of allLevels" nzValue="{{ item.value }}" nzLabel="{{ item.label }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-label nzFor="Date">Date</nz-form-label>
      <nz-form-control [nzSpan]="24" nzErrorTip="Please select a Date!">
        <nz-range-picker class="width-100" [(ngModel)]="dateRange"
          (ngModelChange)="onChangeDateRange($event)"></nz-range-picker>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24">
        <nz-form-label>Sub Admin</nz-form-label>
        <nz-select nzAllowClear nzShowSearch nzServerSearch [(ngModel)]="selectedSubAdmin"
          nzPlaceHolder="Select a Sub Admin" (nzOnSearch)="searchSubAdminForDropdown($event)"
          (ngModelChange)="onChangeSubAdmin()">
          <nz-option *ngFor="let item of allSubAdmins" nzValue="{{ item._id }}" nzLabel="{{ item.name }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24" class="text-left mb5">
    <nz-form-label nzFor="chapter"> Search</nz-form-label>
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input type="text" nz-input placeholder="Search by question name" ngModel
        (ngModelChange)="searchQuestions($event)" />
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div>

</div>
<div nz-row>
  <nz-button-group class="btnPosition">
    <button nz-button [nzType]="!active && deActive?'primary':'link'" (click)="changeListView()"> <span
        class="icon-size" nz-icon nzType="ordered-list" nzTheme="outline"></span></button>
    <!-- <nz-divider nzType="vertical"></nz-divider> -->
    <button nz-button [nzType]="active && !deActive?'primary':'link'" (click)="changeGridView() "><span
        class="icon-size action-button" nz-icon nzType="appstore" nzTheme="outline"></span></button>
  </nz-button-group>
</div>
<!-- list view of a Question -->
<nz-tabset (nzSelectedIndexChange)="changeIndex($event)" [nzSelectedIndex]="index" *ngIf="listView">

  <nz-tab nzTitle="Normal Questions">
    <div *ngIf="showDeleteButton && type === 'normal-question' && isApproved  === 'approved' ">
      <button class="btnPosition" nzType="primary" nz-button nz-popconfirm
        nzPopconfirmTitle="Are you sure delete this selected Questions" (nzOnConfirm)="bulkDelete()">Bulk
        Delete</button>
    </div>
    <div *ngIf="showDeleteButton && type === 'normal-question' && isApproved  === 'unApproved' ">
      <button class="btnPosition" nzType="primary" nz-button nz-popconfirm
        nzPopconfirmTitle="Are you sure delete this selected Questions" (nzOnConfirm)="bulkDelete()">Bulk
        Delete</button>
    </div>
    <nz-tabset [nzSelectedIndex]="statusIndex" (nzSelectedIndexChange)="changeStatusIndex($event)" *ngIf="listView">
      <nz-tab nzTitle="Approved ">
        <nz-table #QuestionstTbl nzShowSizeChanger [nzData]="allApprovedQuestions" [nzLoading]="loading"
          [nzTotal]="totalApprovedDataCount" [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex"
          [nzFrontPagination]="false" (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger
          [nzScroll]="{ x: '900px' }">
          <thead>
            <tr>
              <!-- <th>Concept ID</th> -->
              <th nzWidth="60px" [nzLeft]="'fixedColumn'"
                [nzChecked]="checked && type === 'normal-question' && isApproved === 'approved' "
                [nzIndeterminate]="indeterminate" nzLabel="Select all" (nzCheckedChange)="onAllChecked($event)"></th>
              <!-- <th nzColumnKey="topic" [nzSortFn]="true">Topic</th> -->
              <th>Question</th>
              <th nzWidth="">Information</th>
              <th nzWidth="">Status</th>
              <th nzWidth="150px" class="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of QuestionstTbl.data; index as i">
              <td [nzChecked]="setOfCheckedId.has(data._id) && type === 'normal-question' && isApproved === 'approved'"
                (nzCheckedChange)="onItemChecked(data._id, $event, 'normal-question', 'approved')"></td>
              <td class="{{ data.questionText != null ? 'description' : '' }}">
                <span *ngIf="data.questionText">
                  <app-mathjax [content]="data.questionText"></app-mathjax>
                </span>
              </td>

              <td>
                <p>
                  <b>Difficulty Level : </b>
                  {{ difficultyLevelObject[data.difficultyLevel].label }}
                </p>
                <p *ngIf="data?.subject?.subjectName">
                  <b>Subject : </b>{{ data?.subject?.subjectName }}
                </p>
                <p *ngIf="data?.chapter?.chapterName">
                  <b>Chapter : </b>{{ data?.chapter?.chapterName }}
                </p>
                <p *ngIf="data?.topic?.topicName">
                  <b>Topic : </b>{{ data?.topic?.topicName }}
                </p>
              </td>
              <td>
                <span class="{{ data.status ? 'active' : 'inactive' }} status"></span>
              </td>
              <td class="action-buttons">
                <a nz-button nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
                  [routerLink]="['update', data._id]" [queryParams]="{index: index, statusIndex: statusIndex}"
                  routerLinkActive="router-link-active"><i nz-icon nzType="form" nzTheme="outline"></i></a>
                <a nz-button nzTooltipTitle="Details" nzTooltipPlacement="topCenter" nz-tooltip
                  (click)="openQuestionDetails(data)" routerLinkActive="router-link-active"><i nz-icon nzType="eye"
                    nzTheme="outline"></i></a>
                <a *ngIf="userRole !== 'subAdmin'" nz-button nz-popconfirm nzPopconfirmTitle="Are you sure {{
                    data.status ? 'deActive' : 'active'
                  }} this Question?" (nzOnConfirm)="updateStatus(data._id, data.status)"
                  nzTooltipTitle="{{ data.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight" nz-tooltip><i
                    nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>

                <a *ngIf="userRole !== 'subAdmin'" class="action-button delete" nz-button nz-popconfirm
                  [nzPopconfirmTitle]="popConfirmTemplate" 
                  (nzOnConfirm)="deleteQuestions(data._id)" (nzPopconfirmVisibleChange)="onVisibleChange($event, data._id)">
                  <i nz-icon nzType="delete" nzTheme="fill"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </nz-tab>

      <nz-tab nzTitle="Un-approved ">
        <div nz-row nzJustify="end" class="mb2" *ngIf="userRole !== 'subAdmin'">
          <button nz-button nzType="primary" nzTooltipTitle="Details" nzTooltipPlacement="topCenter" nz-tooltip
            routerLinkActive="router-link-active" (click)="openBulkApprove('normal-question')">
            Bulk Approve<span nz-icon nzType="merge-cells" nzTheme="outline"></span>
          </button>
        </div>
        <nz-table [nzData]="allQuestions" [nzLoading]="loading" [nzTotal]="totalDataCount" [(nzPageSize)]="pageSize"
          [nzPageIndex]="pageIndex" [nzFrontPagination]="false" (nzQueryParams)="onQueryParamsChange($event)"
          nzShowSizeChanger [nzScroll]="{ x: '900px' }">
          <thead>
            <tr>
              <th nzWidth="60px" [nzLeft]="'fixedColumn'"
                [nzChecked]="checked && type === 'normal-question' && isApproved === 'unApproved'"
                [nzIndeterminate]="indeterminate" nzLabel="Select all" (nzCheckedChange)="onAllChecked($event)"></th>
              <th>Question</th>
              <th nzWidth="350px">Information</th>
              <th nzWidth="150px">Created By</th>
              <th nzWidth="150px">Rejected Reason</th>
              <th nzWidth="150px">Status</th>
              <th nzWidth="150px" class="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of allQuestions; index as i">
              <td
                [nzChecked]="setOfCheckedId.has(data._id) && type === 'normal-question' && isApproved === 'unApproved'"
                (nzCheckedChange)="onItemChecked(data._id, $event, 'normal-question', 'unApproved')"></td>
              <td class="{{ data.questionText != null ? 'description' : '' }}">
                <span *ngIf="data.questionText">
                  <app-mathjax [content]="data.questionText"></app-mathjax>
                </span>
              </td>

              <td>
                <p>
                  <b>Difficulty Level : </b>
                  {{ difficultyLevelObject[data.difficultyLevel].label }}
                </p>
                <p *ngIf="data?.subject?.subjectName">
                  <b>Subject : </b>{{ data?.subject?.subjectName }}
                </p>
                <p *ngIf="data?.chapter?.chapterName">
                  <b>Chapter : </b>{{ data?.chapter?.chapterName }}
                </p>
                <p *ngIf="data?.topic?.topicName">
                  <b>Topic : </b>{{ data?.topic?.topicName }}
                </p>
              </td>
              <td><span *ngIf="data.createdBy?.role === 'subAdmin'">
                  {{ data?.createdBy?.name }}
                </span></td>
              <td><span *ngIf="data.rejectionReason" class="ellipsis">{{data.rejectionReason}}</span><button nz-button
                  nzType="link" (click)="rejectionReasonModal(data.rejectionReason)" *ngIf="data.rejectionReason">
                  Read More</button></td>
              <td>
                <nz-tag
                  [nzColor]="data.approveStatus === 'approved' ? 'green':  data.approveStatus === 'pending'? 'lime':'volcano' ">
                  {{data.approveStatus}}

                </nz-tag>
              </td>
              <!-- <td><span class="{{data.status? 'active' : 'inactive' }} status"></span></td> -->
              <td class="action-buttons">
                <a nz-button nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
                  [routerLink]="['update', data._id]" [queryParams]="{index: index, statusIndex: statusIndex}"
                  routerLinkActive="router-link-active"><i nz-icon nzType="form" nzTheme="outline"></i></a>
                <a nz-button nzTooltipTitle="Details" nzTooltipPlacement="topCenter" nz-tooltip
                  (click)="openQuestionDetails(data)" routerLinkActive="router-link-active"><i nz-icon nzType="eye"
                    nzTheme="outline"></i></a>
                <a *ngIf="userRole !== 'subAdmin'" class="action-button" nz-button nz-popconfirm
                  nzPopconfirmTitle="Are you sure {{data.status ? 'deActive' : 'active'}} this Question?"
                  (nzOnConfirm)="updateStatus(data._id, data.status)"
                  nzTooltipTitle="{{data.status ? 'Deactive' : 'Active'}}" nzTooltipPlacement="topRight" nz-tooltip><i
                    nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>

                <a *ngIf="userRole !== 'subAdmin' " class="action-button top-margin" nz-button nz-popconfirm
                  nzPopconfirmTitle="Are you sure Approved this Question?"
                  (nzOnConfirm)="updatePendingStatus(data._id, data.approveStatus)" nzTooltipTitle="{{'Approve'}}"
                  nzTooltipPlacement="topRight" nz-tooltip><i nz-icon nzType="file-done" nzTheme="outline"></i></a>

                <a *ngIf="userRole !== 'subAdmin' && data.approveStatus !== 'rejected'" class="action-button top-margin
                delete" nz-button nz-popconfirm nzPopconfirmTitle="Are you sure Reject this Question?"
                  (nzOnConfirm)="openRejectionReason(data._id, data.approveStatus)" nzTooltipTitle='Reject'
                  nzTooltipPlacement="topRight" nz-tooltip><i nz-icon [nzType]="'close-circle'" nzTheme="fill"></i></a>

              </td>
            </tr>
          </tbody>
        </nz-table>
      </nz-tab>
    </nz-tabset>
  </nz-tab>

  <nz-tab nzTitle="Paragraph Questions">
    <div *ngIf="showDeleteButton && type === 'paragraph-question' && isApproved === 'approved'">
      <button class="btnPosition" nzType="primary" nz-button nz-popconfirm
        nzPopconfirmTitle="Are you sure delete this selected Questions" (nzOnConfirm)="bulkDelete()">Bulk
        Delete</button>
    </div>
    <div *ngIf="showDeleteButton && type === 'paragraph-question' && isApproved === 'unApproved'">
      <button class="btnPosition" nzType="primary" nz-button nz-popconfirm
        nzPopconfirmTitle="Are you sure delete this selected Questions" (nzOnConfirm)="bulkDelete()"> Bulk
        Delete</button>
    </div>
    <nz-tabset [nzSelectedIndex]="statusIndex" (nzSelectedIndexChange)="changeStatusIndex($event)" *ngIf="listView">
      <nz-tab nzTitle="Approved ">
        <nz-table #QuestionstTbl1 [nzData]="allApprovedParagraphQuestions" [nzLoading]="loading"
          [nzTotal]="totalApprovedParagraphDataCount" [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex"
          [nzFrontPagination]="false" (nzQueryParams)="onQueryParamsChangeParagraph($event)" nzShowSizeChanger
          [nzScroll]="{ x: '1000px' }">
          <thead>
            <tr>
              <!-- <th>Concept ID</th> -->
              <!-- <th nzColumnKey="topic" [nzSortFn]="true">Topic</th> -->
              <th nzWidth="60px" [nzLeft]="'fixedColumn'"
                [nzChecked]="checked && type === 'paragraph-question' && isApproved === 'approved'"
                [nzIndeterminate]="indeterminate" nzLabel="Select all" (nzCheckedChange)="onAllChecked($event)"></th>
              <th>Question</th>
              <th>Paragraph</th>
              <th nzWidth="250px">Information</th>
              <th nzWidth="150px">Status</th>
              <th nzWidth="150px" class="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of QuestionstTbl1.data; index as i">
              <td
                [nzChecked]="setOfCheckedId.has(data._id) && type === 'paragraph-question' && isApproved === 'approved'"
                (nzCheckedChange)="onItemChecked(data._id, $event, 'paragraph-question', 'approved')"></td>
              <td>
                <span nz-tooltip [nzTooltipTitle]='question' *ngIf="data.questionText"
                  class="{{ data.questionText ? 'ellipsis' : '' }}">
                  <app-mathjax [content]="data.questionText"></app-mathjax>
                </span>
                <ng-template #question>
                  <span *ngIf="data.questionText ">
                    <app-mathjax [content]="data.questionText"></app-mathjax>
                  </span>
                </ng-template>
              </td>
              <td>
                <span nz-tooltip [nzTooltipTitle]='paragraph' *ngIf="data.paragraph && data.paragraph.name"
                  class="{{ data.paragraph && data.paragraph.name ? 'ellipsis' : '' }}">
                  <app-mathjax [content]="data.paragraph.name"></app-mathjax>
                </span>
                <ng-template #paragraph>
                  <span *ngIf="data.paragraph && data.paragraph.name">
                    <app-mathjax [content]="data.paragraph.name"></app-mathjax>
                  </span>
                </ng-template>
              </td>

              <td>
                <p>
                  <b>Difficulty Level : </b>
                  {{ difficultyLevelObject[data.difficultyLevel].label }}
                </p>
                <p *ngIf="data?.subject?.subjectName">
                  <b>Subject : </b>{{ data?.subject?.subjectName }}
                </p>
                <p *ngIf="data?.chapter?.chapterName">
                  <b>Chapter : </b>{{ data?.chapter?.chapterName }}
                </p>
                <p *ngIf="data?.topic?.topicName">
                  <b>Topic : </b>{{ data?.topic?.topicName }}
                </p>
              </td>
              <td>
                <span class="{{ data.status ? 'active' : 'inactive' }} status"></span>
              </td>
              <td class="action-buttons">
                <a nz-button nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
                  [routerLink]="['update', data._id]" [queryParams]="{index: index, statusIndex: statusIndex}"
                  routerLinkActive="router-link-active"><i nz-icon nzType="form" nzTheme="outline"></i></a>

                <a nz-button nzTooltipTitle="Details" nzTooltipPlacement="topCenter" nz-tooltip
                  (click)="openQuestionDetails(data)" routerLinkActive="router-link-active"><i nz-icon nzType="eye"
                    nzTheme="outline"></i></a>

                <a *ngIf="userRole !== 'subAdmin'" class="" nz-button nz-popconfirm nzPopconfirmTitle="Are you sure {{
                      data.status ? 'deActive' : 'active'
                    }} this Question?" (nzOnConfirm)="updateStatus(data._id, data.status)"
                  nzTooltipTitle="{{ data.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight" nz-tooltip><i
                    nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </nz-tab>

      <nz-tab nzTitle="Un-approved  ">
        <div nz-row nzJustify="end" class="mb2" *ngIf="userRole !== 'subAdmin'">
          <button nz-button nzType="primary" nzTooltipTitle="Details" nzTooltipPlacement="topCenter" nz-tooltip
            routerLinkActive="router-link-active" (click)="openBulkApprove('paragraph')">
            Bulk Approve<span nz-icon nzType="merge-cells" nzTheme="outline"></span>
          </button>
        </div>
        <nz-table #QuestionstTbl1 [nzData]="allParagraphQuestions" [nzLoading]="loading"
          [nzTotal]="totalParagraphDataCount" [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex"
          [nzFrontPagination]="false" (nzQueryParams)="onQueryParamsChangeParagraph($event)" nzShowSizeChanger
          [nzScroll]="{ x: '1000px' }">
          <thead>
            <tr>
              <!-- <th>Concept ID</th> -->
              <!-- <th nzColumnKey="topic" [nzSortFn]="true">Topic</th> -->
              <th nzWidth="60px" [nzLeft]="'fixedColumn'"
                [nzChecked]="checked && type === 'paragraph-question' && isApproved === 'unApproved'"
                [nzIndeterminate]="indeterminate" nzLabel="Select all" (nzCheckedChange)="onAllChecked($event)"></th>
              <th>Question</th>
              <th>Paragraph</th>
              <th nzWidth="250px">Information</th>
              <th nzWidth="150px">Created By</th>
              <th nzWidth="200px">
                Rejected Reason
              </th>
              <th nzWidth="150px">Status</th>
              <th nzWidth="170px" class="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of allParagraphQuestions; index as i">
              <td
                [nzChecked]="setOfCheckedId.has(data._id) && type === 'paragraph-question' && isApproved === 'unApproved'"
                (nzCheckedChange)="onItemChecked(data._id, $event, 'paragraph-question', 'unApproved')"></td>
              <td>
                <span nz-tooltip [nzTooltipTitle]='question' *ngIf="data.questionText"
                  class="{{ data.questionText ? 'ellipsis' : '' }}">
                  <app-mathjax [content]="data.questionText"></app-mathjax>
                </span>
                <ng-template #question>
                  <span *ngIf="data.questionText ">
                    <app-mathjax [content]="data.questionText"></app-mathjax>
                  </span>
                </ng-template>
              </td>

              <td>
                <span nz-tooltip [nzTooltipTitle]='paragraph' *ngIf="data.paragraph && data.paragraph.name"
                  class="{{ data.paragraph && data.paragraph.name ? 'ellipsis' : '' }}">
                  <app-mathjax [content]="data.paragraph.name"></app-mathjax>
                </span>
                <ng-template #paragraph>
                  <span *ngIf="data.paragraph && data.paragraph.name">
                    <app-mathjax [content]="data.paragraph.name"></app-mathjax>
                  </span>
                </ng-template>
              </td>

              <td>
                <p>
                  <b>Difficulty Level : </b>
                  {{ difficultyLevelObject[data.difficultyLevel].label }}
                </p>
                <p *ngIf="data?.subject?.subjectName">
                  <b>Subject : </b>{{ data?.subject?.subjectName }}
                </p>
                <p *ngIf="data?.chapter?.chapterName">
                  <b>Chapter : </b>{{ data?.chapter?.chapterName }}
                </p>
                <p *ngIf="data?.topic?.topicName">
                  <b>Topic : </b>{{ data?.topic?.topicName }}
                </p>
              </td>

              <td><span *ngIf="data.createdBy?.role === 'subAdmin'">
                  {{ data?.createdBy?.name }}
                </span></td>

              <td><span *ngIf="data.rejectionReason" class="ellipsis">{{data.rejectionReason}}</span>
              </td>
              <td>
                <nz-tag
                  [nzColor]="data.approveStatus === 'approved' ? 'green':  data.approveStatus === 'pending'? 'lime':'volcano' ">
                  {{data.approveStatus}}

                </nz-tag>
              </td>
              <!-- <td><span class="{{data.status? 'active' : 'inactive' }} status"></span></td> -->
              <td class="action-buttons">
                <a nz-button nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
                  [routerLink]="['update', data._id]" [queryParams]="{index: index, statusIndex: statusIndex}"
                  routerLinkActive="router-link-active"><i nz-icon nzType="form" nzTheme="outline"></i></a>
                <a nz-button nzTooltipTitle="Details" nzTooltipPlacement="topCenter" nz-tooltip
                  (click)="openQuestionDetails(data)" routerLinkActive="router-link-active"><i nz-icon nzType="eye"
                    nzTheme="outline"></i></a>
                <a *ngIf="userRole !== 'subAdmin'" class="action-button" nz-button nz-popconfirm
                  nzPopconfirmTitle="Are you sure {{data.status ? 'deActive' : 'active'}} this Question?"
                  (nzOnConfirm)="updateStatus(data._id, data.status)"
                  nzTooltipTitle="{{data.status ? 'Deactive' : 'Active'}}" nzTooltipPlacement="topRight" nz-tooltip><i
                    nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>
                <a *ngIf="userRole !== 'subAdmin' " class="action-button top-margin" nz-button nz-popconfirm
                  nzPopconfirmTitle="Are you sure Approved this Question?"
                  (nzOnConfirm)="updatePendingStatus(data._id, data.approveStatus)" nzTooltipTitle="{{'Approve'}}"
                  nzTooltipPlacement="topRight" nz-tooltip><i nz-icon nzType="file-done" nzTheme="outline"></i></a>

                <a *ngIf="userRole !== 'subAdmin' && data.approveStatus !== 'rejected'"
                  class="action-button top-margin delete" nz-button nz-popconfirm
                  nzPopconfirmTitle="Are you sure Reject this Question?"
                  (nzOnConfirm)="openRejectionReason(data._id, data.approveStatus)" nzTooltipTitle='Reject'
                  nzTooltipPlacement="topRight" nz-tooltip><i nz-icon [nzType]="'close-circle'" nzTheme="fill"></i></a>

              </td>
            </tr>
          </tbody>
        </nz-table>
      </nz-tab>
    </nz-tabset>

  </nz-tab>
</nz-tabset>

<!-- grid view of a Question -->
<div *ngIf=" !listView">
  <nz-tabset>
    <nz-tab nzTitle="Normal Questions">
      <app-common-grid-view [unApprovedList]="allQuestions" (index)="changeStatusIndex($event)"
        [list]="allApprovedQuestions" [isOpenDetails]="isOpenDetails"
        [isNormalQuestionComponent]="isNormalQuestionComponent" [isAddOrUpdate]="isAddOrUpdate"></app-common-grid-view>
      <div class="pagination" *ngIf="index === 0">
        <nz-pagination [nzPageIndex]="pageIndex" [nzTotal]="totalApprovedDataCount" nzShowSizeChanger
          (nzPageSizeChange)="onQueryPageSizeChangeForGridView($event)" [(nzPageSize)]="pageSize"
          (nzPageIndexChange)="onQueryParamsChangeForGridView($event)"></nz-pagination>
      </div>
    </nz-tab>
    <nz-tab nzTitle="Paragraph Questions">
      <app-common-grid-view [unApprovedList]="allParagraphQuestions" (index)="changeStatusIndex($event)"
        [list]="allApprovedParagraphQuestions" [isOpenDetails]="isOpenDetails"
        [isParagraphQuestionComponent]="isParagraphQuestionComponent" [loading]="loading"
        [isAddOrUpdate]="isAddOrUpdate"></app-common-grid-view>
      <div class="pagination">
        <nz-pagination [nzPageIndex]="paragraphPageIndex" [nzTotal]="totalApprovedParagraphDataCount" nzShowSizeChanger
          (nzPageSizeChange)="onQueryPageSizeChangeParagraphForGridView($event)" [(nzPageSize)]="paragraphPageSize"
          (nzPageIndexChange)="onQueryParamsChangeParagraphForGridView($event)"></nz-pagination>
      </div>
    </nz-tab>

  </nz-tabset>
</div>

<ng-template #popConfirmTemplate>
  <span>Are you sure you want to delete this Question?</span>
  <div *ngIf="testList.length > 0">
    <p>The following tests are associated with this Question:</p>
    <ul>
      <li *ngFor="let test of testList">{{ test.name }}</li>
    </ul>
  </div>
  <div *ngIf="testList?.length === 0">
    <p>No tests are associated with this Question.</p>
  </div>
</ng-template>