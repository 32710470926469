import { Component, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HttpRequestService } from 'src/app/core/services/http-request.service';
import { QuestionDetailsComponent } from '../question-details/question-details.component';
import { environment } from 'src/environments/environment';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { CommonArrayService } from 'src/app/core/services/common-array.service';
import { Question } from 'src/app/core/interfaces/question';
import { Topic } from 'src/app/core/interfaces/topic';
import { Chapter } from 'src/app/core/interfaces/chapter';
import { Subjects } from 'src/app/core/interfaces/subjects';
import { LocalStorageService } from 'src/app/core/services';
import { RejectionReasonComponent } from 'src/app/shared/components/rejection-reason/rejection-reason.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RejectionReasonModalComponent } from 'src/app/shared/components/rejection-reason-modal/rejection-reason-modal.component';
import { QuestionBulkApproveComponent } from '../question-bulk-approve/question-bulk-approve.component';
import { CommonService } from 'src/app/core/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { toNumber } from 'ng-zorro-antd/core/util';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent implements OnInit {
  index: number = 0;
  skipIndex: number = 0;
  skipIndexForParagraph: number = 0;
  paragraphStatusIndex: number = 0;
  isAddOrUpdate: boolean = true;
  allQuestions: Question[] = [];
  allApprovedQuestions: Question[] = [];
  allParagraphQuestions: Question[] = [];
  allApprovedParagraphQuestions: Question[] = [];
  subAdminAddedParagraphQuestions: Question[] = [];
  totalDataCount = 0;
  totalApprovedDataCount = 0;
  totalParagraphDataCount = 0;
  totalApprovedParagraphDataCount = 0;
  pageSize = 12;
  paragraphPageSize = 12;
  paragraphPageIndex = 1;
  pageIndex = 1;
  loading = false;
  searchExam: Subject<string> = new Subject<string>();
  searchSubject: Subject<string> = new Subject<string>();
  searchSubAdmin: Subject<string> = new Subject<string>();
  searchChapter: Subject<string> = new Subject<string>();
  searchTopics: Subject<string> = new Subject<string>();
  search = '';
  checkAddPermission = false;
  checkUpdatePermission = false;
  visible: boolean = false;
  mediaUploadUrl = environment.mediaBaseUrl;
  difficultyLevelObject: any = {};
  difficultyLevelForQuestion: string = '';
  dateRange: any;
  selectedChapter: string = '';
  selectedSubject: string = '';
  selectedTopic: string = '';
  selectedSubAdmin: string = '';
  allLevels: any[] = [];
  allTopics: Topic[] = [];
  allChapters: Chapter[] = [];
  allSubjects: Subjects[] = [];
  allSubAdmins: any[] = [];
  subAdminSearch: any;
  chapterSearch: any;
  subjectSearch: any;
  topicSearch: any;
  listView = true;
  isOpenDetails = true;
  active: boolean = false;
  deActive: boolean = true;
  isParagraphQuestionComponent: boolean = true;
  isNormalQuestionComponent: boolean = true;
  userRole: string = '';
  startDate: any;
  endDate: any;
  statusIndex: number = 0;
  setOfCheckedId = new Set<String>();
  checked = false;
  indeterminate = false;
  showDeleteButton: boolean = false;
  type: string = '';
  isApproved: string = '';
  questionIndex: number = 0;
  statusTabIndex: number = 0;
  testList: any[] = [];
  confirmVisible: boolean = false;
  constructor(
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private drawerService: NzDrawerService,
    private commonArrayService: CommonArrayService,
    private localStorageService: LocalStorageService,
    private modalService: NzModalService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.questionIndex = toNumber(this.activatedRoute.snapshot.queryParams.index) ;
    this.statusTabIndex = toNumber(this.activatedRoute.snapshot.queryParams.statusIndex) ;
    if(this.questionIndex, this.statusTabIndex){
      this.changeIndex( this.questionIndex, this.statusTabIndex )
      this.changeStatusIndex( this.statusTabIndex, this.questionIndex )

    }
    this.userRole = this.localStorageService.getItem('user').role;
  }

  ngOnInit(): void {
    this.allLevels = this.commonArrayService.difficultyLevel;
    this.searchExam.pipe(debounceTime(1000)).subscribe((success) => {
      this.search = success;
      this.getAllQuestions();
      this.getAllParagraphQuestions();
    });
    this.searchSubAdmin.pipe(debounceTime(1000)).subscribe((success) => {
      this.subAdminSearch = success;
      this.getAllSubAdmins();
    });
    this.searchSubject.pipe(debounceTime(1000)).subscribe((success) => {
      this.subjectSearch = success;
      this.getAllSubjects();
    });
    this.searchChapter.pipe(debounceTime(1000)).subscribe((success) => {
      this.chapterSearch = success;
      this.getAllChapters();
    });
    this.searchTopics.pipe(debounceTime(1000)).subscribe((success) => {
      this.topicSearch = success;
      this.getAllTopics();
    });

    this.difficultyLevelObject =
      this.commonArrayService.difficultyLevelObjectByValue;
  }
  changeGridView() {
    this.listView = false;
    this.active = true;
    this.deActive = false;
  }
  changeListView() {
    this.listView = true;
    this.active = false;
    this.deActive = true;
  }
  changeIndex(data: any, statusIndexData?: number ) {
    this.router.navigate([], { queryParams: {index: data, statusIndex: statusIndexData  } });
    this.index = data;
    if (this.index === 0) {
      this.statusIndex = 0;
      this.getAllQuestions(this.statusIndex);
      
    } else if(this.index === 1) {
      this.statusIndex = 0
      this.getAllParagraphQuestions(this.statusIndex);
    }
  }
  changeStatusIndex(data: any, questionIndexData?: any ) {
    questionIndexData = 0
    this.showDeleteButton = false;
    this.refreshCheckedStatus();
    this.pageIndex = 1;
    this.pageSize = 12;
    this.allApprovedQuestions = [];
    this.allQuestions = [];
    this.paragraphPageSize = 12;
    this.paragraphPageIndex = 1;
    this.allApprovedParagraphQuestions = [];
    this.allParagraphQuestions = [];
    this.statusIndex = data;
    this.router.navigate([], { queryParams: {index: questionIndexData, statusIndex: data } });
    if (this.index === 0) {
      this.getAllQuestions(this.statusIndex);
    } else if(this.index === 1) {
      this.getAllParagraphQuestions(this.statusIndex);
    }
  }
  onChangeDateRange(date: any) {
    this.startDate = date[0];
    this.endDate = date[1];

    this.getAllQuestions();
    this.getAllParagraphQuestions();
  }

  updateCheckedSet(id: string, checked: boolean, type: any, approvedOrNot: any): void {
    if (checked) {
      this.setOfCheckedId.add(id);
      this.showDeleteButton = true;
      this.type = type;
      this.isApproved = approvedOrNot
    } else {
      this.setOfCheckedId.delete(id);
      this.setOfCheckedId.size > 0
        ? (this.showDeleteButton = true )
        : (this.showDeleteButton = false, this.isApproved = '');
      this.type = type;
    }
  }
  refreshCheckedStatus(): void {
    if (this.index === 0) {
      if (this.statusIndex === 0) {
        this.checked = this.allApprovedQuestions.every(({ _id }) =>
          this.setOfCheckedId.has(_id)
        );
        this.indeterminate =
          this.allApprovedQuestions.some(({ _id }) =>
            this.setOfCheckedId.has(_id)
          ) && !this.checked;
      } else {
        this.checked = this.allQuestions.every(({ _id }) =>
          this.setOfCheckedId.has(_id)
        );
        this.indeterminate =
          this.allQuestions.some(({ _id }) => this.setOfCheckedId.has(_id)) &&
          !this.checked;
      }
    } else {
      if (this.statusIndex === 0) {
        this.checked = this.allApprovedParagraphQuestions.every(({ _id }) =>
          this.setOfCheckedId.has(_id)
        );
        this.indeterminate =
          this.allApprovedParagraphQuestions.some(({ _id }) =>
            this.setOfCheckedId.has(_id)
          ) && !this.checked;
      } else {
        this.checked = this.allParagraphQuestions.every(({ _id }) =>
          this.setOfCheckedId.has(_id)
        );
        this.indeterminate =
          this.allParagraphQuestions.some(({ _id }) =>
            this.setOfCheckedId.has(_id)
          ) && !this.checked;
      }
    }
  }

  onItemChecked(id: string, checked: boolean, type: any, approvedOrNot: any): void {
    if(type === 'normal-question'){
      if(approvedOrNot === 'approved'){
          this.updateCheckedSet(id, checked, type, 'approved')
      }else{
        this.updateCheckedSet(id, checked, type, 'unApproved')
    }
    this.refreshCheckedStatus();

  }else{
    if(type === 'paragraph-question'){
      if(approvedOrNot === 'approved'){
          this.updateCheckedSet(id, checked, type, 'approved')
      }else{
        this.updateCheckedSet(id, checked, type, 'unApproved')
    }
  }
  this.refreshCheckedStatus();

}
  }

  onAllChecked(checked: boolean): void {
    if (this.index === 0) {
      if (this.statusIndex === 0) {
        this.allApprovedQuestions.forEach(({ _id }) =>
          this.updateCheckedSet(_id, checked, 'normal-question', 'approved')
        );
      } else {
        this.allQuestions.forEach(({ _id }) =>
          this.updateCheckedSet(_id, checked, 'normal-question', 'unApproved')
        );
      }
    } else {
      if (this.statusIndex === 0) {
        this.allApprovedParagraphQuestions.forEach(({ _id }) =>
          this.updateCheckedSet(_id, checked, 'paragraph-question', 'approved')
        );
      } else {
        this.allParagraphQuestions.forEach(({ _id }) =>
          this.updateCheckedSet(_id, checked, 'paragraph-question', 'unApproved')
        );
      }
    }
    this.refreshCheckedStatus();
  }
  bulkDelete() {
    let requestData;
    let finalData: any[] = [];
    if (this.index === 0) {
      if (this.statusIndex === 0) {
        requestData = this.allApprovedQuestions.filter((data) =>
          this.setOfCheckedId.has(data._id)
        );
        requestData.forEach((data: any) => {
          finalData.push(data._id);
        });
      } else {
        requestData = this.allQuestions.filter((data) =>
          this.setOfCheckedId.has(data._id)
        );
        requestData.forEach((data: any) => {
          finalData.push(data._id);
        });
      }
    } else {
      if (this.statusIndex === 0) {
        requestData = this.allApprovedParagraphQuestions.filter((data) =>
          this.setOfCheckedId.has(data._id)
        );
        requestData.forEach((data: any) => {
          finalData.push(data._id);
        });
      } else {
        requestData = this.allParagraphQuestions.filter((data) =>
          this.setOfCheckedId.has(data._id)
        );
        requestData.forEach((data: any) => {
          finalData.push(data._id);
        });
      }
    }
    if (requestData.length > 0) {
      // this.showDeleteButton = true;
      this.bulkDeleteQuestions(
        finalData,
        'post',
        'questions/bulk-delete',
        'Questions Deleted Successfully'
      );
    }
  }
  bulkDeleteQuestions(
    data: any,
    requestMethod: string,
    requestURL: string,
    message: string
  ) {
    this.httpRequestService
      .request(requestMethod, requestURL, data)
      .subscribe((result: any) => {
        this.notificationService.success('success', message);
        if (this.index === 0) {
          this.getAllQuestions();
          this.type = 'normal-question';
          this.setOfCheckedId.clear();
          this.refreshCheckedStatus();
        } else {
          this.getAllParagraphQuestions();
          this.type = 'paragraph-question';
          this.setOfCheckedId.clear();
          this.refreshCheckedStatus();
        }
      });
  }
  /* Get all questions */
  getAllQuestions(statusIndex?: number, skip = 0, sortBy?: any): void {
    // this.statusIndex = statusIndex;
    let params: any;
    statusIndex = statusIndex ? statusIndex : this.statusIndex
    params = {
      skip: this.skipIndex,
      limit: this.pageSize,
      questionType: 'NORMAL',
    };
    if (this.selectedSubject) {
      params.subject = this.selectedSubject;
    } else {
      delete params.subject;
    }
    if (this.selectedChapter) {
      params.chapter = this.selectedChapter;
    } else {
      delete params.chapter;
    }
    if (this.selectedTopic) {
      params.topic = this.selectedTopic;
    } else {
      delete params.topic;
    }
    if (this.selectedSubAdmin) {
      params.adminId = this.selectedSubAdmin;
    } else {
      delete params.adminId;
    }
    if (
      this.difficultyLevelForQuestion !== '' &&
      this.difficultyLevelForQuestion !== null
    ) {
      params.difficultyLevel = this.difficultyLevelForQuestion;
    } else {
      delete params.difficultyLevel;
    }
    if (this.search) {
      params.search = this.search;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if (this.startDate && this.endDate) {
      params.startDate = this.startDate;
      params.endDate = this.endDate;
    } else {
      delete params.startDate;
      delete params.endDate;
    }
    if (statusIndex === 0) {
      params.approveStatus = 'approved';
    } else if (statusIndex === 1 && this.userRole === 'admin') {
      params.approveStatus = 'pending';
    } else if (statusIndex === 1 && this.userRole === 'subAdmin') {
      params.approveStatus = ['pending', 'rejected'];
    } else {
      delete params.approvedStatus;
    }
    this.loading = true;
    this.httpRequestService.request('get', 'questions', params).subscribe(
      (result) => {
        this.loading = false;
        const data: Question[] = result.data;
        this.allQuestions = [];
        if (statusIndex === 0) {
          this.allApprovedQuestions = data;
          this.totalApprovedDataCount = result.totalCount;
        } else {
          this.allQuestions = data;
          this.totalDataCount = result.totalCount;
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }
  /* Get all questions */
  getAllParagraphQuestions(
    statusIndex: number = 0,
    skip = 0,
    sortBy?: any
  ): void {
    statusIndex = statusIndex? statusIndex : this.statusIndex
    let params: any;
    params = {
      skip: this.skipIndex,
      limit: this.paragraphPageSize,
      questionType: 'PARAGRAPH',
    };
    if (this.selectedSubject) {
      params.subject = this.selectedSubject;
    } else {
      delete params.subject;
    }
    if (this.selectedChapter) {
      params.chapter = this.selectedChapter;
    } else {
      delete params.chapter;
    }
    if (this.selectedTopic) {
      params.topic = this.selectedTopic;
    } else {
      delete params.topic;
    }
    if (this.selectedSubAdmin) {
      params.adminId = this.selectedSubAdmin;
    } else {
      delete params.adminId;
    }

    if (
      this.difficultyLevelForQuestion !== '' &&
      this.difficultyLevelForQuestion !== null
    ) {
      params.difficultyLevel = this.difficultyLevelForQuestion;
    } else {
      delete params.difficultyLevel;
    }
    if (this.search) {
      params.search = this.search;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if (this.startDate && this.endDate) {
      params.startDate = this.startDate;
      params.endDate = this.endDate;
    } else {
      delete params.date;
    }
    if (statusIndex === 0) {
      params.approveStatus = 'approved';
    } else if (statusIndex === 1 && this.userRole === 'admin') {
      params.approveStatus = 'pending';
    } else if (statusIndex === 1 && this.userRole === 'subAdmin') {
      params.approveStatus = ['pending', 'rejected'];
    } else {
      delete params.approvedStatus;
    }
    this.loading = true;
    this.httpRequestService.request('get', 'questions', params).subscribe(
      (result) => {
        this.loading = false;
        const data: Question[] = result.data;
        this.allParagraphQuestions = [];
        if (statusIndex === 0) {
          this.allApprovedParagraphQuestions = data;
          this.totalApprovedParagraphDataCount = result.totalCount;
        } else {
          this.allParagraphQuestions = data;
          this.totalParagraphDataCount = result.totalCount;
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }
  getAllSubAdmins(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      role: 'subAdmin',
    };
    if (this.selectedSubAdmin) {
      params.search = this.selectedSubAdmin;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'auth-admins', params).subscribe(
      (result: any) => {
        this.allSubAdmins = result.data;
      },
      (err: any) => {}
    );
  }

  /* For Pagination / Sending skip */
  onQueryParamsChange(params: NzTableQueryParams): void {
    this.allApprovedQuestions = [];
    this.allQuestions = [];
    const { pageSize, pageIndex } = params;
    this.skipIndex = pageSize * (pageIndex - 1);
    this.getAllQuestions(this.statusIndex, pageSize * (pageIndex - 1));
  }
  /* For Pagination / Sending skip */
  onQueryParamsChangeParagraph(params: NzTableQueryParams): void {
    this.allApprovedParagraphQuestions = [];
    this.allParagraphQuestions = [];
    const { pageSize, pageIndex } = params;
    this.skipIndexForParagraph = pageSize * (pageIndex - 1);
    this.getAllParagraphQuestions(this.statusIndex, pageSize * (pageIndex - 1));
  }

  /* Get all Chapters */
  getAllChapters(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved',
    };
    if (this.selectedSubject !== '' && this.selectedSubject !== null) {
      params.subject = this.selectedSubject;
    } else {
      delete params.subject;
    }
    /* if (this.addUpdateTopicForm.value.subject) {
      params['subject'] = this.addUpdateTopicForm.value.subject;
    } else {
      delete params.subject;
    } */
    if (this.chapterSearch) {
      params.search = this.chapterSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'chapters', params).subscribe(
      (result: any) => {
        this.allChapters = result.data;
      },
      (err: any) => {}
    );
  }
  /* Get all concepts */
  getAllTopics(skip = 0, sortBy?: any): void {
    let params: any;
    params = { skip, limit: this.pageSize, approveStatus: 'approved' };
    if (this.topicSearch) {
      params.search = this.topicSearch;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if (this.selectedSubject !== '' && this.selectedSubject !== null) {
      params.subject = this.selectedSubject;
    } else {
      delete params.subject;
    }
    if (this.selectedChapter !== '' && this.selectedChapter !== null) {
      params.chapter = this.selectedChapter;
    } else {
      delete params.chapter;
    }
    this.loading = true;
    this.httpRequestService.request('get', 'topics', params).subscribe(
      (result) => {
        this.loading = false;
        this.allTopics = result.data;
        this.totalDataCount = result.totalCount;
      },
      (err) => {
        this.loading = false;
      }
    );
  }
  /* Get all Subjects */
  getAllSubjects(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved',
    };
    if (this.subjectSearch) {
      params.search = this.subjectSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'subjects', params).subscribe(
      (result: any) => {
        this.allSubjects = result.data;
      },
      (err: any) => {}
    );
  }

  /* For Pagination / Sending skip for grid view*/
  onQueryParamsChangeForGridView(index: any): void {
    this.allApprovedQuestions = [];
    this.allQuestions = [];
    this.pageIndex = index;
    this.getAllQuestions(
      this.statusIndex,
      this.pageSize * (this.pageIndex - 1)
    );
  }
  onQueryPageSizeChangeForGridView(size: any) {
    this.allApprovedQuestions = [];
    this.allQuestions = [];
    this.pageSize = size;
    this.getAllQuestions(
      this.statusIndex,
      this.pageSize * (this.pageIndex - 1)
    );
  } /* For Pagination / Sending skip for grid view*/
  onQueryParamsChangeParagraphForGridView(index: any): void {
    this.allApprovedParagraphQuestions = [];
    this.allParagraphQuestions = [];
    this.paragraphPageIndex = index;

    this.getAllParagraphQuestions(
      this.statusIndex,
      this.paragraphPageSize * (this.paragraphPageIndex - 1)
    );
  }
  onQueryPageSizeChangeParagraphForGridView(size: any) {
    this.allApprovedParagraphQuestions = [];
    this.allParagraphQuestions = [];
    this.pageSize = size;
    this.getAllParagraphQuestions(
      this.statusIndex,
      this.pageSize * (this.pageIndex - 1)
    );
  }
  /* status change*/
  updateStatus(id: string, status: boolean): void {
    this.httpRequestService
      .request('put', `questions/active-inactive/${id}`, { status: !status })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        if (this.index === 0) {
          this.getAllQuestions(this.statusIndex);
        } else {
          this.getAllParagraphQuestions(this.statusIndex);
        }
      });
  }

  // subject Dropdown changes
  onChangeSubject(): void {
    if (this.index === 0) {
      this.getAllQuestions(this.statusIndex);
    } else {
      this.getAllParagraphQuestions(this.statusIndex);
    }
    if (this.selectedSubject) {
      this.allChapters = [];
      this.allTopics = [];
      this.selectedChapter = '';
      this.selectedTopic = '';
      this.selectedChapter = '';
      if (this.selectedSubject) {
        this.getAllChapters();
        this.getAllTopics();
      }
    }
  }
  // SubAdmin Dropdown changes
  onChangeSubAdmin(): void {
    if (this.index === 0) {
      this.getAllQuestions(this.statusIndex);
    } else {
      this.getAllParagraphQuestions(this.statusIndex);
    }
  }

  /* On change chapter get course */
  onChangeChapter(): void {
    if (this.index === 0) {
      this.getAllQuestions(this.statusIndex);
    } else {
      this.getAllParagraphQuestions(this.statusIndex);
    }
    if (this.selectedChapter) {
      this.allTopics = [];
      this.selectedTopic = ''; //resetting the topic dropdown value to empty string.
      if (this.selectedChapter) {
        this.getAllTopics();
      }
    }
  }

  onChangeTopics(): void {
    if (this.index === 0) {
      this.getAllQuestions(this.statusIndex);
    } else {
      this.getAllParagraphQuestions(this.statusIndex);
    }
  }

  onChangeDifficultyLevel() {
    if (this.index === 0) {
      this.getAllQuestions(this.statusIndex);
    } else {
      this.getAllParagraphQuestions(this.paragraphPageIndex);
    }
  }
  updatePendingStatus(id: string, pendingStatus: string) {
    this.httpRequestService
      .request('put', `questions/approved-rejected/${id}`, {
        approveStatus: 'approved',
        status: true,
      })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        if (this.index === 0) {
          this.getAllQuestions(this.statusIndex);
        } else {
          this.getAllParagraphQuestions(this.statusIndex);
        }
      });
  }
  openRejectionReason(id: string, pendingStatus: string) {
    const pendingStatusData = 'rejected';
    const rejectionReason = this.modalService.create({
      nzTitle: 'Add A Reason',
      nzContent: RejectionReasonComponent,
      nzWidth: '800px',
      nzFooter: null,
      nzComponentParams: {
        updateId: id,
        component: 'questions',
      },
      nzCancelText: null,
      nzOkText: null,
    });

    const questionService = rejectionReason.afterClose.subscribe(
      (data: any) => {
        if (this.index === 0) {
          this.getAllQuestions(this.statusIndex);
        } else {
          this.getAllParagraphQuestions(this.statusIndex);
        }
      }
    );
  }
  // search question
  searchQuestions(event: any): void {
    this.searchExam.next(event);
  }
  // earch topic
  searchTopicForDropdown(event: any): void {
    this.searchTopics.next(event);
  }
  // seach chapter
  searchChapterForDropdown(event: any): void {
    this.searchChapter.next(event);
  }
  // search subject
  searchSubjectForDropdown(event: any): void {
    this.searchSubject.next(event);
  }
  // search subject
  searchSubAdminForDropdown(event: any): void {
    this.searchSubAdmin.next(event);
  }

  openQuestionDetails(item: any) {
    const drawerRef = this.drawerService.create({
      nzTitle: 'Question Details',
      nzFooter: '',
      nzWidth: '60%',
      nzContent: QuestionDetailsComponent,
      nzContentParams: {
        value: item,
        component: 'isFromQuestion',
      },
    });

    this.commonService.$addQuestion.subscribe((data: any) => {
      if (data) {
        this.getAllQuestions(this.statusIndex);
      }
    });
    this.commonService.$addParagraphQuestion.subscribe((data: any) => {
      if (data) {
        this.getAllParagraphQuestions(this.statusIndex);
      }
    });
  }
  openBulkApprove(type: string) {
    const drawerRef = this.drawerService.create({
      nzTitle: 'Bulk Approve Preview',
      nzFooter: '',
      nzWidth: '80%',
      nzContent: QuestionBulkApproveComponent,
      nzContentParams: {
        type: type,
      },
    });

    drawerRef.afterClose.subscribe((data: any) => {
      if (this.index === 0) {
        this.getAllQuestions(this.statusIndex);
      } else {
        this.getAllParagraphQuestions(this.statusIndex);
      }
    });
  }
  rejectionReasonModal(data: any) {
    this.modalService.create({
      nzTitle: 'Reason Info',
      nzContent: RejectionReasonModalComponent,
      nzWidth: '800px',
      nzFooter: null,
      nzComponentParams: {
        rejectionReason: data,
      },
      nzCancelText: null,
      nzOkText: null,
    });
  }
  deleteQuestions(id: any) {
    this.httpRequestService
      .request('delete', `questions/${id}`)
      .subscribe((result: any) => {
        this.notificationService.success('', 'Question Deleted Successfully');
        if (this.index === 0) {
          this.getAllQuestions(this.statusIndex);
        } else {
          this.getAllParagraphQuestions(this.statusIndex);
        }
      });
  }


  onVisibleChange(visible: any, questionId: any): void {
    if (visible) {
      this.getTest(questionId);
    }
    this.confirmVisible = visible;
  }
  getTest(id: any){
    const params = {
      questionId:  id
    }
    this.httpRequestService
      .request('get', `tests/get-tests-for-questions`, params)
      .subscribe((result: any) => {
        this.testList = result.data
        return;
      });
  }
}
