<form nz-form [formGroup]="addUpdateBulkQuestionForm">
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <ng-container>
            <nz-collapse>
                <nz-collapse-panel *ngFor="let item of questionsFormArray.controls; index as j"
                    [nzHeader]="'Question' + (j + 1)" [nzExtra]="remove" [nzActive]="active">
                    <!-- <div *ngIf="j > 0" class="deletesect" nz-popconfirm
                            nzPopconfirmTitle="Are you sure delete this Questions?" (click)="$event.stopPropagation()"
                            (nzOnConfirm)="removeQuestion(j)" class="right-aline">
                            <i nz-icon nzType="delete" nzTheme="outline"></i>
                        </div> -->
                    <nz-row nzGutter="16">

                        <app-single-question [isFromOtherComponent]="isFromOtherComponent" [addUpdateSingleQuestionForm]="questionsFormArray.controls[j]" [idForUpdate]="idForUpdate">

                        </app-single-question>
                    </nz-row>
                    <ng-template #remove>
                        <button nz-button nzType="primary" *ngIf="addUpdateBulkQuestionForm.value.questions.length > 1"
                            nz-popconfirm nzPopconfirmTitle="Are you sure delete this questions?"
                            (click)="$event.stopPropagation()" (nzOnConfirm)="removeQuestion(j)" class="right-aline">
                            <span nz-icon nzType="delete" nzTheme="outline"></span>
                        </button>
                    </ng-template>
                    <!-- <app-add-update-question [isFromOtherComponent]="true" [otherForm]="questionsFormArray.controls[j]"></app-add-update-question>-->
                </nz-collapse-panel>
            </nz-collapse>

        </ng-container>
        <div class=" mt3 width-100"  nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" *ngIf="!idForUpdate">
            <button nz-button nzType="dashed" nzDanger nzBlock (click)="addQuestion()">
                ADD MORE QUESTIONS<i nz-icon nzType="plus-circle" nzTheme="outline"></i>
            </button>
        </div>
        <div  class="width-100">
            <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="text-right mt4">
                <button nz-button nzType="primary" [nzLoading]="Loading" [disabled]="Loading" (click)="submit()">
                    {{ idForUpdate ? "Update" : "Submit" }}
                </button>
            </div>
        </div>
    </div>
</form>
