import { Component, Input, OnInit } from '@angular/core';
import { HttpRequestService } from 'src/app/core/services';

@Component({
  selector: 'app-activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.scss'],
})
export class ActivityDetailsComponent implements OnInit {
  @Input() id = '';
  activityData: any[] = [];

  activityPageIndex: number = 1;
  isActivityLimit: boolean = false;
  adminId: string = '';
  pageLimit = 10;
  skipIndex: number = 0;
  activityTotalDataCount = 0;
  loading = false;
  constructor(private httpRequestService: HttpRequestService) {}

  ngOnInit(): void {
    this.adminId = this.id;
    this.openActivity();
    
  }
  // getColorForMessage(message: string): any {
  //   return message.toLowerCase().includes('added') ? 'green' : message.toLowerCase().includes('updated') ? 'gray' :message.toLowerCase().includes('deleted') ?'red' : message.toLowerCase().includes('Logged In') ? 'green' : message.toLowerCase().includes('Logged Out') ?'red' : message.toLowerCase().includes('approved')? 'green' : message.toLowerCase().includes('rejected')? 'red' : message.toLowerCase().includes('activated')? 'green' : message.toLowerCase().includes('deactivated')? 'red' : 'grey';
  // }

  getColorForMessage(
    message: string
  ): 'red' | 'green' | 'blue' | 'grey' | 'gray' {
    const lowerCaseMessage = message.toLowerCase();

    switch (true) {
      case lowerCaseMessage.includes('added'):
      case lowerCaseMessage.includes('logged in'):
      case lowerCaseMessage.includes('approved'):
      case lowerCaseMessage.includes('activated'):
        return 'green';
      case lowerCaseMessage.includes('updated'):
        return 'gray';
      case lowerCaseMessage.includes('deleted'):
      case lowerCaseMessage.includes('logged out'):
      case lowerCaseMessage.includes('rejected'):
      case lowerCaseMessage.includes('deactivated'):
        return 'red';
      default:
        return 'gray';
    }
  }
  // /* openActivity*/
  openActivity(skip = 0): void {
    this.loading = true
    let params: any;
    params = { skip, limit: this.pageLimit, adminId: this.adminId };
    this.httpRequestService
      .request('get', 'admin-activities', params)
      .subscribe((result: any) => {
        this.loading = false;
        const allData = result.data;
        
        this.activityData.push(...allData);
        this.activityTotalDataCount = result.totalCount;
        if (this.activityData.length === this.activityTotalDataCount) {
          this.isActivityLimit = true;
        }
      });
  }

  /* For Pagination / Sending skip */
  paginationForActivity(): void {
    this.activityPageIndex = this.activityPageIndex + 1;
    this.openActivity(this.pageLimit * (this.activityPageIndex - 1));
  }
}
