import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer, Subject, async } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Chapter } from 'src/app/core/interfaces/chapter';
import { LocalArray } from 'src/app/core/interfaces/local-array';
import { Question } from 'src/app/core/interfaces/question';
import { Subjects } from 'src/app/core/interfaces/subjects';
import { Topic } from 'src/app/core/interfaces/topic';
import { HttpRequestService } from 'src/app/core/services';
import { CommonArrayService } from 'src/app/core/services/common-array.service';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bulk-question',
  templateUrl: './bulk-question.component.html',
  styleUrls: ['./bulk-question.component.scss'],
})
export class BulkQuestionComponent implements OnInit {
  @Input() questionType = '';
  @Input() subject: any;
  @Input() idForUpdate: string;
  @Input() chapter: string | null = null;
  @Input() topic: string | null = null;
  @Input() paragraph: string | null = null;
  @Input() isFromOtherComponent: boolean = false;
  addUpdateBulkQuestionForm: FormGroup;
  current = 0;
  index = 'First-content';
  Loading: boolean = false;

  loadingButton = false;
  buttonLoading = false;
  active: boolean = true;
  paragraphId: string = '';
  activate: boolean = true;
  activateTopic: boolean = true;
  name: string = '';
  mediaBaseUrl = environment.mediaBaseUrl;
  mediaUploadUrl = environment.apiBaseUrl + '/api/media';
  search = '';
  searchChapter: Subject<string> = new Subject<string>();
  searchSubject: Subject<string> = new Subject<string>();
  searchTopic: Subject<string> = new Subject<string>();
  allLevels: LocalArray[] = [];
  allSubjects: Subjects[] = [];
  allChapters: Chapter[] = [];
  allTopics: Topic[] = [];
  subjectSearch = '';
  chapterSearch = '';
  topicSearch = '';
  statusIndex: number = 0;
  tabIndex: number = 0;
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private msg: NzMessageService,
    private router: Router,
    private commonArrayService: CommonArrayService,
    private commonService: CommonService
  ) {
    this.tabIndex = this.activatedRoute.snapshot.queryParams.index;
    this.statusIndex = this.activatedRoute.snapshot.queryParams.statusIndex;
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.addUpdateBulkQuestionForm = this.fb.group({
      questions: this.fb.array([]),
    });
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.questionType || (this.subject && this.chapter && this.topic)) {
      this.addQuestion();
    }
  }

  // Question Form Array
  createQuestion(item?: any): FormGroup {
    if (item) {
      return this.fb.group({
        serialNo: [item.serialNo],
        questionText: [item.questionText],
        solution: [item.solution],
        options: [item.options],
        subject: [item.subject, [Validators.required]],
        chapter: [item.chapter],
        topic: [item.topic],
        difficultyLevel: [item.difficultyLevel],
      });
    }
    return this.fb.group({
      serialNo: [null],
      questionText: ['', [Validators.required]],
      solution: [null],
      questionType: [this.questionType],
      options: this.fb.array([]),
      subject: [this.subject, [Validators.required]],
      chapter: [this.chapter],
      topic: [this.topic],
      paragraph: [this.paragraph],
      difficultyLevel: ['BEGINNER', [Validators.required]],
      buttonLoading: [false],
    });
  }

  // for tutor form array lodingButton
  get questionsFormArray(): FormArray<FormGroup> {
    return this.addUpdateBulkQuestionForm.get('questions') as FormArray;
  }

  // Add question
  addQuestion(): void {
    this.questionsFormArray.push(this.createQuestion());
  }

  // Remove question
  removeQuestion(index: number): void {
    this.questionsFormArray.removeAt(index);
  }

  submit() {
    let count = 0;
    let isCorrect = 0;

    this.addUpdateBulkQuestionForm.value.questions.map((question: any) => {
      //itarate option
      if (question.options.length > 0) {
        // question.options.forEach((option: any) => {
        //   if ( option.optionText && option.optionText.length > 0 ) {
        //     count += 1;
        //   }
        //   if (option.isCorrectOption) {
        //     isCorrect += 1;
        //   }
        // });

        count = question.options.every((item: any) => {
          return item && item.optionText;
        });
        isCorrect = question.options.some((item: any) => {
          return item && item.isCorrectOption;
        });
      }
    });
    if (!count) {
      this.notificationService.error('', 'Please filled all options!');
      return;
    }
    if (!isCorrect) {
      this.notificationService.error('', 'Please select one correct option!');
      return;
    }
    if (!this.addUpdateBulkQuestionForm.valid) {
      this.markFormGroupTouched(this.addUpdateBulkQuestionForm);
    } else {
      if (this.idForUpdate) {
        this.addOrUpdateQuestion(
          'put',
          `questions/${this.idForUpdate}`,
          'Question Successfully Updated'
        );
      } else {
        this.addOrUpdateQuestion(
          'post',
          'questions/insert-many',
          'Questions Added Successfully '
        );
      }
    }
  }
  /* Add Or Edit paragraph form data */
  addOrUpdateQuestion(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    this.Loading = true;

    if (
      this.addUpdateBulkQuestionForm &&
      this.addUpdateBulkQuestionForm.value &&
      this.addUpdateBulkQuestionForm.value.questions &&
      this.addUpdateBulkQuestionForm.value.questions.length
    ) {
      let finalData: Question;
      if (this.idForUpdate) {
        finalData = this.addUpdateBulkQuestionForm.value.questions[0];
      } else {
        finalData = this.addUpdateBulkQuestionForm.value;
      }

      this.httpRequestService
        .request(requestMethod, requestURL, finalData)
        .subscribe(
          (result: any) => {
            this.notificationService.success('', successMessage);

            if (this.isFromOtherComponent) {
              let questionTypeTemp = 'NORMAL';
              if (this.idForUpdate) {
                questionTypeTemp = result.data.questionType;
                if (questionTypeTemp === 'PARAGRAPH') {
                  this.commonService.$addParagraphQuestion.next(result.data);
                }
                if (questionTypeTemp === 'NORMAL') {
                  this.commonService.$addQuestion.next(result.data);
                }
              } else {
                questionTypeTemp = result.data[0].questionType;
                if (questionTypeTemp === 'PARAGRAPH') {
                  this.commonService.$addParagraphQuestion.next([
                    ...result.data,
                  ]);
                }
                if (questionTypeTemp === 'NORMAL') {
                  this.commonService.$addQuestion.next([...result.data]);
                }
              }
              // setTimeout(()=>{this.Loading = false},2000)
              // this.Loading = false;
            } else {
              this.router.navigate(['/main/question'], { queryParams: {index: this.tabIndex, statusIndex: this.statusIndex } });
              this.Loading = false;
            }
          },
          (error: any) => {
            this.Loading = false;
            if (error.error.errors) {
              const allErrors: string[] = Object.values(error.error.errors);
              for (const err of allErrors) {
                this.notificationService.error('', err);
              }
            } else {
              this.notificationService.error('', error.error.message);
            }
          }
        );
    } else {
      // show error
      this.Loading = false;
    }
  }
  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
        // nested formgroup
        if (formGroup.controls[i] instanceof FormGroup) {
          this.markFormGroupTouched(formGroup.controls[i] as FormGroup);
        }

        // nested form array
        if (formGroup.controls[i] instanceof FormArray) {
          const formArray = formGroup.controls[i] as FormArray;
          for (const j in formArray.controls) {
            if (formArray.controls.hasOwnProperty(j)) {
              formArray.controls[j].markAsDirty();
              formArray.controls[j].updateValueAndValidity();

              if (formArray.controls[j] instanceof FormGroup) {
                this.markFormGroupTouched(formArray.controls[j] as FormGroup);
              }
            }
          }
        }
      }
    }
  }
  /* Make All Form Controls Dirty */
  // private markFormGroupTouched(formGroup: FormGroup): void {
  //   for (const i in formGroup.controls) {
  //     if (formGroup.controls.hasOwnProperty(i)) {
  //       formGroup.controls[i].markAsDirty();
  //       formGroup.controls[i].updateValueAndValidity();
  //     }
  //   }
  // }
}
