import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor() {}
  public exportAsExcelFile(data: any[], excelFileName: string, isBlank: boolean = false): void {
    const workbook = XLSX.utils.book_new();
  
    // Create a new empty worksheet
    // const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([]);
    const worksheet: XLSX.WorkSheet = {} as XLSX.WorkSheet
  
    // Define the static header row
    const header = [
      'serialNo',
      'questionText',
      'questionType',
      'subject',
      'chapter',
      'topic',
      'difficultyLevel',
      'paragraph',
      'option1',
      'option2',
      'option3',
      'option4',
      'answer',
    ];
  
    // Add the static header row to the worksheet
    XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: 'A1' });
  
    // Add the static data rows to the worksheet
  
    // Calculate the index where dynamic data should start
    const dynamicDataStartIndex = 3;
  
    // Append the dynamic data rows to the worksheet
    if(!isBlank){

      data.forEach((row, index) => {
        const rowNumber = dynamicDataStartIndex + index;
        const rowData = Object.values(row);
        XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: 'A' + rowNumber });
      });
    }
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    // Generate Excel file from the workbook
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
  
    // Convert buffer to a Blob object
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  
    const fileName = excelFileName || 'Demo-Question-Format.xlsx';
  
    FileSaver.saveAs(blob, fileName);
  }
  
  

}