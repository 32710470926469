import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SuccessStoriesComponent } from './success-stories/success-stories.component';
import { AddUpdateStoriesComponent } from './add-update-stories/add-update-stories.component';

const routes: Routes = [
  {
    path: '',
    component: SuccessStoriesComponent
  },
  {
    path: 'add',
    component: AddUpdateStoriesComponent
  },
  {
    path: 'update/:id',
    component: AddUpdateStoriesComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuccessStoriesRoutingModule { }
