import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AuthService,
  HttpRequestService,
  LocalStorageService,
  MenuService,
} from 'src/app/core/services';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { NotificationDrawerComponent } from '../../../shared/notification-drawer/notification-drawer.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Subject } from 'rxjs';

import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isCollapsed = false;
  notificationSelectedClass = false;
  profileSelectedClass = false;
  selectedCommunity = '';
  showCommunity = false;
  user: any = {};
  menuData: any[] = [];
  currentRoute = '';
  menuChildren: Subject<any[]> = new Subject<any[]>();
  userInfo = [
    {
      title: 'My profile',
    },
    {
      title: 'Settings',
    },
    {
      title: 'Logout',
    },
  ];
  @Output() collapsed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private authService: AuthService,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private localStorageService: LocalStorageService,
    private drawerService: NzDrawerService,
    private menuService: MenuService,
    private router: Router
  ) {
    this.menuService.menu.subscribe((menudata) => {
      this.menuData = menudata;
      //;
      this.menuData.forEach((menu) => {
        menu.isOpen = this.currentRoute.includes(menu.route);
      });
    });
  }

  ngOnInit(): void {
    this.user = this.localStorageService.getItem('user');
    this.currentRoute = this.router.url;
    this.authService.authState.subscribe((status) => {});
    const userRole = this.localStorageService.getItem('user').role;
    this.menuService.generateMenu(userRole);
  }

  onChangeCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.collapsed.emit(this.isCollapsed);
  }

  logout(): void {
    let localStorageDraftData: any =
      this.localStorageService.getQuestionDataFromLOcalStorage(
        'dataOfQuestionSelected'
      );

    if (Object.keys(localStorageDraftData)?.length !== 0) {
      this.httpRequestService
        .request('put', 'drafts', localStorageDraftData)
        .subscribe(
          (res: any) => {
          },
          (error: any) => {
           ;
          }
        );
    }
    this.httpRequestService
      .request('delete', 'auth-admins/logout')
      .subscribe((success) => {
        this.notificationService.success(
          'Success',
          'You Successfully Logged out'
        );
      });
    this.authService.logout();
  }

  openNotificationComponent(): void {
    const drawerRef = this.drawerService.create<
      NotificationDrawerComponent,
      { value: null },
      string
    >({
      nzTitle: 'Notifications',
      nzContent: NotificationDrawerComponent,
      nzWidth: 350,
      nzContentParams: {
        value: null,
      },
    });

    drawerRef.afterOpen.subscribe(() => {});

    drawerRef.afterClose.subscribe((data) => {});
  }
}
