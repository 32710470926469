import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardConfigComponent } from './dashboard-config/dashboard-config.component';
import { AddUpdateDashboardConfigComponent } from './add-update-dashboard-config/add-update-dashboard-config.component';

const routes: Routes = [
  {
  path:'',
  component:AddUpdateDashboardConfigComponent
},
{
  path: 'add',
  component: AddUpdateDashboardConfigComponent
},
{
  path: 'update/:id',
  component: AddUpdateDashboardConfigComponent
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardConfigRoutingModule { }
