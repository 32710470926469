<form nz-form [formGroup]="addBulkQuestionForm">
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
        <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzFor="subject" nzRequired>Subject</nz-form-label>
                <nz-form-control [nzSpan]="24" nzErrorTip="Please select your subject!">
                    <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" formControlName="subject"
                        nzPlaceHolder="Select a option" (nzOnSearch)="searchSubjectForDropdown($event)"
                        (ngModelChange)="onChangeSubject($event)">
                        <nz-option *ngFor="let item of allSubjects" nzValue="{{ item._id }}"
                            nzLabel="{{ item.subjectName }}"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzFor="subject" >Chapter</nz-form-label>
                <nz-form-control [nzSpan]="24" nzErrorTip="Please select your chapter!">
                    <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" formControlName="chapter"
                        nzPlaceHolder="Select a option" (nzOnSearch)="searchChapterForDropdown($event)"
                        (ngModelChange)="onChangeChapter($event)">
                        <nz-option *ngFor="let item of allChapters" nzValue="{{ item._id }}"
                            nzLabel="{{ item.chapterName }}"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24">
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzFor="subject" >Topic</nz-form-label>
                <nz-form-control [nzSpan]="24" nzErrorTip="Please select your topic!">
                    <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" formControlName="topic"
                        nzPlaceHolder="Select a option" (nzOnSearch)="searchTopicForDropdown($event)">
                        <nz-option *ngFor="let item of allTopics" nzValue="{{ item._id }}"
                            nzLabel="{{ item.topicName }}"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
          Upload Excel :  <nz-upload [(nzFileList)]="fileItem" [nzBeforeUpload]="beforeRelatedMediaUpload">
            <button nz-button *ngIf="fileItem.length === 0 " nzType="primary">
                  <span nz-icon nzType="upload"></span>
                  Select File
              </button>
          </nz-upload>
      </div>
        <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
          Download Sample:  <button nz-button nzType="link" (click)="downloadExcel()">
            <i class="upload-icon" nz-icon [nzType]="'download'"></i>
            Download
        </button>
        </div>

    </div>
    <div nz-row nzGutter="16" nzJustify="end">
        <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="right-aline" >
            <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()">
                Submit
            </button>
        </div>
    </div>

</form>
