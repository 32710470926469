<div class="site-page-header-ghost-wrapper">
  <nz-page-header [nzGhost]="false">
    <nz-page-header-title class="ml4">Exam-categories </nz-page-header-title>
    <nz-page-header-extra class="mr4">
      <button nz-button nzType="primary" [routerLink]="['add']" [queryParams]="{index: index}" routerLinkActive="router-link-active"
        class="submitoraddbutton">
        Add Exam Category
      </button>
    </nz-page-header-extra>
  </nz-page-header>
</div>
<div nz-row nzJustify="end" class="mt4">
  <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24" class="mb5">
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input type="text" nz-input placeholder="Search by exam category" ngModel
        (ngModelChange)="searchConcepts($event)" />
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div>
</div>
<div nz-row nzJustify="end" class="mb2">
  <nz-button-group class="ml3">
    <button nz-button [nzType]="!active && deActive?'primary':'link'" (click)="changeListView()"> <span
        class="icon-size" nz-icon nzType="ordered-list" nzTheme="outline"></span></button>
    <!-- <nz-divider nzType="vertical"></nz-divider> -->
    <button nz-button [nzType]="active && !deActive?'primary':'link'" (click)="changeGridView() "><span
        class="icon-size action-button" nz-icon nzType="appstore" nzTheme="outline"></span></button>
  </nz-button-group>
</div>
<nz-tabset (nzSelectedIndexChange)="changeIndex($event)" [nzSelectedIndex]="index" *ngIf="listView && !active && deActive">
  <nz-tab nzTitle="Approved Categories">
    <nz-table #ExamCategoryTbl [nzData]="allApprovedExamCategories" [nzLoading]="loading" [nzTotal]="totalApprovedDataCount"
      [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex" [nzFrontPagination]="false"
      (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger [nzScroll]="{ x: '900px' }" *ngIf="listView">
      <thead>
        <tr>
          <!-- <th>Concept ID</th> -->
          <!-- <th nzColumnKey="topic" [nzSortFn]="true">Topic</th> -->
          <th>ExamCategory Name</th>
          <th nzWidth="300px">Description</th>
          <th nzWidth="100px">Status</th>
          <th nzWidth="200px" class="text-right">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of ExamCategoryTbl.data; index as i">
          <!-- <td>{{data.conceptId}}</td> -->
        <tr *ngFor="let data of ExamCategoryTbl.data; index as i">
          <!-- <td>{{data.conceptId}}</td> -->
          <td><span *ngIf="data.categoryName" class="ellipsis">{{ data.categoryName }}</span></td>
          <td><span *ngIf="data.description" class="ellipsis">{{ data.description }}</span></td>
          <td>
            <span class="{{ data.status ? 'active' : 'inactive' }} status"></span>
          </td>

          <td class="action-buttons">
            <a nz-button  nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
              [routerLink]="['update', data._id]" [queryParams]="{index: index}" routerLinkActive="router-link-active"><i nz-icon nzType="form"
                nzTheme="outline"></i></a>
            <a *ngIf="userRole !== 'subAdmin'" class="action-button" nz-button nz-popconfirm nzPopconfirmTitle="Are you sure {{
                data.status ? 'deActive' : 'active'
              }} this exam-category?" (nzOnConfirm)="updateStatus(data._id, data.status)"
              nzTooltipTitle="{{ data.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight" nz-tooltip><i
                nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>
          </td>
        </tr>
      </tbody>
    </nz-table></nz-tab>
  <nz-tab nzTitle="Un-approved Categories ">
    <nz-table #ExamCategoryTbl [nzData]="allExamCategories" [nzLoading]="loading" [nzTotal]="totalDataCount"
      [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex" [nzFrontPagination]="false"
      (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger [nzScroll]="{ x: '900px' }" *ngIf="listView">
      <thead>
        <tr>
          <!-- <th>Concept ID</th> -->
          <!-- <th nzColumnKey="topic" [nzSortFn]="true">Topic</th> -->
          <th>ExamCategory Name</th>
          <th nzWidth="300px">Description</th>
          <th nzWidth="200px">Created By</th>
          <th nzWidth="200px">
            Rejected Reason
          </th>
          <th nzWidth="100px">Status</th>
          <th nzWidth="150px" class="text-right">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of allExamCategories; index as i">
          <!-- <td>{{data.conceptId}}</td> -->
          <!-- <tr *ngFor="let data of ExamCategoryTbl.data; index as i"> -->
          <!-- <td>{{data.conceptId}}</td> -->
          <td><span *ngIf="data.categoryName" class="">{{ data.categoryName }}</span></td>
          <td><span *ngIf="data.description" class="ellipsis">{{ data.description }}</span></td>
          <td><span *ngIf="data.createdBy?.role === 'subAdmin'">
              {{ data?.createdBy?.name }}
            </span></td>
          <td><span *ngIf="data.rejectionReason" class="ellipsis">{{data.rejectionReason}}</span></td>
          <td>
            <nz-tag
              [nzColor]="data.approveStatus === 'approved' ? 'green':  data.approveStatus === 'pending'? 'lime':'volcano' ">
              {{data.approveStatus}}

            </nz-tag>
          </td>
          <!-- <td><span class="{{data.status? 'active' : 'inactive' }} status"></span></td> -->
          <td class="action-buttons">
            <a nz-button  nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
              [routerLink]="['update', data._id]" [queryParams]="{index: index}" routerLinkActive="router-link-active"><i nz-icon nzType="form"
                nzTheme="outline"></i></a>
            <a *ngIf="userRole !== 'subAdmin'" class="action-button" nz-button nz-popconfirm
              nzPopconfirmTitle="Are you sure {{data.status ? 'deActive' : 'active'}} this Category?"
              (nzOnConfirm)="updateStatus(data._id, data.status)"
              nzTooltipTitle="{{data.status ? 'Deactive' : 'Active'}}" nzTooltipPlacement="topRight" nz-tooltip><i
                nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>
            <a *ngIf="userRole !== 'subAdmin' " class="action-button top-margin" nz-button nz-popconfirm
              nzPopconfirmTitle="Are you sure Approved this Category?"
              (nzOnConfirm)="updatePendingStatus(data._id, data.approveStatus)" nzTooltipTitle="{{'Approve'}}"
              nzTooltipPlacement="topRight" nz-tooltip><i nz-icon nzType="file-done" nzTheme="outline"></i></a>

            <a *ngIf="userRole !== 'subAdmin' && data.approveStatus !== 'rejected'" class="action-button top-margin delete"
              nz-button nz-popconfirm nzPopconfirmTitle="Are you sure Reject this Category?"
              (nzOnConfirm)="openRejectionReason(data._id, data.approveStatus)" nzTooltipTitle='Reject'
              nzTooltipPlacement="topRight" nz-tooltip><i nz-icon [nzType]="'close-circle'" nzTheme="fill"></i></a>

          </td>
        </tr>
      </tbody>
    </nz-table></nz-tab>
</nz-tabset>

<div *ngIf=" !listView">
  <app-grid-view [isExamCategoryComponent]="isExamCategoryComponent" [unApprovedList]="allExamCategories"
    [list]="allApprovedExamCategories" [isOpenDetails]="isOpenDetails" [loading]="loading"></app-grid-view>
  <div class="pagination">
    <nz-pagination [nzPageIndex]="pageIndex" [nzTotal]="totalDataCount" nzShowSizeChanger
      (nzPageSizeChange)="onQueryPageSizeChangeForGridView($event)" [(nzPageSize)]="pageSize"
      (nzPageIndexChange)="onQueryParamsChangeForGridView($event)"></nz-pagination>
  </div>
</div>