import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';
import { CommonArrayService } from 'src/app/core/services/common-array.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-update-bulk-question',
  templateUrl: './add-update-bulk-question.component.html',
  styleUrls: ['./add-update-bulk-question.component.scss'],
})
export class AddUpdateBulkQuestionComponent implements OnInit {
  addUpdateBulkQuestionForm!: FormGroup;
  title = 'excel-upload-download';
  excel: any[] = [];
  errorDataList: any[] = [];
  duplicateDataList: any[] = [];
  buttonLoading: boolean = false;
  mediaBaseUrl = environment.mediaBaseUrl;;
  mediaUploadUrl = environment.apiBaseUrl+ '/api/questions/upload-excel';
  pageSize = 12;
  pageIndex = 1;
  difficultyLevelObject: any = {};
  difficultyLevelList: any[] = [];
  difficultyLevelListData: any[] = [];
  totalDataCount:number = 0;
  loading: boolean = false
  constructor(
    private excelService: ExcelService,
    private http: HttpClient,
    private msg: NzMessageService,
    private notificationService: NzNotificationService,
    private fb: FormBuilder,
    private router: Router,
    private commonArrayService: CommonArrayService
  ) {
    this.addUpdateBulkQuestionForm = this.fb.group({
      image: [null, [Validators.required]],
    });
  }
  ngOnInit(): void {
    this.difficultyLevelObject =
      this.commonArrayService.difficultyLevelObjectByValue;
  }
  //  send auth in headers //
  customRequestHeaders = () => {
    return { Authorization: `Bearer ${localStorage.getItem('token')}` };
  };
  // image Upload
  UploadImageFile(
    info: { file: NzUploadFile },
    FormControl: string,
    index: number
  ) {
    switch (info.file.status) {
      case 'uploading':
        this.buttonLoading = true;
        this.loading = true
        break;
      case 'done':
        this.buttonLoading = false;
       
        this.loading = false;
        this.errorDataList = info.file.response.data.data.errorData
        this.duplicateDataList = info.file.response.data.data.duplicateData
        this.totalDataCount = info.file.response.data.data.errorCount
        // this.excelService.getData(this.errorDataList)
        // this.excel.push(this.errorDataList)
        this.notificationService.success('','Upload successfully');
        // this.router.navigate(['/main/question']);
        break;
      case 'error':
        this.msg.error('Network error');
        this.buttonLoading = false;
        break;
    }
  }

  beforeRelatedMediaUpload = (
    file: NzUploadFile,
    fileList: NzUploadFile[]
  ): Observable<any> => {
    return new Observable((observer: Observer<boolean>) => {
     ;
      // const isImage =
      //   file.type === '.xlsx/.xls';

      if (typeof file?.type === 'string') {
        const findType = file.type.split('.')[file.type.split('.').length - 1];
       ;

        if (findType === 'sheet') {
          observer.next(true);
          observer.complete();
          return;
        } else {
          this.msg.error('You can only upload Excel file!');
          observer.complete();
          return;
        }
      }

      // const isLt3M = file.size ? file.size / 1024 / 1024 < 3 : false;
      // if (!isLt3M) {
      //   this.msg.error('Image must smaller than 3MB!');
      //   observer.complete();
      //   return;
      // }
      // observer.next(isImage && isLt3M);
      // observer.complete();
    });
  };
  removeImage(): void {
    this.addUpdateBulkQuestionForm.value.image = null;
  }
  downloadExcel(isBlank = true) {
    if(this.errorDataList && this.errorDataList.length){
      this.excel = this.errorDataList;
      this.excelService.exportAsExcelFile(this.excel, 'Error-Sheet', isBlank);
    }else{
      this.excelService.downloadExcel('Demo-Question.xlsx')
    }
  }
  //  /* For Pagination / Sending skip for grid view*/
  //  onQueryParamsChange(index: any): void {
  //   this.pageIndex = index;
  //   this.getAllQuestions(this.pageSize * (this.pageIndex - 1));
  // }
}
