<nz-card style="width: 100%" class="mb-15">
  <nz-page-header nzTitle="My Profile"></nz-page-header>
  <form nz-form [formGroup]="profileForm">
    <div nz-row [nzGutter]="16">
      <div nz-col class="gutter-row" nzLg="12" nzMd="12" nzSm="24" nzXs="24">
        <!------ Name-->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="name" nzRequired>Name </nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
            <input nz-input formControlName="name" type="text" placeholder="Enter Name" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col class="gutter-row" nzLg="12" nzMd="12" nzSm="24" nzXs="24">
        <!----- Email -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="email">Email</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input nz-input formControlName="email" type="email" readonly />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col class="gutter-row" nzLg="24" nzMd="24" nzSm="24" nzXs="24" style="text-align: right">
        <nz-form-item>
          <nz-form-control>
            <button nz-button [nzType]="'primary'" [nzLoading]="buttonLoading" nz-popconfirm
              nzPopconfirmTitle="While updating your profile you may lose your saved incomplete report data from the draft. Are you sure to still update your profile?"
              nzPopconfirmPlacement="top" (nzOnConfirm)="submit()" (nzOnCancel)="cancel()" nzOkText="Confirm"
              nzCancelText="Skip Update">
              Update Profile
            </button>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</nz-card>

<nz-card style="width: 100%" class="mbt-15" *ngIf="userRole !== 'subAdmin'">
  <nz-page-header nzTitle="Change Password"></nz-page-header>
  <form nz-form [formGroup]="changePasswordForm">
    <div nz-row [nzGutter]="16">
      <div nz-col class="gutter-row" nzLg="12" nzMd="12" nzSm="24" nzXs="24">
        <!------ Password-->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="password" nzRequired>Password</nz-form-label>
          <nz-form-control nzErrorTip="Please input your Password!">
            <nz-input-group [nzAddOnAfter]="passwordIconTpl">
              <input [type]="!viewPassword ? 'password' : 'text'" nz-input formControlName="password"
                placeholder="Password" (ngModelChange)="updateConfirmValidator()" />
            </nz-input-group>
            <ng-template #passwordIconTpl>
              <i nz-icon [nzType]="!viewPassword ? 'eye' : 'eye-invisible'" nzTheme="outline"
                (click)="viewPassword = !viewPassword"></i>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col class="gutter-row" nzLg="12" nzMd="12" nzSm="24" nzXs="24">
        <!----- Confirm Password -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="reEnterPassword" nzRequired>Confirm Password</nz-form-label>
          <nz-form-control [nzSpan]="24" [nzErrorTip]="errorTpl">
            <nz-input-group [nzAddOnAfter]="reEnterpasswordIconTpl">
              <input [type]="!viewReEnterPassword ? 'password' : 'text'" nz-input formControlName="reEnterPassword"
                placeholder="Re-Enter Password" />
              <ng-template #reEnterpasswordIconTpl>
                <i nz-icon [nzType]="!viewReEnterPassword ? 'eye' : 'eye-invisible'" nzTheme="outline"
                  (click)="viewReEnterPassword = !viewReEnterPassword"></i>
              </ng-template>
            </nz-input-group>
            <ng-template #errorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Please confirm your password
              </ng-container>
              <ng-container *ngIf="control.hasError('confirm')">
                Password and Confirm Password must be match!
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col class="gutter-row" nzLg="24" nzMd="24" nzSm="24" nzXs="24" style="text-align: right">
        <nz-form-item>
          <nz-form-control>
            <button nz-button [nzType]="'primary'" (click)="updatePassword()" [nzLoading]="passwordChangeLoading">
              Update Password
            </button>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</nz-card>

<nz-card style="width: 100%" class="mb-15" *ngIf="userRole !== 'subAdmin'">
  <!-- <h3 class="changepassword">My Profile</h3> -->
  <nz-page-header nzTitle="Configuration"></nz-page-header>
  <form nz-form [formGroup]="siteConfigureFrom">
    <div nz-row [nzGutter]="16">
      <!-- banner config -->
      <div nz-col class="gutter-row" nzLg="24" nzMd="24" nzSm="24" nzXs="24">
        <h3 class="">Banner Configuration : </h3>
        <div nz-row [nzGutter]="16">
          <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="name" nzRequired>Slide duration (in Sec)</nz-form-label>
              <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                <nz-input-number class="width-100" formControlName="slideDuration" [nzMin]="2"
                  [nzStep]="1"></nz-input-number>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired>Show Pagination</nz-form-label>
              <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                <nz-radio-group formControlName="isShowPagination" nzName="radiogroup">
                  <label nz-radio [nzValue]="true">True</label>
                  <label nz-radio [nzValue]="false">False</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired>Show Navigation</nz-form-label>
              <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                <nz-radio-group formControlName="isShowNavigation" nzName="radiogroup">
                  <label nz-radio [nzValue]="true">True</label>
                  <label nz-radio [nzValue]="false">False</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>

      <nz-divider [nzType]="'horizontal'"></nz-divider>
      <!-- site-config -->
      <div nz-col class="gutter-row" nzLg="24" nzMd="24" nzSm="24" nzXs="24">
        <h3 class="">Site Configuration : </h3>
        <div nz-row [nzGutter]="16">
          <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="name">Facebook</nz-form-label>
              <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                <input nz-input formControlName="facebook" placeholder="Enter Your Facebook Account Link" />
                <span>
                  <small>
                    <i nz-icon nzType="info-circle" nzTheme="outline"></i>&nbsp;
                    <span>
                      <span>URL should be in the format of- </span>
                      <span class="text-primary">https://www.facebook.com/( Your profile url or page url )
                      </span>
                    </span>
                  </small>
                </span>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="name">Twitter</nz-form-label>
              <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                <input nz-input formControlName="twitter" placeholder="Enter Your Twitter Account Link" />
                <span>
                  <small>
                    <i nz-icon nzType="info-circle" nzTheme="outline"></i>&nbsp;
                    <span>
                      <span>URL should be in the format of- </span>
                      <span class="text-primary">https://twitter.com/( Your profile url or page url )
                      </span>
                    </span>
                  </small>
                </span>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="name">Instagram</nz-form-label>
              <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                <input nz-input formControlName="instagram" placeholder="Enter Your Instagram Account Link" />
                <span>
                  <small>
                    <i nz-icon nzType="info-circle" nzTheme="outline"></i>&nbsp;
                    <span>
                      <span>URL should be in the format of- </span>
                      <span class="text-primary">https://www.instagram.com/( Your profile url or page url )
                      </span>
                    </span>
                  </small>
                </span>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="name">Youtube</nz-form-label>
              <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                <input nz-input formControlName="youtube" placeholder="Enter Your Youtube Account Link" />
                <span>
                  <small>
                    <i nz-icon nzType="info-circle" nzTheme="outline"></i>&nbsp;
                    <span>
                      <span>URL should be in the format of- </span>
                      <span class="text-primary">https://www.youtube.com/( Your profile url or page url )
                      </span>
                    </span>
                  </small>
                </span>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="name">LinkedIn</nz-form-label>
              <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                <input nz-input formControlName="linkedIn" placeholder="Enter Your LinkedIn Account Link" />
                <span>
                  <small>
                    <i nz-icon nzType="info-circle" nzTheme="outline"></i>&nbsp;
                    <span>
                      <span>URL should be in the format of- </span>
                      <span class="text-primary">https://www.linkedIn.com/( Your profile url or page url )
                      </span>
                    </span>
                  </small>
                </span>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="name">Contact Email</nz-form-label>
              <nz-form-control [nzSpan]="24" [nzErrorTip]="errorTipForEmail">
                <input nz-input formControlName="contactEmail" placeholder="Enter Your Email Address" />
                <ng-template #errorTipForEmail>
                  <!-- Error for required field -->
                  <ng-container *ngIf="this.siteConfigureFrom.get('contactEmail')?.hasError('required')">
                    Should not be blank.
                  </ng-container>
                  <!-- Error for invalid pattern -->
                  <ng-container *ngIf="siteConfigureFrom.get('contactEmail')?.hasError('pattern')">
                    Please enter a valid Email.
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="name">Contact Address</nz-form-label>
              <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                <textarea nz-input formControlName="contactAddress" placeholder="Enter Your Address"></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="name">Contact Whatsapp</nz-form-label>
              <nz-form-control [nzSpan]="24" [nzErrorTip]="errorTipForWhatsapp">
                <nz-input-number-group class="width-100" nzAddOnBefore="+91">
                  <nz-input-number formControlName="contactWhatsapp" [nzMin]="1" [nzStep]="10"
                    placeholder="Enter Your Whatsapp No"></nz-input-number>
                </nz-input-number-group>
                <ng-template #errorTipForWhatsapp>
                  <!-- Error for invalid pattern -->
                  <ng-container *ngIf="siteConfigureFrom.get('contactWhatsapp')?.hasError('pattern')">
                    Please enter a valid 10-digit mobile number.
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="contactNumber">Contact Number</nz-form-label>
              <nz-form-control [nzSpan]="24" [nzErrorTip]="errorTipForNumbers">
                <nz-input-number-group class="width-100" nzAddOnBefore="+91">
                  <nz-input-number formControlName="contactNumber" [nzMin]="1" [nzStep]="1"
                    placeholder="Enter Your Contact No">
                  </nz-input-number>
                </nz-input-number-group>
                <!-- Error Tip Template -->
                <ng-template #errorTipForNumbers>
                  <!-- Error for invalid pattern -->
                  <ng-container *ngIf="siteConfigureFrom.get('contactNumber')?.hasError('pattern')">
                    Please enter a valid 10-digit mobile number.
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

        </div>
      </div>
      <div nz-col class="gutter-row" nzLg="24" nzMd="24" nzSm="24" nzXs="24" style="text-align: right">
        <nz-form-item>
          <nz-form-control>
            <button nz-button [nzType]="'primary'" [nzLoading]="buttonLoading" nz-popconfirm
              nzPopconfirmTitle="Are you sure you want to Change this Configuration?" nzPopconfirmPlacement="top"
              (nzOnConfirm)="submitConfig()" (nzOnCancel)="cancel()" nzOkText="Ok" nzCancelText="cancel">
              Submit
            </button>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>

</nz-card>