<nz-card>
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
            <div class="width-100"> <strong>{{TestSeriesDetails.name}}</strong> </div>
        </div>
        <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="mt4">
            <nz-tag *ngIf="TestSeriesDetails.price" [nzColor]="'blue'">
                <b>
                    {{TestSeriesDetails.price}} ₹
                </b>
            </nz-tag>
            <nz-tag [nzColor]="TestSeriesDetails.isPaid ?'red': 'green'" >
                <b>
                    {{TestSeriesDetails.isPaid !== true ?  'Un Paid' :'Paid'}}
                </b>
            </nz-tag>
            <nz-tag [nzColor]="'gold'"> <b>Tests- {{TestSeriesDetails.tests.length}} </b></nz-tag>
            
            <div *ngFor="let item of TestSeriesDetails.tests; index as i" class="padding-div mt3">
                <nz-card *ngIf="item.test.name">
                    <b nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" [nzJustify]="'space-between'">
                        <span nz-col>
                            {{i + 1}}.{{item.test.name}}
                        </span>
                        <span nz-col >
                            <nz-tag [nzColor]="'blue'" *ngIf="totalQuestions">Total Questions- {{totalQuestions[i]}}</nz-tag>
                            <nz-tag [nzColor]="item.isOpen !== true ?'red': 'green'" >{{item.isOpen !== true ? 'Paid' :'Free'}}</nz-tag>
                        </span>
                    </b>
                </nz-card>
            </div>
        </div>
        <!-- <nz-collapse nzAccordion *ngIf="TestSeriesDetails.tests">
            <nz-collapse-panel *ngFor="let item of TestSeriesDetails.tests; index as i" [nzHeader]="item.test.name"
                [nzExtra]="extraTpl" [nzActive]="true">
                <ng-template #extraTpl>
                    <nz-tag [nzColor]="'magenta'" *ngIf="totalQuestions">Total Questions- {{totalQuestions[i]}}</nz-tag>
                    <nz-tag *ngIf="item.isOpen" [nzColor]="'gold'" >{{item.isOpen !== true ? 'paid': 'un paid'}}</nz-tag>
                </ng-template>
            </nz-collapse-panel>
        </nz-collapse> -->

    </div>
</nz-card>