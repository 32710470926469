<div class="site-page-header-ghost-wrapper">
  <nz-page-header [nzGhost]="false">
    <nz-page-header-title class="ml4">Exams </nz-page-header-title>
    <nz-page-header-extra class="mr4">
      <button nz-button nzType="primary" [routerLink]="['add']" [queryParams]="{index: index}" routerLinkActive="router-link-active"
        class="submitoraddbutton">
        Add Exam
      </button>
    </nz-page-header-extra>
  </nz-page-header>
</div>
<div nz-row nzJustify="end" [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }" class="mt4">
  <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control
        [nzSpan]="24"
        nzErrorTip="Please select your Exam-Category!"
      >
      <nz-form-label nzFor="chapter">Exam-Category</nz-form-label>
        <nz-select
        nzAllowClear
          nzShowSearch
          nzServerSearch
          id="examCategory"
          [(ngModel)]="selectedExamCategory"
          nzPlaceHolder="Select a Exam-Category"
          (nzOnSearch)="searchExamCategoryForDropDown($event)"
          (ngModelChange)="onChangeExamCategory()"
          
        >
          <nz-option
            *ngFor="let item of allExamCategoryList"
            nzValue="{{ item._id }}"
            nzLabel="{{ item.categoryName }}"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24" class="mb5">
    <nz-form-label nzFor="chapter">Search</nz-form-label>
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input type="text" nz-input placeholder="Search by exam name" ngModel (ngModelChange)="searchExams($event)" />
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div>
</div>
<div nz-row nzJustify="end" class="mb2">
  <nz-button-group class="ml3">
    <button nz-button [nzType]="!active && deActive?'primary':'link'" (click)="changeListView()"> <span class="icon-size" nz-icon nzType="ordered-list"
        nzTheme="outline"></span></button>
        <!-- <nz-divider nzType="vertical"></nz-divider> -->
    <button nz-button [nzType]="active && !deActive?'primary':'link'"  (click)="changeGridView() "><span class="icon-size action-button" nz-icon
        nzType="appstore" nzTheme="outline"></span></button>
  </nz-button-group>
</div>
<nz-tabset (nzSelectedIndexChange)="changeIndex($event)" [nzSelectedIndex]="index" *ngIf="listView && !active && deActive">
  <nz-tab nzTitle="Approved Exam"><nz-table #examTbl [nzData]="allApprovedExams" [nzLoading]="loading" [nzTotal]="totalApprovedDataCount" [(nzPageSize)]="pageSize"
    [nzPageIndex]="pageIndex" [nzFrontPagination]="false" (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger
    [nzScroll]="{ x: '900px' }" *ngIf="listView">
    <thead>
      <tr>
        <!-- <th>Concept ID</th> -->
        <!-- <th nzColumnKey="topic" [nzSortFn]="true">Topic</th> -->
        <th >Exam Name</th>
        <th >Exam Category</th>
        <th nzWidth="350px">Description</th>
        <th nzWidth="100px">Status</th>
        <th nzWidth="150px" class="text-right">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of examTbl.data; index as i">
        <!-- <td>{{data.conceptId}}</td> -->
        <td><span *ngIf="data.examName">{{ data.examName }}</span></td>
        <td><span *ngIf="data.examCategory && data.examCategory.categoryName">{{ data.examCategory.categoryName }}</span>
        </td>
        <td ><span *ngIf="data.description" class="ellipsis">{{ data.description }}</span></td>
        <td>
          <span class="{{ data.status ? 'active' : 'inactive' }} status"></span>
        </td>
  
        <td class="action-buttons">
          <a nz-button  nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
            [routerLink]="['update', data._id]" [queryParams]="{index: index}" routerLinkActive="router-link-active"><i nz-icon nzType="form"
              nzTheme="outline"></i></a>
          <a *ngIf="userRole !== 'subAdmin'"
              class="action-button"
              nz-button
              nz-popconfirm
              nzPopconfirmTitle="Are you sure {{
                data.status ? 'deActive' : 'active'
              }} this exam?"
              (nzOnConfirm)="updateStatus(data._id, data.status)"
              nzTooltipTitle="{{ data.status ? 'Deactive' : 'Active' }}"
              nzTooltipPlacement="topRight"
              nz-tooltip
              ><i
                nz-icon
                [nzType]="data.status ? 'stop' : 'check'"
                nzTheme="outline"
              ></i
            ></a>
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-tab>
  <nz-tab nzTitle="Un-approved Exams">
    <nz-table #examTbl [nzData]="allExams" [nzLoading]="loading" [nzTotal]="totalDataCount" [(nzPageSize)]="pageSize"
    [nzPageIndex]="pageIndex" [nzFrontPagination]="false" (nzQueryParams)="onQueryParamsChange($event)" nzShowSizeChanger
    [nzScroll]="{ x: '900px' }" *ngIf="listView">
    <thead>
      <tr>
        <!-- <th>Concept ID</th> -->
        <!-- <th nzColumnKey="topic" [nzSortFn]="true">Topic</th> -->
        <th>Exam Name</th>
        <th>Exam Category</th>
        <th nzWidth="350px">Description</th>
        <th nzWidth="150px"> Created By </th>
        <th nzWidth="150px">
          Rejected Reason
        </th>
        <th nzWidth="100px">Status</th>
        <th nzWidth="150px" class="text-right">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of allExams; index as i">
        <!-- <td>{{data.conceptId}}</td> -->
        <td><span *ngIf="data.examName">{{ data.examName }}</span></td>
        <td><span *ngIf="data.examCategory && data.examCategory.categoryName">{{ data.examCategory.categoryName }}</span>
        </td>
        <td ><span *ngIf="data.description" class="description">{{ data.description }}</span></td>
        <td><span *ngIf="data.createdBy?.role === 'subAdmin'" >
          {{ data?.createdBy?.name }}
        </span></td>
        <td><span *ngIf="data.rejectionReason" class="ellipsis">{{data.rejectionReason}}</span></td>
        <td>
          <nz-tag [nzColor]="data.approveStatus === 'approved' ? 'green':  data.approveStatus === 'pending'? 'lime':'volcano' " >
            {{data.approveStatus}}   
            
          </nz-tag>
        </td>
        <!-- <td><span class="{{data.status? 'active' : 'inactive' }} status"></span></td> -->
        <td class="action-buttons">
          <a
            nz-button
            
            nzTooltipTitle="Edit"
            nzTooltipPlacement="topCenter"
            nz-tooltip
            [routerLink]="['update', data._id]"
            [queryParams]="{index: index}"
            routerLinkActive="router-link-active"
            ><i nz-icon nzType="form" nzTheme="outline"></i
          ></a>
          <a *ngIf="userRole !== 'subAdmin'" class="action-button" nz-button nz-popconfirm
            nzPopconfirmTitle="Are you sure {{data.status ? 'deActive' : 'active'}} this Exam?"
            (nzOnConfirm)="updateStatus(data._id, data.status)" nzTooltipTitle="{{data.status ? 'Deactive' : 'Active'}}"
            nzTooltipPlacement="topRight" nz-tooltip><i nz-icon [nzType]="data.status ? 'stop' : 'check'"
              nzTheme="outline"></i></a>
              <a *ngIf="userRole !== 'subAdmin' " class="action-button top-margin" nz-button nz-popconfirm
              nzPopconfirmTitle="Are you sure Approved this Exam?"
              (nzOnConfirm)="updatePendingStatus(data._id, data.approveStatus)" nzTooltipTitle="{{'Approve'}}"
              nzTooltipPlacement="topRight" nz-tooltip><i nz-icon nzType="file-done" nzTheme="outline"></i></a>
                <a *ngIf="userRole !== 'subAdmin' && data.approveStatus !== 'rejected'" class="action-button top-margin delete" nz-button nz-popconfirm
              nzPopconfirmTitle="Are you sure Reject this Exam?"
              (nzOnConfirm)="openRejectionReason(data._id, data.approveStatus)" nzTooltipTitle='Reject'
              nzTooltipPlacement="topRight" nz-tooltip><i nz-icon [nzType]="'close-circle'"
                nzTheme="fill"></i></a>
                
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-tab>
</nz-tabset>

<div *ngIf=" !listView">
  <app-common-grid-view [list]="allApprovedExams" (index)="changeIndex($event)" [unApprovedList]="allExams" [isExamComponent]="isExamComponent"  [isAddOrUpdate]="isAddOrUpdate" [isOpenDetails]="isOpenDetails" [loading]="loading"></app-common-grid-view>
  <div class="pagination">
    <nz-pagination [nzPageIndex]="pageIndex" [nzTotal]="totalDataCount" nzShowSizeChanger
      (nzPageSizeChange)="onQueryPageSizeChangeForGridView($event)" [(nzPageSize)]="pageSize"
      (nzPageIndexChange)="onQueryParamsChangeForGridView($event)"></nz-pagination>
  </div>
</div>