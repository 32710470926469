<div *ngIf="addUpdateSingleQuestionForm">

    <form [formGroup]="addUpdateSingleQuestionForm">
        <!-- <nz-card> -->
        <app-update-paragraph [paragraphDetails]='paragraphDetails' *ngIf="typeChange"></app-update-paragraph>
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
            <div nz-col nzLg="{{isFromOtherComponent ? 5: 6}}" nzMd="{{isFromOtherComponent ? 5: 6}}" nzSm="24"
                nzXs="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="subject" nzRequired>Subject</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Please select your subject!">
                        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" formControlName="subject"
                            nzPlaceHolder="Select a option" (nzOnSearch)="searchSubjectForDropdown($event)"
                            (ngModelChange)="onChangeSubject($event)">
                            <nz-option *ngFor="let item of allSubjects" nzValue="{{ item._id }}"
                                nzLabel="{{ item.subjectName }}"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzLg="{{isFromOtherComponent ? 5: 6}}" nzMd="{{isFromOtherComponent ? 5: 6}}" nzSm="24"
                nzXs="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="subject">Chapter</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Please select your chapter!">
                        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" formControlName="chapter"
                            nzPlaceHolder="Select a option" (nzOnSearch)="searchChapterForDropdown($event)"
                            (ngModelChange)="onChangeChapter($event)">
                            <nz-option *ngFor="let item of allChapters" nzValue="{{ item._id }}"
                                nzLabel="{{ item.chapterName }}"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzLg="{{isFromOtherComponent ? 5: 6}}" nzMd="{{isFromOtherComponent ? 5: 6}}" nzSm="24"
                nzXs="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="subject">Topic</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Please select your topic!">
                        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" formControlName="topic"
                            nzPlaceHolder="Select a option" (nzOnSearch)="searchTopicForDropdown($event)">
                            <nz-option *ngFor="let item of allTopics" nzValue="{{ item._id }}"
                                nzLabel="{{ item.topicName }}"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzLg="{{isFromOtherComponent ? 5: 6}}" nzMd="{{isFromOtherComponent ? 5: 6}}" nzSm="24"
                nzXs="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="difficultyLevel" nzRequired>Difficulty Level</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Please select your difficulty level!">
                        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory"
                            formControlName="difficultyLevel" nzPlaceHolder="Select a option">
                            <nz-option *ngFor="let item of allLevels" nzValue="{{ item.value }}"
                                nzLabel="{{ item.label }}"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzLg="{{isFromOtherComponent ? 4: 6}}" nzMd="{{isFromOtherComponent ? 4: 6}}" nzSm="24"
                nzXs="24" class="width-100" *ngIf="testForm">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="difficultyLevel" nzRequired>Serial No</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Please select a Serial No">
                        <nz-input-number formControlName="serialNo" [nzPlaceHolder]="'Enter a number'"
                            [nzSize]="'default'" class="width-100"></nz-input-number>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="24" nzFor="questionText">Question Text</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                        <!-- <ckeditor #editor id="editor1" name="questionText" formControlName="questionText"
                                type="divarea">
                            </ckeditor> -->
                        <ngx-suneditor [options]="editorOptionsSingle" (onChange)="onQuestionTextChange($event)"
                            [editorID]="getEditorId('questionText')"></ngx-suneditor>

                    </nz-form-control>
                </nz-form-item>
            </div>
            <!-- <nz-divider nzText="Options" nzOrientation="left" class="abnormaldividor"></nz-divider> -->
            <!-- <h4 class="ml3"><b>Answers</b></h4> -->
            <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                <div nz-row  formArrayName="options"
                    *ngFor="let item of optionsFormArray.controls; index as i" nzJustify="space-between">
                    <ng-container [formGroupName]="i">
                        <div nz-col nzLg="1" nzMd="1" nzSm="24" nzXs="24">
                            <!-- <nz-form-item > -->
                            <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                                <label nz-checkbox formControlName="isCorrectOption"></label>
                            </nz-form-control>
                            <!-- </nz-form-item> -->
                        </div>
                        <div nz-col nzLg="22" nzMd="22" nzSm="24" nzXs="24">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                                    <ngx-suneditor [options]="editorOptionsSingle" (onChange)="onOptionTextChange($event,i)"
                                        [editorID]="getEditorId('option', i)"></ngx-suneditor>
                                    <!-- <ngx-suneditor [options]="editorOptionsSingle" (onChange)="onOptionTextChange($event,i)" [editorID]="idOfEditor"></ngx-suneditor> -->
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col nzLg="1" nzMd="1" nzSm="24" nzXs="24" class="text-right" >
                            <button nz-button nzType="dashed" nzDanger
                                *ngIf="addUpdateSingleQuestionForm.value.options.length > 1" nz-popconfirm
                                nzPopconfirmTitle="Are you sure delete this option?" (click)="$event.stopPropagation()"
                                (nzOnConfirm)="removeOption(i)" class="right-aline deleteOption" nz-tooltip
                                [nzTooltipTitle]="'Delete option ' + (i + 1)">
                                <i nz-icon nzType="delete" nzTheme="fill"></i>
                            </button>
                        </div>
                    </ng-container>
                </div>
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="center mt2" >
                    <button nz-button nzType="dashed" nzDanger nzBlock (click)="addOption()">
                        ADD MORE OPTIONS<i nz-icon nzType="plus-circle" nzTheme="outline"></i>
                    </button>
                </div>
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="questionText">Question Solution</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">

                            <ngx-suneditor [options]="editorOptionsSingle" (onChange)="onSolutionChange($event)"
                                [editorID]="getEditorId('solution')"></ngx-suneditor>
                        </nz-form-control>
                    </nz-form-item>
                </div>


            </div>
        </div>
        <!-- <div nz-row nzGutter="16" nzJustify="end">
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="right-aline">
                    <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()">
                        {{ idForUpdate ? "Update" : "Submit" }}
                    </button>
                </div>
            </div> -->
        <!-- </nz-card> -->
    </form>
</div>