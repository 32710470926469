import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { HttpRequestService, LocalStorageService } from 'src/app/core/services';
import { TestDetailsComponent } from 'src/app/main/test/test-details/test-details.component';
import { environment } from 'src/environments/environment';
import { RejectionReasonComponent } from '../rejection-reason/rejection-reason.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Test } from 'src/app/core/interfaces/test';

@Component({
  selector: 'app-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrls: ['./grid-view.component.scss'],
})
export class GridViewComponent implements OnInit {
  @Input() list: any;
  @Input() unApprovedList: any;
  @Input() loading: any;
  @Input() isOpenDetails: any;
  @Input() isTestComponent:boolean = false;
  @Input() isTestSeriesComponent:boolean = false;
  @Input() isExamCategoryComponent: boolean = false;
  mediaBaseUrl = environment.mediaBaseUrl;
  totalDataCount = 0;
  pageSize = 10;
  pageIndex = 1;
  userRole: string = '';
  @Output() index = new EventEmitter<number>();
  
  constructor(
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private drawerService: NzDrawerService,
    private localStorageService: LocalStorageService,
    private modalService: NzModalService,
  ) {}

  ngOnInit(): void {
    this.userRole = this.localStorageService.getItem('user').role;
  }
  ngOnChanges() {
  }
  changeIndex(data: number){
   
    // this.index = data
    this.index.emit(data);
  }
  updateStatus(id: string, status: boolean, component: string): void {
    this.httpRequestService
      .request('put', `${component}/active-inactive/${id}`, { status: !status })
      .subscribe((result: any) => {
        const id = result.data._id;
        this.list.forEach((item: any) => {
          if (item._id === id) {
            item.status = !item.status;
          }
        });
        this.unApprovedList.forEach((item: any) => {
          if (item._id === id) {
            item.status = !item.status;
          }
        });
        this.notificationService.success('', 'Status Updated Successfully');
      });
  }
  updatePendingStatus(id: string, pendingStatus: string, component: string) {
    this.httpRequestService
      .request('put', `${component}/approved-rejected/${id}`, {
        approveStatus: 'approved',
      })
      .subscribe((result: any) => {
        if(result){
          const id = result.data._id;
          this.unApprovedList.forEach((item: any) => {
            if (item._id === id) {
              item.approveStatus = 'approved';
            }
          });
        }
        this.notificationService.success('', 'Status Updated Successfully');
      });
  }
  openRejectionReason(id: string, pendingStatus: string, component: string) {
      const pendingStatusData = 'rejected';
      const rejectionReason = this.modalService.create({
        nzTitle: 'Add A Reason',
        nzContent: RejectionReasonComponent,
        nzWidth: '800px',
        nzFooter: null,
        nzComponentParams: {
          updateId: id,
          component: `${component}`,
        },
        nzCancelText: null,
        nzOkText: null,
      });

    const questionService = rejectionReason.afterClose.subscribe(
      (data: any) => {
        // this.getAllTopics();
        if(data){
          const id = data.data._id;
          this.unApprovedList.forEach((item: any) => {
            if (item._id === id) {
              item.approveStatus = 'rejected';
            }
          });
        }
      }
    );
  }
  openDetails(item: any) {
    const drawerRef = this.drawerService.create({
      nzTitle: 'Test Details',
      nzFooter: '',
      nzWidth: '80%',
      nzContent: TestDetailsComponent,
      nzContentParams: {
        value: item,
      },
    });
  }
  removeTest(id: string): void{
    this.httpRequestService
    .request('delete', `tests/${id}`)
    .subscribe((result: any) => {
      this.notificationService.success('', 'Test Deleted Successfully');
      this.list = this.list.filter((item: any) => {
        return item._id !== id
      })
    });

  }

  
}
