import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Resource } from 'src/app/core/interfaces/resource';
import { HttpRequestService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-update-resource',
  templateUrl: './add-update-resource.component.html',
  styleUrls: ['./add-update-resource.component.scss']
})
export class AddUpdateResourceComponent implements OnInit {
  addUpdateResourceForm: FormGroup;
  idForUpdate: string;
  buttonLoading = false;
  imageLoading = false;
  allExamCategories: any;
  checkAddPermission = false;
  checkUpdatePermission = false;
  search = '';
  searchChapter: Subject<any> = new Subject<any>();
  searchExamCategory: Subject<any> = new Subject<any>();
  loading: boolean = false
  mediaBaseUrl = environment.mediaBaseUrl;
  mediaUploadUrl = environment.apiBaseUrl + '/api/media';
  pdfName: any;
  pdfId: any;
  tabIndex: number = 0
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private msg: NzMessageService,
  ) {
    this.tabIndex = this.activatedRoute.snapshot.queryParams.index;
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.addUpdateResourceForm = this.fb.group({
      examCategory: [null],
      file: [null, [Validators.required]],
      title: [null, [Validators.required]],
      publishedDate : [null, [Validators.required]],
      status: [true]

    });
  }

  ngOnInit(): void {

    this.getAllExamCategory();

    if (this.idForUpdate) {
      this.getResourceById();
    }
    this.searchExamCategory.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.search = success;
      // this.getAllChapters();
    });
  }



  /* Get all ExamCategory */
  getAllExamCategory(): void {
    const params: any = {
      skip: 0, limit: 100, approveStatus: 'approved'
    };
    if (this.search) {
      params.search = this.search;
    }
    this.httpRequestService.request('get', 'exam-categories', params).subscribe(
      (result: any) => {
        this.allExamCategories = result.data;
      },
      (err: any) => { }
    );
  }

   /* Get single chapter details by Id */
  getResourceById(): void {
    this.httpRequestService
      .request('get', `resources/${this.idForUpdate}`)
      .subscribe(
        (result: any) => {
          const data :Resource = result.data;
          if(data.file){
            this.pdfId = data.file._id
            this.pdfName = data.file.originalname;
          }
          this.addUpdateResourceForm.patchValue({
            title: data.title,
            examCategory: data.examCategory && data.examCategory._id? data.examCategory._id : null,
            publishedDate: data.publishedDate,
            file: this.pdfId
            // file: data.file.originalname,
          });
        },
        (error: any) => { }
      );
  }

  /* Submit Exam form */
  submit(): void {
    //
    // return

    if(!this.addUpdateResourceForm.value.file ){
      this.notificationService.error('', 'Please insert a resources! ');
    }
    if (!this.addUpdateResourceForm.valid || this.addUpdateResourceForm.value.file === null) {
      this.markFormGroupTouched(this.addUpdateResourceForm);
    } else {

      if (this.idForUpdate) {
        this.addOrUpdateResource(
          'put',
          `resources/${this.idForUpdate}`,
          'Resource Successfully Updated'
        );
      } else {
        this.addOrUpdateResource(
          'post',
          'resources',
          'Resource Added Successfully '
        );
      }
    }
  }

  /* Add Or Exam Concept */
  addOrUpdateResource(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    this.buttonLoading = true;

    const finalData:Resource = this.addUpdateResourceForm.value;
    this.httpRequestService
      .request(requestMethod, requestURL, finalData)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          this.router.navigate(['/main/resource'], { queryParams: {index: this.tabIndex } });
          this.buttonLoading = false;
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          } else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }


    //  send auth in headers //
    customRequestHeaders = () => {
      return { Authorization: `Bearer ${localStorage.getItem('token')}` };
    };
    // image Upload
    UploadImageFile( info: { file: NzUploadFile }, FormControl: string, index: number  ) {
      switch (info.file.status) {
        case 'uploading':
          this.imageLoading = true;
          this.loading = true
          break;
        case 'done':
          this.imageLoading = false;
          this.pdfName = info.file.response.data.originalname;
          this.pdfId = info.file.response.data._id
          this.addUpdateResourceForm.patchValue({
            file: this.pdfId
          });
          this.loading = false;
          this.notificationService.success('','Upload successfully');
          break;
        case 'error':
          this.msg.error('Network error');
          this.imageLoading = false;
          break;
      }
    }

    beforeRelatedMediaUpload = ( file: NzUploadFile, fileList: NzUploadFile[]): Observable<any> => {
      return new Observable((observer: Observer<boolean>) => {
        const isPdf =
          file.type === 'application/pdf'
        if (!isPdf) {
          this.msg.error('You can only upload PDF file!');
          observer.complete();
          return;
        }
        const isLt3M = file.size ? file.size / 1024 / 1024 < 30 : false;
        if (!isLt3M) {
          this.msg.error('Image must smaller than 3MB!');
          observer.complete();
          return;
        }
        observer.next(isPdf && isLt3M);
        observer.complete();
      });
    };
    removeImage(id: string): void {
      this.pdfName = null;
      this.pdfId = null;
      this.addUpdateResourceForm.patchValue({
        file: null
      });
      if(id){
        this.httpRequestService.request( 'delete', `media/${id}` ).subscribe((result: any)=>{
          if(result && result.data){
            this.notificationService.success('', 'Resources Deleted Successfully');
          }
        });
      }
    }

  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

  // search ExamCategory
  searchExamCategoryForDropdown(event: any): void {
    this.searchExamCategory.next(event);
  }


}