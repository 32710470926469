import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { HttpRequestService, LocalStorageService } from 'src/app/core/services';
import { TestDetailsComponent } from 'src/app/main/test/test-details/test-details.component';
import { UserDetailsComponent } from 'src/app/main/user/user-details/user-details.component';
import { environment } from 'src/environments/environment';
import { RejectionReasonComponent } from '../rejection-reason/rejection-reason.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { QuestionDetailsComponent } from 'src/app/main/question/question-details/question-details.component';
// import { EventEmitter } from 'stream';

@Component({
  selector: 'app-common-grid-view',
  templateUrl: './common-grid-view.component.html',
  styleUrls: ['./common-grid-view.component.scss'],
})
export class CommonGridViewComponent implements OnInit {
  @Input() list: any;
  @Input() unApprovedList: any;
  @Input() isOpenDetails: boolean = false;
  @Input() loading: boolean = false;
  @Input() isAddOrUpdate: boolean = false;
  @Input() isViewDetailsOfUser: boolean = false;
  @Input() isSubjectComponent: boolean = false;
  @Input() isChapterComponent: boolean = false;
  @Input() isTopicComponent: boolean = false;
  @Input() isExamComponent: boolean = false;
  @Input() isExamCategoryComponent: boolean = false;
  @Input() isParagraphQuestionComponent: boolean = false;
  @Input() isNormalQuestionComponent: boolean = false;
  @Output() index = new EventEmitter<number>();
  userRole: string = '';
  mediaBaseUrl = environment.mediaBaseUrl;
  constructor(
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private drawerService: NzDrawerService,
    private localStorageService: LocalStorageService,
    private modalService: NzModalService
  ) {}

  ngOnInit(): void {
    this.userRole = this.localStorageService.getItem('user').role;
  }
  changeIndex(data: number) {
    // this.index = data
    this.index.emit(data);
  }
  ngOnChanges() {}
  updateStatus(id: string, status: boolean, component: string): void {
    this.httpRequestService
      .request('put', `${component}/active-inactive/${id}`, { status: !status })
      .subscribe((result: any) => {
        const id = result.data._id;
        this.list.forEach((item: any) => {
          if (item._id === id) {
            item.status = !item.status;
          }
        });
        this.unApprovedList.forEach((item: any) => {
          if (item._id === id) {
            item.status = !item.status;
          }
        });
        this.notificationService.success('', 'Status Updated Successfully');
      });
  }
  updatePendingStatus(id: string, pendingStatus: string, component: string) {
    this.httpRequestService
      .request('put', `${component}/approved-rejected/${id}`, {
        approveStatus: 'approved',
      })
      .subscribe((result: any) => {
        if (result) {
          const id = result.data._id;
          this.unApprovedList.forEach((item: any) => {
            if (item._id === id) {
              item.approveStatus = 'approved';
            }
          });
        }
        this.notificationService.success('', 'Status Updated Successfully');
      });
  }
  openRejectionReason(id: string, pendingStatus: string, component: string) {
    const pendingStatusData = 'rejected';
    const rejectionReason = this.modalService.create({
      nzTitle: 'Add A Reason',
      nzContent: RejectionReasonComponent,
      nzWidth: '800px',
      nzFooter: null,
      nzComponentParams: {
        updateId: id,
        component: `${component}`,
      },
      nzCancelText: null,
      nzOkText: null,
    });

    const questionService = rejectionReason.afterClose.subscribe(
      (data: any) => {
        // this.getAllTopics();
        if (data) {
          const id = data.data._id;
          this.unApprovedList.forEach((item: any) => {
            if (item._id === id) {
              item.approveStatus = 'rejected';
            }
          });
        }
      }
    );
  }
  // updateStatusChapter(id: string, status: boolean): void {
  //   this.httpRequestService
  //     .request('put', `chapters/active-inactive/${id}`, { status: !status })
  //     .subscribe((result: any) => {
  //       const id = result.data._id;
  //       this.list.forEach((item: any) => {
  //         if (item._id === id) {
  //           item.status = !item.status;
  //         }
  //       });
  //       this.notificationService.success('', 'Status Updated Successfully');
  //     });
  // }
  // updateStatusTopic(id: string, status: boolean): void {
  //   this.httpRequestService
  //     .request('put', `topics/active-inactive/${id}`, { status: !status })
  //     .subscribe((result: any) => {
  //       const id = result.data._id;
  //       this.list.forEach((item: any) => {
  //         if (item._id === id) {
  //           item.status = !item.status;
  //         }
  //       });
  //       this.notificationService.success('', 'Status Updated Successfully');
  //     });
  // }
  // updateStatusExam(id: string, status: boolean): void {
  //   this.httpRequestService
  //     .request('put', `exams/active-inactive/${id}`, { status: !status })
  //     .subscribe((result: any) => {
  //       const id = result.data._id;
  //       this.list.forEach((item: any) => {
  //         if (item._id === id) {
  //           item.status = !item.status;
  //         }
  //       });
  //       this.notificationService.success('', 'Status Updated Successfully');
  //     });
  // }
  // updateStatusQuestion(id: string, status: boolean): void {
  //   this.httpRequestService
  //     .request('put', `questions/active-inactive/${id}`, { status: !status })
  //     .subscribe((result: any) => {
  //       const id = result.data._id;
  //       this.list.forEach((item: any) => {
  //         if (item._id === id) {
  //           item.status = !item.status;
  //         }
  //       });
  //       this.notificationService.success('', 'Status Updated Successfully');
  //     });
  // }
  openUserDetails(item: any) {
    const drawerRef = this.drawerService.create({
      nzTitle: 'User Details',
      nzFooter: '',
      nzWidth: '50%',
      nzContent: UserDetailsComponent,
      nzContentParams: {
        value: item,
      },
    });
  }
  openQuestionDetails(item: any) {
    const drawerRef = this.drawerService.create({
      nzTitle: 'Question Details',
      nzFooter: '',
      nzWidth: '60%',
      nzContent: QuestionDetailsComponent,
      nzContentParams: {
        value: item,
      },
    });
  }
  deleteQuestions(id: any) {
    this.httpRequestService
      .request('delete', `questions/${id}`)
      .subscribe((result: any) => {
        this.notificationService.success('', 'Question Deleted Successfully');
        this.list = this.list.filter((item: any) => {
          return item._id !== id;
        });
      });
  }
}
