import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NgxSuneditorComponent } from 'ngx-suneditor';
import { Observable, Observer, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Chapter } from 'src/app/core/interfaces/chapter';
import { LocalArray } from 'src/app/core/interfaces/local-array';
import { Question } from 'src/app/core/interfaces/question';
import { Subjects } from 'src/app/core/interfaces/subjects';
import { Topic } from 'src/app/core/interfaces/topic';
import { HttpRequestService } from 'src/app/core/services';
import { CkEditorConfigService } from 'src/app/core/services/ck-editor-config.service';
import { CommonArrayService } from 'src/app/core/services/common-array.service';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-single-question',
  templateUrl: './single-question.component.html',
  styleUrls: ['./single-question.component.scss'],
})
export class SingleQuestionComponent implements OnInit {
  @ViewChildren(NgxSuneditorComponent)
  editors!: QueryList<NgxSuneditorComponent>;
  editorContent = '';
  token = 'Bearer ' + localStorage.getItem('token');
  @Input() isFromOtherComponent: boolean = false;
  @Input() addUpdateSingleQuestionForm: FormGroup | null = null;
  @Input() questionIndex: number = 0;
  fileList: NzUploadFile[] = [];
  @Input() idForUpdate: string;
  buttonLoading = false;
  allSubjects: Subjects[] = [];
  allChapters: Chapter[] = [];
  allTopics: Topic[] = [];
  allLevels: LocalArray[] = [];
  checkAddPermission = false;
  checkUpdatePermission = false;
  mediaBaseUrl = environment.mediaBaseUrl;
  mediaUploadUrl = environment.apiBaseUrl + '/api/media';
  searchChapter: Subject<string> = new Subject<string>();
  searchSubject: Subject<string> = new Subject<string>();
  searchTopic: Subject<string> = new Subject<string>();
  checked: boolean = false;
  paragraphDetails: any;
  subjectSearch = '';
  chapterSearch = '';
  topicSearch = '';
  search = '';
  typeChange: boolean = false;
  apiBaseUrl: any = environment.apiBaseUrl;
  // mediaBaseUrl: any = environment.mediaBaseUrl;
  // ck editor
  name = 'test';
  public editorOptionsSingle: any;
  log: string = '';
  testForm: boolean = false;
  questionData: any;
  isDataLoaded: boolean = false;
  uniqueId: string = Math.random().toString(36).substring(7);
  getEditorId(type: string, optionIndex?: number): string {
    const baseId = `${type}_${this.questionIndex}`;
    return optionIndex !== undefined ? `${baseId}_${optionIndex}` : baseId;
  }
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private msg: NzMessageService,
    private router: Router,
    private commonArrayService: CommonArrayService,
    private commonService: CommonService,
    private ckEditorService: CkEditorConfigService
  ) {
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
  }

  ngOnInit(): void {
    // config of ck editor
    this.testForm = this.isFromOtherComponent;
    const token = `${'Bearer ' + localStorage.getItem('token')}`;
    this.editorOptionsSingle = this.ckEditorService.editorOptions;
    this.editorOptionsSingle.height = '40';

    this.allLevels = this.commonArrayService.difficultyLevel;
    this.getAllChapters();
    this.getAllSubjects();
    this.getAllTopics();

    if (this.idForUpdate) {
      this.getQuestionsById();
    } else {
      this.addOption();
      this.addOption();
      this.addOption();
      this.addOption();
    }
    this.searchChapter.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.chapterSearch = success;
      this.getAllChapters();
    });
    this.searchSubject.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.subjectSearch = success;
      this.getAllSubjects();
    });
    this.searchTopic.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.topicSearch = success;
      this.getAllTopics();
    });
  }
  ngAfterViewInit(): void {    
    // Use a subscription to wait for the editors to be ready
    this.editors.changes.subscribe(() => {
      if (this.isDataLoaded) {
        this.setEditorContents();
      }
    });
  }
  onOptionTextChange(content: any, i: number) {
    this.optionsFormArray.at(i).patchValue(
      {
        optionText: content.content,
      },
      { emitEvent: false }
    );
  }
  onSolutionChange(content: any) {
    this.addUpdateSingleQuestionForm?.patchValue(
      {
        solution: content.content,
      },
      { emitEvent: false }
    );
  }

  onQuestionTextChange(content: any) {
    this.addUpdateSingleQuestionForm?.patchValue(
      {
        questionText: content.content,
      },
      { emitEvent: false }
    );
  }
  // send auth in headers //
  customRequestHeaders = () => {
    return { Authorization: `Bearer ${localStorage.getItem('token')}` };
  };
  /* Get all Subjects */
  getAllSubjects(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved',
    };
    if (this.subjectSearch) {
      params.search = this.subjectSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'subjects', params).subscribe(
      (result: any) => {
        this.allSubjects = result.data;
      },
      (err: any) => {}
    );
  }
  /* On change subject get chapter */
  onChangeSubject(value: string, skip = 0): void {
    if (
      this.addUpdateSingleQuestionForm?.value &&
      this.addUpdateSingleQuestionForm?.value.subject
    ) {
      this.allChapters = [];
      this.allTopics = [];
      this.addUpdateSingleQuestionForm?.patchValue({
        chapter: null,
        topic: null,
      });
      if (this.addUpdateSingleQuestionForm?.value.subject) {
        this.getAllChapters();
        this.getAllTopics();
      }
    }
  }

  /* Get all chapters */
  getAllChapters(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved',
    };
    if (this.addUpdateSingleQuestionForm?.value.subject) {
      params['subject'] = this.addUpdateSingleQuestionForm?.value.subject;
    } else {
      delete params.subject;
    }
    if (this.chapterSearch) {
      params.search = this.chapterSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'chapters', params).subscribe(
      (result: any) => {
        //;

        this.allChapters = result.data;
      },
      (err: any) => {}
    );
  }

  /* On change chapter get course */
  onChangeChapter(value: string, skip = 0): void {
    if (
      this.addUpdateSingleQuestionForm?.value &&
      this.addUpdateSingleQuestionForm?.value.chapter
    ) {
      this.allTopics = [];
      this.addUpdateSingleQuestionForm?.patchValue({
        topic: null,
      });
      if (this.addUpdateSingleQuestionForm?.value.chapter) {
        this.getAllTopics();
      }
    }
  }

  /* Get all topics */
  getAllTopics(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved',
    };
    if (this.addUpdateSingleQuestionForm?.value.subject) {
      params['subject'] = this.addUpdateSingleQuestionForm?.value.subject;
    } else {
      delete params.subject;
    }
    if (this.addUpdateSingleQuestionForm?.value.chapter) {
      params['chapter'] = this.addUpdateSingleQuestionForm?.value.chapter;
    } else {
      delete params.search;
    }
    if (this.topicSearch) {
      params.search = this.topicSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'topics', params).subscribe(
      (result: any) => {
        this.allTopics = result.data;
      },
      (err: any) => {}
    );
  }


  /* Get single concept details by Id */
  getQuestionsById(): void {
    this.httpRequestService
      .request('get', `questions/${this.idForUpdate}`)
      .subscribe(
        (result: any) => {
          this.questionData = result.data; // Store the fetched data
          this.isDataLoaded = true; // Set the flag to true
          this.paragraphDetails = this.questionData.paragraph;

          if (this.questionData.questionType === 'PARAGRAPH') {
            this.typeChange = true;
          }

          this.addUpdateSingleQuestionForm?.patchValue({
            subject: this.questionData.subject._id ? this.questionData.subject._id : '',
            questionType: this.questionData.questionType ? this.questionData.questionType : '',
            difficultyLevel: this.questionData.difficultyLevel ? this.questionData.difficultyLevel : '',
          });

          if (this.questionData.chapter != null) {
            this.addUpdateSingleQuestionForm?.patchValue({
              chapter: this.questionData.chapter._id,
            });
          }
          if (this.questionData.topic != null) {
            this.addUpdateSingleQuestionForm?.patchValue({
              topic: this.questionData.topic._id,
            });
          }
          if (this.questionData.serialNo != null) {
            this.addUpdateSingleQuestionForm?.patchValue({
              serialNo: this.questionData.serialNo,
            });
          }

          this.questionData.options.forEach((element: any, index: number) => {
            this.optionsFormArray.push(this.createOption(element, index));
          });

          // Call setEditorContents if editors are already available
          if (this.editors.length > 0) {
            this.setEditorContents();
          }
        },
        (error: any) => {
          console.error('Error fetching question data:', error);
        }
      );
  }

  setEditorContents(): void {
    this.editors.forEach((editor: any) => {
      const [type, questionIndex, optionIndex] = editor.editorID.split('_');
      
      switch (type) {
        case 'questionText':
          // Set question text content
          if (this.questionData.questionText) {
            editor.setContents(this.questionData.questionText);
          }
          break;
          
        case 'solution':
          // Set solution content
          if (this.questionData.solution) {
            editor.setContents(this.questionData.solution);
          }
          break;
          
        case 'option':
          // Set option content
          if (this.questionData.options && 
              this.questionData.options[optionIndex] && 
              this.questionData.options[optionIndex].optionText) {
            editor.setContents(this.questionData.options[optionIndex].optionText);
          }
          break;
      }
    });
  }
  

  // options Form Array
  createOption(item?: any, index?: number): FormGroup {
    if (item) {
      return this.fb.group({
        optionText: [item.optionText],
        isCorrectOption: [item.isCorrectOption],
        loadingButton: [false],
        editorId: this.getEditorId('option', index),
      });
    }
    return this.fb.group({
      optionText: [null],
      isCorrectOption: [null],
      loadingButton: [false],
      editorId: this.getEditorId('option', index),
    });
  }

  // for tutor form array
  get optionsFormArray(): FormArray<any> {
    return this.addUpdateSingleQuestionForm?.get('options') as FormArray;
  }

  // Add question
  addOption(): void {
    this.optionsFormArray.push(this.createOption());
  }

  // Remove
  removeOption(index: number): void {
    this.optionsFormArray.removeAt(index);
  }
  //Update time (single get)

  // seach Chapter
  searchChapterForDropdown(event: any): void {
    this.searchChapter.next(event);
  }
  // seach Subject
  searchSubjectForDropdown(event: any): void {
    this.searchSubject.next(event);
  }
  // seach Topic
  searchTopicForDropdown(event: any): void {
    this.searchTopic.next(event);
  }
}
