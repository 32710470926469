import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { HttpRequestService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  @Input() value = {};
  totalTime: number = 0
  userDetails: any;
  pageIndex: number = 0;
  pageSize: number = 10;
  totalDataCount: number = 0;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  predefinedColors: string[] = [ 'blue', 'green', 'red', 'purple'];
  loading:boolean = false;
  allReport: any;
  allSUbscribeTestSeries: any;
  mediaBaseUrl: string = environment.mediaBaseUrl;
  userId:any;
  expandSet = new Set<String>();
  singleExamAnalytics = {};
  isExpand: boolean = false;
  index: Number = 0;
  constructor(    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,) { }

  ngOnInit(): void {
    this.userDetails = this.value
    this.userId = this.activatedRoute.snapshot.params.id;

    //
    //
    this.getAnalysisReportById();
    this.getUserProfile();
    this.getAllSubscribeTestSeries();
  }
  colorMap: { [key: string]: string } = {};
  getUserProfile(){
    let params: any;
      params = { skip: 0, limit: 0 };
      this.loading = true;
      this.httpRequestService.request('get', `users/${this.userId}`, params).subscribe(
        (result) => {
          this.loading = false;
          this.userDetails = result.data;
          //
          this.totalDataCount = result.totalCount;
        },
        (err) => {
          this.loading = false;
        }
      );
  }
    /* Get all AnalysisReport */
    getAnalysisReportById(skip = 0, sortBy?: any): void {
      let params: any;
      params = { skip : 0, limit: this.pageSize };
      this.loading = true;
      this.httpRequestService.request('get', `answer-sheets/performance-analysis-reports/${this.userId}`, params).subscribe(
        (result) => {
          this.loading = false;
          this.allReport = result.data;
          //
          this.totalDataCount = result.totalCount;
          //
        },
        (err) => {
          this.loading = false;
        }
      );
    }

      /* For Pagination / Sending skip */
  onQueryParamsChange(params: NzTableQueryParams): void {

    //
    const { pageSize, pageIndex} = params;
    this.getAnalysisReportById(pageSize * (pageIndex - 1));
  }
  // get all subscribe test- series
  getAllSubscribeTestSeries(skip = 0, sortBy?: any): void {
    let params: any
    params = { skip : 0, limit: this.pageSize, user: this.userId, success: true };
    this.loading = true;
    this.httpRequestService.request('get', `subscriptions`, params)
      .subscribe(
        (result) => {
          this.loading = false;
          this.allSUbscribeTestSeries = result.data.filter((item: any) => item.success === true)

          this.totalDataCount = result.totalCount;
        },
        (
          err
        ) => {
          this.loading = false;
        }
      );

  }
  getRandomColor(): string {
    const randomIndex = Math.floor(Math.random() * this.predefinedColors.length);
    return this.predefinedColors[randomIndex];
  }
  getColorForItem(item: any): string {
    if (!this.colorMap[item.name]) {
      this.colorMap[item.name] = this.getRandomColor();
    }
    return this.colorMap[item.name];
  }

  onExpandChange(id: string, index: number, checked: boolean): void {
    if (checked) {
      this.isExpand = true;
      this.index = index;
      const params = { skip : 0, limit: this.pageSize  };
      const examId = id;
      this.httpRequestService.request('get', `answer-sheets/performance-analysis/${examId}/${this.userId}`, params)
      .subscribe((result)=>{
        this.singleExamAnalytics = {}
        this.singleExamAnalytics = result.data.scoreAnalysisData;
        this.totalTime = result.data.overAllScore.time
        this.allReport.forEach((element: any, eleIndex: number) => {
          if(eleIndex === index){
            element.extraData = this.singleExamAnalytics;
          }
        });
      })
      // this.expandSet.add(id);
    } else {
      // this.expandSet.delete(id);
      this.isExpand = false;
    }
  }

}
