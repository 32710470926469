import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
  import { Exam } from 'src/app/core/interfaces/exam';
import { ExamCategory } from 'src/app/core/interfaces/exam-category';
import { HttpRequestService } from 'src/app/core/services';

@Component({
  selector: 'app-dashboard-config',
  templateUrl: './dashboard-config.component.html',
  styleUrls: ['./dashboard-config.component.scss']
})
export class DashboardConfigComponent implements OnInit {
  allExams: Exam[] = [];
  isAddOrUpdate:boolean = true
  totalDataCount = 0;
  pageSize = 12;
  pageIndex = 1;
  loading = false;
  searchExamCategory: Subject<string> = new Subject<string>();
  search = '';
  examCategorySearch: string = '';
  allExamCategoryList: ExamCategory[] = [];
  checkAddPermission = false;
  checkUpdatePermission = false;
  selectedExamCategory: ExamCategory | undefined;
  isOpenDetails = false;
  listView = true;
  active: boolean = false;
  deActive: boolean = true;
  isExamComponent: boolean = true;
  constructor(
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.searchExamCategory.pipe(debounceTime(1000)).subscribe((success) => {
      this.search = success;
      this.getAllExamCategory();
    });
    this.getAllExamCategory();
  }
  changeGridView() {
    this.listView = false;
    this.active = true;
    this.deActive = false;
  }
  changeListView() {
    this.listView = true;
    this.active = false;
    this.deActive = true;
  }
  // get allExamCategory
  getAllExamCategory(skip = 0, sortBy?: any): void {
    let params: any;
    params = { skip, limit: this.pageSize };
    if (this.examCategorySearch) {
      params.search = this.examCategorySearch;
    } else {
      delete params.search;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    this.loading = true;
    this.httpRequestService.request('get', 'exam-categories', params).subscribe(
      (result) => {
        this.loading = false;
        this.allExamCategoryList = result.data;
        this.totalDataCount = result.totalCount;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  /* For Pagination / Sending skip */
  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex } = params;
    this.getAllExamCategory(pageSize * (pageIndex - 1));
  }


  /* status change*/
  updateStatus(id: string, status: boolean): void {
    this.httpRequestService
      .request('put', `exams/active-inactive/${id}`, { status: !status })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllExamCategory();
      });
  }

  // search concept
  searchByExamCategory(event: any): void {
    this.searchExamCategory.next(event);
  }
}
