import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditorialsComponent } from './editorials/editorials.component';
import { AddUpdateEditorialComponent } from './add-update-editorial/add-update-editorial.component';

const routes: Routes = [

  {
    path: '',
    component: EditorialsComponent
  },
  {
    path: 'add',
    component: AddUpdateEditorialComponent
  },
  {
    path: 'update/:id',
    component: AddUpdateEditorialComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EditorialRoutingModule { }
