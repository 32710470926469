import { Component, Input, OnInit } from '@angular/core';
import { CommonArrayService } from 'src/app/core/services/common-array.service';
import { environment } from 'src/environments/environment';
import { from } from 'rxjs';
import { groupBy, mergeMap, toArray } from 'rxjs/operators';
import { NzModalCloseComponent, NzModalRef } from 'ng-zorro-antd/modal';
@Component({
  selector: 'app-test-details',
  templateUrl: './test-details.component.html',
  styleUrls: ['./test-details.component.scss'],
})
export class TestDetailsComponent implements OnInit {
  @Input() value = {};
  @Input() isTestSeriesComponent = false;

  testDetails: any;
  difficultyLevelObject: any = {};
  questionTypeObject: any = {};
  examTypeObject: any = {};
  testTypeObject: any = {};
  mediaUploadUrl = environment.mediaBaseUrl;
  time: string = '';
  sectionTime: number = 0;
  questionGroups: any[] = [];
  component: string = 'isFromTest';
  totalQuestions: number = 0;
  constructor(private commonArrayService: CommonArrayService) {}

  ngOnInit(): void {
    this.difficultyLevelObject =
    this.commonArrayService.difficultyLevelObjectByValue;
    this.questionTypeObject = this.commonArrayService.questionTypeObjectByValue;
    this.examTypeObject = this.commonArrayService.examTypeObjectByValue;
    this.testTypeObject = this.commonArrayService.testsTypesObjectByValue;
    this.testDetails = this.value;
    this.groupByParagraphQuestion();
    if(this.testDetails.examType === 'SEQUENTIAL'){
      this.time = this.getHourMinuteFromMinute(this.sectionTime);
    }else{
      this.time = this.getHourMinuteFromMinute(this.testDetails.time);
    }
  }
  getHourMinuteFromMinute(time: any) {
    const hours = Math.floor(parseInt(time) / 60);
    const remainingMinutes = parseInt(time) % 60;
    return (
      (hours ? hours : 0) +
      'h' +
      ' ' +
      (remainingMinutes ? remainingMinutes : '00') +
      'm'
    );
  }
  groupByParagraphQuestion() {
    this.testDetails.section.forEach((item: any) => {
      const sectionQuestion = item.questions.length;
      this.totalQuestions += sectionQuestion;
      this.sectionTime += item.time;
      item.questions.forEach((element: any) => {
        if (element.question && element.question.questionType === 'PARAGRAPH') {
          //emit each perso
          const questionArray = item.questions;
          const source = from(item.questions);
          //group by age
          const example = source
            .pipe
            // groupBy(questionArray => questionArray),
            // return each item in group as array
            // mergeMap(group => group.pipe(toArray()))
            ();
        }
      });
    });
  }
}
