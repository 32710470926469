import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { toNumber } from 'ng-zorro-antd/core/util';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Editorial } from 'src/app/core/interfaces/editorial';
import { HttpRequestService, LocalStorageService } from 'src/app/core/services';
import { CommonService } from 'src/app/core/services/common.service';
import { RejectionReasonComponent } from 'src/app/shared/components/rejection-reason/rejection-reason.component';

@Component({
  selector: 'app-editorials',
  templateUrl: './editorials.component.html',
  styleUrls: ['./editorials.component.scss']
})
export class EditorialsComponent implements OnInit {
  allEditorials: Editorial[] = [];
  allApprovedEditorials: Editorial[] = [];
  isAddOrUpdate: boolean = true;
  totalDataCount = 0;
  totalApprovedDataCount = 0;
  pageSize = 12;
  pageIndex = 1;
  loading = false;
  searchEditorial: Subject<string> = new Subject<string>();
  search = '';
  userRole: string = '';
  subAdminAddedEditorial: Editorial[] = [];
  startDate: any;
  endDate: any;
  dateRange: any;
  predefinedColors: string[] = ['magenta', 'blue', 'green', 'red', 'purple'];
  index: number = 0;
  skipIndex: number = 0;
  // listView = true;
  // isOpenDetails = false;
  // active: boolean = false;
  // deActive: boolean = true;
  // isSubjectComponent: boolean = true;
  getRandomColor(): string {
    const randomIndex = Math.floor(
      Math.random() * this.predefinedColors.length
    );
    // Use setTimeout to defer execution until the next tick

    return this.predefinedColors[randomIndex];
  }
  snapShootIndex: number = 0
  constructor(
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private modalService: NzModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.snapShootIndex = toNumber(this.activatedRoute.snapshot.queryParams.index) ;
    this.userRole = this.localStorageService.getItem('user').role;
    if(this.snapShootIndex){
      this.changeIndex(this.snapShootIndex)
    }
  }

  // on page load functions
  ngOnInit(): void {
    this.searchEditorial.pipe(debounceTime(1000)).subscribe((success: string) => {
      this.search = success;
      this.getallEditorials(this.index);
      this.getRandomColor()
    });
  }
  onChangeDateRange(date: any) {
    this.startDate = date[0];
    this.endDate = date[1];
    this.getallEditorials(this.index);
  }
  changeIndex(data: any) {
    this.router.navigate([], { queryParams: {index: data } });
    this.pageSize = 12;
    this.pageIndex = 1;
    this.allApprovedEditorials = [];
    this.allEditorials = [];
    this.index = data
    this.getallEditorials();
  }
  /* Get all subjects */
  getallEditorials(index: number = 0, skip = 0, sortBy?: any): void {
    index = index? index : this.index
    let params: any;
    params = { skip: this.skipIndex, limit: this.pageSize };

    if (this.search) {
      params.search = this.search;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if (this.startDate && this.endDate) {
      params.startDate = this.startDate;
      params.endDate = this.endDate;
    } else {
      delete params.startDate;
      delete params.endDate;
    }
    if (index === 0) {
      params.approveStatus = 'approved';
    } else if (index === 1 && this.userRole === 'admin') {
      params.approveStatus = 'pending';
    } else if (index === 1 && this.userRole === 'subAdmin') {
      params.approveStatus = ['pending', 'rejected'];
    } else {
      delete params.approvedStatus;
    }
    this.loading = true;
    this.httpRequestService.request('get', 'editorials', params).subscribe(
      (result) => {
        this.loading = false;
        const data: Editorial[] = result.data;
        if (index === 0) {
          this.allApprovedEditorials = data;
          this.totalApprovedDataCount = result.totalCount;
        } else {
          this.allEditorials = data;
          this.totalDataCount = result.totalCount;
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }
  // deleteAffair(id: any) {
  //   this.httpRequestService
  //     .request('delete', `current-affairs/${id}`)
  //     .subscribe((result: any) => {
  //       this.notificationService.success(
  //         '',
  //         'Current Affair Deleted Successfully'
  //       );
  //       this.getallEditorials(this.index);
  //     });
  // }
  /* For Pagination / Sending skip */
  onQueryParamsChange(params: NzTableQueryParams): void {
    this.allApprovedEditorials = [];
    this.allEditorials = [];
    const { pageSize, pageIndex } = params;
    this.skipIndex = pageSize * (pageIndex - 1)
    this.getallEditorials(this.index, pageSize * (pageIndex - 1));
  }
  /* For Pagination / Sending skip for grid view*/
  /* Status change*/
  updateStatus(id: string, status: boolean): void {
    this.httpRequestService
      .request('put', `editorials/active-inactive/${id}`, {
        status: !status,
      })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getallEditorials(this.index);
      });
  }
  updatePendingStatus(id: string, pendingStatus: string) {
    this.httpRequestService
      .request('put', `editorials/approved-rejected/${id}`, {
        approveStatus: 'approved',
      })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getallEditorials(this.index);
      });
  }
  openRejectionReason(id: string, pendingStatus: string) {
    const rejectionReason = this.modalService.create({
      nzTitle: 'Add A Reason',
      nzContent: RejectionReasonComponent,
      nzWidth: '800px',
      nzFooter: null,
      nzComponentParams: {
        updateId: id,
        component: 'editorials',
      },
      nzCancelText: null,
      nzOkText: null,
    });

    const questionService = rejectionReason.afterClose.subscribe(
      (data: any) => {
        this.getallEditorials(this.index);
      }
    );
  }

  // Search affairs
  searchEditorials(event: any): void {
    this.searchEditorial.next(event);
  }
}

