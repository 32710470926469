import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paragraph-question-details',
  templateUrl: './paragraph-question-details.component.html',
  styleUrls: ['./paragraph-question-details.component.scss']
})
export class ParagraphQuestionDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
