import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuestionRoutingModule } from './question-routing.module';
import { AddUpdateQuestionComponent } from './add-update-question/add-update-question.component';
import { QuestionsComponent } from './questions/questions.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { QuestionDetailsComponent } from './question-details/question-details.component';
// import { ParagraphQuestionComponent } from './paragraph-question/paragraph-question.component';
import { AddUpdateParagraphQuestionComponent } from './add-update-paragraph-question/add-update-paragraph-question.component';
import { ParagraphQuestionDetailsComponent } from './paragraph-question-details/paragraph-question-details.component';
import { AddUpdateBulkQuestionComponent } from './add-update-bulk-question/add-update-bulk-question.component';
import { QuestionBulkApproveComponent } from './question-bulk-approve/question-bulk-approve.component';

@NgModule({
  declarations: [
    QuestionsComponent,
    QuestionDetailsComponent,
    // ParagraphQuestionComponent,
    AddUpdateParagraphQuestionComponent,
    ParagraphQuestionDetailsComponent,
    AddUpdateQuestionComponent,
    AddUpdateBulkQuestionComponent,
    QuestionBulkApproveComponent,
  ],
  imports: [CommonModule, QuestionRoutingModule, SharedModule ],
  exports: [QuestionDetailsComponent],
})
export class QuestionModule {}
