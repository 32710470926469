import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlanRoutingModule } from './plan-routing.module';
import { AddUpdatePlanComponent } from './add-update-plan/add-update-plan.component';
import { PlanDetailsComponent } from './plan-details/plan-details.component';
import { PlansComponent } from './plans/plans.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    AddUpdatePlanComponent,
    PlanDetailsComponent,
    PlansComponent
  ],
  imports: [
    CommonModule,
    PlanRoutingModule,
    SharedModule

  ]
})
export class PlanModule { }
