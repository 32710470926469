import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExamsCategoriesComponent } from './exams-categories/exams-categories.component';
import { AddUpdateExamsCategoryComponent } from './add-update-exams-category/add-update-exams-category.component';

const routes: Routes = [
  {
    path: '',
    component: ExamsCategoriesComponent
  },
  {
    path: 'add',
    component: AddUpdateExamsCategoryComponent
  },
  {
    path: 'update/:id',
    component: AddUpdateExamsCategoryComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExamCategoryRoutingModule { }
