import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HttpRequestService } from 'src/app/core/services';
import { CommonArrayService } from 'src/app/core/services/common-array.service';
import { environment } from 'src/environments/environment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Test } from 'src/app/core/interfaces/test';
import { ExamCategory } from 'src/app/core/interfaces/exam-category';
import { LocalArray } from 'src/app/core/interfaces/local-array';
import { Exam } from 'src/app/core/interfaces/exam';
import { TestSeries } from 'src/app/core/interfaces/test-series';
// import { CkEditorConfigService } from 'src/app/core/services/ck-editor-config.service';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ClassicEditor } from '../../../../../node_modules/ckeditor5';
import { CkEditorConfigService } from 'src/app/core/services/ck-editor-config.service';
@Component({
  selector: 'app-add-update-test-series',
  templateUrl: './add-update-test-series.component.html',
  styleUrls: ['./add-update-test-series.component.scss'],
})
export class AddUpdateTestSeriesComponent implements OnInit {
  addUpdateTestSeriesForm: FormGroup;
  idForUpdate: string;
  buttonLoading = false;
  imageLoading = false;
  allExamCategories: ExamCategory[] = [];
  checkAddPermission = false;
  checkUpdatePermission = false;
  search = '';
  searchExam: Subject<string> = new Subject<string>();
  searchExamCategory: Subject<string> = new Subject<string>();
  allTestTypes: LocalArray[] = [];
  allLevels: LocalArray[] = [];
  time: Date | null = null;
  defaultOpenValue: any;
  selectedTests: Test[] = [];
  mediaBasePath: string = '';
  loading: boolean = false;
  filterTests: Test[] = [];
  allTests: Test[] = [];
  totalDataCount: number = 0;
  allExams: Exam[] = [];
  switchValue: boolean = false;
  mediaBaseUrl = environment.mediaBaseUrl;
  mediaUploadUrl = environment.apiBaseUrl + '/api/media';
  examSearch: string = '';
  examCategorySearch: string = '';
  topicSearch: string = '';
  newSelectedTests: Test[] = [];
  pageIndex: number = 1;
  pageSize: number = 10;
  limit: boolean = false;
  name = 'test';
  log: string = '';
  selectedExam: string = '';
  hasNextPage: boolean = true;
  public Editor = ClassicEditor;
  public ckeConfig: any 
  tabIndex: number = 0
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private msg: NzMessageService,
    private commonArrayService: CommonArrayService,
    private ckEditorService: CkEditorConfigService
  ) {
    // this.mycontent = `<p>My html content</p>`;
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.tabIndex = this.activatedRoute.snapshot.queryParams.index;
    this.addUpdateTestSeriesForm = this.fb.group({
      name: [null, [Validators.required]],
      exam: ['', [Validators.required]],
      examCategory: ['', [Validators.required]],
      status: [true],
      difficultyLevel: [null],
      price: [0],
      description: [null],
      extraDescription: [null],
      isPaid: [false],
      durationTime: [null],
      tests: [[]],
      image: [null],
    });
  }
   ngOnInit(){
    this.ckeConfig = this.ckEditorService.getConfig();
    this.allTestTypes = this.commonArrayService.testsTypes;
    this.allLevels = this.commonArrayService.difficultyLevel;
    // this.getAllChapters();

    if (this.idForUpdate) {
      this.getTestSeriesById();
    }else{
    this.getAllTests();
    }
    this.searchExam.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.examSearch = success;
      this.getAllExams();
    });
    this.searchExamCategory
      .pipe(debounceTime(1000))
      .subscribe((success: any) => {
        this.examCategorySearch = success;
        this.getAllExamsCategory();
      });
    this.getAllExams();
    this.getAllExamsCategory();
  }
  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.selectedTests,
      event.previousIndex,
      event.currentIndex
    );
    this.newSelectedTests = [...this.selectedTests];
  }
  //  send auth in headers //
  customRequestHeaders = () => {
    return { Authorization: `Bearer ${localStorage.getItem('token')}` };
  };
  clickChange(data: any) {
    this.switchValue = data;
  }
  /* Get all exam */
  getAllExams(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved',
    };
    if (this.examSearch) {
      params.search = this.examSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'exams', params).subscribe(
      (result: any) => {
        this.allExams = result.data;
      },
      (err: any) => {}
    );
  }
  /* Get all exam-category */
  getAllExamsCategory(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved',
    };
    if (this.examCategorySearch) {
      params.search = this.examCategorySearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'exam-categories', params).subscribe(
      (result: any) => {
        this.allExamCategories = result.data;
      },
      (err: any) => {}
    );
  }
  // getAllFilteredTests(): void {
  //   this.allTests = [];
  //   this.getAllTests(0);
  // }
  onChangeDifficultyLevel(value: string, skip = 0): void {
    this.filterSectionValues();
  }
  getAllTests(skip = 0, sortBy?: any): void {

    // this.allTests = [];
    let params: any;
    params = {
      skip,
      limit: this.pageSize,
      status: true,
      approveStatus: 'approved',
    };
    if (
      this.addUpdateTestSeriesForm &&
      this.addUpdateTestSeriesForm.value &&
      this.addUpdateTestSeriesForm.value.exam
    ) {
      params.exam = this.addUpdateTestSeriesForm.value.exam;
    }else{
      delete params.exam
    }
    if (this.addUpdateTestSeriesForm.value.difficultyLevel) {
      params['difficultyLevel'] =
        this.addUpdateTestSeriesForm.value.difficultyLevel;
    } else {
      delete params.difficultyLevel;
    }

    if (this.search) {
      params.search = this.search;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }

    this.loading = true;
    this.httpRequestService.request('get', 'tests', params).subscribe(
      (result) => {
        this.hasNextPage = result.meta.hasNextPage;
        let data: Test[] = result.data;
        this.allTests.push(...data);
        this.totalDataCount = result.totalCount;
        this.filterSectionValues();
        this.loading = false;

      },
      (err) => {
        this.loading = false;
      }
    );
  }
  // Question remove
  removeTests(): void {
    const removedItems = this.selectedTests.filter((x) => x.isChecked);
    let allItemsToBeRemoved: any[] = [...removedItems];
    this.selectedTests = this.selectedTests.filter(
      (item: any) =>
        !allItemsToBeRemoved.map((rmItem: any) => rmItem._id).includes(item._id)
    );
    this.newSelectedTests = [...this.selectedTests];
    this.filterSectionValues();
  }
  // Add Normal question
  addTests(): void {
    this.selectedTests = [
      ...this.selectedTests,
      ...this.filterTests
        .filter((x: any) => x.isChecked)
        .map((x: any, index: number) => ({
          ...x,
          isChecked: false,
          questionType: 'NORMAL',
          key: index,
        })),
    ];
    this.newSelectedTests = [...this.selectedTests];

    this.filterSectionValues();
  }
  filterSectionValues() {
    this.filterTests = this.allTests
      .filter((item: any) => {
        return !this.selectedTests.map((q: any) => q._id).includes(item._id);
      })
      .map((item: any) => ({ ...item, isChecked: false, isOpen: true }));
    const uniqueData: any = Array.from(new Set(this.filterTests.map(item => item._id)))
    .map(id => this.filterTests.find(obj => obj._id === id ));
    this.filterTests = [...uniqueData];
  }
  /* Get single concept details by Id */
   getTestSeriesById() {
    try {
      const data = this.httpRequestService
      .request('get', `test-series/${this.idForUpdate}`).subscribe((data)=> {
        const singleData: TestSeries = data.data;
        this.addUpdateTestSeriesForm.patchValue({
          name: singleData.name,
          exam: singleData.exam._id,
          description: singleData.description,
          extraDescription: singleData.extraDescription,
          price: singleData.price,
          durationTime: singleData.durationTime,
          isPaid: singleData.isPaid,
          image: singleData.image,
          examCategory: singleData.examCategory._id,
        });
        this.selectedTests = [
          ...singleData.tests.map((x: any) => ({
            ...x.test,
            isOpen: x.isOpen,
          })),
        ];
        this.newSelectedTests = [...this.selectedTests];
        this.getAllTests();
      });

    } catch (error) {

    }

  }

  /* Submit concept form */
  submit(): void {
    if (!this.addUpdateTestSeriesForm.valid) {
      this.markFormGroupTouched(this.addUpdateTestSeriesForm);
    } else {
      if (this.idForUpdate) {
        this.addOrUpdateTestSeries(
          'put',
          `test-series/${this.idForUpdate}`,
          'Test-Series Successfully Updated'
        );
      } else {
        this.addOrUpdateTestSeries(
          'post',
          'test-series',
          'Test-Series Added Successfully '
        );
      }
    }
  }

  /* Add Or Edit Concept */
  addOrUpdateTestSeries(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    let testData: any = [];
    this.selectedTests.forEach((data: any) => {
      testData.push({
        test: data._id,
        isOpen: data.isOpen,
      });
    });
    this.addUpdateTestSeriesForm.patchValue({
      tests: [...testData],
    });
    if (this.selectedTests && !this.selectedTests.length) {
      this.notificationService.error('', 'Add some tests');
      return;
    }
    this.buttonLoading = true;
    const finalData: TestSeries = this.addUpdateTestSeriesForm.value;
    if (finalData.isPaid === false) {
      finalData.price = 0;
      finalData.durationTime = 0;
    }
    this.httpRequestService
      .request(requestMethod, requestURL, finalData)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          this.router.navigate(['/main/test-series'], { queryParams: {index: this.tabIndex } });
          this.buttonLoading = false;
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          } else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }
  // image Upload
  UploadImageFile(
    info: { file: NzUploadFile },
    FormControl: string,
    index: number
  ) {
    switch (info.file.status) {
      case 'uploading':
        this.imageLoading = true;
        break;
      case 'done':
        this.imageLoading = false;
          this.addUpdateTestSeriesForm.patchValue({
            image: info.file.response.data.path,
          });
        break;
      case 'error':
        this.msg.error('Network error');
        this.imageLoading = false;
        break;
    }
  }

  beforeRelatedMediaUpload = (
    file: NzUploadFile,
    fileList: NzUploadFile[]
  ): Observable<any> => {
    return new Observable((observer: Observer<boolean>) => {
      const isImage =
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/JPG' ||
        file.type === 'image/JPEG' ||
        file.type === 'image/PNG';
      if (!isImage) {
        this.msg.error('You can only upload image file!');
        observer.complete();
        return;
      }
      const isLt3M = file.size ? file.size / 1024 / 1024 < 3 : false;
      if (!isLt3M) {
        this.msg.error('Image must smaller than 3MB!');
        observer.complete();
        return;
      }
      observer.next(isImage && isLt3M);
      observer.complete();
    });
  };
  removeImage(path: string): void {
    let params: any;
    params = { path: path };
    this.addUpdateTestSeriesForm.patchValue({
      image: null,
    });
    this.httpRequestService.request( 'post', 'media/delete-media', params ).subscribe((result: any)=>{
      if(result){
        this.notificationService.success('', 'Image Deleted Successfully');
      }
    });
  }

  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }
  /* For Pagination / Sending skip */
  testPagination(): void {
    this.pageIndex = this.pageIndex + 1;
    this.getAllTests(this.pageSize * (this.pageIndex - 1));
  }
  // seach Concept
  searchExamForDropdown(event: any): void {
    this.searchExam.next(event);
  }
  searchExamCategoryForDropdown(event: any): void {
    this.searchExamCategory.next(event);
  }
// (ngModelChange)="examChange($event)"
  examChange(exam: any) {
    if(this.addUpdateTestSeriesForm && this.addUpdateTestSeriesForm.value && this.addUpdateTestSeriesForm.value.exam){
      this.getAllTests();
      this.allTests = [];
    }

  }
}
