import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GlobalService } from 'src/app/core/services/global.service';

@Component({
  selector: 'app-mathjax',
  templateUrl: './mathjax.component.html',
  styleUrls: ['./mathjax.component.scss']
})
export class MathjaxComponent implements OnInit, OnChanges {

  @Input() content = '';

  constructor(public gs: GlobalService) { }
  mathJaxObject: any;

  // on Change
  ngOnChanges(changes: SimpleChanges): void {
   if (changes.content) {
    this.renderMath();
    }
  }

  // Render mathematical/ Chemical equation
  renderMath(): void{
  this.mathJaxObject  = this.gs.nativeGlobal().MathJax ;
  const angObj = this;
  setTimeout(() => {
  angObj.mathJaxObject && angObj.mathJaxObject.Hub && angObj.mathJaxObject.Hub.Queue(['Typeset', angObj.mathJaxObject.Hub], 'mathContent');

  }, 1000);
  }

  // Load mathematical configuration
  loadMathConfig(): void{
      this.mathJaxObject  = this.gs.nativeGlobal().MathJax ;
      this.mathJaxObject && this.mathJaxObject.Hub && this.mathJaxObject.Hub.Config({
      showMathMenu: false,
      tex2jax: {inlineMath: [['$', '$'], ['\\(', '\\)']]},
      menuSettings: { zoom: 'Double-Click', zscale: '150%' },
      CommonHTML: { linebreaks: { automatic: true } },
      'HTML-CSS': { linebreaks: { automatic: true } },
             SVG: { linebreaks: { automatic: true } }
    });
  }

  // on page load functions
  ngOnInit(): void{
     this.loadMathConfig();
     this.renderMath();
  }


}
