<nz-card>
    <div nz-row>
        <form [formGroup]="addUpdateSubAdminPasswordForm" class="width-100">
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" *ngIf="!idForUpdate">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="password" nzRequired>Password</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your Password!">
                            <nz-input-group [nzAddOnAfter]="passwordIconTpl">
                                <input [type]="!viewPassword ? 'password' : 'text'" nz-input formControlName="password"
                                    placeholder="Password" (ngModelChange)="updateConfirmValidator()" />
                            </nz-input-group>
                            <ng-template #passwordIconTpl>
                                <i nz-icon [nzType]="!viewPassword ? 'eye' : 'eye-invisible'" nzTheme="outline"
                                    (click)="viewPassword = !viewPassword"></i>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" *ngIf="!idForUpdate">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="reEnterPassword" nzRequired>Confirm Password</nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="errorTpl">
                            <nz-input-group [nzAddOnAfter]="reEnterpasswordIconTpl">
                                <input [type]="!viewReEnterPassword ? 'password' : 'text'" nz-input
                                    formControlName="reEnterPassword" placeholder="Re-Enter Password" />
                                <ng-template #reEnterpasswordIconTpl>
                                    <i nz-icon [nzType]="!viewReEnterPassword ? 'eye' : 'eye-invisible'" nzTheme="outline"
                                        (click)="viewReEnterPassword = !viewReEnterPassword"></i>
                                </ng-template>
                            </nz-input-group>
                            <ng-template #errorTpl let-control>
                                <ng-container *ngIf="control.hasError('required')">
                                    Please confirm your password
                                </ng-container>
                                <ng-container *ngIf="control.hasError('confirm')">
                                    Password and Confirm Password must be match!
                                </ng-container>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div nz-row nzJustify="end">
                <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()" class="submitoraddbutton">
                    {{idForUpdate? 'Update':'Submit'}}
                </button>
            </div>
        </form>
    </div>
</nz-card>