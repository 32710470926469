<div class="site-page-header-ghost-wrapper">
    <nz-page-header [nzGhost]="false">
        <nz-page-header-title class="ml4">Sub Admins </nz-page-header-title>
        <nz-page-header-extra class="mr4">
            <button nz-button nzType="primary" [routerLink]="['add']" routerLinkActive="router-link-active"
                class="submitoraddbutton">
                Add Sub Admins
            </button>
        </nz-page-header-extra>
    </nz-page-header>
</div>
<div nz-row nzJustify="end" class="mt4">
    <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24" class="mb5">
        <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input placeholder="Search by Sub-Admins name" ngModel
                (ngModelChange)="searchSubAdmins($event)" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <i nz-icon nzType="search"></i>
        </ng-template>
    </div>
</div>
<nz-table #subjectTbl [nzData]="allSubAdmins" [nzLoading]="loading" [nzTotal]="totalDataCount" [(nzPageSize)]="pageSize"
    [nzPageIndex]="pageIndex" [nzFrontPagination]="false" (nzQueryParams)="onQueryParamsChange($event)"
    nzShowSizeChanger [nzScroll]="{ x: '900px' }" *ngIf="listView">
    <thead>
        <tr>
            <th nzColumnKey="name" [nzSortFn]="true">Sub Admin Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th nzWidth="150px">Created On</th>
            <th nzWidth="100px">Status</th>
            <!-- <th nzWidth="150px" class="center">Password Update</th> -->
            <th class="text-right" nzWidth="150px">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of subjectTbl.data; index as i">
            <td><span *ngIf="data.name"> {{ data.name }} </span></td>
            <td><span *ngIf="data.email "> {{ data.email }}</span></td>
            <td><span *ngIf="data.mobile "> {{ data.mobile }}</span></td>
            <td><span *ngIf="data.createdAt">{{ data.createdAt | date : "mediumDate" }}</span></td>
            <td><span class="{{data.status? 'active' : 'inactive' }} status"></span></td>
            <!-- <td class="center"> </td> -->
            <td class="action-buttons">
                <a nz-button  nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
                    [routerLink]="['update', data._id]" routerLinkActive="router-link-active"><i nz-icon nzType="form"
                        nzTheme="outline"></i></a>
                <a class="action-button"   nz-button nz-popconfirm
                    nzPopconfirmTitle="Are you sure {{data.status ? 'deActive' : 'active'}} this Sub Admin?"
                    (nzOnConfirm)="updateStatus(data._id, data.status)"
                    nzTooltipTitle="{{data.status ? 'Deactive' : 'Active'}}" nzTooltipPlacement="topRight" nz-tooltip><i
                        nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>
                <a class="action-button" nz-button nz-popconfirm  
                nzPopconfirmTitle="Are you sure update password of this Sub Admin?"
                    (nzOnConfirm)="openPassword(data._id)"
                    nzTooltipTitle="update Password" nzTooltipPlacement="topRight" nz-tooltip><i nz-icon nzType="edit" nzTheme="outline" ></i></a>
                        <!-- <button nz-button  >></button> -->
            </td>
        </tr>
    </tbody>
</nz-table>