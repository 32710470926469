import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Subjects } from 'src/app/core/interfaces/subjects';
import { HttpRequestService } from 'src/app/core/services';
import { ChangePasswordComponent } from 'src/app/shared/components/change-password/change-password.component';

@Component({
  selector: 'app-sub-admins',
  templateUrl: './sub-admins.component.html',
  styleUrls: ['./sub-admins.component.scss']
})
export class SubAdminsComponent implements OnInit {
  allSubAdmins: any[] = [];
  isAddOrUpdate:boolean = true;
  totalDataCount = 0;
  pageSize = 12;
  pageIndex = 1;
  loading = false;
  searchSubAdmin: Subject<string> = new Subject<string>();
  search = '';
  listView = true;
  isOpenDetails = false;
  active: boolean = false;
  deActive: boolean = true;
  isSubjectComponent: boolean = true;
  skipIndex: number = 0;
  constructor(
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private modalService: NzModalService
  ) {}

  // on page load functions
  ngOnInit(): void {
    this.searchSubAdmin.pipe(debounceTime(1000)).subscribe((success) => {
      this.search = success;
      this.getAllSubAdmins();
    });
  }
  openPassword(id: string){
    const updatePassword = this.modalService.create({
      nzTitle: 'Update Password',
      nzContent: ChangePasswordComponent,
      nzWidth: '800px',
      nzFooter: null,
      nzComponentParams: {
        updateId: id,
        component: 'auth-admins',
      },
      nzCancelText: null,
      nzOkText: null,
    });

  const questionService = updatePassword.afterClose.subscribe(
    (data: any) => {
      //;
      // if (data) {
      // this.notificationService.success("", 'password Update SuccessFully')
      // }
    }
  );
  }
  /* Get all subjects */
  getAllSubAdmins(skip = 0, sortBy?: any): void {
    let params: any;
    params = { skip: this.skipIndex, limit: this.pageSize , role: 'subAdmin'};
    if (this.search) {
      params.search = this.search;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    this.loading = true;
    this.httpRequestService.request('get', 'auth-admins', params).subscribe(
      (result) => {
        this.loading = false;
        this.allSubAdmins = result.data;
       
        this.totalDataCount = result.totalCount;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  /* For Pagination / Sending skip */
  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex} = params;
    this.skipIndex = pageSize * (pageIndex - 1)
    this.getAllSubAdmins(pageSize * (pageIndex - 1));
  }

  /* Status change*/
  updateStatus(id: string, status: boolean): void {
    this.httpRequestService
      .request('put', `auth-admins/active-inactive/${id}`, { status: !status })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllSubAdmins();
      });
  }
  // Seach subject
  searchSubAdmins(event: any): void {
    this.searchSubAdmin.next(event);
  }
}

