<div class="site-page-header-ghost-wrapper">
  <nz-page-header [nzGhost]="false">
    <nz-page-header-title class="ml4">Test Series </nz-page-header-title>
    <nz-page-header-extra class="mr4">
      <button nz-button nzType="primary" [routerLink]="['add']" [queryParams]="{index: index}" routerLinkActive="router-link-active"
        class="submitoraddbutton">
        Add Test Series
      </button>
    </nz-page-header-extra>
  </nz-page-header>
</div>
<div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }" class="mt4">
  <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
        <nz-form-label nzFor="paid">Search By Paid Or Unpaid </nz-form-label>
        <!-- <nz-switch [(ngModel)]="switchValue"  (ngModelChange)="getAllTestSeries()"></nz-switch> -->
        <nz-select nzAllowClear nzShowSearch nzServerSearch id="selectedPaidOrUnPaid" [(ngModel)]="selectedPaidOrUnPaid"
          nzPlaceHolder="Select Paid Or Unpaid" (ngModelChange)="getAllTestSeries()">
          <nz-option *ngFor="let item of allPaidOrUnPaid" nzValue="{{ item.value }}"
            nzLabel="{{ item.label }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzErrorTip="Please select your subject!">
        <nz-form-label nzFor="chapter">Search By Exam Category</nz-form-label>
        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" [(ngModel)]="selectedExamCategory"
          nzPlaceHolder="Select exam-category" (nzOnSearch)="searchExamCategoryDropdown($event)"
          (ngModelChange)="onChangeExamCategory()">
          <nz-option *ngFor="let item of allExamCategories" nzValue="{{ item._id }}"
            nzLabel="{{ item.categoryName }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzErrorTip="Please select your chapter!">
        <nz-form-label nzFor="chapter">Search By Exam</nz-form-label>
        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" [(ngModel)]="selectedExam"
          nzPlaceHolder="Select exam" (nzOnSearch)="searchExamDropdown($event)" (ngModelChange)="onChangeExam()">
          <nz-option *ngFor="let item of allExams" nzValue="{{ item._id }}" nzLabel="{{ item.examName }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24" class="mb5">
    <nz-form-label nzFor="chapter">Search</nz-form-label>
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input type="text" nz-input placeholder="Search by Test-series name" ngModel
        (ngModelChange)="searchQuestions($event)" />
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div>
</div>
<div nz-row nzJustify="end" class="mb2">
  <nz-button-group class="ml3">
    <button nz-button [nzType]="!active && deActive?'primary':'link'" (click)="changeListView()"> <span
        class="icon-size" nz-icon nzType="ordered-list" nzTheme="outline"></span></button>
    <button nz-button [nzType]="active && !deActive?'primary':'link'" (click)="changeGridView() "><span
        class="icon-size action-button" nz-icon nzType="appstore" nzTheme="outline"></span></button>
  </nz-button-group>
</div>
<nz-tabset (nzSelectedIndexChange)="changeIndex($event)" [nzSelectedIndex]="index" *ngIf="listView && !active && deActive">
  <nz-tab nzTitle="Approved Test Series ({{approvedTestSeriesCount}})">
    <nz-table #QuestionstTbl [nzData]="allApprovedTestSeries" [nzLoading]="loading" [nzTotal]="totalApprovedDataCount"
      [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex" [nzFrontPagination]="false" nzShowSizeChanger
      [nzScroll]="{ x: '900px' }" *ngIf="listView && index === 0 " (nzQueryParams)="onQueryParamsChange($event)">
      <thead>
        <tr>
          <th>Test-series Name</th>
          <th>Total Test</th>
          <th>Exam</th>
          <th>Status</th>
          <th nzWidth="150px" class="text-right">action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of QuestionstTbl.data; index as i">

          <td class="{{ data.name != null ? 'description' : '' }}">
            <span *ngIf="data.name">
              {{data.name}}
            </span>
          </td>
          <td>
            <span *ngIf="data.tests && data.tests.length">
              {{data.tests.length}}
            </span>

          </td>
          <td>
            <span *ngIf="data.exam.examName">
              {{data.exam.examName}}
            </span>
          </td>
          <td>
            <span class="{{ data.status ? 'active' : 'inactive' }} status"></span>
          </td>
          <td class="action-buttons">
            <a nz-button nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
              [routerLink]="['update', data._id]" [queryParams]="{index: index}" routerLinkActive="router-link-active"><i nz-icon nzType="form"
                nzTheme="outline"></i></a>
            <a nz-button nzTooltipTitle="Details" nzTooltipPlacement="topCenter" nz-tooltip [nzLoading]="data.loading"
              (click)="openTestSeriesDetails(data)" routerLinkActive="router-link-active"><i nz-icon nzType="eye"
                nzTheme="outline"></i></a>

            <a *ngIf="userRole !== 'subAdmin'" class="action-button " nz-button nz-popconfirm nzPopconfirmTitle="Are you sure {{
                    data.status ? 'deActive' : 'active'
                }} this Task?" (nzOnConfirm)="updateStatus(data._id, data.status)"
              nzTooltipTitle="{{ data.status ? 'deActive' : 'Active' }}" nzTooltipPlacement="topRight" nz-tooltip><i
                nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
  <nz-tab nzTitle="Un-approved  Test Series ({{unApprovedTestSeriesCount}})">
    <nz-table #QuestionstTbl [nzData]="allTestSeries" [nzLoading]="loading" [nzTotal]="totalDataCount"
      [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex" [nzFrontPagination]="false" nzShowSizeChanger
      [nzScroll]="{ x: '900px' }" *ngIf="listView && index === 1" (nzQueryParams)="onQueryParamsChange($event)">
      <thead>
        <tr>
          <th>Test-series Name</th>
          <th>Total Test</th>
          <th>Exam</th>
          <th>Created By</th>
          <th>Rejected Reason</th>

          <th>Status</th>
          <th nzWidth="150px" class="text-right">action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of allTestSeries; index as i">

          <td class="{{ data.name != null ? 'description' : '' }}">
            <span *ngIf="data.name">
              {{data.name}}
            </span>
          </td>
          <td>
            <span *ngIf="data.tests && data.tests.length">
              {{data.tests.length}}
            </span>

          </td>
          <td>
            <span *ngIf="data.exam.examName">
              {{data.exam.examName}}
            </span>
          </td>
          <td><span *ngIf="data.createdBy?.role === 'subAdmin'">
              {{ data?.createdBy?.name }}
            </span></td>
          <td><span *ngIf="data.rejectionReason" class="ellipsis">{{data.rejectionReason}}</span></td>
          <td>
            <nz-tag
              [nzColor]="data.approveStatus === 'approved' ? 'green':  data.approveStatus === 'pending'? 'lime':'volcano' ">
              {{data.approveStatus}}

            </nz-tag>
          </td>
          <td class="action-buttons">
            <a nz-button nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
              [routerLink]="['update', data._id]" [queryParams]="{index: index}" routerLinkActive="router-link-active"><i nz-icon nzType="form"
                nzTheme="outline"></i></a>

            <a *ngIf="userRole !== 'subAdmin'" class="action-button" nz-button nz-popconfirm
              nzPopconfirmTitle="Are you sure {{data.status ? 'deActive' : 'active'}} this Test Series?"
              (nzOnConfirm)="updateStatus(data._id, data.status)"
              nzTooltipTitle="{{data.status ? 'Deactive' : 'Active'}}" nzTooltipPlacement="topRight" nz-tooltip><i
                nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>

            <a *ngIf="userRole !== 'subAdmin' " class="action-button top-margin" nz-button nz-popconfirm
              nzPopconfirmTitle="Are you sure Approved this Test Series?"
              (nzOnConfirm)="updatePendingStatus(data._id, data.approveStatus)" nzTooltipTitle="{{'Approve'}}"
              nzTooltipPlacement="topRight" nz-tooltip><i nz-icon nzType="file-done" nzTheme="outline"></i></a>

            <a *ngIf="userRole !== 'subAdmin' && data.approveStatus !== 'rejected'"
              class="action-button top-margin delete" nz-button nz-popconfirm
              nzPopconfirmTitle="Are you sure Reject this Test Series?"
              (nzOnConfirm)="openRejectionReason(data._id, data.approveStatus)" nzTooltipTitle='Reject'
              nzTooltipPlacement="topRight" nz-tooltip><i nz-icon [nzType]="'close-circle'" nzTheme="fill"></i></a>

          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
</nz-tabset>

<div *ngIf=" !listView">
  <app-grid-view [list]="allApprovedTestSeries" (index)="changeIndex($event)" [isOpenDetails]="isOpenDetails"
    [unApprovedList]="allTestSeries" [isTestSeriesComponent]="isTestSeriesComponent" [loading]=loading> </app-grid-view>
  <div class="pagination">
    <nz-pagination [nzPageIndex]="pageIndex" [nzTotal]="totalDataCount" nzShowSizeChanger [(nzPageSize)]="pageSize"
      (nzPageIndexChange)="onQueryParamsChangeForGridView($event)"
      (nzPageSizeChange)="onQueryPageSizeChangeForGridView($event)"></nz-pagination>
  </div>
</div>