import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-plan-details',
  templateUrl: './plan-details.component.html',
  styleUrls: ['./plan-details.component.scss']
})
export class PlanDetailsComponent implements OnInit {
  @Input() value = {};
  planDetails: any;
  constructor(private router: Router,
    private drawerReference:NzDrawerRef, ) { }

  ngOnInit(): void {
    this.planDetails = this.value
  }
  updatePlan(){
    this.drawerReference.close();
    this.router.navigate(['/','main', 'plan', 'update', this.planDetails._id]);
  }
}
