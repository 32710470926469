import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddUpdateTestSeriesComponent } from './add-update-test-series/add-update-test-series.component';
import { TestSeriesComponent } from './test-series/test-series.component';

const routes: Routes = [
  {
    path: '',
    component: TestSeriesComponent
  },
  {
    path: 'add',
    component: AddUpdateTestSeriesComponent
  },
  {
    path: 'update/:id',
    component: AddUpdateTestSeriesComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TestSeriesRoutingModule { }
