import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  $addBulkQuestion: Subject<any> = new Subject<any>();
  $addQuestion: Subject<any> = new Subject<any>();
  $addParagraphQuestion: Subject<any> = new Subject<any>();
  $rejectionReason: Subject<any> = new Subject<any>();

  constructor() {}
}
