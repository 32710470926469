import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TestSeriesRoutingModule } from './test-series-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddUpdateTestSeriesComponent } from './add-update-test-series/add-update-test-series.component';
import { TestSeriesComponent } from './test-series/test-series.component';
import { TestSeriesDetailsComponent } from './test-series-details/test-series-details.component';


@NgModule({
  declarations: [
    AddUpdateTestSeriesComponent,
    TestSeriesComponent,
    TestSeriesDetailsComponent
  ],
  imports: [
    CommonModule,
    TestSeriesRoutingModule,
    SharedModule,
]
})
export class TestSeriesModule { }
