<div *ngIf="questionDetails.questionType === 'NORMAL'">
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <nz-card nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24"
            [ngClass]="questionDetails.approveStatus==='rejected'? 'rejected-card': ''" class="mb1">
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                <div nz-col nzSpan="24">
                    <div class="text-right">

                        <span>
                            <nz-tag [nzColor]="'magenta'">
                                {{difficultyLevelObject[questionDetails.difficultyLevel].label}}
                            </nz-tag>
                        </span>
                        <span *ngIf="isShowMarkNagativeMark">
                            Marks: <nz-tag nzColor="green">{{questionMarks}}</nz-tag> <nz-tag
                                nzColor="red">{{questionNegativeMarks}}</nz-tag>
                        </span>
                    </div>
                </div>
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <span *ngIf="questionDetails && questionDetails.questionText" class="question-option-text">
                            <b>{{questionIndex + 1}}.</b> <app-mathjax
                                [content]="questionDetails.questionText"></app-mathjax>
                        </span>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                    <nz-row>
                        <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24"
                            *ngFor="let item of questionDetails.options; index as i">
                            <span *ngIf="item && item.optionText" class="question-option-text">
                                <b>{{i + 1}}. </b>
                                <span [ngClass]="{'is-correct-ans':item.isCorrectOption}">

                                    <app-mathjax [content]="item.optionText"> </app-mathjax>
                                </span>
                            </span>
                        </div>
                    </nz-row>
                </div>

                <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24"
                    *ngIf="questionDetails && questionDetails.subject && questionDetails.subject.subjectName">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="subject">Subject:
                            {{questionDetails.subject.subjectName}}</nz-form-label>

                    </nz-form-item>
                </div>
                <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24"
                    *ngIf="questionDetails && questionDetails.chapter && questionDetails.chapter.chapterName">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="subject">Chapter :
                            {{questionDetails.chapter.chapterName}}</nz-form-label>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="5" nzMd="5" nzSm="24" nzXs="24"
                    *ngIf="questionDetails && questionDetails.topic && questionDetails.topic.topicName">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="subject">Topic: {{questionDetails.topic.topicName}}
                        </nz-form-label>
                    </nz-form-item>
                </div>
                <div nz-col nz-button-group [nzSpan]="24">
                    <div nz-row nzJustify="end" [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                        <span nz-col>
                            <button nz-button (click)="openQuestion(id)" nzType="primary" class="submitoraddbutton">
                                Update Question
                            </button>
                        </span>
                        <span nz-col>
                            <button *ngIf="isShowRejectOption && questionDetails.approveStatus !=='rejected'" nz-button
                        (click)="rejectQuestion(id)" nzType="primary" class="submitoraddbutton">
                        Reject Question
                    </button>
                        </span>
                    </div>

                    <!--button for reject question-->



                </div>
            </div>
        </nz-card>
    </div>
</div>
<div *ngIf="questionDetails.questionType === 'PARAGRAPH'">
    <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" *ngIf="questionDetails.paragraph ">
        <div class="text-right mt3">
            <span>
                <nz-tag [nzColor]="'magenta'">
                    {{questionDetails.questionType}}
                </nz-tag>
            </span>
        </div>
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="subject">Paragraph Name</nz-form-label>
            <span *ngIf="questionDetails.paragraph && questionDetails.paragraph.name">
                <app-mathjax
                    [content]="showMore ? questionDetails.paragraph.name : (questionDetails.paragraph.name | slice: 0:365 )"></app-mathjax>
                <span *ngIf="!showMore">...</span>
                <a href="javascript:;" *ngIf="!showMore" class="text-right width-100" (click)="onShow()">[Show More]</a>
                <a href="javascript:;" *ngIf="showMore" class="text-right width-100" (click)="onShow()">[Show Less]</a>
            </span>
        </nz-form-item>
    </div>
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <nz-card nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                <div nz-col nzSpan="24">
                    <div class="text-right">

                        <span>
                            <nz-tag [nzColor]="'magenta'">
                                {{difficultyLevelObject[questionDetails.difficultyLevel].label}}
                            </nz-tag>
                        </span>
                        <span *ngIf="isShowMarkNagativeMark">
                            Marks: <nz-tag nzColor="green">{{questionMarks}}</nz-tag> <nz-tag
                                nzColor="red">{{questionNegativeMarks}}</nz-tag>
                        </span>
                    </div>
                </div>
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                    <nz-form-item>
                        <span *ngIf="questionDetails.questionText" class="question-option-text">
                            <b>{{questionIndex + 1}}.</b> <app-mathjax
                                [content]="questionDetails.questionText"></app-mathjax>
                        </span>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                    <nz-row>
                        <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24"
                            *ngFor="let item of questionDetails.options; index as i">
                            <span *ngIf="item.optionText" class="question-option-text">
                                <b>{{i + 1}}. </b>
                                <span [ngClass]="{'is-correct-ans':item.isCorrectOption}">

                                    <app-mathjax [content]="item.optionText"> </app-mathjax>
                                </span>
                            </span>
                        </div>
                    </nz-row>
                </div>

                <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24"
                    *ngIf="questionDetails.subject && questionDetails.subject.subjectName">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="subject">Subject:
                            {{questionDetails.subject.subjectName}}</nz-form-label>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24"
                    *ngIf="questionDetails.chapter && questionDetails.chapter.chapterName">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="subject">Chapter :
                            {{questionDetails.chapter.chapterName}}</nz-form-label>
                    </nz-form-item>
                </div>
                <div nz-col nzLg="5" nzMd="5" nzSm="24" nzXs="24"
                    *ngIf="questionDetails.topic && questionDetails.topic.topicName">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzFor="subject">Topic: {{questionDetails.topic.topicName}}
                        </nz-form-label>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="24" class="text-right">
                    <button nz-button *ngIf="
                    !bulkApprove" (click)="openQuestion(id)" nzType="primary" class="submitoraddbutton">
                        Update Question
                    </button>

                     <!--button for reject question-->
                     <button *ngIf="isShowRejectOption && questionDetails.approveStatus !=='rejected'" nz-button (click)="rejectQuestion(id)" nzType="primary"
                     class="submitoraddbutton">
                     Reject Question
                 </button>
                </div>
            </div>
        </nz-card>
    </div>
</div>
