<div nz-row nzJustify="end" [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }" class="mt4">
  <div nz-col nzLg="5" nzMd="5" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzErrorTip="Please select your subject!">
        <nz-form-label nzFor="chapter">Subject</nz-form-label>
        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" [(ngModel)]="selectedSubject"
          nzPlaceHolder="Select a subject" (nzOnSearch)="searchSubjectForDropdown($event)"
          (ngModelChange)="onChangeSubject()">
          <nz-option *ngFor="let item of allSubjects" nzValue="{{ item._id }}"
            nzLabel="{{ item.subjectName }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="5" nzMd="5" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzErrorTip="Please select your chapter!">
        <nz-form-label nzFor="chapter">Chapter</nz-form-label>
        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" [(ngModel)]="selectedChapter"
          nzPlaceHolder="Select a Chapter" (nzOnSearch)="searchChapterForDropdown($event)"
          (ngModelChange)="onChangeChapter()">
          <nz-option *ngFor="let item of allChapters" nzValue="{{ item._id }}"
            nzLabel="{{ item.chapterName }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="5" nzMd="5" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzErrorTip="Please select your Topic!">
        <nz-form-label nzFor="chapter">Topic</nz-form-label>
        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" [(ngModel)]="selectedTopic"
          nzPlaceHolder="Select a Topic" (nzOnSearch)="searchTopicForDropdown($event)"
          (ngModelChange)="onChangeTopics()">
          <nz-option *ngFor="let item of allTopics" nzValue="{{ item._id }}" nzLabel="{{ item.topicName }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="5" nzMd="5" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzErrorTip="Please select a SubAdmin!">
        <nz-form-label nzFor="chapter"> Created By</nz-form-label>
        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" [(ngModel)]="selectedAdmin"
          nzPlaceHolder="Select a SubAdmin" (nzOnSearch)="searchAdminForDropdown($event)" (ngModelChange)="onChangeSubAdmins()"> 
          <nz-option *ngFor="let item of allAdmins" nzValue="{{ item._id }}" nzLabel="{{ item.name }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzErrorTip="Please select a Date!">
        <nz-form-label nzFor="Date">Date</nz-form-label>
        <nz-range-picker [(ngModel)]="dateRange" (ngModelChange)="onChangeDateRange($event)"></nz-range-picker>
      </nz-form-control>
    </nz-form-item>
  </div>
  <!-- <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24">
    <nz-form-label nzFor="chapter"> Search</nz-form-label>
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <nz-range-picker ></nz-range-picker>
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div> -->
</div>
<div *ngIf="previewDetails && previewDetails.length ; else noData">
  <div class="mb3" nz-row nzJustify="end">
    <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submitApproveQuestion()" nzType="primary" class="submitoraddbutton">
        Approved All
    </button>
</div>
<!---scroll -->
<div class="scroll">

  <div *ngFor="let item of previewDetails index as i">
      <app-question-details [value]="item" [index]="i" [bulkApprove]="true" [isShowRejectOption]="true" [isShowMarkNagativeMark]="false"></app-question-details>

  </div>
</div>

</div>

<ng-template #noData>
  <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'No unapprove questions'"></nz-empty>
</ng-template>
