<div class="site-page-header-ghost-wrapper">
  <nz-page-header [nzGhost]="false">
    <nz-page-header-title class="ml4">Tests </nz-page-header-title>
    <nz-page-header-extra class="mr4">
      <div nz-col nzSpan="24">
        <button nz-button nzType="primary" [routerLink]="['add' ]" [queryParams]="{bulk: true, index: index}"
          routerLinkActive="router-link-active" class="submitoraddbutton" class="right-margin">
          Add Bulk Test
        </button>
        <button nz-button nzType="primary" [routerLink]="['add']" [queryParams]="{index: index}" routerLinkActive="router-link-active"
          class="submitoraddbutton">
          Add Test
        </button>
      </div>
    </nz-page-header-extra>
  </nz-page-header>
</div>
<div nz-row nzJustify="end" [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }" class="mt4">
  <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" >
        <nz-form-label>Exam</nz-form-label>
        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" [(ngModel)]="selectedExam"
          nzPlaceHolder="Select a Exam" (nzOnSearch)="searchExamForDropdown($event)" (ngModelChange)="onChangeExam()">
          <nz-option *ngFor="let item of allExams" nzValue="{{ item._id }}" nzLabel="{{ item.examName }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>  
  <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" >
        <nz-form-label nzFor="chapter">Difficulty Level</nz-form-label>
        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" [(ngModel)]="selectedDifficultyLevel"
          nzPlaceHolder="Select Difficulty Level" (ngModelChange)="onChangeDifficultyLevel()">
          <nz-option *ngFor="let item of allLevels" nzValue="{{ item.value }}" nzLabel="{{ item.label }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzErrorTip="Please select Test Type!">
        <nz-form-label nzFor="chapter"> Test Type</nz-form-label>
        <nz-select nzAllowClear nzShowSearch nzServerSearch id="examCategory" [(ngModel)]="selectedTestType"
          nzPlaceHolder="Select Test Type" (ngModelChange)="onChangeTestType()">
          <nz-option *ngFor="let item of allTestTypes" nzValue="{{ item.value }}"
            nzLabel="{{ item.label }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-control [nzSpan]="24">
        <nz-form-label>Sub Admin</nz-form-label>
        <nz-select nzAllowClear nzShowSearch nzServerSearch [(ngModel)]="selectedSubAdmin"
          nzPlaceHolder="Select a Sub Admin" (nzOnSearch)="searchSubAdminForDropdown($event)"
          (ngModelChange)="onChangeSubAdmin()">
          <nz-option *ngFor="let item of allSubAdmins" nzValue="{{ item._id }}"
            nzLabel="{{ item.name }}"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24" class="mb5">
    <nz-form-label nzFor="chapter">Search</nz-form-label>
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input type="text" nz-input placeholder="Search by test name" ngModel (ngModelChange)="searchQuestions($event)" />
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div>
</div>
<div nz-row nzJustify="end" class="mb2">
  <nz-button-group class="ml3">
    <button nz-button [nzType]="!active && deActive?'primary':'link'" (click)="changeListView()"> <span
        class="icon-size" nz-icon nzType="ordered-list" nzTheme="outline"></span></button>
    <!-- <nz-divider nzType="vertical"></nz-divider> -->
    <button nz-button [nzType]="active && !deActive?'primary':'link'" (click)="changeGridView() "><span
        class="icon-size action-button" nz-icon nzType="appstore" nzTheme="outline"></span></button>
  </nz-button-group>
</div>
<nz-tabset (nzSelectedIndexChange)="changeIndex($event)" [nzSelectedIndex]="index" *ngIf="listView && !active && deActive">
  <nz-tab nzTitle="Approved Tests - {{approvedTestCount}}">
    <nz-table [nzData]="allApprovedTests" [nzLoading]="loading" [nzTotal]="totalApprovedDataCount"
      [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex" [nzFrontPagination]="false" nzShowSizeChanger
      [nzScroll]="{ x: '900px' }" *ngIf="listView" (nzQueryParams)="onQueryParamsChange($event)">
      <thead>
        <tr>
          <!-- <th>Concept ID</th> -->
          <!-- <th nzColumnKey="topic" [nzSortFn]="true">Topic</th> -->
          <th>Test Name</th>
          <th>Test Type</th>
          <th>Difficulty Level</th>
          <th>time</th>
          <!-- <th nzWidth="100px" class="text-right">Questions</th> -->
          <th>Status</th>
          <th>Published Status</th>
          <th nzWidth="150px" class="text-right">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of allApprovedTests; index as i">
          <td class="{{ data.name != null ? 'description' : '' }}">
            <span *ngIf="data.name">{{ data.name }}</span>
          </td>
          <td>
            <span *ngIf="testTypeObject[data.testType].label">{{
              testTypeObject[data.testType].label
              }}</span>
          </td>
          <td>
            <span *ngIf="difficultyLevelObject[data.difficultyLevel].label">{{
              difficultyLevelObject[data.difficultyLevel].label
              }}</span>
          </td>
          <td>
            <span *ngIf="data.time">{{ data.time }}</span>
          </td>

          <td>
            <span class="{{ data.status ? 'active' : 'inactive' }} status"></span>
          </td>
          <td>
            <span *ngIf="data.publishedStatus">
              <nz-select id="testType" [(ngModel)]="data.publishedStatus"
                (ngModelChange)="onChangeStatus($event, data._id)" nzPlaceHolder="Select a option"
                [nzBorderless]="true">
                <nz-option *ngFor="let item of allPublishedStatus" nzValue="{{ item.value }}"
                  nzLabel="{{ item.label }}"></nz-option>
              </nz-select>
            </span>
          </td>

          <td class="action-buttons">
            <a nz-button  nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
              [routerLink]="['update', data._id]" [queryParams]="{index: index}" routerLinkActive="router-link-active"><i nz-icon nzType="form"
                nzTheme="outline"></i></a>

            <a nz-button  nzTooltipTitle="Details" nzTooltipPlacement="topCenter" nz-tooltip [nzLoading]="data.loading"
              (click)="openTestDetails(data)" routerLinkActive="router-link-active"><i nz-icon nzType="eye"
                nzTheme="outline"></i></a>

            <a *ngIf="userRole !== 'subAdmin'" class="action-button" nz-button nz-popconfirm nzPopconfirmTitle="Are you sure {{
              data.status ? 'deActive' : 'active'
            }} this Test?" (nzOnConfirm)="updateStatus(data._id, data.status)"
              nzTooltipTitle="{{ data.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight" nz-tooltip><i
                nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>

                <a *ngIf="userRole !== 'subAdmin'" nz-button class="delete" nzTooltipTitle="Delete" nzTooltipPlacement="topCenter" nz-tooltip nz-popconfirm
                nzPopconfirmTitle="Are you sure delete this Test?"
                (nzOnConfirm)="removeTest(data._id)"><i nz-icon nzType="delete" nzTheme="fill"></i></a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
  <nz-tab nzTitle="Un-approved  Tests - {{userRole !== 'subAdmin'?  pendingTestCount : pendingTestCount + rejectedTestCount}}">
    <nz-table [nzData]="allTests" [nzLoading]="loading" [nzTotal]="totalDataCount"
      [(nzPageSize)]="pageSize" [nzPageIndex]="pageIndex" [nzFrontPagination]="false" nzShowSizeChanger
      [nzScroll]="{ x: '900px' }" *ngIf="listView" (nzQueryParams)="onQueryParamsChange($event)"
      >
      <thead>
        <tr>
          <th>Test Name</th>
          <th>Test Type</th>
          <th>Difficulty Level</th>
          <th>time</th>
          <th>Created By</th>
          <th>Rejected Reason</th>
          <th>Status</th>
          <th nzWidth="150px" class="text-right">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of allTests; index as i">
          <td class="{{ data.name != null ? 'description' : '' }}">
            <span *ngIf="data.name">{{ data.name }}</span>
          </td>
          <td>
            <span *ngIf="testTypeObject[data.testType].label">{{
              testTypeObject[data.testType].label
              }}</span>
          </td>
          <td>
            <span *ngIf="difficultyLevelObject[data.difficultyLevel].label">{{
              difficultyLevelObject[data.difficultyLevel].label
              }}</span>
          </td>
          <td>
            <span *ngIf="data.time">{{ data.time }}</span>
          </td>
          <td><span *ngIf="data.createdBy">
              {{ data?.createdBy?.name }}
            </span></td>
          <td><span *ngIf="data.rejectionReason" class="ellipsis">{{data.rejectionReason}}</span></td>
          <td>
            <nz-tag
              [nzColor]="data.approveStatus === 'approved' ? 'green':  data.approveStatus === 'pending'? 'lime':'volcano' ">
              {{data.approveStatus}}

            </nz-tag>
          </td>
          <!-- <td><span class="{{data.status? 'active' : 'inactive' }} status"></span></td> -->
          <td class="action-buttons">
            <a nz-button  nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
              [routerLink]="['update', data._id]" [queryParams]="{index: index}" routerLinkActive="router-link-active"><i nz-icon nzType="form"
                nzTheme="outline"></i></a>

            <a nz-button  nzTooltipTitle="Details" nzTooltipPlacement="topCenter" nz-tooltip
              (click)="openTestDetails(data)" routerLinkActive="router-link-active"><i nz-icon nzType="eye"
                nzTheme="outline"></i></a>

            <a *ngIf="userRole !== 'subAdmin'" class="action-button" nz-button nz-popconfirm
              nzPopconfirmTitle="Are you sure {{data.status ? 'deActive' : 'active'}} this Test?"
              (nzOnConfirm)="updateStatus(data._id, data.status)"
              nzTooltipTitle="{{data.status ? 'Deactive' : 'Active'}}" nzTooltipPlacement="topRight" nz-tooltip><i
                nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>

            <a *ngIf="userRole !== 'subAdmin' " class="action-button top-margin" nz-button nz-popconfirm
              nzPopconfirmTitle="Are you sure Approved this Test?"
              (nzOnConfirm)="updatePendingStatus(data._id, data.approveStatus)" nzTooltipTitle="{{'Approve'}}"
              nzTooltipPlacement="topRight" nz-tooltip><i nz-icon nzType="file-done" nzTheme="outline"></i></a>

            <a *ngIf="userRole !== 'subAdmin' && data.approveStatus !== 'rejected'" class="action-button top-margin"
              nz-button nz-popconfirm nzPopconfirmTitle="Are you sure Reject this Test?"
              (nzOnConfirm)="openRejectionReason(data._id, data.approveStatus)" nzTooltipTitle='Reject'
              nzTooltipPlacement="topRight" nz-tooltip><i nz-icon [nzType]="'close-circle'" nzTheme="outline"></i></a>

            <a *ngIf="userRole !== 'subAdmin'" nz-button class="delete" nzTooltipTitle="Delete" nzTooltipPlacement="topCenter" nz-tooltip nz-popconfirm
            nzPopconfirmTitle="Are you sure delete this Test?"
            (nzOnConfirm)="removeTest(data._id)"><i nz-icon nzType="delete" nzTheme="fill"></i></a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
</nz-tabset>
<div *ngIf=" !listView">
  <app-grid-view [list]="allTests" (index)="changeIndex($event)" [isOpenDetails]="isOpenDetails"
    [unApprovedList]="allTests" [isTestComponent]="isTestComponent" [loading]=loading> </app-grid-view>
  <div class="pagination">
    <nz-pagination [nzPageIndex]="pageIndex" [nzTotal]="totalDataCount" nzShowSizeChanger [(nzPageSize)]="pageSize"
      (nzPageIndexChange)="onQueryParamsChangeForGridView($event)"
      (nzPageSizeChange)="onQueryPageSizeChangeForGridView($event)"></nz-pagination>
  </div>
</div>
