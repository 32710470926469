<nz-page-header
  class="site-page-header"
  nzBackIcon
  [nzTitle]="idForUpdate ? 'Update Chapter' : 'Add Chapter'"
>
</nz-page-header>
<form nz-form [formGroup]="addUpdateChapterForm">
  <div nz-row>
    <nz-card nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="chapterName" nzRequired
          >Chapter Name</nz-form-label
        >
        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
          <input
            nz-input
            formControlName="chapterName"
            type="text"
            placeholder="Enter chapter name "
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="subject" nzRequired
          >Select Subject</nz-form-label
        >
        <nz-form-control [nzSpan]="24" nzErrorTip="Please select subject">
          <nz-select nzAllowClear
          (nzScrollToBottom)="loadMore()"
            nzShowSearch
            nzServerSearch
            formControlName="subject"
            nzPlaceHolder="Select a option"
            (nzOnSearch)="searchSubjectForDropdown($event)"

          >
            <nz-option
              *ngFor="let subject of allSubjects"
              nzValue="{{ subject._id }}"
              nzLabel="{{ subject.subjectName }}"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="description"
          >Chapter Description</nz-form-label
        >
        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
          <textarea
            formControlName="description"
            nz-input
            rows="4"
            placeholder="Enter chapter description"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
      <div nz-row nzJustify="end">
        <button
          nz-button
          nzType="primary"
          [nzLoading]="buttonLoading"
          (click)="submit()"
          class="submitoraddbutton"
        >
        {{idForUpdate? 'Update':'Submit'}}
        </button>
      </div>
    </nz-card>
  </div>
</form>
