import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TestRoutingModule } from './test-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TestComponent } from './test/test.component';
import { AddUpdateTestComponent } from '../test/add-update-test/add-update-test.component';
import { TestDetailsComponent } from './test-details/test-details.component';
import { QuestionModule } from '../question/question.module';


@NgModule({
  declarations: [
    TestComponent,
    AddUpdateTestComponent,
    TestDetailsComponent
  ],
  imports: [
    CommonModule,
    TestRoutingModule,
    SharedModule,
    QuestionModule
  ],
  exports: [],
})
export class TestModule { }
