import { Component, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs'; 
import { HttpRequestService } from 'src/app/core/services';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ActivityDetailsComponent } from '../activity-details/activity-details.component';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-login-logs',
  templateUrl: './login-logs.component.html',
  styleUrls: ['./login-logs.component.scss']
})
export class LoginLogsComponent implements OnInit  {
  allSubAdmins: any[] = [];
  totalDataCount = 0;
  pageSize = 10 ;
  pageIndex = 1;
  activityPageIndex = 1;
  adminId: string = '';
  loading = false;
  searchSubAdmin: Subject<any> = new Subject<any>();

  search = '';
  selectedAdmin = '';
  listView = true;
  isOpenDetails = true;
  isAddOrUpdate = false;
  isViewDetailsOfUser:boolean = true;
  active: boolean = false;
  deActive: boolean = true;
  dateRange : any;
  startDate: any;
  endDate: any;
  isVisible:boolean = false;
  apiUrl = environment.apiBaseUrl
  type: any
  adminSearch= '';
  allAdmins: any[] = [];
  skipIndex: number = 0;
  constructor(
    private route : Router,
    private drawerService: NzDrawerService,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService
  ) {}

  // on page load functions
  ngOnInit(): void {
    this.getAllAdmins();

    this.searchSubAdmin.pipe(debounceTime(1000)).subscribe((success) => {
      this.search = success;
      this.getAllAdmins();
    });
  }
    
  // exportFile(type: string): void {
  //   window.open(
  //     this.apiUrl + `/api/users/export/${type}?token=${localStorage.getItem('token')}`,
  //     '_blank'
  //   );
  //   this.isVisible = false;
  //   this.type = '';
    
  // }
  
  onChangeDateRange(date: any){
    this.startDate = date[0]
    this.endDate = date[1]
    this.getallSubAdmins();
  }
  /* Get all subjects */
  getallSubAdmins(skip = 0, sortBy?: any): void {
    let params: any;
    params = { skip: this.skipIndex, limit: this.pageSize };
    if (this.selectedAdmin) {
      params._id = this.selectedAdmin;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if( this.startDate &&  this.endDate){
      params.startDate = this.startDate;
      params.endDate = this.endDate   ;
    }else{
      delete params.startDate;
      delete params.endDate;
    }
    this.loading = true;
    this.httpRequestService.request('get', 'auth-admins', params).subscribe(
      (result) => {
        this.loading = false;
        this.allSubAdmins = result.data;
        this.totalDataCount = result.totalCount;
      },
      (err) => {
        this.loading = false;
      }
    );
  }
  getAllAdmins(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
    };
    if (this.search) {
      params.search = this.search;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'auth-admins', params).subscribe(
      (result: any) => {
        this.allAdmins = result.data;
      },
      (err: any) => {}
    );
  }

  /* For Pagination / Sending skip */
  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex} = params;
    this.skipIndex = pageSize * (pageIndex - 1)
    this.getallSubAdmins(pageSize * (pageIndex - 1));
  }

  // /* openActivity*/
  openActivityDrawer(id: string, skip = 0): void {
        this.drawerService.create({
          nzTitle: 'Activity Details',
          nzFooter: '',
          nzWidth: '40%',
          nzContent: ActivityDetailsComponent,
          nzContentParams: {
            id
          },
        });
  }


  onChangeAdmin(): void {
    this.getallSubAdmins();
  }
  // Seach subject
  // searchSubAdmins(event: any): void {
  //   this.searchSubAdmin.next(event);
  // }
  searchAdminForDropdown(event: any): void {
    this.searchSubAdmin.next(event);
  }
}
