<div class="site-page-header-ghost-wrapper">
    <nz-page-header [nzGhost]="false">
        <nz-page-header-title class="ml4">Users </nz-page-header-title>
        <nz-page-header-extra class="mr4">
            <button nz-button  nzTooltipTitle="Export Excel" nz-tooltip nzTooltipPlacement="top" (click)="exportFile('excel')">
                <i nz-icon nzType="file-excel" nzTheme="outline"></i> Export Excel
            </button>
        </nz-page-header-extra>
    </nz-page-header>
</div>
<div nz-row nzJustify="end" class="mt4">
    <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24" class="mb5 mr3">
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="Date">Date</nz-form-label>
            <nz-form-control [nzSpan]="24" nzErrorTip="Please select a Date!">
                <nz-range-picker class="width-100" [(ngModel)]="dateRange" (ngModelChange)="onChangeDateRange($event)"></nz-range-picker>
            </nz-form-control>
        </nz-form-item>
    </div>
    <div nz-col nzLg="8" nzMd="8" nzSm="24" nzXs="24" class="mb5">
        <nz-form-label [nzSpan]="24" nzFor="Date">Search</nz-form-label>
        <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input placeholder="Search by Users name" ngModel
                (ngModelChange)="searchUsers($event)" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <i nz-icon nzType="search"></i>
        </ng-template>
    </div>
</div>

<!-- <div nz-row nzJustify="end" class="mb2">
    <nz-button-group class="ml3">
        <button nz-button [nzType]="!active && deActive?'primary':'link'" (click)="changeListView()"> <span
                class="icon-size" nz-icon nzType="ordered-list" nzTheme="outline"></span></button>
        <button nz-button [nzType]="active && !deActive?'primary':'link'" (click)="changeGridView() "><span
                class="icon-size action-button" nz-icon nzType="appstore" nzTheme="outline"></span></button>
    </nz-button-group>
</div> -->
<nz-table #subjectTbl [nzData]="allUsers" [nzLoading]="loading" [nzTotal]="totalDataCount" [(nzPageSize)]="pageSize"
    [nzPageIndex]="pageIndex" [nzFrontPagination]="false" (nzQueryParams)="onQueryParamsChange($event)"
    nzShowSizeChanger [nzScroll]="{ x: '900px' }" *ngIf="listView">
    <thead>
        <tr>
            <th nzColumnKey="name" nzWidth="" [nzSortFn]="true">User Name</th>
            <th nzWidth="">Email</th>
            <th nzWidth="">Mobile</th>
            <th nzWidth="">Created On</th>
            <th nzWidth="">Subscribe </th>
            <th nzWidth="">Status</th>
            <th class="text-right" nzWidth="150px">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of subjectTbl.data; index as i">
            <td><span *ngIf="data.name  "> {{ data.name }} </span></td>
            <td><span *ngIf="data.email "> {{ data.email }}</span></td>
            <td><span *ngIf="data.mobile "> {{ data.mobile }}</span></td>
            <td><span *ngIf="data.createdAt">{{ data.createdAt | date : "mediumDate" }}</span></td>

            <td>
                <nz-tag *ngIf="data.subscriptionStatus" nzColor="green">{{data.subscriptionStatus ? 'Subscribed' : '' }}</nz-tag>
                <i *ngIf="data.subscriptionStatus"  nz-icon nzType="info-circle" nzTheme="outline" nz-button nz-tooltip [nzTooltipTitle]="allSeries" [nzTooltipColor]="'#f75959'"></i>
            </td>
            <td ><span class="{{data.status? 'active' : 'inactive' }} status"></span></td>
            <td class="action-buttons">
                <!-- <a nz-button  nzTooltipTitle="Edit" nzTooltipPlacement="topCenter" nz-tooltip
                        [routerLink]="['update', data._id]" routerLinkActive="router-link-active"><i nz-icon nzType="form"
                            nzTheme="outline"></i></a> -->
                <a nz-button  nzTooltipTitle="Details" nzTooltipPlacement="topCenter" nz-tooltip
                    (click)="openUserDetails(data)" routerLinkActive="router-link-active"><i nz-icon nzType="eye"
                        nzTheme="outline"></i></a>
                <a class="action-button" nz-button nz-popconfirm
                    nzPopconfirmTitle="Are you sure {{data.status ? 'deActive' : 'active'}} this User?"
                    (nzOnConfirm)="updateStatus(data._id, data.status)"
                    nzTooltipTitle="{{data.status ? 'Deactive' : 'Active'}}" nzTooltipPlacement="topRight" nz-tooltip><i
                        nz-icon [nzType]="data.status ? 'stop' : 'check'" nzTheme="outline"></i></a>
            </td>
            <ng-template #allSeries>
                <h3>
                    Plans:
                </h3>
                    <div *ngFor="let item of data.subscribeTestSeries">
                             <nz-tag [nzColor]="'orange'" *ngIf="item.isFromComboPlan">{{item.planName}}</nz-tag>
                        </div>
                        <h3>
                            Test Series:
                        </h3>
                        <div *ngFor="let item of data.subscribeTestSeries">
                            <nz-tag [nzColor]="'green'" *ngIf="!item.isFromComboPlan">{{item.testSeries[0].name}}</nz-tag>
                    </div>
            </ng-template>
        </tr>
    </tbody>
</nz-table>
<!-- <div *ngIf=" !listView">
        <app-common-grid-view [list]="allUsers" [isOpenDetails]="isOpenDetails"
            [loading]="loading" [isAddOrUpdate]="isAddOrUpdate" [isViewDetailsOfUser]="isViewDetailsOfUser"></app-common-grid-view>
        <div class="pagination">
            <nz-pagination [nzPageIndex]="pageIndex" [nzTotal]="totalDataCount" nzShowSizeChanger
                (nzPageSizeChange)="onQueryPageSizeChangeForGridView($event)" [(nzPageSize)]="pageSize"
                (nzPageIndexChange)="onQueryParamsChangeForGridView($event)"></nz-pagination>
        </div>
    </div> -->
    
