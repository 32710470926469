export const mockShalaAdminMenu = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    route: '/main/dashboard',
    icon: 'dashboard',
    children: [],
  },
  {
    key: 'masters',
    title: 'Master Forms',
    route: '',
    icon: 'home',
    children: [
      {
        key: 'subject',
        title: 'Subject',
        route: '/main/subjects',
        icon: 'home',
        children: [],
      },
      {
        key: 'chapter',
        title: 'Chapter',
        route: '/main/chapters',
        icon: 'home',
        children: [],
      },

      {
        key: 'topic',
        title: 'Topic',
        route: '/main/topics',
        icon: 'home',
        children: [],
      },
      {
        key: 'exam',
        title: 'Examination',
        route: '/main/exam',
        icon: 'home',
        children: [],
      },
      {
        key: 'exam  category',
        title: 'Exam Category',
        route: '/main/exam-category',
        icon: 'home',
        children: [],
      },
      {
        key: 'Success Story',
        title: 'success Story',
        route: '/main/success-stories',
        icon: 'container',
        children: [],
      },
      {
        key: 'Current Affairs',
        title: 'Current Affair',
        route: '/main/current-affairs',
        icon: 'credit-card',
        children: [],
      },
      {
        key: 'Resource',
        title: 'Resource',
        route: '/main/resource',
        icon: 'credit-card',
        children: [],
      },
      {
        key: 'Dashboard Config',
        title: 'Dashboard Config',
        route: '/main/dashboard-config',
        icon: 'credit-card',
        children: [],
      },
      {
        key: 'Sub Admin',
        title: 'Sub Admin',
        route: '/main/sub-admin',
        icon: '',
        children: [],
      },
      {
        key: 'testimonial',
        title: 'Testimonial',
        route: '/main/testimonial',
        icon: '',
        children: [],
      },
      {
        key: 'editorials',
        title: 'Editorial',
        route: '/main/editorials',
        icon: '',
        children: [],
      },
      {
        key: 'banners',
        title: 'Banners',
        route: '/main/banners',
        icon: '',
        children: [],
      },

    ],
  },

  {
    key: 'question',
    title: 'Question',
    route: '/main/question',
    icon: 'question',
    children: [],
  },
  {
    key: 'Test',
    title: 'Test',
    route: '/main/test',
    icon: 'book',
    children: [],
  },
  {
    key: 'Test series',
    title: 'Test series',
    route: '/main/test-series',
    icon: 'book',
    children: [],
  },
  {
    key: 'User',
    title: 'User',
    route: '/main/user',
    icon: 'user',
    children: [],
  },
  {
    key: 'Plan',
    title: 'Plan',
    route: '/main/plan',
    icon: 'credit-card',
    children: [],
  },
  {
    key: 'login-logs',
    title: 'Activity Logs',
    route: '/main/login-logs',
    icon: 'schedule',
    children: [],
  },
  
];
export const mockShalaSubAdminMenu = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    route: '/main/dashboard',
    icon: 'dashboard',
    children: [],
    expanded: false,
  },
  {
    key: 'masters',
    title: 'Master Forms',
    route: '',
    icon: 'home',
    children: [
      {
        key: 'subjects',
        title: 'Subject',
        route: '/main/subjects',
        icon: 'home',
        children: [],
      },
      {
        key: 'chapters',
        title: 'Chapter',
        route: '/main/chapters',
        icon: 'home',
        children: [],
      },

      {
        key: 'topics',
        title: 'Topic',
        route: '/main/topics',
        icon: 'home',
        children: [],
      },
      {
        key: 'exam',
        title: 'Examination',
        route: '/main/exam',
        icon: 'home',
        children: [],
      },
      {
        key: 'exam-category',
        title: 'Exam Category',
        route: '/main/exam-category',
        icon: 'home',
        children: [],
      },
      {
        key: 'success-stories',
        title: 'success Story',
        route: '/main/success-stories',
        icon: 'container',
        children: [],
      },
      {
        key: 'current-affairs',
        title: 'Current Affair',
        route: '/main/current-affairs',
        icon: 'credit-card',
        children: [],
      },
      {
        key: 'resource',
        title: 'Resource',
        route: '/main/resource',
        icon: 'credit-card',
        children: [],
      },
      {
        key: 'dashboard-config',
        title: 'Dashboard Config',
        route: '/main/dashboard-config',
        icon: 'credit-card',
        children: [],
      },
      {
        key: 'testimonial',
        title: 'Testimonial',
        route: '/main/testimonial',
        icon: '',
        children: [],
      },
      {
        key: 'editorials',
        title: 'editorial',
        route: '/main/editorials',
        icon: '',
        children: [],
      },
      {
        key: 'banners',
        title: 'Banners',
        route: '/main/banners',
        icon: '',
        children: [],
      },
    ],
  },

  {
    key: 'question',
    title: 'Question',
    route: '/main/question',
    icon: 'question',
    children: [],
  },
  {
    key: 'test',
    title: 'Test',
    route: '/main/test',
    icon: 'book',
    children: [],
  },
  {
    key: 'test-series',
    title: 'Test series',
    route: '/main/test-series',
    icon: 'book',
    children: [],
  },
  {
    key: 'plan',
    title: 'Plan',
    route: '/main/plan',
    icon: 'credit-card',
    children: [],
  },
];
