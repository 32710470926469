<nz-layout class="app-layout">
  <app-header (collapsed)="onCollapseChange($event)"></app-header>

  <nz-layout>
    <nz-content>
      <div class="container">
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </nz-content>
  </nz-layout>
  <nz-footer>
    <app-footer></app-footer>
  </nz-footer>
</nz-layout>
