<nz-page-header class="site-page-header" nzBackIcon [nzTitle]="idForUpdate ? 'Update Testimonial' : 'Add Testimonial'">
</nz-page-header>
<form nz-form [formGroup]="addUpdateTestimonialForm">
    <nz-card nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
            <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="subjectName" nzRequired>Name</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                        <input nz-input formControlName="name" type="text" placeholder="Enter a Name " />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="subjectName" nzRequired>Review Rating</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                        <!-- <input nz-input formControlName="name" type="text" placeholder="Enter Title " /> -->
                        <nz-input-number class="width-100" formControlName="reviewStar"  placeholder="Enter Rating " [nzMin]="1" [nzMax]="10" [nzStep]="1"></nz-input-number>
                    </nz-form-control>
                </nz-form-item>
            </div>


            <div nz-col nzLg="18" nzMd="18" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                        <nz-form-label [nzSpan]="24" nzFor="description" nzRequired>Comment</nz-form-label>
                        <!-- <ckeditor #editor id="description" name="testimonial" formControlName="testimonial"
                            type="divarea">
                        </ckeditor> -->
                        <textarea nz-input placeholder="enter Comment"  formControlName="comment" [nzAutosize]="{ minRows: 3, maxRows: 5 }"></textarea>
                    </nz-form-control>
                </nz-form-item>

            </div>
            <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="subject" nzRequired>Image</nz-form-label>
                    <nz-upload class="upload-list-inline" nzName="avatar"
                        (nzChange)="UploadImageFile($event, 'image', 0)" [nzName]="'file'" [nzShowUploadList]="false"
                        [nzBeforeUpload]="beforeRelatedMediaUpload" [nzAction]="mediaUploadUrl"
                        [nzHeaders]="customRequestHeaders">
                        <button nz-button *ngIf="!addUpdateTestimonialForm.value.image" [nzLoading]="imageLoading">
                            <span nz-icon nzType="upload"></span>
                            Upload
                        </button>
                    </nz-upload>
                    <nz-card *ngIf="addUpdateTestimonialForm.value.image" class="question-image-card">
                        <img nz-image width="auto" max-height="150px" class="img-responsive"
                            [nzSrc]="mediaBaseUrl + addUpdateTestimonialForm.value.image" />

                        <button nz-button nzDanger class="remove-image image-card-del-icon"
                            nzType="primary"
                            nzTooltipTitle="Delete" nz-tooltip nz-popconfirm
            nzPopconfirmTitle="Are you sure delete this?"
            (nzOnConfirm)="removeImage(addUpdateTestimonialForm.value.image)"
                            >
                            <span nz-icon nzType="delete" nzTheme="outline"></span>
                        </button>
                    </nz-card>
                </nz-form-item>
            </div>
        </div>
        <div nz-row nzJustify="end">
            <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()" class="submitoraddbutton">
                {{idForUpdate? 'Update':'Submit'}}
            </button>
        </div>
    </nz-card>
</form>
