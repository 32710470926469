import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChaptersComponent } from './chapters/chapters.component';
import { AddUpdateChapterComponent } from './add-update-chapter/add-update-chapter.component';

const routes: Routes = [
  {
    path: '',
    component: ChaptersComponent
  },
  {
    path: 'add',
    component: AddUpdateChapterComponent
  },
  {
    path: 'update/:id',
    component: AddUpdateChapterComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChapterRoutingModule { }
