import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService, HttpRequestService } from 'src/app/core/services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup
  isLoading = false;
  isOtpSend: boolean = false;
  viewReEnterPassword = false;
  viewPassword = false;
  showButton:boolean = false;
  countdownInMinute: number = 2; // Initial countdownInMinute time in seconds
  countdownInterval: any; // Initial countdownInMinute time in seconds
  countdownInSec: number = 0; // Initial countdownInMinute time in seconds
  countdownInMinuteMinuteInterval: any; // Variable to store the interval reference
  countdownInMinuteSecInterval: any; // Variable to store the interval reference

  constructor(
    private fb: FormBuilder,
    private notificationService: NzNotificationService,
    private authService: AuthService,
    private router: Router,
    private httpRequestService: HttpRequestService
  ) {
    this.forgotPasswordForm = this.fb.group({
      email: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            '^([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$'
          ),
        ],
      ],
      remember: [true],
    })
  }

  ngOnInit(): void {
  }
  
  startCountdownInMinute() {
    this.countdownInterval = setInterval(() => {
      if (this.countdownInMinute > 0 || this.countdownInSec > 0) {
        if (this.countdownInSec === 0) {
          this.countdownInMinute--;
          this.countdownInSec = 59; // Reset seconds to 59 when minutes decrement
        } else {
          this.countdownInSec--;
        }
        //;
      } else {
        // Countdown is complete, show the button
        this.showButton = true;
        clearInterval(this.countdownInterval); // Stop the interval
      }
    }, 1000); // Update the countdown every 1 second (1000 milliseconds)
  }
  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (
      control.value !==
      this.forgotPasswordForm.controls.password.value
      ) {
        return { confirm: true, error: true };
      }
      return {};
    };
    updateConfirmValidator(): void {
      /** wait for refresh value */
      Promise.resolve().then(() =>
      this.forgotPasswordForm.controls.reEnterPassword.updateValueAndValidity()
      );
    }
    //otp send 
  sendOTP() {
    if (!this.forgotPasswordForm.valid) {
      this.markFormGroupTouched(this.forgotPasswordForm);
    } else {
      this.isLoading = true;
      this.httpRequestService
        .request('post', 'auth-admins/request-password-reset', {
          email: this.forgotPasswordForm.value.email,
          // password: this.forgotPasswordForm.value.password,
        })
        .subscribe(
          (result) => {
            this.isLoading = false;
            this.forgotPasswordForm.addControl('otp', new FormControl(null, Validators.required));
            this.forgotPasswordForm.addControl('password', new FormControl(
              null, Validators.required
            ));
            this.forgotPasswordForm.addControl('reEnterPassword', new FormControl(
              null, [Validators.required, this.confirmationValidator]
            ));
            this.isOtpSend = true;
            if(this.isOtpSend){
              this.startCountdownInMinute();
            }
          },
          (err) => {
            this.isLoading = false;
            this.isOtpSend = false;
            this.notificationService.error('Error', err.error.message);
          }
        );
    }
  }
  resendOTP(){
    this.isLoading = true;
    this.showButton= false;
    this.isOtpSend = true;
    this.countdownInMinute = 2;
    this.startCountdownInMinute();
    this.httpRequestService
      .request('post', 'auth-admins/request-password-reset', {
        email: this.forgotPasswordForm.value.email,
        // password: this.forgotPasswordForm.value.password,
      })
      .subscribe(
        (result) => {
          this.isLoading = false;
          this.forgotPasswordForm.addControl('otp', new FormControl(null, Validators.required));
          this.forgotPasswordForm.addControl('password', new FormControl(
            null, Validators.required
          ));
          this.forgotPasswordForm.addControl('reEnterPassword', new FormControl(
            null, [Validators.required, this.confirmationValidator]
          ));

        },
        (err) => {
          this.isLoading = false;
          this.notificationService.error('Error', err.error.message);
        }
      );
  }
  //submit for update password
  submitForResetPassword(): void {
    if (!this.forgotPasswordForm.valid) {
      this.markFormGroupTouched(this.forgotPasswordForm);
     
    } else {
     
      this.isLoading = true;
      this.httpRequestService
      .request('post', 'auth-admins/password-reset', {
        email: this.forgotPasswordForm.value.email,
        password: this.forgotPasswordForm.value.password,
        otp: this.forgotPasswordForm.value.otp,
      }).subscribe(
        (result) => {
          if(result.statusCode==200){
            this.isLoading = false;
            this.notificationService.success('Success', 'password SuccessFully Updated');
            this.router.navigate(['/auth/login']);
          }
        },
        (err) => {
          this.isLoading = false;
          this.notificationService.error('Error', err.error.message);
        }
      );
        
    }
  }
  // setTimeInterval(){
  //   if(this.isOtpSend){
  //     setInterval(() => {
  //       this.isOtpSend = false;
  //     }, 60000)
  //   }
  // }

  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }
}
