import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-update-question',
  templateUrl: './add-update-question.component.html',
  styleUrls: ['./add-update-question.component.scss'],
})
export class AddUpdateQuestionComponent implements OnInit {
  @Input() isFromOtherComponent: boolean = false
  @Input() otherForm: any = null;
  @Input() idForUpdate: any;


  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    }


  ngOnInit(): void { }
  }
