import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExamRoutingModule } from './exam-routing.module';
import { ExamsComponent } from './exams/exams.component';
import { AddUpdateExamsComponent } from './add-update-exams/add-update-exams.component';
import { ExamDetailsComponent } from './exam-details/exam-details.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    ExamsComponent,
    AddUpdateExamsComponent,
    ExamDetailsComponent
  ],
  imports: [
    CommonModule,
    ExamRoutingModule,
    SharedModule
  ]
})
export class ExamModule { }
