<nz-skeleton [nzActive]="true" *ngIf="loading ">
</nz-skeleton>
<div style="width: 100%; text-align: center;" *ngIf="loading">
  <nz-spin nzSimple></nz-spin> Please wait while we load the data...
</div>
<div *ngIf="!activityData.length && !loading"  class="empty-dialog">
    <nz-empty></nz-empty>
</div>
<div *ngIf="!loading">
    <div *ngFor="let data of activityData">
        <nz-divider nzText="{{data.date | date: 'dd/MM/yyyy'}}"></nz-divider>
        <nz-timeline>
            <nz-timeline-item [nzColor]="getColorForMessage(item.message)" *ngFor="let item of data.activity">
                {{item.message | titlecase}}
            </nz-timeline-item>
        </nz-timeline>
    
    </div>
    <div  class="loadMore-button  mt3">
        <button [disabled]="isActivityLimit" nz-button nzType="primary" nzSize="small" (click)="paginationForActivity()"> Load
            More
        </button>
    </div>
</div>