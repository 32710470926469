import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rejection-reason-modal',
  templateUrl: './rejection-reason-modal.component.html',
  styleUrls: ['./rejection-reason-modal.component.scss']
})
export class RejectionReasonModalComponent implements OnInit {

  @Input() rejectionReason: string = '';
  constructor() {}
  ngOnInit(): void {
    
  }

}
