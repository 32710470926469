import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { toNumber } from 'ng-zorro-antd/core/util';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Chapter } from 'src/app/core/interfaces/chapter';
import { Subjects } from 'src/app/core/interfaces/subjects';
import { Topic } from 'src/app/core/interfaces/topic';
import { HttpRequestService, LocalStorageService } from 'src/app/core/services';
import { RejectionReasonComponent } from 'src/app/shared/components/rejection-reason/rejection-reason.component';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss'],
})
export class TopicsComponent implements OnInit {
  allTopics: Topic[] = [];
  allApprovedTopics: Topic[] = [];
  isAddOrUpdate:boolean = true;
  totalDataCount:number = 0;
  totalApprovedDataCount:number = 0;
  pageSize:number = 20;
  pageIndex:number = 1;
  loading = false;
  searchConcept: Subject<string> = new Subject<string>();
  search:string = '';
  checkAddPermission = false;
  checkUpdatePermission = false;
  allChapters:Chapter[]= []
  allSubjects:Subjects[] = [];
  selectedSubject:string = ''
  selectedChapter:string = ''
  subjectSearch:string = '';
  chapterSearch:string = '';
  searchChapter: Subject<string> = new Subject<string>();
  searchSubject: Subject<string> = new Subject<string>();
  listView = true;
  isOpenDetails = false;
  active: boolean = false;
deActive: boolean = true;
isTopicComponent: boolean = true;
userRole: string = ''
subAdminAddedTopics:Topic[] = [];
index: number = 0;
skipIndex:number = 0;
snapShootIndex:number = 0;
  constructor(
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private localStorageService: LocalStorageService,
    private modalService : NzModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.snapShootIndex = toNumber(this.activatedRoute.snapshot.queryParams.index) ;
    if(this.snapShootIndex){
      this.changeIndex(this.snapShootIndex)
    }
    this.userRole = this.localStorageService.getItem('user').role;
  }
  /* on page load */
  ngOnInit(): void {
    this.getAllSubjects();
    this.getAllChapters();
    this.searchConcept.pipe(debounceTime(1000)).subscribe((success) => {
      this.search = success;
      this.getAllTopics();
    });
    this.searchChapter.pipe(debounceTime(1000)).subscribe((success) => {
      this.chapterSearch = success;
      this.getAllChapters();
    });
    this.searchSubject.pipe(debounceTime(1000)).subscribe((success) => {
      this.subjectSearch = success;
      this.getAllSubjects();
    });
  }
  changeIndex(data: any) {
    this.router.navigate([], { queryParams: {index: data } });
    this.pageSize = 12;
    this.pageIndex = 1;
    this.allApprovedTopics = []
    this.allTopics = []
    this.index = data;
      this.getAllTopics();
  }
  changeGridView() {
    this.listView = false;
    this.active = true;
    this.deActive = false;
  }
  changeListView() {
    this.listView = true;
    this.active = false;
    this.deActive = true;
  }
  /* Get all Chapters */
  getAllChapters(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved'
    };
    if(this.selectedSubject){
      params.subject = this.selectedSubject;
    }else{
      delete params.subject
    }
    /* if (this.addUpdateTopicForm.value.subject) {
      params['subject'] = this.addUpdateTopicForm.value.subject;
    } else {
      delete params.subject;
    } */
    if (this.chapterSearch) {
      params.search = this.chapterSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'chapters', params).subscribe(
      (result: any) => {
        this.allChapters = result.data;
      },
      (err: any) => {}
    );
  }

  /* Get all Subjects */
  getAllSubjects(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved'
    };
    if (this.subjectSearch) {
      params.search = this.subjectSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'subjects', params).subscribe(
      (result: any) => {
        this.allSubjects = result.data;
      },
      (err: any) => {}
    );
  }
  /* Get all concepts */
  getAllTopics(index: number = 0, skip = 0, sortBy?: any): void {
    index = index? index : this.index
    let params: any;
    params = { skip: this.skipIndex, limit: this.pageSize };
    if (this.search) {
      params.search = this.search;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if(this.selectedSubject){
      params.subject = this.selectedSubject;
    }else{
      delete params.subject;
    }
    if(this.selectedChapter){
      params.chapter = this.selectedChapter;
    }else{
      delete params.chapter;
    }
    if(index === 0){
      params.approveStatus = 'approved'
    }else if(index === 1 && this.userRole === 'admin'){
      params.approveStatus = "pending"
    }else if( index === 1 && this.userRole === 'subAdmin' ){
      params.approveStatus = ["pending", "rejected"]
    }else{
      delete params.approvedStatus
    }

    this.loading = true;
    this.httpRequestService.request('get', 'topics', params).subscribe(
      (result) => {
        this.loading = false;
        const data:Topic[] = result.data; 
        if(index === 0){
          this.allApprovedTopics = data;
          this.totalApprovedDataCount = result.totalCount;
        }else{
          this.allTopics = data
          this.totalDataCount = result.totalCount;
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  /* For Pagination / Sending skip */
  onQueryParamsChange(params: NzTableQueryParams): void {
    this.allApprovedTopics = []
    this.allTopics = []
    const { pageSize, pageIndex, sort } = params;
    let sortBy: any = {};
    sortBy[sort[0].key] = sort[0].value === 'ascend' ? 1 : -1;

    if (!sort[0].value) {
      sortBy = null;
    }
    this.skipIndex = pageSize * (pageIndex - 1)
    this.getAllTopics(this.index, pageSize * (pageIndex - 1), sortBy);
  }
  /* For Pagination / Sending skip for grid view*/
  onQueryParamsChangeForGridView(index: any): void {
    this.allApprovedTopics = []
    this.allTopics = []
    this.pageIndex = index;
    this.getAllTopics(this.index, this.pageSize * (this.pageIndex - 1));
  }
  onQueryPageSizeChangeForGridView(size: any) {
    this.allApprovedTopics = []
    this.allTopics = []
    this.pageSize = size;
    this.getAllTopics(this.index, this.pageSize * (this.pageIndex - 1));
  }

  /* status change*/
  updateStatus(id: string, status: boolean): void {
    this.httpRequestService
      .request('put', `topics/active-inactive/${id}`, { status: !status })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllTopics(this.index);
      });
  }
  updatePendingStatus(id: string, pendingStatus: string) {
    this.httpRequestService
      .request('put', `topics/approved-rejected/${id}`, {
        approveStatus: 'approved',
      })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllTopics(this.index);
      });
  }
  openRejectionReason(id: string, pendingStatus: string) {
      const pendingStatusData = 'rejected';
      const rejectionReason = this.modalService.create({
        nzTitle: 'Add A Reason',
        nzContent: RejectionReasonComponent,
        nzWidth: '800px',
        nzFooter: null,
        nzComponentParams: {
          updateId: id,
          component: 'topics',
        },
        nzCancelText: null,
        nzOkText: null,
      });

    const questionService = rejectionReason.afterClose.subscribe(
      (data: any) => {
        this.getAllTopics(this.index);
       ;
      }
    );
  }
  onChangeSubject():void{
    this.getAllTopics(this.index);
    this.getAllChapters();
    }
    onChangeChapter():void{
    this.getAllTopics(this.index);
    }
  // search concept
  searchConcepts(event: any): void {
    this.searchConcept.next(event);
  }
  // seach Concept
  searchChapterForDropdown(event: any): void {
      this.searchChapter.next(event);
  }
  searchSubjectForDropdown(event: any): void {
      this.searchSubject.next(event);
  }
}
