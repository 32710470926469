import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService, HttpRequestService } from 'src/app/core/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: any;
  isLoading = false;
  isOtpSend: boolean = false;
  show: boolean = false;
  showButton:boolean = false;
  countdownInMinute: number = 2; // Initial countdownInMinute time in seconds
  countdownInterval: any; // Initial countdownInMinute time in seconds
  countdownInSec: number = 0; // Initial countdownInMinute time in seconds
  countdownInMinuteMinuteInterval: any; // Variable to store the interval reference
  countdownInMinuteSecInterval: any; // Variable to store the interval reference
  constructor(
    private fb: FormBuilder,
    private notificationService: NzNotificationService,
    private authService: AuthService,
    private router: Router,
    private httpRequestService: HttpRequestService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            '^([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$'
          ),
        ],
      ],
      password: [null, [Validators.required]],
      remember: [true],
    });
  }
  passwordShowHide() {
    this.show = !this.show;
  }
  startCountdownInMinute() {
    this.countdownInterval = setInterval(() => {
      if (this.countdownInMinute > 0 || this.countdownInSec > 0) {
        if (this.countdownInSec === 0) {
          this.countdownInMinute--;
          this.countdownInSec = 59; // Reset seconds to 59 when minutes decrement
        } else {
          this.countdownInSec--;
        }
        //;
      } else {
        // Countdown is complete, show the button
        this.showButton = true;
        clearInterval(this.countdownInterval); // Stop the interval
      }
    }, 1000); // Update the countdown every 1 second (1000 milliseconds)
  }
  sendOTP() {
    if (!this.loginForm.valid) {
      this.markFormGroupTouched(this.loginForm);
    } else {
      this.isLoading = true;
      this.httpRequestService
        .request('post', 'auth-admins/send-login-otp', {
          email: this.loginForm.value.email,
          password: this.loginForm.value.password,
        })
        .subscribe(
          (result) => {
            this.isLoading = false;
            this.loginForm.addControl('otp', new FormControl());
            this.isOtpSend = true;
            this.startCountdownInMinute();

          },
          (err) => {
            this.isLoading = false;
            this.notificationService.error('Error', err.error.message);
          }
        );
    }
  }
  resendOTP(){
    this.isLoading = true;
    this.showButton= false;
    this.isOtpSend = true;
    this.httpRequestService
    .request('post', 'auth-admins/send-login-otp', {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    })
      .subscribe(
        (result) => {
          this.isLoading = false;
          this.isOtpSend = true;
          this.countdownInMinute = 2;
          this.startCountdownInMinute();

        },
        (err) => {
          this.isLoading = false;
          this.notificationService.error('Error', err.error.message);
        }
      );
  }
  submitLoginForm(): void {
    if (!this.loginForm.valid) {
      this.markFormGroupTouched(this.loginForm);
    } else {
      this.isLoading = true;
      this.authService
        .login(
          this.loginForm.value.email,
          this.loginForm.value.password,
          this.loginForm.value.otp
        )
        .then((success) => {
          this.isLoading = false;
          this.notificationService.success('Success', 'Successfully Logged In');
        })
        .catch((error) => {
          this.isLoading = false
          this.notificationService.error('Error', error.error.message);
        });
    }
  }

  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }
}
