import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subjects } from 'src/app/core/interfaces/subjects';
import { HttpRequestService } from 'src/app/core/services';

@Component({
  selector: 'app-add-update-subject',
  templateUrl: './add-update-subject.component.html',
  styleUrls: ['./add-update-subject.component.scss']
})
export class AddUpdateSubjectComponent implements OnInit {

  addUpdateSubjectForm: FormGroup;
  idForUpdate: string;
  buttonLoading = false;
  checkAddPermission = false;
  checkUpdatePermission = false;
  tabIndex: number = 0
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.tabIndex = this.activatedRoute.snapshot.queryParams.index;
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.addUpdateSubjectForm = this.fb.group({
      subjectName: [null, [Validators.required]],
    });
  }

  // on page load functions
  ngOnInit(): void {
    if (this.idForUpdate) {
      this.getSubjectById();
    }
  }

  /* Get single subject details by Id */
  getSubjectById(): void {
    this.httpRequestService
      .request('get', `subjects/${this.idForUpdate}`)
      .subscribe(
        (result: any) => {
          const data:Subjects = result.data;
          this.addUpdateSubjectForm.patchValue({
            subjectName: data.subjectName
          });
        },
        (error: any) => {}
      );
  }

  /* Submit subject form */
  submit(): void {
    if (!this.addUpdateSubjectForm.valid) {
      this.markFormGroupTouched(this.addUpdateSubjectForm);
    } else {
      if (this.idForUpdate) {
        this.addOrUpdateSubject(
          'put',
          `subjects/${this.idForUpdate}`,
          'Subject Successfully Updated'
        );
      } else {
        this.addOrUpdateSubject(
          'post',
          'subjects',
          'Subject Added Successfully '
        );
      }
    }
  }

  /* Add Or Edit Subject */
  addOrUpdateSubject(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    this.buttonLoading = true;
    const finalData:Subjects = this.addUpdateSubjectForm.value;
    this.httpRequestService
      .request(requestMethod, requestURL, finalData)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          this.router.navigate(['/main/subjects'], { queryParams: {index: this.tabIndex } });
          this.buttonLoading = false;
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          }else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }

  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

}
