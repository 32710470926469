<nz-page-header class="site-page-header" nzBackIcon
    [nzTitle]="idForUpdate ? 'Update Current Affair' : 'Add Current Affair'">
</nz-page-header>
<form nz-form [formGroup]="addUpdateCurrentAffairForm">
    <nz-card nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
            <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="subjectName" nzRequired>Title</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                        <input nz-input formControlName="title" type="text" placeholder="Enter Title " />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="titleName">Title In Hindi</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                        <input nz-input formControlName="titleInHindi" type="text" placeholder="Enter Title " />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="exam-category">Tags
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                        <nz-select [nzMaxTagCount]="3" nzMode="tags" nzPlaceHolder="Please select"
                            formControlName="tags">
                            <nz-option *ngFor="let item of listOfTags" [nzLabel]="item.name"
                                [nzValue]="item.name"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzRequired nzFor="exam-category">Published Date
                    </nz-form-label>
                    <nz-form-control>
                        <nz-date-picker class="width-100" formControlName="publishedDate"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                        <nz-form-label [nzSpan]="24" nzRequired nzFor="description">Description</nz-form-label>
                        <ngx-suneditor [editorID]="'description'" (onChange)="onDescriptionChange($event)"
                            [options]="editorOptions">
                        </ngx-suneditor>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzLg="18" nzMd="18" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                        <nz-form-label [nzSpan]="24" nzFor="description">Description In Hindi</nz-form-label>
                        <ngx-suneditor (onChange)="onDescriptionHindiChange($event)" [editorID]="'descriptionInHindi'"
                            [options]="editorOptions">
                        </ngx-suneditor>
                    </nz-form-control>
                </nz-form-item>

            </div>
            <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzFor="subject">Image ( 1000px * 500px )</nz-form-label>
                    <nz-upload class="upload-list-inline" nzName="avatar"
                        (nzChange)="UploadImageFile($event, 'image', 0)" [nzName]="'file'" [nzShowUploadList]="false"
                        [nzBeforeUpload]="beforeRelatedMediaUpload" [nzAction]="mediaUploadUrl"
                        [nzHeaders]="customRequestHeaders">
                        <button nz-button *ngIf="!addUpdateCurrentAffairForm.value.image" [nzLoading]="imageLoading">
                            <span nz-icon nzType="upload"></span>
                            Upload
                        </button>
                    </nz-upload>
                    <nz-card *ngIf="addUpdateCurrentAffairForm.value.image" class="question-image-card">
                        <img nz-image width="auto" max-height="150px" class="img-responsive"
                            [nzSrc]="mediaBaseUrl + addUpdateCurrentAffairForm.value.image" />

                        <button nz-button nzDanger nzTooltipTitle="Delete" nz-tooltip nz-popconfirm
                            nzPopconfirmTitle="Are you sure delete this?"
                            (nzOnConfirm)="removeImage(addUpdateCurrentAffairForm.value.image)"
                            class="remove-image image-card-del-icon" nzType="primary">
                            <span nz-icon nzType="delete" nzTheme="outline"></span>
                        </button>
                    </nz-card>
                </nz-form-item>
            </div>
        </div>
        <div nz-row nzJustify="end">
            <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()" class="submitoraddbutton">
                {{idForUpdate? 'Update':'Submit'}}
            </button>
        </div>
    </nz-card>
</form>