import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-series-details',
  templateUrl: './test-series-details.component.html',
  styleUrls: ['./test-series-details.component.scss'],
})
export class TestSeriesDetailsComponent implements OnInit {
  @Input() value: any;
  TestSeriesDetails: any;
  totalQuestions: any[] = [];
  constructor() {}

  ngOnInit(): void {
    this.TestSeriesDetails = this.value;
    let total: number = 0;
    this.TestSeriesDetails.tests.forEach((item: any) => {
      item.test.section.forEach((section: any) => {
        const singleSectionQuestions = section.questions.length;
        total += singleSectionQuestions;
      });
      if (total) {
        this.totalQuestions.push(total);
      }
      total = 0;
    });
  }
}
