import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Chapter } from 'src/app/core/interfaces/chapter';
import { Subjects } from 'src/app/core/interfaces/subjects';
import { Topic } from 'src/app/core/interfaces/topic';
import { HttpRequestService } from 'src/app/core/services';

@Component({
  selector: 'app-add-update-topic',
  templateUrl: './add-update-topic.component.html',
  styleUrls: ['./add-update-topic.component.scss'],
})
export class AddUpdateTopicComponent implements OnInit {
  addUpdateTopicForm: FormGroup;
  idForUpdate: string;
  buttonLoading = false;
  allChapters: Chapter[] = [];
  allSubjects: Subjects[] = [];
  checkAddPermission = false;
  checkUpdatePermission = false;
  search = '';
  pageSize = 10;
  pageIndex = 1;
  searchChapter: Subject<string> = new Subject<string>();
  searchSubject: Subject<string> = new Subject<string>();
  subjectSearch = '';
  chapterSearch = '';
  totalDataCount: any
  totalChapterDataCount: any
  tabIndex: number = 0
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.tabIndex = this.activatedRoute.snapshot.queryParams.index;
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.addUpdateTopicForm = this.fb.group({
      topicName: [null, [Validators.required]],
      description: [null],
      chapter: [null],
      subject: [null, [Validators.required]],
    });
  }
  /* on page load */
  ngOnInit(): void {
      this.getAllChapters();
      this.getAllSubjects();

    if (this.idForUpdate) {
      this.getTopicById();
    }
    this.searchChapter.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.chapterSearch = success;
      this.getAllChapters();
    });
    this.searchSubject.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.subjectSearch = success;
    });
  }
  loadMore(data: any) {
    // calculate skip
    this.pageIndex = this.pageIndex + 1;
    const skip = this.pageSize * (this.pageIndex - 1);
    if(data === 'subject'){
      if (this.totalDataCount !== this.allSubjects.length) {
        this.getAllSubjects(skip);
      }
    }else{
      if (this.totalChapterDataCount !== this.allChapters.length) {
        this.getAllChapters(skip);
      }
    }
  }
  /* Get all Chapters */
  getAllChapters(skip = 0): void {
    const params: any = {
      skip,
      limit: this.pageSize,
      status: true,
      approveStatus: 'approved'
    };
    if (this.addUpdateTopicForm.value.subject) {
      params['subject'] = this.addUpdateTopicForm.value.subject;
    } else {
      delete params.subject;
    }
    if (this.chapterSearch) {
      params.search = this.chapterSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'chapters', params).subscribe(
      (result: any) => {
        //;

        this.allChapters.push(...result.data);
        this.totalChapterDataCount = result.totalCount;
      },
      (err: any) => {}
    );
  }

  /* Get all Subjects */
  getAllSubjects(skip = 0): void {
    const params: any = {
      skip,
      limit:this.pageSize,
      status: true,
      approveStatus: 'approved'
    };
    if (this.subjectSearch) {
      params.search = this.subjectSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'subjects', params).subscribe(
      (result: any) => {
        this.allSubjects.push(...result.data);
        this.totalDataCount = result.totalCount;
      },
      (err: any) => {}
    );
  }
  /* On change subject get chapter */
  onChangeSubject(value: string, skip = 0): void {
    this.allChapters = [];
    this.addUpdateTopicForm.patchValue({
      chapter: null,
    });
    this.getAllChapters();
  }

  /* Get single Topic details by Id */
  getTopicById(): void {
    this.httpRequestService
      .request('get', `topics/${this.idForUpdate}`)
      .subscribe(
        (result: any) => {
          const data : Topic = result.data;
          this.addUpdateTopicForm.patchValue({
            topicName: data.topicName,
            description: data.description,
            subject: data.subject,
            chapter: data.chapter,
          });
        },
        (error: any) => {}
      );
  }

  /* Submit Topic form */
  submit(): void {
    if (!this.addUpdateTopicForm.valid) {
      this.markFormGroupTouched(this.addUpdateTopicForm);
    } else {
      if (this.idForUpdate) {
        this.addOrUpdateTopic(
          'put',
          `topics/${this.idForUpdate}`,
          'Topics Successfully Updated'
        );
      } else {
        this.addOrUpdateTopic('post', 'topics', 'Topics Added Successfully ');
      }
    }
  }

  /* Add Or Edit Topic */
  addOrUpdateTopic(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    this.buttonLoading = true;
    const finalData: Topic = this.addUpdateTopicForm.value; 
    this.httpRequestService
      .request(requestMethod, requestURL, finalData)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          this.router.navigateByUrl('/main/topics');
          this.router.navigate(['/main/topics'], { queryParams: {index: this.tabIndex } });
          this.buttonLoading = false;
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          } else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }

  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

  // seach Concept
  searchChapterForDropdown(event: any): void {
    this.searchChapter.next(event);
  }
  searchSubjectForDropdown(event: any): void {
    this.searchSubject.next(event);
  }
}
