import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NgxSuneditorComponent } from 'ngx-suneditor';
import { Observable, Observer, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Editorial } from 'src/app/core/interfaces/editorial';
// import { Editorial } from 'src/app/core/interfaces/current-affair';
import { HttpRequestService } from 'src/app/core/services';
import { CkEditorConfigService } from 'src/app/core/services/ck-editor-config.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-add-update-editorial',
  templateUrl: './add-update-editorial.component.html',
  styleUrls: ['./add-update-editorial.component.scss'],
})
export class AddUpdateEditorialComponent implements OnInit {
  @ViewChildren(NgxSuneditorComponent) editors!: QueryList<NgxSuneditorComponent>;
  addUpdateEditorialForm: FormGroup;
  idForUpdate: string;
  buttonLoading = false;
  imageLoading = false;
  pageSize = 10;
  pageIndex = 1;
  totalTagDataCount: number = 0;
  thumbImageLoading = false;
  checkAddPermission = false;
  checkUpdatePermission = false;
  mediaBaseUrl = environment.mediaBaseUrl;
  mediaUploadUrl = environment.apiBaseUrl + '/api/media';
  searchExamCategory: Subject<string> = new Subject<string>();
  searchTag: Subject<string> = new Subject<string>();
  tagSearch: string = '';
  examCategorySearch: string = '';
  public editorOptions: any;
  listOfTags: any[] = [];
  tabIndex: number = 0
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private msg: NzMessageService,
    private ckEditorService: CkEditorConfigService,
    private httpClient : HttpClient
  ) {
    this.tabIndex = this.activatedRoute.snapshot.queryParams.index;
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.addUpdateEditorialForm = this.fb.group({
      title: [null, [Validators.required]],
      metaTitle: [null, [Validators.required]],
      description: [null, [Validators.required]],
      metaDescription: [null, [Validators.required]],
      image: [null],
      thumbnailImage: [null, [Validators.required]],
      tags: [[]],
      status: [true],
    });
  }
  onChange(data: any) {
    // this.ckeditor.instances.editor1.setData( '<p>This is the editor data.</p>' );
  }
  // on page load functions
  ngOnInit(): void {
    this.searchTag.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.tagSearch = success;
    this.getAllTags();

    });
    this.editorOptions = this.ckEditorService.editorOptions;
    if (this.idForUpdate) {
      this.editorOptions.height = '150';
      this.getEditorialsById();
    }
    this.getAllTags();
  }

  onDescriptionChange(content: any) {
    this.addUpdateEditorialForm.patchValue({
      description: content.content
    }, { emitEvent: false });
  }

  onMetaDescriptionChange(content: any) {
    this.addUpdateEditorialForm.patchValue({
      metaDescription: content.content
    }, { emitEvent: false });
  }
  //  send auth in headers //
  customRequestHeaders = () => {
    return { Authorization: `Bearer ${localStorage.getItem('token')}` };
  };
  /* Get single subject details by Id */
  getEditorialsById(): void {
    this.httpRequestService
      .request('get', `editorials/${this.idForUpdate}`)
      .subscribe(
        (result: any) => {
          const data: Editorial = result.data;
          this.addUpdateEditorialForm.patchValue({
            title: data.title,
            metaTitle: data.metaTitle,
            // description: data.description,
            // metaDescription: data.metaDescription,
            image: data.image,
            thumbnailImage: data.thumbnailImage,
            tags: data.tags,
            status: true,
          });
          
          this.editors.forEach((editor: any) => {
            if (editor.editorID === 'description') {
              editor.setContents(data.description);
            } else if (editor.editorID === 'metaDescription') {
              editor.setContents(data.metaDescription);
            }
          });
        },
        (error: any) => {}
      );
  }
  loadMore() {
    // calculate skip
    this.pageIndex = this.pageIndex + 1;
    const skip = this.pageSize * (this.pageIndex - 1);
    if (this.totalTagDataCount !== this.listOfTags.length) {
      this.getAllTags(skip);
      
    } else {
    }
  }

  onSearch(event: any): void {
    this.listOfTags = []
    this.searchTag.next(event);
  }
  /* Get all Tags */
  getAllTags(skip = 0): void {
    let params : any;
    params = { skip, limit: this.pageSize };
    if (this.tagSearch) {
      params.search =  this.tagSearch;
    }
    this.httpRequestService.request('get', 'current-affairs/tags', params).subscribe(
      (result: any) => {
        if(this.tagSearch !== ''){
          this.listOfTags = result.data;
        }else{
          this.listOfTags.push(...result.data);
        }
        
        this.totalTagDataCount = result.totalCount;
      },
      (err: any) => {}
    );
  }

  /* Submit subject form */
  submit(): void {
    if (!this.addUpdateEditorialForm.valid) {
      this.markFormGroupTouched(this.addUpdateEditorialForm);
    } else {
      if (this.idForUpdate) {
        this.addOrUpdateEditorials(
          'put',
          `editorials/${this.idForUpdate}`,
          'Editorial Successfully Updated'
        );
      } else {
        this.addOrUpdateEditorials(
          'post',
          'editorials',
          'Editorials Added Successfully '
        );
      }
    }
  }

  /* Add Or Edit Subject */
  addOrUpdateEditorials(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    this.buttonLoading = true;
    const finalData: Editorial = this.addUpdateEditorialForm.value;
    this.httpRequestService
      .request(requestMethod, requestURL, finalData)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          this.router.navigate(['/main/editorials'], { queryParams: {index: this.tabIndex } });
          this.buttonLoading = false;
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          } else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }

  // image Upload
  UploadImageFile(
    info: { file: NzUploadFile },
    FormControl: string,
    index: number
  ) {
    switch (info.file.status) {
      case 'uploading':
        if (FormControl === 'thumbnailImage') {
          this.thumbImageLoading = true;
        } else {
          this.imageLoading = true;
        }
        break;
      case 'done':
        if (FormControl === 'thumbnailImage') {
          this.addUpdateEditorialForm.patchValue({
            thumbnailImage: info.file.response.data.path,
          });
          this.thumbImageLoading = false;
        } else {
          this.imageLoading = false;
          this.addUpdateEditorialForm.patchValue({
            image: info.file.response.data.path,
          });
        }
        break;
      case 'error':
        this.msg.error('Network error');
        if (FormControl === 'thumbnailImage') {
          this.thumbImageLoading = false;
        } else {
          this.imageLoading = false;
        }
        break;
    }
  }

  beforeRelatedMediaUpload = (
    file: NzUploadFile,
    fileList: NzUploadFile[]
  ): Observable<any> => {
    return new Observable((observer: Observer<boolean>) => {
      const isImage =
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/JPG' ||
        file.type === 'image/JPEG' ||
        file.type === 'image/PNG';
      if (!isImage) {
        this.msg.error('You can only upload image file!');
        observer.complete();
        return;
      }
      const isLt3M = file.size ? file.size / 1024 / 1024 < 3 : false;
      if (!isLt3M) {
        this.msg.error('Image must smaller than 3MB!');
        observer.complete();
        return;
      }
      observer.next(isImage && isLt3M);
      observer.complete();
    });
  };
  removeImage(path: string): void {
    let params: any;
    params = { path: path };
    this.addUpdateEditorialForm.patchValue({
      image: null,
    });
    this.httpRequestService.request( 'post', 'media/delete-media', params ).subscribe((result: any)=>{
      if(result){
        this.notificationService.success('', 'Image Deleted Successfully');
      }
    });
  }
  removeThumbImage(path: string): void {
    let params: any;
    params = { path: path };
    this.addUpdateEditorialForm.patchValue({
      thumbnailImage: null,
    });
    this.httpRequestService.request( 'post', 'media/delete-media', params ).subscribe((result: any)=>{
      if(result && result.data){
        this.notificationService.success('', 'Image Deleted Successfully');
      }
    });
   

  }
  searchExamCategoryForDropdown(event: any): void {
    this.searchExamCategory.next(event);
  }

  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }
}
