<nz-page-header class="site-page-header" [nzTitle]="'Banner'">
</nz-page-header>
<form nz-form [formGroup]="addUpdateBannerForm">
    <div cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="slideFormArray.controls">
        <div *ngFor="let item of slideFormArray.controls as FormArray; index as i" cdkDrag>
            <nz-card class="mt2" formArrayName="slides" nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                <div nz-row nzJustify="end">
                    <button nz-button nzType="primary" *ngIf="addUpdateBannerForm.value.slides.length > 1" nz-popconfirm
                        nzPopconfirmTitle="Are you sure delete this Slide?" (click)="$event.stopPropagation()"
                        (nzOnConfirm)="removeSlide(i, item.value._id)" class="right-aline">
                        <span nz-icon nzType="delete" nzTheme="outline"></span>
                    </button>
                </div>
                <div [formGroupName]="i" nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                    <div nz-col nzLg="18" nzMd="18" nzSm="24" nzXs="24">
                        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                            <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
                                <nz-form-item>
                                    <nz-form-label [nzSpan]="24">Title</nz-form-label>
                                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                                        <input nz-input formControlName="title" type="text"
                                            placeholder="Enter Title " />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
                                <nz-form-item>
                                    <nz-form-label [nzSpan]="24">Position</nz-form-label>
                                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                                        <nz-input-number class="width-100" formControlName="position" [nzMin]="1"
                                            [nzStep]="1" nzDisabled></nz-input-number>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
                                <nz-form-item>
                                    <nz-form-label [nzSpan]="24" nzRequired>Redirect Url</nz-form-label>
                                    <nz-form-control [nzSpan]="24" [nzErrorTip]="redirectUrlErrorTip">
                                        <input nz-input formControlName="redirectUrl" type="url"
                                            placeholder="Enter Url " />
                                    </nz-form-control>
                                    <span>
                                        <small>
                                            <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                                            <span>
                                                <span>URL should be in the format of </span>
                                                <span
                                                    class="text-primary">https://mockshala.com/user-panel/freemocks</span>
                                                <span> or </span>
                                                <span class="text-primary">https://mockshala.com/user-panel/plans</span>
                                            </span>

                                        </small>
                                    </span>
                                </nz-form-item>
                                <ng-template #redirectUrlErrorTip>
                                    <ng-container *ngIf="slideFormArray.at(i).get('redirectUrl')?.hasError('required')">
                                        Should not be blank
                                    </ng-container>
                                    <ng-container
                                        *ngIf="this.slideFormArray.at(i).get('redirectUrl')?.hasError('pattern')">
                                        Please enter a valid URL
                                    </ng-container>
                                </ng-template>
                            </div>
                            <div nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
                                <nz-form-item>
                                    <nz-form-label [nzSpan]="24" nzRequired>Status</nz-form-label>
                                    <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                                        <nz-radio-group formControlName="status" nzName="radiogroup">
                                            <label nz-radio nzValue="active">Active</label>
                                            <label nz-radio nzValue="inActive">Inactive</label>
                                        </nz-radio-group>

                                    </nz-form-control>
                                </nz-form-item>
                            </div>

                        </div>
                    </div>
                    <div nz-col nzLg="6" nzMd="6" nzSm="24" nzXs="24">
                        <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                            <nz-form-item>
                                <nz-form-label [nzSpan]="24" nzFor="subject" nzRequired>Desktop Banner Image (2880px *
                                    544px)</nz-form-label>
                                <nz-upload class="upload-list-inline" nzName="avatar"
                                    (nzChange)="UploadImageFile($event, 'image', i)" [nzName]="'file'"
                                    [nzShowUploadList]="false" [nzBeforeUpload]="beforeRelatedMediaUpload"
                                    [nzAction]="mediaUploadUrl" [nzHeaders]="customRequestHeaders">
                                    <button nz-button *ngIf="!addUpdateBannerForm.value.slides[i].image"
                                        [nzLoading]="imageLoading">
                                        <span nz-icon nzType="upload"></span>
                                        Upload
                                    </button>
                                </nz-upload>
                                <nz-card *ngIf="addUpdateBannerForm.value.slides[i].image" class="question-image-card">
                                    <img nz-image width="auto" max-height="150px" class="img-responsive"
                                        [nzSrc]="mediaBaseUrl + addUpdateBannerForm.value.slides[i].image" />

                                    <button nz-button nzDanger nzTooltipTitle="Delete" nz-tooltip nz-popconfirm
                                        nzPopconfirmTitle="Are you sure delete this?"
                                        (nzOnConfirm)="removeImage(addUpdateBannerForm.value.slides[i].image, i)"
                                        class="remove-image image-card-del-icon" nzType="primary">
                                        <span nz-icon nzType="delete" nzTheme="outline"></span>
                                    </button>
                                </nz-card>
                            </nz-form-item>
                        </div>

                        <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
                            <nz-form-item>
                                <nz-form-label [nzSpan]="24" nzFor="subject" nzRequired>Mobile Banner Image (768px *
                                    332px)</nz-form-label>
                                <nz-upload class="upload-list-inline" nzName="avatar"
                                    (nzChange)="UploadImageFile($event, 'imageForMobile', i)" [nzName]="'file'"
                                    [nzShowUploadList]="false" [nzBeforeUpload]="beforeRelatedMediaUpload"
                                    [nzAction]="mediaUploadUrl" [nzHeaders]="customRequestHeaders">
                                    <button nz-button *ngIf="!addUpdateBannerForm.value.slides[i].imageForMobile"
                                        [nzLoading]="thumbImageLoading">
                                        <span nz-icon nzType="upload"></span>
                                        Upload
                                    </button>
                                </nz-upload>
                                <nz-card *ngIf="addUpdateBannerForm.value.slides[i].imageForMobile"
                                    class="question-image-card">
                                    <img nz-image width="auto" max-height="150px" class="img-responsive"
                                        [nzSrc]="mediaBaseUrl + addUpdateBannerForm.value.slides[i].imageForMobile" />

                                    <button nz-button nzDanger nzTooltipTitle="Delete" nz-tooltip nz-popconfirm
                                        nzPopconfirmTitle="Are you sure delete this?"
                                        (nzOnConfirm)="removeThumbImage(addUpdateBannerForm.value.slides[i].imageForMobile, i)"
                                        class="remove-image image-card-del-icon" nzType="primary">
                                        <span nz-icon nzType="delete" nzTheme="outline"></span>
                                    </button>
                                </nz-card>
                            </nz-form-item>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
    </div>
    <div class=" mt3 width-100" nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24" *ngIf="!idForUpdate">
        <button nz-button nzType="dashed" nzDanger nzBlock (click)="addSlides()">
            ADD MORE BANNERS<i nz-icon nzType="plus-circle" nzTheme="outline"></i>
        </button>
    </div>

    <div nz-row nzJustify="end" class="mt3">
        <button nz-button nzType="primary" [nzLoading]="buttonLoading" (click)="submit()" class="submitoraddbutton">
            Submit
        </button>
    </div>
</form>