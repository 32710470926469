import { Component, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject } from 'rxjs';
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  take,
} from 'rxjs/operators';
import { HttpRequestService, LocalStorageService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';
import { TestDetailsComponent } from '../test-details/test-details.component';
import { CommonArrayService } from 'src/app/core/services/common-array.service';
import { Test } from 'src/app/core/interfaces/test';
import { LocalArray } from 'src/app/core/interfaces/local-array';
import { Exam } from 'src/app/core/interfaces/exam';
import { RejectionReasonComponent } from 'src/app/shared/components/rejection-reason/rejection-reason.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { switchMap} from 'rxjs/operators';
import { toNumber } from 'ng-zorro-antd/core/util';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {
  allTests: Test[] = [];
  allApprovedTests: Test[] = [];
  isOpenDetails = true;
  totalDataCount = 0;
  totalApprovedDataCount = 0;
  approvedTestCount = 0;
  pendingTestCount = 0;
  rejectedTestCount = 0;
  pageSize = 10
  pageIndex = 1;
  loading = false;
  searchExam: Subject<string> = new Subject<string>();
  searchByExam: Subject<string> = new Subject<string>();
  search = '';
  checkAddPermission = false;
  checkUpdatePermission = false;
  visible: boolean = false;
  mediaUploadUrl = environment.mediaBaseUrl;
  difficultyLevelObject: any = {};
  testTypeObject: any = {};
  allLevels: LocalArray[] = []; // all types of tests.  Each is an object with name and id.  Used for dropdown list.  Used for form
  allTestTypes: LocalArray[] = [];
  selectedTestType: string = '';
  selectedDifficultyLevel: string = '';
  examSearch = '';
  selectedExam = '';
  allExams: Exam[] = [];
  listView = true;
  active: boolean = false;
  deActive: boolean = true;
  isTestComponent: boolean = true;
  userRole: string = '';
  subAdminAddedTests: Test[] = [];
  publishedStatusObject: any;
  allPublishedStatus: LocalArray[] = [];
  index: number = 0;
  skipIndex: number = 0;
  selectedSubAdmin = '';
  allSubAdmins: any[] = [];
  searchSubAdmin: Subject<string> = new Subject<string>();
  subAdminSearch = '';
  snapShootIndex: number = 0
  // publishedStatus: any
  constructor(
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private drawerService: NzDrawerService,
    private commonArrayService: CommonArrayService,
    private localStorageService: LocalStorageService,
    private modalService: NzModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.snapShootIndex = toNumber(this.activatedRoute.snapshot.queryParams.index) ;
    this.userRole = this.localStorageService.getItem('user').role;

    if(this.snapShootIndex){
      this.changeIndex(this.snapShootIndex)
    }
  }

  ngOnInit(): void {
    this.allPublishedStatus = this.commonArrayService.publishedStatus;
    this.allTestTypes = this.commonArrayService.testsTypes;
    this.allLevels = this.commonArrayService.difficultyLevel;
    this.difficultyLevelObject =
      this.commonArrayService.difficultyLevelObjectByValue;
    this.testTypeObject = this.commonArrayService.testsTypesObjectByValue;
    this.publishedStatusObject =
      this.commonArrayService.publishedStatusObjectByValue;
    this.searchExam.pipe(debounceTime(1000)).subscribe((success) => {
      this.search = success;
      this.getAllTests(this.index);
    });

    this.searchByExam.pipe(debounceTime(1000)).subscribe((success) => {
      this.examSearch = success;
      this.getAllExams();
    });
    this.searchSubAdmin.pipe(debounceTime(1000)).subscribe((success) => {
      this.subAdminSearch = success;
      this.getAllSubAdmins();
    });
    this.getAllExams();
    this.getApprovedUnApprovedTestCount();
  }

  getApprovedUnApprovedTestCount() {
    let params: any;
    params = {};
    if (this.selectedExam) {
      params.exam = this.selectedExam;
    } else {
      delete params.exam;
    }
    if (this.search) {
      params.search = this.search;
    }
    if (this.selectedDifficultyLevel) {
      params.difficultyLevel = this.selectedDifficultyLevel;
    } else {
      delete params.difficultyLevel;
    }
    if (this.selectedTestType) {
      params.testType = this.selectedTestType;
    } else {
      delete params.testType;
    }
    
    this.httpRequestService
      .request('get', 'tests/approved-unapproved-count', params)
      .subscribe(
        (result: any) => {
          const data = result.data;
          this.approvedTestCount = data.approved;
          this.pendingTestCount = data.pending;
          this.rejectedTestCount = data.rejected;
        },
        (err: any) => {}
      );
  }

  changeIndex(data: any) {
    this.router.navigate([], { queryParams: {index: data } });
    this.pageIndex = 1;
    this.pageSize = 20;
    this.allTests = [];
    this.allApprovedTests = []
    this.index = data
    this.getAllTests();
    // console.log(this.index)

  }
  onChangeDifficultyLevel() {
    this.getAllTests(this.index);
  }
  onChangeTestType() {
    this.getAllTests(this.index);
  }
  onChangeExam(): void {
    this.getAllTests(this.index);
  }
  onChangeSubAdmin(): void {
    this.getAllTests(this.index);
  }
  changeGridView() {
    this.listView = false;
    this.active = true;
    this.deActive = false;
  }
  changeListView() {
    this.listView = true;
    this.active = false;
    this.deActive = true;
  }
  /* Get all Subjects */
  getAllExams(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved',
    };
    if (this.examSearch) {
      params.search = this.examSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'exams', params).subscribe(
      (result: any) => {
        this.allExams = result.data;
      },
      (err: any) => {}
    );
  }

  getAllSubAdmins(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      role :'subAdmin'
    };
    if (this.selectedSubAdmin) {
      params.search = this.selectedSubAdmin;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'auth-admins', params).subscribe(
      (result: any) => {
        this.allSubAdmins = result.data;
      },
      (err: any) => {}
    );
  }

  getAllTests(index: number = 0, skip = 0, sortBy?: any): void {
    index = index? index : this.index
    this.getApprovedUnApprovedTestCount();
    let params: any;
    params = { skip:this.skipIndex, limit: this.pageSize };
    if (this.selectedExam) {
      params.exam = this.selectedExam;
    } else {
      delete params.exam;
    }
    if (this.search) {
      params.search = this.search;
    }
    if (this.selectedDifficultyLevel) {
      params.difficultyLevel = this.selectedDifficultyLevel;
    } else {
      delete params.difficultyLevel;
    }
    if (this.selectedTestType) {
      params.testType = this.selectedTestType;
    } else {
      delete params.testType;
    }
    if (this.selectedSubAdmin) {
      params.adminId = this.selectedSubAdmin;
    } else {
      delete params.adminId;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }

    // Set approveStatus based on the index value
    if (index === 0) {
      params.approveStatus = 'approved';
    } else if (index === 1 && this.userRole === 'admin') {
      params.approveStatus = 'pending';
    } else if (index === 1 && this.userRole === 'subAdmin') {
      params.approveStatus = ['pending', 'rejected'];
    } else {
      delete params.approveStatus; // Remove the property if index doesn't match any condition
    }

    this.loading = true;

    // Use switchMap to switch to new observable and cancel previous requests
    this.httpRequestService.request('get', 'tests', params).subscribe(
      (result) => {
        this.loading = false;
        const data: Test[] = result.data;
        if (index === 0) {
          this.allApprovedTests = data;
          this.totalApprovedDataCount = result.totalCount
        } else if(index === 1) {
          this.allTests = data;
          this.totalDataCount = result.totalCount;
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  // Subject to emit when index changes

  /* For Pagination / Sending skip */
  // onQueryParamsChange(params: NzTableQueryParams): void {
  //   const { pageSize, pageIndex, sort } = params;
  //   let sortBy: any = {};
  //   sortBy[sort[0]?.key] = sort[0].value === 'ascend' ? 1 : -1;

  //   if (!sort[0].value) {
  //     sortBy = null;
  //   }
  //   this.getAllExams(pageSize * (pageIndex - 1), sortBy);
  // }
  /* For Pagination / Sending skip */
  onQueryParamsChange(params: NzTableQueryParams): void {
    this.allTests = [];
    const { pageSize, pageIndex } = params;
    this.skipIndex = pageSize * ( pageIndex -1 )
    this.getAllTests(this.index, pageSize * (pageIndex - 1));
  }

  onQueryParamsChangeForGridView(index: any): void {
    // const { pageSize, pageIndex} = params;
    this.pageIndex = index;
    this.skipIndex = this.pageSize * (this.pageIndex - 1)
    this.getAllTests(this.index, this.pageSize * (this.pageIndex - 1));
  }
  onQueryPageSizeChangeForGridView(size: any) {
    this.pageSize = size;
    this.skipIndex = this.pageSize * (this.pageIndex - 1)
    this.getAllTests(this.index, this.pageSize * (this.pageIndex - 1));
  }
  /* status change*/
  updateStatus(id: string, status: boolean): void {
    this.httpRequestService
      .request('put', `tests/active-inactive/${id}`, { status: !status })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllTests();
      });
  }
  updatePendingStatus(id: string, pendingStatus: string) {
    this.httpRequestService
      .request('put', `tests/approved-rejected/${id}`, {
        approveStatus: 'approved',
      })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllTests(this.index);
      });
  }
  openRejectionReason(id: string, pendingStatus: string) {
    const pendingStatusData = 'rejected';

    const rejectionReason = this.modalService.create({
      nzTitle: 'Add A Reason',
      nzContent: RejectionReasonComponent,
      nzWidth: '800px',
      nzFooter: null,
      nzComponentParams: {
        updateId: id,
        component: 'tests',
      },
      nzCancelText: null,
      nzOkText: null,
    });

    const questionService = rejectionReason.afterClose.subscribe(
      (data: any) => {
        this.getAllTests(this.index);
        this.getApprovedUnApprovedTestCount();
      }
    );
  }

  removeTest(id: string): void {
    this.httpRequestService
      .request('delete', `tests/${id}`)
      .subscribe((result: any) => {
        this.notificationService.success('', 'Test Deleted Successfully');
        this.getAllTests(this.index);
      });
  }
  onChangeStatus(status: any, id: any) {
    //
    this.httpRequestService
      .request('put', `tests/published-status/${id}`, {
        publishedStatus: `${status}`,
      })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllTests(this.index);
      });
  }
  // search concept
  searchQuestions(event: any): void {
    this.searchExam.next(event);
  }
  searchExamForDropdown(event: any): void {
    this.searchByExam.next(event);
  }
  searchSubAdminForDropdown(event: any): void {
    this.searchSubAdmin.next(event);
  }
  openTestDetails(item: any) {
    item['loading'] = true;
    this.httpRequestService
      .request('get', `tests/${item._id}`)
      .subscribe((result: any) => {
        item['loading'] = false;
        const data = result;
        console.log('test>>>>>>>>>>>>>>>>', data);
        const drawerRef = this.drawerService.create({
          nzTitle: 'Test Details',
          nzFooter: '',
          nzWidth: '80%',
          nzContent: TestDetailsComponent,
          nzContentParams: {
            value: data.data,
          },
        });
      }, (err) => {
        item['loading'] = false;
      });
  }


}
