<div class="outerContainer lgrgbg">
  <nz-card class="loginCard">
    <div nz-row nzAlign="middle">
      <div nz-col nzXl="24" nzLg="24" nzMd="24" nzSm="24" nzXs="24">
        <div class="reg-log-form-inner">
          <!-- <div class="logo-area mb2">
            <img src="/assets/images/logo.jpg" alt="" />
          </div> -->
          <h1>Mockshala</h1>
          <h2 class="loginHeader">Sign In</h2>
          <form nz-form [formGroup]="loginForm" class="login-form">
            <nz-form-item class="loginfld">
              <nz-form-control nzErrorTip="Please input your email!" [nzErrorTip]="errorTplEmail">
                <input type="text" nz-input formControlName="email" placeholder="Email" [readonly]="isOtpSend" />
                <ng-template #errorTplEmail let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Please input your email address
                  </ng-container>
                  <ng-container *ngIf="control.hasError('pattern')">
                    Please input a valid email address
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item class="loginfld">
              <nz-form-control nzErrorTip="Please input your Password!">
                <input [type]="show ? 'text' : 'password'" nz-input formControlName="password" placeholder="Password" [readonly]="isOtpSend"/>
                <span class="eye-icon" nz-icon (click)="passwordShowHide()" nzType="{{show ? 'eye-invisible' :'eye'}}" nzTheme="outline"></span>
                <!-- <button (click)="passwordShowHide()"></button> -->
              </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="isOtpSend">
              <nz-form-control nzErrorTip="Please input OTP!">
                <nz-input-group nzPrefixIcon="lock">
                  <input type="text" nz-input formControlName="otp" placeholder="OTP" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <div class="text-right">
              <P class="top-margin" *ngIf="!showButton && isOtpSend"> Resend OTP in: {{ countdownInMinute }}:{{countdownInSec}} sec</P>
              <button nz-button class="top-margin" [nzType]="'link'"
                      *ngIf="showButton" [nzLoading]="isLoading" (click)="resendOTP()">Resend OTP</button>
          </div>
            <div nz-col [nzSpan]="24" class="login-button">
              <!-- <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'"
                (click)="submitLoginForm()" [nzLoading]="isLoading">
                SUBMIT
              </button> -->
              <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'" *ngIf="!isOtpSend" [nzLoading]="isLoading" (click)="sendOTP()"
        >Log
        in</button>
        <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'" *ngIf="isOtpSend" [nzLoading]="isLoading" (click)="submitLoginForm()"
        >Submit</button>
            </div>

            <div nz-row class="login-form-margin">
              <div nz-col [nzSpan]="24" class="login-form-forgot">
                Forgot Password? <a [routerLink]="['/auth/forgot-password']">Reset Password</a>.
              </div>
            </div>
            <!-- <div class="text-right">
              <a [routerLink]="['/auth/forgot-password']">forgot Password?</a>
            </div> -->
          </form>
        </div>
      </div>
    </div>
  </nz-card>
</div>