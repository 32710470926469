<nz-page-header
  class="site-page-header"
  nzBackIcon
  [nzTitle]="idForUpdate ? 'Update Exam' : 'Add Exam'"
>
</nz-page-header>
<form nz-form [formGroup]="addUpdateExamForm">
  <div nz-row>
    <nz-card nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="topicName" nzRequired
          >Exam name</nz-form-label
        >
        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
          <input
            nz-input
            formControlName="examName"
            type="text"
            placeholder="Enter Exam Name "
          />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="subject" nzRequired
          >Exam Category</nz-form-label
        >
        <nz-form-control [nzSpan]="24" nzErrorTip="Please select your subject!">
          <nz-select
          nzAllowClear
            nzShowSearch
            nzServerSearch
            id="examCategory"
            formControlName="examCategory"
            nzPlaceHolder="Select a option"
            (nzOnSearch)="searchExamCategoryForDropdown($event)"
          >
            <nz-option
              *ngFor="let exam of allExamCategories"
              nzValue="{{ exam._id }}"
              nzLabel="{{ exam.categoryName }}"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <!-- <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="chapter" nzRequired
          >Chapter</nz-form-label
        >
        <nz-form-control [nzSpan]="24" nzErrorTip="Please select your chapter!">
          <nz-select
            nzShowSearch
            nzServerSearch
            id="chapter"
            formControlName="chapter"
            nzPlaceHolder="Select a option"
            (nzOnSearch)="searchConceptForDropdown($event)"
          >
            <nz-option
              *ngFor="let chapter of allChapters"
              nzValue="{{ chapter._id }}"
              nzLabel="{{ chapter.chapterName }}"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item> -->

      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="description"
          >Exam Description</nz-form-label
        >
        <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
          <textarea
            formControlName="description"
            nz-input
            rows="4"
            placeholder="Enter Exam description"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
      <div nz-row nzJustify="end">
        <button
          nz-button
          nzType="primary"
          [nzLoading]="buttonLoading"
          (click)="submit()"
          class="submitoraddbutton"
        >
        {{idForUpdate? 'Update':'Submit'}}
        </button>
      </div>
    </nz-card>
  </div>
</form>