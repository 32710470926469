import { Component, Input, OnChanges, OnInit, SimpleChanges, ElementRef } from '@angular/core';
import { GlobalService } from 'src/app/core/services/global.service';

@Component({
  selector: 'app-mathjax',
templateUrl: './mathjax.component.html',
styleUrls: ['./mathjax.component.scss']
})
export class MathjaxComponent implements OnInit, OnChanges {
  @Input() content = '';
  
  constructor(
    public gs: GlobalService,
    private el: ElementRef
  ) { }

  // Load KaTeX scripts dynamically
  loadKatexScripts(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Check if KaTeX is already loaded
      if (this.gs.nativeGlobal().katex) {
        resolve();
        return;
      }

      // Load KaTeX CSS
      const linkElement = document.createElement('link');
      linkElement.rel = 'stylesheet';
      linkElement.href = 'https://cdn.jsdelivr.net/npm/katex@0.16.8/dist/katex.min.css';
      document.head.appendChild(linkElement);

      // Load KaTeX JS
      const scriptElement = document.createElement('script');
      scriptElement.src = 'https://cdn.jsdelivr.net/npm/katex@0.16.8/dist/katex.min.js';
      scriptElement.onload = () => {
        // Load auto-render extension after KaTeX is loaded
        const autoRenderScript = document.createElement('script');
        autoRenderScript.src = 'https://cdn.jsdelivr.net/npm/katex@0.16.8/dist/contrib/auto-render.min.js';
        autoRenderScript.onload = () => resolve();
        autoRenderScript.onerror = () => reject(new Error('Failed to load KaTeX auto-render extension'));
        document.head.appendChild(autoRenderScript);
      };
      scriptElement.onerror = () => reject(new Error('Failed to load KaTeX'));
      document.head.appendChild(scriptElement);
    });
  }

  // on Change
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.content) {
      this.renderKatex();
    }
  }

  // Render mathematical/Chemical equation
  async renderKatex(): Promise<void> {
    if (!this.content) return;
    
    try {
      // Ensure KaTeX is loaded
      await this.loadKatexScripts();
      
      const katex = this.gs.nativeGlobal().katex;
      if (!katex) {
        console.error('KaTeX still not loaded after attempting to load it');
        return;
      }
      
      const mathContentElement = this.el.nativeElement.querySelector('#mathContent');
      if (!mathContentElement) return;
      
      // Set the content
      mathContentElement.innerHTML = this.content;
      
      // Auto-render all math in the content
      const renderMathInElement = this.gs.nativeGlobal().renderMathInElement;
      if (renderMathInElement) {
        renderMathInElement(mathContentElement, {
          delimiters: [
            {left: '$$', right: '$$', display: true},
            {left: '$', right: '$', display: false},
            {left: '\\(', right: '\\)', display: false},
            {left: '\\[', right: '\\]', display: true}
          ],
          throwOnError: false
        });
      } else {
        console.error('KaTeX auto-render extension not loaded');
      }
    } catch (error) {
      console.error('KaTeX rendering error:', error);
    }
  }

  // on page load functions
  ngOnInit(): void {
    this.renderKatex();
  }
}