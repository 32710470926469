import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ExamCategory } from 'src/app/core/interfaces/exam-category';
import { Stories } from 'src/app/core/interfaces/stories';
import { HttpRequestService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-update-stories',
  templateUrl: './add-update-stories.component.html',
  styleUrls: ['./add-update-stories.component.scss']
})
export class AddUpdateStoriesComponent implements OnInit {

  addUpdateSuccessStoriesForm: FormGroup;
  idForUpdate: string;
  buttonLoading = false;
  imageLoading = false;
  checkAddPermission = false;
  checkUpdatePermission = false;
  mediaBaseUrl = environment.mediaBaseUrl;
  mediaUploadUrl = environment.apiBaseUrl + '/api/media';   
  searchExamCategory: Subject<string> = new Subject<string>();
  examCategorySearch:string = '';
  allExamCategories: ExamCategory[] = [];
  tabIndex: number = 0
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private msg: NzMessageService,
  ) {
    this.tabIndex = this.activatedRoute.snapshot.queryParams.index;
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.addUpdateSuccessStoriesForm = this.fb.group({
      name: [null, [Validators.required]],
      description: [null],
      image: [null],
      starCount:[null],
      examCategory: [null, [Validators.required]],
      status:[true] 
    });
  }

  // on page load functions
  ngOnInit(): void {
    this.getAllExamsCategory();
    if (this.idForUpdate) {
      this.getStoryById();
    }
    this.searchExamCategory
      .pipe(debounceTime(1000))
      .subscribe((success: any) => {
        this.examCategorySearch = success;
        this.getAllExamsCategory();
      });
  }
   /* Get all exam-category */
  getAllExamsCategory(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved'
    };
    if (this.examCategorySearch) {
      params.search = this.examCategorySearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'exam-categories', params).subscribe(
      (result: any) => {
        this.allExamCategories = result.data;
      },
      (err: any) => {}
    );
  }
   //  send auth in headers //
  customRequestHeaders = () => {
    return { Authorization: `Bearer ${localStorage.getItem('token')}` };
  };
  /* Get single subject details by Id */
  getStoryById(): void {
    this.httpRequestService
      .request('get', `success-stories/${this.idForUpdate}`)
      .subscribe(
        (result: any) => {
          const data:Stories = result.data;
         
          this.addUpdateSuccessStoriesForm.patchValue({
            // subjectName: data.subjectName
            name: data.name,
            starCount: data.starCount,
            description: data.description,
            image: data.image,
            examCategory: data.examCategory?._id,
            status:true,

          });
        },
        (error: any) => {}
      );
  }

  /* Submit subject form */
  submit(): void {
    if (!this.addUpdateSuccessStoriesForm.valid) {
      this.markFormGroupTouched(this.addUpdateSuccessStoriesForm);
    } else {
      if (this.idForUpdate) {
        this.addOrUpdateStory(
          'put',
          `success-stories/${this.idForUpdate}`,
          'Story Successfully Updated'
        );
      } else {
        this.addOrUpdateStory(
          'post',
          'success-stories',
          'Story Added Successfully '
        );
      }
    }
  }

  /* Add Or Edit Subject */
  addOrUpdateStory(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    this.buttonLoading = true;
    const finalData:Stories = this.addUpdateSuccessStoriesForm.value;
    this.httpRequestService
      .request(requestMethod, requestURL, finalData)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          this.router.navigate(['/main/success-stories'], { queryParams: {index: this.tabIndex } });
          this.buttonLoading = false;
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          }else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }

  // image Upload
  UploadImageFile(
    info: { file: NzUploadFile },
    FormControl: string,
    index: number
  ) {
    switch (info.file.status) {
      case 'uploading':
          this.imageLoading = true;
        break;
      case 'done':
        this.imageLoading = false;
          this.addUpdateSuccessStoriesForm.patchValue({
            image: info.file.response.data.path,
          });
        break;
      case 'error':
        this.msg.error('Network error');
        this.imageLoading = false;
        break;
    }
  }

  beforeRelatedMediaUpload = (
    file: NzUploadFile,
    fileList: NzUploadFile[]
  ): Observable<any> => {
    return new Observable((observer: Observer<boolean>) => {
     ;
      
      const isImage =
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/JPG' ||
        file.type === 'image/JPEG' ||
        file.type === 'image/PNG';
      if (!isImage) {
        this.msg.error('You can only upload image file!');
        observer.complete();
        return;
      }
      const isLt3M = file.size ? file.size / 1024 / 1024 < 3 : false;
      if (!isLt3M) {
        this.msg.error('Image must smaller than 3MB!');
        observer.complete();
        return;
      }
      observer.next(isImage && isLt3M);
      observer.complete();
    });
  };
  removeImage(path: string): void {
    let params: any;
    params = { path: path };
    this.addUpdateSuccessStoriesForm.patchValue({
      image: null,
    });
    this.httpRequestService.request( 'post', 'media/delete-media', params ).subscribe((result: any)=>{
      if(result){
        this.notificationService.success('', 'Image Deleted Successfully');
      }
    });
  }
  searchExamCategoryForDropdown(event: any): void {
    this.searchExamCategory.next(event);
}

  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

}
