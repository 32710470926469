import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { mockShalaAdminMenu, mockShalaSubAdminMenu } from '../data/menu.data';
import { LocalStorageService } from './local-storage.service';
@Injectable({
  providedIn: 'root',
})
export class MenuService {
  menu: Subject<any[]> = new Subject<any[]>();
  childKeyArray = ['banners', 'editorials', 'subjects', 'chapters', 'topics', 'exam', 'exam-category', 'success-stories', 'current-affairs', 'resource', 'testimonial']
  constructor(private localStorageService: LocalStorageService) {}
  public generateMenu(userRole: string): void {
    let keysToInclude =this.localStorageService.getItem('user').allowedRoute;
    let mastersAdded = false;
      keysToInclude = keysToInclude.reduce((acc: any, item: any) => {
      if (this.childKeyArray.includes(item)) {
        if (!mastersAdded) {
          acc.push('masters');
          mastersAdded = true;
        }
      }
      acc.push(item);
      return acc;
    }, []);
    //

    const filteredMenu = mockShalaSubAdminMenu.filter(item => keysToInclude.includes(item.key)).map((menuItem)=> {
      if (keysToInclude.includes(menuItem.key)) {
        return {
          ...menuItem,
          children: menuItem.children.filter((childItem) =>
          keysToInclude.includes(childItem.key)
          ),
        };
      } else {
        return menuItem;
      }
    });

    if(userRole === 'subAdmin'){
      this.menu.next( filteredMenu);
    }else{
      this.menu.next(mockShalaAdminMenu);
    }

    // if (userRole === 'admin') {
    //   this.menu.next(etAdminMenu);
    // }
  }
}
