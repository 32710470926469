import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceRoutingModule } from './resource-routing.module';
import { ResourcesComponent } from './resources/resources.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddUpdateResourceComponent } from './add-update-resource/add-update-resource.component';


@NgModule({
  declarations: [
    ResourcesComponent,
    AddUpdateResourceComponent
  ],
  imports: [
    CommonModule,
    ResourceRoutingModule,
    SharedModule
  ]
})
export class ResourceModule { }
