import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SubjectsComponent } from './subjects/subjects.component';
import { AddUpdateSubjectComponent } from './add-update-subject/add-update-subject.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'list',
  },
  {
    path: '',
    component: SubjectsComponent,
  },
  {
    path: 'add',
    component: AddUpdateSubjectComponent,
  },
  {
    path: 'update/:id',
    component: AddUpdateSubjectComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubjectRoutingModule {}
