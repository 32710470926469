import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const user = this.authService.getLocalUser(); // Get the logged-in user from your AuthService or user service

    // If user is an admin, allow access to all routes
    if (user && user.role === 'admin') {
      return true;
    }

    // If user is a sub-admin, check if the route's key is in their allowed keys
    if (user && user.role === 'subAdmin') {
      let allowedKeys = user.allowedRoute;
      // if(allowedKeys.includes('masters')){
      //   allowedKeys.push('subjects', 'chapters', 'topics', 'exam', 'exam-category', 'success-stories', 'current-affairs', 'resource')
      // }
      const routeKey = next.data.key;
      if (allowedKeys.includes(routeKey)) {
        return true; // Allow access to the route
      }
    }

    // If the user is neither admin nor sub-admin or doesn't have access, redirect to an unauthorized page or handle access denied logic
    this.router.navigate(['/unauthorized']);
    return false;
  }
}
