import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EditorialRoutingModule } from './editorial-routing.module';
import { EditorialsComponent } from './editorials/editorials.component';
import { AddUpdateEditorialComponent } from './add-update-editorial/add-update-editorial.component';
import { EditorialDetailsComponent } from './editorial-details/editorial-details.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    EditorialsComponent,
    AddUpdateEditorialComponent,
    EditorialDetailsComponent
  ],
  imports: [
    CommonModule,
    EditorialRoutingModule,
    SharedModule
  ]
})
export class EditorialModule { }
