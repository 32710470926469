import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Chapter } from 'src/app/core/interfaces/chapter';
import { Question } from 'src/app/core/interfaces/question';
import { Subjects } from 'src/app/core/interfaces/subjects';
import { Topic } from 'src/app/core/interfaces/topic';
import { HttpRequestService } from 'src/app/core/services';
import { CommonArrayService } from 'src/app/core/services/common-array.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-question-bulk-approve',
  templateUrl: './question-bulk-approve.component.html',
  styleUrls: ['./question-bulk-approve.component.scss'],
})
export class QuestionBulkApproveComponent implements OnInit {
  @Input() type = 'normal-question';
  previewDetails: any;
  difficultyLevelObject: any = {};
  questionTypeObject: any = {};
  allQuestionsId: any[] = [];
  buttonLoading: boolean = false;
  loading: boolean = false;

  // difficultyLevelObject: any = {};
  difficultyLevelForQuestion: string = '';
  dateRange: any;
  selectedChapter: string = '';
  selectedAdmin: string = '';
  selectedSubject: string = '';
  selectedTopic: string = '';
  allLevels: any[] = [];
  allTopics: Topic[] = [];
  allChapters: Chapter[] = [];
  allSubjects: Subjects[] = [];
  chapterSearch: any;
  subjectSearch: any;
  topicSearch: any;
  adminSearch: any;
  totalDataCount = 0;
  searchSubject: Subject<string> = new Subject<string>();
  searchChapter: Subject<string> = new Subject<string>();
  searchTopics: Subject<string> = new Subject<string>();
  searchAdmin: Subject<string> = new Subject<string>();
  startDate: any;
  endDate: any;
  allAdmins: any[] = [];
  constructor(
    private commonService: CommonService,
    private commonArrayService: CommonArrayService,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private router: Router,
    private drawerReference: NzDrawerRef
  ) {
    this.difficultyLevelObject =
      this.commonArrayService.difficultyLevelObjectByValue;
    this.questionTypeObject = this.commonArrayService.questionTypeObjectByValue;
    //
  }

  ngOnInit(): void {
    if (this.type === 'normal-question') {
      this.normalUnApproveQuestion();
    } else {
      this.paragraphUnApproveQuestion();
    }

    this.searchSubject.pipe(debounceTime(1000)).subscribe((success) => {
      this.subjectSearch = success;
      this.getAllSubjects();
    });
    this.searchAdmin.pipe(debounceTime(1000)).subscribe((success) => {
      this.adminSearch = success;
      this.getAllAdmins();
    });
    this.searchChapter.pipe(debounceTime(1000)).subscribe((success) => {
      this.chapterSearch = success;
      this.getAllChapters();
    });
    this.searchTopics.pipe(debounceTime(1000)).subscribe((success) => {
      this.topicSearch = success;
      this.getAllTopics();
    });
  }
  submitApproveQuestion() {
    this.buttonLoading = true;
    this.allQuestionsId = [];
    this.previewDetails.forEach((item: any) => {
      this.allQuestionsId.push(item._id);
    });
    let requestMethod = 'post';
    let requestURL = 'questions/bulk-approve';
    let successMessage = 'Question approved successfully';

    this.httpRequestService
      .request(requestMethod, requestURL, this.allQuestionsId)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          this.drawerReference.close();
          this.router.navigateByUrl('/main/question');
          this.buttonLoading = false;
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          } else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }
  getAllAdmins(skip = 0, sortBy?: any): void {
    let params: any;
    params = { skip, limit: 0, role: 'subAdmin' };
    if (this.adminSearch) {
      params.search = this.adminSearch;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    this.httpRequestService.request('get', 'auth-admins', params).subscribe(
      (result) => {
        this.allAdmins = result.data;
      },
      (err) => {
        this.loading = false;
      }
    );
  }
  normalUnApproveQuestion(): void {
    let params: any;
    params = {
      skip: 0,
      limit: 0,
      questionType: 'NORMAL',
      approveStatus: 'pending',
    };
    if (this.selectedAdmin) {
      params.adminId = this.selectedAdmin;
    } else {
      delete params.adminId;
    }
    if (this.selectedSubject) {
      params.subject = this.selectedSubject;
    } else {
      delete params.subject;
    }
    if (this.selectedChapter) {
      params.chapter = this.selectedChapter;
    } else {
      delete params.chapter;
    }
    if (this.selectedTopic) {
      params.topic = this.selectedTopic;
    } else {
      delete params.topic;
    }
    if (
      this.difficultyLevelForQuestion !== '' &&
      this.difficultyLevelForQuestion !== null
    ) {
      params.difficultyLevel = this.difficultyLevelForQuestion;
    } else {
      delete params.difficultyLevel;
    }
    if (this.startDate && this.endDate) {
      params.startDate = this.startDate;
      params.endDate = this.endDate;
    } else {
      delete params.startDate;
      delete params.endDate;
    }
    this.loading = true;
    this.httpRequestService.request('get', 'questions', params).subscribe(
      (result) => {
        this.loading = false;
        const data: Question[] = result.data;

        this.previewDetails = data;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  paragraphUnApproveQuestion(): void {
    let params: any;
    params = {
      skip: 0,
      limit: 0,
      questionType: 'PARAGRAPH',
      approveStatus: 'pending',
    };
    if (this.selectedAdmin) {
      params.adminId = this.selectedAdmin;
    } else {
      delete params.adminId;
    }
    if (this.selectedSubject) {
      params.subject = this.selectedSubject;
    } else {
      delete params.subject;
    }
    if (this.selectedChapter) {
      params.chapter = this.selectedChapter;
    } else {
      delete params.chapter;
    }
    if (this.selectedTopic) {
      params.topic = this.selectedTopic;
    } else {
      delete params.topic;
    }
    if (
      this.difficultyLevelForQuestion !== '' &&
      this.difficultyLevelForQuestion !== null
    ) {
      params.difficultyLevel = this.difficultyLevelForQuestion;
    } else {
      delete params.difficultyLevel;
    }
    if (this.startDate && this.endDate) {
      params.startDate = this.startDate;
      params.endDate = this.endDate;
    } else {
      delete params.startDate;
      delete params.endDate;
    }
    this.loading = true;
    this.httpRequestService.request('get', 'questions', params).subscribe(
      (result) => {
        this.loading = false;
        const data: Question[] = result.data;

        this.previewDetails = data;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  /* Get all Chapters */
  getAllChapters(): void {
    const params: any = {
      skip: 0,
      limit: 0,
      status: true,
      approveStatus: 'approved'
    };
    if (this.selectedSubject !== '' && this.selectedSubject !== null) {
      params.subject = this.selectedSubject;
    } else {
      delete params.subject;
    }
    /* if (this.addUpdateTopicForm.value.subject) {
        params['subject'] = this.addUpdateTopicForm.value.subject;
      } else {
        delete params.subject;
      } */
    if (this.chapterSearch) {
      params.search = this.chapterSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'chapters', params).subscribe(
      (result: any) => {
        this.allChapters = result.data;
      },
      (err: any) => {}
    );
  }

  /* Get all Subjects */
  getAllSubjects(): void {
    const params: any = {
      skip: 0,
      limit: 0,
      status: true,
      approveStatus: 'approved'
    };
    if (this.subjectSearch) {
      params.search = this.subjectSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'subjects', params).subscribe(
      (result: any) => {
        this.allSubjects = result.data;
      },
      (err: any) => {}
    );
  }
  /* Get all concepts */
  getAllTopics(skip = 0, sortBy?: any): void {
    let params: any;
    params = { skip, limit: 0 , approveStatus: 'approved'};
    if (this.topicSearch) {
      params.search = this.topicSearch;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if (this.selectedSubject !== '' && this.selectedSubject !== null) {
      params.subject = this.selectedSubject;
    } else {
      delete params.subject;
    }
    if (this.selectedChapter !== '' && this.selectedChapter !== null) {
      params.chapter = this.selectedChapter;
    } else {
      delete params.chapter;
    }
    this.loading = true;
    this.httpRequestService.request('get', 'topics', params).subscribe(
      (result) => {
        this.loading = false;
        this.allTopics = result.data;
        this.totalDataCount = result.totalCount;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  // subject Dropdown changes
  onChangeSubject(): void {
    if (this.type === 'normal-question') {
      this.normalUnApproveQuestion();
    } else {
      this.paragraphUnApproveQuestion();
    }
    if (this.selectedSubject) {
      this.allChapters = [];
      this.allTopics = [];
      this.selectedChapter = '';
      this.selectedTopic = '';
      this.selectedChapter = '';
      if (this.selectedSubject) {
        this.getAllChapters();
        this.getAllTopics();
      }
    }
  }
  
  onChangeSubAdmins(): void {
    if (this.type === 'normal-question') {
      this.normalUnApproveQuestion();
    } else {
      this.paragraphUnApproveQuestion();
    }
  }

  /* On change chapter get course */
  onChangeChapter(): void {
    if (this.type === 'normal-question') {
      this.normalUnApproveQuestion();
    } else {
      this.paragraphUnApproveQuestion();
    }
    if (this.selectedChapter) {
      this.allTopics = [];
      this.selectedTopic = ''; //resetting the topic dropdown value to empty string.
      if (this.selectedChapter) {
        this.getAllTopics();
      }
    }
  }

  onChangeTopics(): void {
    if (this.type === 'normal-question') {
      this.normalUnApproveQuestion();
    } else {
      this.paragraphUnApproveQuestion();
    }
  }


  onChangeDateRange(date: any) {
    this.startDate = date[0];
    this.endDate = date[1];

    if (this.type === 'normal-question') {
      this.normalUnApproveQuestion();
    } else {
      this.paragraphUnApproveQuestion();
    }
  }

  // earch topic
  searchTopicForDropdown(event: any): void {
    this.searchTopics.next(event);
  }
  // seach chapter
  searchChapterForDropdown(event: any): void {
    this.searchChapter.next(event);
  }
  // search subject
  searchSubjectForDropdown(event: any): void {
    this.searchSubject.next(event);
  }
  // search admin
  searchAdminForDropdown(event: any): void {
    this.searchAdmin.next(event);
  }
}
