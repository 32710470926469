import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SuccessStoriesRoutingModule } from './success-stories-routing.module';
import { AddUpdateStoriesComponent } from './add-update-stories/add-update-stories.component';
import { SuccessStoriesComponent } from './success-stories/success-stories.component';
import { SuccessStoriesDetailsComponent } from './success-stories-details/success-stories-details.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    AddUpdateStoriesComponent,
    SuccessStoriesComponent,
    SuccessStoriesDetailsComponent
  ],
  imports: [
    CommonModule,
    SuccessStoriesRoutingModule,
    SharedModule
  ]
})
export class SuccessStoriesModule { }
