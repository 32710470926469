import { Component, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HttpRequestService, LocalStorageService } from 'src/app/core/services';
import { UserDetailsComponent } from '../../user/user-details/user-details.component';
import { PlanDetailsComponent } from '../plan-details/plan-details.component';
import { RejectionReasonComponent } from 'src/app/shared/components/rejection-reason/rejection-reason.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { toNumber } from 'ng-zorro-antd/core/util';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {
  allPlans: any[] = [];
  isAddOrUpdate:boolean = false;
  isViewDetailsOfUser:boolean = true;
  totalDataCount = 0;
  totalApprovedDataCount = 0;
  pageSize = 12;
  pageIndex = 1;
  loading = false;
  searchUser: Subject<any> = new Subject<any>();
  search = '';
  listView = true;
  isOpenDetails = false;
  active: boolean = false;
  deActive: boolean = true;
  userRole: string = ''
  subAdminAddedPlans: any[] = [];
  index: number = 0;
  skipIndex: number = 0;
  allApprovedPlans: any[] = [];
  snapShootIndex: number = 0
  constructor(
    private drawerService: NzDrawerService,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private localStorageService: LocalStorageService,
    private modalService: NzModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.snapShootIndex = toNumber(this.activatedRoute.snapshot.queryParams.index) ;
    if(this.snapShootIndex){
      this.changeIndex(this.snapShootIndex)
    }
    this.userRole = this.localStorageService.getItem('user').role;
  }

  // on page load functions
  ngOnInit(): void {
    this.searchUser.pipe(debounceTime(1000)).subscribe((success) => {
      this.search = success;
      this.getAllPlans(this.index);
    });
  }
  changeIndex(data: any) {
    this.router.navigate([], { queryParams: {index: data } });
    this.allApprovedPlans = [];
    this.allPlans = [];
    this.pageSize = 12;
    this.pageIndex = 1
    this.index = data
      this.getAllPlans();
  }

  /* Get all subjects */
  getAllPlans(index: number = 0, skip = 0, sortBy?: any): void {
    index = index? index : this.index;
    let params: any;
    params = { skip: this.skipIndex, limit: this.pageSize };
    if (this.search) {
      params.search = this.search;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if(index === 0){
      params.approveStatus = 'approved'
    }else if(index === 1 && this.userRole === 'admin'){
      params.approveStatus = "pending"
    }else if( index === 1 && this.userRole === 'subAdmin' ){
      params.approveStatus = ["pending", "rejected"]
    }else{
      delete params.approvedStatus
    }
    this.loading = true;
    this.httpRequestService.request('get', 'plans', params).subscribe(
      (result) => {
        this.loading = false;
        const data: any[] = result.data;
        if(index === 0){
          this.allApprovedPlans = data
          this.totalApprovedDataCount = result.totalCount;
        }else{
          this.allPlans = data
          this.totalDataCount = result.totalCount;
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  /* For Pagination / Sending skip */
  onQueryParamsChange(params: NzTableQueryParams): void {
    this.allApprovedPlans = [];
    this.allPlans = [];
    const { pageSize, pageIndex} = params;
    this.skipIndex = pageSize * (pageIndex - 1)
    this.getAllPlans(this.index, pageSize * (pageIndex - 1));
  }
  /* For Pagination / Sending skip for grid view*/
  onQueryParamsChangeForGridView(index: any): void {
    this.allApprovedPlans = [];
    this.allPlans = [];
    this.pageIndex = index;
    this.skipIndex = this.pageSize * (this.pageIndex - 1)
    this.getAllPlans(this.index, this.pageSize * (this.pageIndex - 1));
  }
  onQueryPageSizeChangeForGridView(size: any) {
    this.allApprovedPlans = [];
    this.allPlans = [];
    this.pageSize = size;
    this.skipIndex = this.pageSize * (this.pageIndex - 1)
    this.getAllPlans(this.index, this.pageSize * (this.pageIndex - 1));
  }

  /* Status change*/
  updateStatus(id: string, status: boolean): void {
    this.httpRequestService
      .request('put', `plans/active-inactive/${id}`, { status: !status })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllPlans(this.index);
      });
  }
  updatePendingStatus(id: string, pendingStatus: string) {
    this.httpRequestService
      .request('put', `plans/approved-rejected/${id}`, {
        approveStatus: 'approved',
      })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllPlans(this.index);
      });
  }
  openRejectionReason(id: string, pendingStatus: string) {
      const pendingStatusData = 'rejected';
      const rejectionReason = this.modalService.create({
        nzTitle: 'Add A Reason',
        nzContent: RejectionReasonComponent,
        nzWidth: '800px',
        nzFooter: null,
        nzComponentParams: {
          updateId: id,
          component: 'plans',
        },
        nzCancelText: null,
        nzOkText: null,
      });

    const questionService = rejectionReason.afterClose.subscribe(
      (data: any) => {
        this.getAllPlans(this.index);
      }
    );
  }
  openPlanDetails(item: any) {
    const drawerRef = this.drawerService.create({
      nzTitle: 'Plan Details',
      nzFooter: '',
      nzWidth: '50%',
      nzContent: PlanDetailsComponent,
      nzContentParams: {
        value: item,
      },
    });
  }
  changeGridView() {
    this.listView = false;
    this.active = true;
    this.deActive = false;
  }
  changeListView() {
    this.listView = true;
    this.active = false;
    this.deActive = true;
  }
  // Seach subject
  searchUsers(event: any): void {
    this.searchUser.next(event);
  }
}
