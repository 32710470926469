<nz-header>
  <div class="app-header">
    <div class="container">
      <div nz-row>
        <div nz-col nzSpan="3">
          <div class="logo">
            <h2>
              <a [routerLink]="['/main']" routerLinkActive="router-link-active">
                <img
                  src="/assets/images/logo-inner.png"
                  alt="inner logo"
                  class="img-responsive"
                />
              </a>
            </h2>
          </div>
        </div>
        <div nz-col nzSpan="18">
          <ul nz-menu nzMode="horizontal" nzTheme="light" class="customsidebar">
            <ng-container *ngFor="let item of menuData">
              <li
                nz-menu-item
                nzMatchRouter
                nz-tooltip
                nzTooltipPlacement="right"
                [nzTooltipTitle]="isCollapsed ? item.title : ''"
                *ngIf="!item.children || item.children.length === 0"
                routerLinkActive="router-link-active"
                [routerLink]="[item.route]"
              >
                <i nz-icon [nzType]="item.icon"></i>
                <span>{{ item.title }}</span>
              </li>
              <li
                nz-submenu
                *ngIf="item.children && item.children.length > 0"
                [nzTitle]="item.title"
                [nzIcon]="item.icon"
                routerLinkActive="router-link-active"
              >
                <ul>
                  <li
                    nz-menu-item
                    nzMatchRouter
                    [routerLink]="[data.route]"
                    *ngFor="let data of item.children"
                  >
                    {{ data.title }}
                  </li>
                </ul>
              </li>
            </ng-container>
          </ul>
        </div>
        <div nz-col nzSpan="3" class="rightPart">
          <div>
            <nz-avatar
              nzIcon="user"
              class="avatar"
              nzSrc=""
              nz-button
              nz-dropdown
              nzTrigger="click"
              [nzDropdownMenu]="menu"
              [nzPlacement]="'bottomRight'"
            ></nz-avatar>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item>
                  <a [routerLink]="['/main/profile']"
                    ><i nz-icon nzType="user" nzTheme="outline"></i> Profile</a
                  >
                </li>
                <li nz-menu-item (click)="logout()">
                  <a><i nz-icon nzType="logout" nzTheme="outline"></i>Logout</a>
                </li>
              </ul>
            </nz-dropdown-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</nz-header>
