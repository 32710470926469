import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginLogsRoutingModule } from './login-logs-routing.module';
import { LoginLogsComponent } from './login-logs/login-logs.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ActivityDetailsComponent } from './activity-details/activity-details.component';


@NgModule({
  declarations: [
    LoginLogsComponent,
    ActivityDetailsComponent
  ],
  imports: [
    CommonModule,
    LoginLogsRoutingModule,
    SharedModule
  ]
})
export class LoginLogsModule { }
