import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubAdminRoutingModule } from './sub-admin-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SubAdminsComponent } from './sub-admins/sub-admins.component';
import { AddUpdateSubAdminComponent } from './add-update-sub-admin/add-update-sub-admin.component';


@NgModule({
  declarations: [
    SubAdminsComponent,
    AddUpdateSubAdminComponent
  ],
  imports: [
    CommonModule,
    SubAdminRoutingModule,
    SharedModule
  ]
})
export class SubAdminModule { }
