import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TopicsComponent } from './topics/topics.component';
import { AddUpdateChapterComponent } from '../chapter/add-update-chapter/add-update-chapter.component';
import { AddUpdateTopicComponent } from './add-update-topic/add-update-topic.component';

const routes: Routes = [
  {
    path: '',
    component: TopicsComponent
  },
  {
    path: 'add',
    component: AddUpdateTopicComponent
  },
  {
    path: 'update/:id',
    component: AddUpdateTopicComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TopicRoutingModule { }
