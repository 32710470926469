import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { BehaviorSubject, EMPTY, Observable, Subject, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { Subjects } from '../../../core/interfaces/subjects';
import { HttpRequestService, LocalStorageService } from 'src/app/core/services';
import { RejectionReasonComponent } from 'src/app/shared/components/rejection-reason/rejection-reason.component';
import { ActivatedRoute, Router } from '@angular/router';
import { toNumber } from 'ng-zorro-antd/core/util';

@Component({
  selector: 'app-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.scss'],
})
export class SubjectsComponent implements OnInit {
  allSubjects: Subjects[] = [];
  allApprovedSubjects: Subjects[] = [];
  isAddOrUpdate: boolean = true;
  totalDataCount = 0;
  totalApprovedDataCount = 0;
  pageSize = 12;
  pageIndex: number = 1;
  loading = false;
  searchSubject: Subject<string> = new Subject<string>();
  search = '';
  listView = true;
  isOpenDetails = false;
  active: boolean = false;
  deActive: boolean = true;
  isSubjectComponent: boolean = true;
  userRole: string = '';
  subAdminAddedSubjects: Subjects[] = [];
  index: number = 0;
  skipIndex: number = 0;
  snapShootIndex: number = 0;
  constructor(
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private localStorageService: LocalStorageService,
    private modalService: NzModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.snapShootIndex = toNumber(this.activatedRoute.snapshot.queryParams.index) ;
    if(this.snapShootIndex){
      this.changeIndex(this.snapShootIndex)
    }
    this.userRole = this.localStorageService.getItem('user').role;
  }

  // on page load functions
  ngOnInit(): void {

    this.searchSubject.pipe(debounceTime(1000)).subscribe((success) => {
      this.search = success;
      this.getAllSubjects(this.index);
    });
  }
  changeIndex(data: any) {
    this.router.navigate([], { queryParams: {index: data } });
    this.pageIndex = 1;
    this.pageSize = 12;
    this.allSubjects = []
    this.allApprovedSubjects = []
    this.index = data
    this.getAllSubjects();
    // if(this.index === 0 || this.index === 1){
    // }
  }
  // /* Get all subjects */
  // getAllSubjects(index: number = 0, skip = 0, sortBy?: any): void {

  //   this.httpRequestService.request('get', 'subjects', params).subscribe(
  //     (res: any) => {
  //       const data: Subjects[] = res.data
  //       this.loading = false;
  //       if(index === 0){
  //         this.allApprovedSubjects = data;
  //       }else{
  //         this.allSubjects = data;
  //       }
  //     },
  //     (err) => {
  //       this.loading = false;
  //     }
  //   );
  // }

  getAllSubjects(index: number = 0, skip: number = 0, sortBy?: any): void {
    let params: any;
    index = index? index : this.index
    params = { skip : this.skipIndex, limit: this.pageSize };
    if (this.search) {
      params.search = this.search;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if (index === 0) {
      params.approveStatus = 'approved';
    } else if (index === 1 && this.userRole === 'admin') {
      params.approveStatus = 'pending';
    } else if (index === 1 && this.userRole === 'subAdmin') {
      params.approveStatus = ['pending', 'rejected'];
    } else {
      delete params.approvedStatus;
    }
    this.loading = true;

    this.httpRequestService.request('get', 'subjects', params).subscribe(
      (result) => {
        this.loading = false;
        const data: Subjects[] = result.data;
        if (index === 0) {
          this.allApprovedSubjects= data;
          this.totalApprovedDataCount = result.totalCount;
        } else {
          this.allSubjects = data;
          this.totalDataCount = result.totalCount;
        }

      },
      (err) => {
        this.loading = false;
      }
    );
  }

  /* For Pagination / Sending skip */
  onQueryParamsChange(params: NzTableQueryParams): void { 
    this.allSubjects = [];
    this.allApprovedSubjects = []
    const { pageSize, pageIndex } = params;
    this.skipIndex = pageSize * (pageIndex - 1)
    this.getAllSubjects(this.index, pageSize * (pageIndex - 1));
  }
  /* For Pagination / Sending skip for grid view*/
  onQueryParamsChangeForGridView(index: any): void {
    this.allSubjects = [];
    this.allApprovedSubjects = []
    this.pageIndex = index;
    this.getAllSubjects(this.index, this.pageSize * (this.pageIndex - 1));
  }
  onQueryPageSizeChangeForGridView(size: any) {
    this.allSubjects = [];
    this.allApprovedSubjects = []
    this.pageSize = size;
    this.getAllSubjects(this.index, this.pageSize * (this.pageIndex - 1));
  }

  /* Status change*/
  updateStatus(id: string, status: boolean): void {
    this.httpRequestService
      .request('put', `subjects/active-inactive/${id}`, { status: !status })
      .subscribe((result: any) => {
        if (this.userRole === 'subAdmin') {
          this.notificationService.error(
            '',
            'You are not Authorized To Change Status'
          );
        } else {
          this.notificationService.success('', 'Status Updated Successfully');
        }
        this.getAllSubjects(this.index);
      });
  }
  updatePendingStatus(id: string, pendingStatus: string) {
    this.httpRequestService
      .request('put', `subjects/approved-rejected/${id}`, {
        approveStatus: 'approved',
      })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllSubjects(this.index);
      });
  }
  openRejectionReason(id: string, pendingStatus: string) {
    const pendingStatusData = 'rejected';
    const rejectionReason = this.modalService.create({
      nzTitle: 'Add A Reason',
      nzContent: RejectionReasonComponent,
      nzWidth: '800px',
      nzFooter: null,
      nzComponentParams: {
        updateId: id,
        component: 'subjects',
      },
      nzCancelText: null,
      nzOkText: null,
    });

    const questionService = rejectionReason.afterClose.subscribe(
      (data: any) => {
        this.getAllSubjects(this.index);
      }
    );
  }
  changeGridView() {
    this.listView = false;
    this.active = true;
    this.deActive = false;
  }
  changeListView() {
    this.listView = true;
    this.active = false;
    this.deActive = true;
  }
  // Seach subject
  searchSubjects(event: any): void {
    this.searchSubject.next(event);
  }
}
