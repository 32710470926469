import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BannerRoutingModule } from './banner-routing.module';
import { BannersComponent } from './banners/banners.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    BannersComponent,
  ],
  imports: [
    CommonModule,
    BannerRoutingModule,
    SharedModule
  ]
})
export class BannerModule { }
