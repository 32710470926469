import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tr } from 'date-fns/locale';
import { toNumber } from 'ng-zorro-antd/core/util';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Chapter } from 'src/app/core/interfaces/chapter';
import { Subjects } from 'src/app/core/interfaces/subjects';
import { HttpRequestService, LocalStorageService } from 'src/app/core/services';
import { CommonService } from 'src/app/core/services/common.service';
import { RejectionReasonComponent } from 'src/app/shared/components/rejection-reason/rejection-reason.component';

@Component({
  selector: 'app-chapters',
  templateUrl: './chapters.component.html',
  styleUrls: ['./chapters.component.scss'],
})
export class ChaptersComponent implements OnInit {
  allChapters: Chapter[] = [];
  allApprovedChapters: Chapter[] = [];
  isAddOrUpdate: boolean = true;
  totalDataCount = 0;
  totalApprovedDataCount = 0;
  pageSize = 12;
  pageIndex = 1;
  loading = false;
  searchChapter: Subject<string> = new Subject<string>();
  searchSubject: Subject<string> = new Subject<string>();
  search = '';
  subjectSearch = '';
  selectedSubject = '';
  allSubjects: Subjects[] = [];
  isOpenDetails = false;
  listView = true;
  active: boolean = false;
  deActive: boolean = true;
  isChapterComponent: boolean = true;
  userRole: string = '';
  subAdminAddedChapters: Chapter[] = [];
  index:number = 0;
  skipIndex:number = 0;
  snapShootIndex:number;
  constructor(
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private localStorageService: LocalStorageService,
    private modalService: NzModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.snapShootIndex = toNumber(this.activatedRoute.snapshot.queryParams.index) ;
    this.userRole = this.localStorageService.getItem('user').role;
    if(this.snapShootIndex){
      this.changeIndex(this.snapShootIndex)
    }
  }
  /* on page load function */
  ngOnInit(): void {

    this.getAllSubjects();
    this.searchChapter.pipe(debounceTime(1000)).subscribe((success) => {
      this.search = success;
      this.getAllChapters(this.index);
    });
    this.searchSubject.pipe(debounceTime(1000)).subscribe((success) => {
      this.subjectSearch = success;
      this.getAllSubjects();
    });
  }
  changeIndex(data: number) {
    this.router.navigate([], { queryParams: {index: data } });
    this.pageSize = 12;
    this.pageIndex = 1;
    this.allApprovedChapters = [];
    this.allChapters = [];
    this.index = data;
    this.getAllChapters();
    

  }
  changeGridView() {
    this.listView = false;
    this.active = true;
    this.deActive = false;
  }
  changeListView() {
    this.listView = true;
    this.active = false;
    this.deActive = true;
  }
  /* Get all chapters */
  getAllChapters(index?: number , skip?:number , sortBy?: any): void {
    // this.index = index;
    index = index? index : this.index

    let params: any = {};
    params = { skip : this.skipIndex, limit: this.pageSize };
    if (this.search) {
      params.search = this.search;
    } else {
      delete params.search;
    }
    if (this.selectedSubject) {
      params.subject = this.selectedSubject;
    } else {
      delete params.subject;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    if (index === 0) {
      params.approveStatus = 'approved';
    } else if (index === 1 && this.userRole === 'admin') {
      params.approveStatus = 'pending';
    } else if (index === 1 && this.userRole === 'subAdmin') {
      params.approveStatus = ['pending', 'rejected'];
    }
    this.loading = true;
    this.httpRequestService.request('get', 'chapters', params).subscribe(
      (result) => {
        this.loading = false;
        const data: Chapter[] = result.data;
        if (index === 0) {
          this.allApprovedChapters = data;
          this.totalApprovedDataCount = result.totalCount;
        } else if(index === 1){
          this.allChapters = data;
          this.totalDataCount = result.totalCount;
        }

      },
      (err) => {
        this.loading = false;
      }
    );
  }
  /* Get all Subjects */
  getAllSubjects(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved',
    };
    if (this.subjectSearch) {
      params.search = this.subjectSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'subjects', params).subscribe(
      (result: any) => {
        this.allSubjects = result.data;
      },
      (err: any) => {}
    );
  }
  onChangeSubject(): void {
    this.getAllChapters(this.index);
  }
  /* For Pagination / Sending skip */
  onQueryParamsChange(params: NzTableQueryParams): void {
    this.allApprovedChapters = [];
    this.allChapters = [];
    const { pageSize, pageIndex, sort } = params;
    let sortBy: any = {};
    sortBy[sort[0].key] = sort[0].value === 'ascend' ? 1 : -1;

    if (!sort[0].value) {
      sortBy = null;
    }
    this.skipIndex = pageSize * (pageIndex - 1);
    this.getAllChapters(this.index, pageSize * (pageIndex - 1), sortBy);
  }
   
  /* For Pagination / Sending skip for grid view*/
  onQueryParamsChangeForGridView(index: any): void {
    this.allApprovedChapters = [];
    this.allChapters = [];
    this.pageIndex = index;
    this.getAllChapters(this.index,this.pageSize * (this.pageIndex - 1));
  }
  onQueryPageSizeChangeForGridView(size: any) {
    this.allApprovedChapters = [];
    this.allChapters = [];
    this.pageSize = size;
    this.getAllChapters(this.index, this.pageSize * (this.pageIndex - 1));
  }

  /* status change*/
  updateStatus(id: string, status: boolean): void {
    this.httpRequestService
      .request('put', `chapters/active-inactive/${id}`, { status: !status })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllChapters(this.index);
      });
  }
  updatePendingStatus(id: string, pendingStatus: string) {
    this.httpRequestService
      .request('put', `chapters/approved-rejected/${id}`, {
        approveStatus: 'approved',
      })
      .subscribe((result: any) => {
        this.notificationService.success('', 'Status Updated Successfully');
        this.getAllChapters(this.index);
      });
  }
  openRejectionReason(id: string, pendingStatus: string) {
    const pendingStatusData = 'rejected';
    const rejectionReason = this.modalService.create({
      nzTitle: 'Add A Reason',
      nzContent: RejectionReasonComponent,
      nzWidth: '800px',
      nzFooter: null,
      nzComponentParams: {
        updateId: id,
        component: 'chapters',
      },
      nzCancelText: null,
      nzOkText: null,
    });

    const questionService = rejectionReason.afterClose.subscribe(
      (data: any) => {
        this.getAllChapters(this.index);
      }
    );
  }
  // search chapter
  searchChapters(event: any): void {
    this.searchChapter.next(event);
  }
  searchSubjectForDropdown(event: any): void {
    this.searchSubject.next(event);
  }
}
