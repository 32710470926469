<div *ngIf="sectionForm">
  <form [formGroup]="sectionForm">
    <div nz-row [nzGutter]="16">
      <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="subject">Subject</nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Please select your subject!">
            <nz-select nzShowSearch nzAllowClear nzServerSearch id="examCategory" formControlName="subject"
              nzPlaceHolder="Select a option" (nzOnSearch)="searchSubjectForDropdown($event)"
              (ngModelChange)="onChangeSubject($event)">
              <nz-option *ngFor="let item of allSubjects" nzValue="{{ item._id }}"
                nzLabel="{{ item.subjectName }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="chapter">Chapter</nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Please select your chapter!">
            <nz-select nzShowSearch nzAllowClear nzServerSearch id="examCategory" formControlName="chapter"
              nzPlaceHolder="Select a option" (nzOnSearch)="searchChapterForDropdown($event)"
              (ngModelChange)="onChangeChapter($event)">
              <nz-option *ngFor="let item of allChapters" nzValue="{{ item._id }}"
                nzLabel="{{ item.chapterName }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="topic">Topic</nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Please select your topic!">
            <nz-select nzShowSearch nzAllowClear nzServerSearch id="examCategory" formControlName="topic"
              nzPlaceHolder="Select a option" (nzOnSearch)="searchTopicForDropdown($event)"
              (ngModelChange)="onChangeTopic($event)">
              <nz-option *ngFor="let item of allTopics" nzValue="{{ item._id }}"
                nzLabel="{{ item.topicName }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="subject">Difficulty Level</nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Please select your Difficulty Level!">
            <nz-select nzShowSearch nzAllowClear nzServerSearch id="difficultyLevel" formControlName="difficultyLevel"
              nzPlaceHolder="Select a option" (ngModelChange)="onChangeDifficultyLevel($event)">
              <nz-option *ngFor="let item of allLevels" nzValue="{{ item.value }}"
                nzLabel="{{ item.label }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="Date">Date</nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Please select a Date!">
            <nz-range-picker  formControlName="dateRange"  (ngModelChange)="onChangeDateRange($event)"></nz-range-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-control [nzSpan]="24">
            <nz-form-label [nzSpan]="24">Sub Admin</nz-form-label>
            <nz-select nzAllowClear nzShowSearch nzServerSearch formControlName="adminId"
              nzPlaceHolder="Select a Sub Admin" (nzOnSearch)="searchSubAdminForDropdown($event)"
              (ngModelChange)="onChangeSubAdmin()">
              <nz-option *ngFor="let item of allSubAdmins" nzValue="{{ item._id }}"
                nzLabel="{{ item.name }}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="16">
      <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="description"> Fixed Marks </nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
            <nz-switch formControlName="isFixedNegativeMark" (click)="clickChange()"></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="10" nzMd="10" nzSm="24" nzXs="24"
        *ngIf=" sectionForm && sectionForm.value && sectionForm.value.isFixedNegativeMark">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="description"> Marks &nbsp;<small>(Per
              questions)</small></nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
            <span nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
              <nz-input-number class='width-100' formControlName="marks" type="number"
                (ngModelChange)="marksChangeInSection($event)" placeholder="Enter Marks" [nzMin]="0"></nz-input-number>
            </span>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzLg="10" nzMd="10" nzSm="24" nzXs="24"
        *ngIf="sectionForm && sectionForm.value && sectionForm.value.isFixedNegativeMark">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="description">Negative Marks &nbsp;<small>(Per
              questions)</small></nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
            <span nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
              <nz-input-number class='width-100' formControlName="negativeMarks" type="number"
                (ngModelChange)="negativeMarksChangeInSection($event)" placeholder="Enter Negative Marks"
                [nzMin]="0"></nz-input-number>
            </span>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="16">
      <div nz-col class="gutter-row" [nzMd]="12" [nzSm]="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="sectionName" nzRequired>Section Name</nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
            <input nz-input type="text" formControlName="sectionName" placeholder="Enter Section Name "
              (blur)="patchSectionValue()" />
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col class="gutter-row" [nzMd]="12" [nzSm]="24" *ngIf="examType === 'SEQUENTIAL'">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="time" nzRequired>Section Time<small>&nbsp;(in
              Minutes)</small></nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
            <nz-input-number class='width-100' type="number" formControlName="time" placeholder="Enter Section Time "
              [nzMin]="0" (blur)="patchSectionValue()"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
      <!-- <div nz-row> -->
        <div nz-col nzSpan="24" class="text-right mb3" >
          <button class="mr3" *ngIf="idForUpdate || bulkSwitchValue || errorDataList.length > 0" nz-button (click)="openBulkQuestion()" nzType="primary">
            Add bulk Question
          </button>
          <button nz-button (click)="openQuestion()" nzType="primary" class="ml3">
            Add Question
          </button>
          <button nz-button (click)="openParagraphQuestion()" nzType="primary" class="ml3">
            Add Paragraph Question
          </button>
          <!-- <button *ngIf="!bulkSwitchValue" nz-button (click)="openQuestion()" nzType="primary" class="ml3" >
            Add Question
          </button>
          <button *ngIf="!bulkSwitchValue" nz-button (click)="openParagraphQuestion()" nzType="primary" class="ml3" >
            Add Paragraph Question
          </button> -->
        </div>
      </div>
    <!-- </div> -->
    <!-- <div nz-row [nzGutter]="16">
      <div nz-col class="gutter-row" [nzMd]="12" [nzSm]="24" nzJustify="end">
          <nz-form-label [nzSpan]="24" nzFor="sectionName">Download Error Sheet</nz-form-label>

      </div>
    </div> -->
    <div nz-row nzType="flex" nzJustify="end" class="mb4" [nzGutter]="16"  *ngIf=" errorDataList && errorDataList.length > 0  ">
       <button class="mr3" nz-button (click)="downloadExcel(false)" nzType="primary">
      Download Error Sheet <span nz-icon nzType="file-excel" nzTheme="outline"></span>
    </button>
  </div>
    <div nz-row [nzGutter]="16">
      <!---Total paragraph Question-->
      <div nz-col class="gutter-row" [nzMd]="12" [nzSm]="24" *ngIf="!bulkSwitchValue">
        <nz-list nzBordered nzSize="small" class="samelistheight questionlistbox">
          <nz-list-header>
            <div nz-row>
              <!-- <h4 *ngIf="index !== 1">
                Total Questions:
                {{ allNormalQuestions.length - newSelectedQuestions.length }}
            </h4> -->
              <!-- <h4 *ngIf="index === 1">
                Total Questions:
                {{ allParagraphQuestions.length - newSelectedQuestions.length }}
            </h4> -->
              <button  style="text-align: right" nz-button nzType="primary" nzSize="small" (click)="addQuestion()">
                Add
              </button>
            </div>
          </nz-list-header>
          <nz-tabset (nzSelectedIndexChange)="tabChange($event)">
            <nz-tab nzTitle="Normal">
              <div>
                <div class="myqlistitems">
                  <nz-list-item *ngFor="let item of normalFilterData;  let i = index" class="qlist flex-wrapneed">
                    <label nz-checkbox [(ngModel)]="item.isChecked" [ngModelOptions]="{ standalone: true }"></label>
                    <p class="mr3">{{i+1}}.</p>
                    <!-- <p>{{item | json}}</p> -->
                    <app-mathjax [content]="item.questionText" class="mr2"></app-mathjax>
                    <a nz-button nzType="default" nzTooltipTitle="Details" nzTooltipPlacement="topCenter" nz-tooltip
                      (click)="openQuestionDetails(item)" routerLinkActive="router-link-active"><span nz-icon
                        nzType="info-circle" nzTheme="outline" nzJustify="end"></span></a>
                  </nz-list-item>
                  <div *ngIf="!loading && !normalQuestionLimit  " class=" mt3 loadMore-button">
                    <button [disabled]="normalQuestionLimit"  nz-button nzType="primary" nzSize="small"
                      (click)="paginationForNormalQuestions()"> Load More
                    </button>
                  </div>
                </div>
                <div class="loader" *ngIf="loading">
                  <nz-spin nzSimple class="loader-span"></nz-spin>
                </div>
              </div>
            </nz-tab>
            <nz-tab nzTitle="Paragraph">
              <div>
                <div class="myqlistitems">
                  <nz-list-item *ngFor="let item of paragraphFilterData; let i = index" class="qlist flex-wrapneed">
                    <label nz-checkbox [(ngModel)]="item.isChecked" [ngModelOptions]="{ standalone: true }"></label>
                    <p class="mr3">{{i+1}}.</p>
                    <span class="short-text" *ngIf="item.paragraph && item.paragraph.name">
                      <app-mathjax [content]="item.paragraph.name" class="mr2"></app-mathjax>
                    </span>
                    <!-- <div class="inquestionlistonform">
                      <p *ngFor="let relMedia of item.questionMedia; index as i">
                        <span *ngIf="relMedia.title">{{ relMedia.title }}</span>
                        <span *ngIf="relMedia.file?.path"><img [src]="mediaBasePath + relMedia.file.path" /></span>
                      </p>
                    </div> -->
                  </nz-list-item>
                  <div *ngIf="!loading && !paragraphQuestionLimit" class="loadMore-button  mt3">
                    <button [disabled]="paragraphQuestionLimit" nz-button nzType="primary" nzSize="small"
                      (click)="paginationForParagraphQuestions()"> Load More
                    </button>
                  </div>
                </div>
                <div class="loader" *ngIf="loading">
                  <nz-spin nzSimple class="loader-span"></nz-spin>
                </div>
              </div>
            </nz-tab>
          </nz-tabset>
        </nz-list>
      </div>
      <!---Total Selected Question-->
      <div nz-col class="gutter-row" [nzMd]=" bulkSwitchValue ? 24 : 12" [nzSm]="24">
        <nz-list nzBordered nzSize="small" class="samelistheight questionlistbox">
          <nz-list-header>
            <h4>Total Selected Question: {{ newSelectedQuestions.length }}</h4>

            <div nz-row>
              <button style="text-align: right" nz-button nzDanger nzSize="small" (click)="removeQuestion()">
                Remove
              </button>
            </div>
          </nz-list-header>

          <div class="myqlistitems">
            <nz-table #basicTable [nzData]="newSelectedQuestions" [nzShowPagination]="false" class="valigntop">
              <thead>
                <tr>
                  <th></th>
                  <th nzWidth="0px"></th>
                  <th >Question</th>
                  <th>
                    Type
                  </th>
                  <th>Mark</th>
                  <th>Neg. Mark</th>
                  <th>Info</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of selectedQuestions ; let i = index">

                  <td>
                    <label nz-checkbox [(ngModel)]="data.isChecked" [ngModelOptions]="{ standalone: true }"></label>
                  </td>
                  <td>
                    <span *ngIf="!data.isChangeToEditIndex;else inputBox"
                      (click)="data.isChangeToEditIndex=!data.isChangeToEditIndex">
                      {{data.index}}
                    </span>
                    <ng-template #inputBox>
                      <nz-input-number [nzMin]="1" nzBorderless [ngModel]="data.index"
                        [ngModelOptions]="{ standalone: true }"
                        (ngModelChange)="inputValueChanged(data.index, $event)"></nz-input-number>
                    </ng-template>
                  </td>
                  <td>
                    <!-- <span *ngIf="data.questionType === 'PARAGRAPH'">
                      <p *ngFor="let item of data.questions; index as i">
                        <app-mathjax [content]="item.questionText"></app-mathjax>
                      </p>
                    </span> -->
                    <span *ngIf="data.questionText">
                      <app-mathjax [content]="data.questionText"></app-mathjax>

                    </span>
                    <!-- <app-mathjax [content]="data.questions.questionText"></app-mathjax> -->
                    <!-- <div class="inquestionlistonform">
                      <p *ngFor="let relMedia of data.questionImage; index as i">
                        <span><img [src]="mediaBasePath + relMedia.relMedia" /></span>
                      </p>
                    </div> -->
                  </td>
                  <td>
                    <nz-tag [nzColor]="data.questionType=== 'NORMAL' ? 'volcano' : 'green'">{{data.questionType===
                      'NORMAL' ? 'NORMAL': 'PARAGRAPH'}}</nz-tag>
                  </td>
                  <td>
                    <nz-input-number class="section-time" [(ngModel)]="data.marks" (ngModelChange)="patchSectionValue()"
                      [disabled]="addUpdateTestForm?.value.isFixedNegativeMark" [ngModelOptions]="{ standalone: true }"
                      [nzMin]="0"></nz-input-number>
                  </td>
                  <td>
                    <nz-input-number class="section-time" [disabled]="addUpdateTestForm?.value.isFixedNegativeMark"
                      [(ngModel)]="data.negativeMarks" (ngModelChange)="patchSectionValue()"
                      [ngModelOptions]="{ standalone: true }" [nzMin]="0"></nz-input-number>
                  </td>
                  <td >
                    <nz-button-group [nzSize]="'small'" class="button-gap">
                      <a nz-button nzType="default" nzTooltipTitle="Details" nzTooltipPlacement="topCenter" nz-tooltip
                        (click)="openQuestionDetails(data)" routerLinkActive="router-link-active"><span nz-icon
                          nzType="info-circle" nzTheme="outline" nzJustify="end"></span></a>

                          <a nz-button class="action-button ml3"  nz-popconfirm
                          nzPopconfirmTitle="Are you sure delete this question"
                          (nzOnConfirm)="deleteQuestions(data._id)"
                          z><span nz-icon nzType="delete" nzTheme="outline"></span></a>
                          <a *ngIf="targetIndex && data.index === currentIndex" nz-button nzType="default" nzTooltipTitle="Swipe Question" nzTooltipPlacement="topCenter" nz-tooltip
                        (click)="changeIndex(data, data.index, targetIndex)" routerLinkActive="router-link-active"><i nz-icon nzType="swap" nzTheme="outline"></i></a>

                    </nz-button-group>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </nz-list>
      </div>
    </div>
  </form>
</div>
