import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuestionsComponent } from './questions/questions.component';
import { AddUpdateQuestionComponent } from './add-update-question/add-update-question.component';
import { QuestionDetailsComponent } from './question-details/question-details.component';
import { AddUpdateParagraphQuestionComponent } from './add-update-paragraph-question/add-update-paragraph-question.component';
import { ParagraphQuestionDetailsComponent } from './paragraph-question-details/paragraph-question-details.component';
// import { ParagraphQuestionComponent } from './paragraph-question/paragraph-question.component';
import { AddUpdateBulkQuestionComponent } from './add-update-bulk-question/add-update-bulk-question.component';

const routes: Routes = [
  {
    path: '',
    component: QuestionsComponent,
  },
  {
    path: 'add',
    component: AddUpdateQuestionComponent,
  },
  {
    path: 'update/:id',
    component: AddUpdateQuestionComponent,
  },
  {
    path: 'detail/:id',
    component: QuestionDetailsComponent,
  },
  // {
  //   path: 'paragraph-questions',
  //   component: ParagraphQuestionComponent,
  // },
  {
    path: 'add-bulk-question',
    component: AddUpdateBulkQuestionComponent,
  },
  {
    path: 'add-paragraph-question',
    component: AddUpdateParagraphQuestionComponent,
    // children: [
    //   { path: 'add-paragraph', component: AddUpdateParagraphQuestionComponent ,  },
    //   { path: 'add-question', component: AddUpdateParagraphQuestionComponent,   },
    // ],
  },
  {
    path: 'update-paragraph-question/:id',
    component: AddUpdateParagraphQuestionComponent,
  },
  {
    path: 'details-paragraph-question/:id',
    component: ParagraphQuestionDetailsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuestionRoutingModule {}
