<nz-tabset (nzSelectedIndexChange)="changeIndex($event)">
    <nz-tab nzTitle="Approved">
        <nz-card *ngIf="list" [nzLoading]="loading">
            <div *ngIf=" list.length === 0">
                <nz-empty nzNotFoundImage="simple"></nz-empty>
            </div>
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
                <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24" class="mt4" *ngFor="let item of list">
                    <nz-card class="height-100">
                        <div class="test-height title-ellipsis">
                            <b class="" *ngIf="item.subjectName"> <app-mathjax
                                    [content]='item.subjectName'></app-mathjax></b>
                            <b class="  " *ngIf="item.chapterName"> <app-mathjax
                                    [content]='item.chapterName'></app-mathjax></b>
                            <b class="" *ngIf="item.topicName"> <app-mathjax
                                    [content]='item.topicName'></app-mathjax></b>
                            <b class="" *ngIf="item.examName"> <app-mathjax [content]='item.examName'></app-mathjax></b>
                            <b class="" *ngIf="item.questionText"> <app-mathjax
                                    [content]='item.questionText'></app-mathjax></b>
                            <b class="" *ngIf="item.name"> <app-mathjax [content]='item.name'></app-mathjax></b>
                        </div>

                        <!-- <p class="ellipsis" *ngIf="item.slug"> <app-mathjax [content]='item.slug'></app-mathjax></p> -->
                        <p class="ellipsis" *ngIf="item.email"> <app-mathjax [content]='item.email'></app-mathjax></p>
                        <p class="ellipsis" *ngIf="item.mobile"> <app-mathjax [content]='item.mobile'></app-mathjax></p>
                        <!-- <span nz-row nzJustify="start">
                        <h4><b>Type:</b></h4>
                        <p class="ellipsis ml2" *ngIf="item.questionType"> <app-mathjax [content]='item.questionType'></app-mathjax></p>
                    </span> -->

                        <nz-divider></nz-divider>
                        <div nz-row nzJustify="space-between">
                            <a *ngIf="isAddOrUpdate" nzType="primary" nzTooltipTitle="Edit"
                                nzTooltipPlacement="topCenter" nz-tooltip [routerLink]="['update', item._id]"
                                routerLinkActive="router-link-active"><i nz-icon nzType="form"
                                    nzTheme="outline"></i></a>
                            <a *ngIf="isViewDetailsOfUser" nzType="primary" nzTooltipTitle="Details"
                                nzTooltipPlacement="topCenter" nz-tooltip (click)="openUserDetails(item)"
                                routerLinkActive="router-link-active"><i nz-icon nzType="eye" nzTheme="outline"></i></a>

                            <!-- subject action buttons -->
                            <a *ngIf="isSubjectComponent && userRole !== 'subAdmin'" class="action-button" nz-popconfirm
                                nzPopconfirmTitle="Are you sure {{
                        item.status ? 'deactive' : 'active'
                    }} this task?" (nzOnConfirm)="updateStatus(item._id, item.status, 'subjects')"
                                nzTooltipTitle="{{ item.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                                nz-tooltip><i nz-icon [nzType]="item.status ? 'stop' : 'check'" nzTheme="outline"></i>
                            </a>
                            <!-- chapter action buttons -->

                            <a *ngIf="isChapterComponent && userRole !== 'subAdmin'" class="action-button" nz-popconfirm
                                nzPopconfirmTitle="Are you sure {{
                        item.status ? 'deactive' : 'active'
                    }} this task?" (nzOnConfirm)="updateStatus(item._id, item.status, 'subjects')"
                                nzTooltipTitle="{{ item.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                                nz-tooltip><i nz-icon [nzType]="item.status ? 'stop' : 'check'" nzTheme="outline"></i>
                            </a>
                            <!-- topic action buttons -->
                            <a *ngIf="isTopicComponent && userRole !== 'subAdmin'" class="action-button" nz-popconfirm
                                nzPopconfirmTitle="Are you sure {{
                        item.status ? 'deactive' : 'active'
                    }} this task?" (nzOnConfirm)="updateStatus(item._id, item.status, 'topics')"
                                nzTooltipTitle="{{ item.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                                nz-tooltip><i nz-icon [nzType]="item.status ? 'stop' : 'check'" nzTheme="outline"></i>
                            </a>
                            <!-- exam action buttons -->
                            <a *ngIf="isExamComponent && userRole !== 'subAdmin'" class="action-button" nz-popconfirm
                                nzPopconfirmTitle="Are you sure {{
                        item.status ? 'deactive' : 'active'
                    }} this task?" (nzOnConfirm)="updateStatus(item._id, item.status, 'exams')"
                                nzTooltipTitle="{{ item.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                                nz-tooltip><i nz-icon [nzType]="item.status ? 'stop' : 'check'" nzTheme="outline"></i>
                            </a>
                            <!-- question action buttons -->
                            <a *ngIf="isParagraphQuestionComponent || isNormalQuestionComponent" nzType="primary" nzTooltipTitle="Details"
                                nzTooltipPlacement="topCenter" nz-tooltip (click)="openQuestionDetails(item)"
                                routerLinkActive="router-link-active"><i nz-icon nzType="eye" nzTheme="outline"></i></a>

                            <a *ngIf="isParagraphQuestionComponent || isNormalQuestionComponent && userRole !== 'subAdmin'" class="action-button"
                                nz-popconfirm nzPopconfirmTitle="Are you sure {{
                        item.status ? 'deactive' : 'active'
                    }} this task?" (nzOnConfirm)="updateStatus(item._id, item.status, 'questions')"
                                nzTooltipTitle="{{ item.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                                nz-tooltip><i nz-icon [nzType]="item.status ? 'stop' : 'check'" nzTheme="outline"></i>
                            </a>
                            <a  *ngIf="isNormalQuestionComponent && userRole !== 'subAdmin'" class="action-button"  nz-popconfirm
                            nzPopconfirmTitle="Are you sure delete this question"
                            (nzOnConfirm)="deleteQuestions(item._id)"
                            z><i nz-icon nzType="delete" nzTheme="outline"></i></a>

                        </div>
                    </nz-card>
                    <ng-template #extraTemplate>
                    </ng-template>
                </div>
            </div>
        </nz-card>
    </nz-tab>
    <nz-tab nzTitle="UnApproved">

        <div *ngIf=" unApprovedList.length === 0">
            <nz-card>
                <nz-empty nzNotFoundImage="simple"></nz-empty>
            </nz-card>
        </div>
        <nz-card [nzLoading]="loading" *ngIf="unApprovedList && unApprovedList.length > 0">
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
                <div nz-col nzLg="4" nzMd="4" nzSm="24" nzXs="24" class="mt4" *ngFor="let item of unApprovedList">
                    <nz-card class="height-100">
                        <nz-tag
                            [nzColor]="item.approveStatus === 'approved' ? 'green':  item.approveStatus === 'pending'? 'lime':'volcano' "
                            class="status-position">
                            {{item.approveStatus}} </nz-tag>
                        <div class="test-height title-ellipsis">
                            <b class="" *ngIf="item.subjectName"> <app-mathjax
                                    [content]='item.subjectName'></app-mathjax></b>
                            <b class="  " *ngIf="item.chapterName"> <app-mathjax
                                    [content]='item.chapterName'></app-mathjax></b>
                            <b class="" *ngIf="item.topicName"> <app-mathjax
                                    [content]='item.topicName'></app-mathjax></b>
                            <b class="" *ngIf="item.examName"> <app-mathjax [content]='item.examName'></app-mathjax></b>
                            <b class="" *ngIf="item.questionText"> <app-mathjax
                                    [content]='item.questionText'></app-mathjax></b>
                            <b class="" *ngIf="item.name"> <app-mathjax [content]='item.name'></app-mathjax></b>
                        </div>
                        <!-- <div>
                            <p *ngIf="item.rejectionReason" class="ellipsis"> <b>Reason:

                                </b>{{item.rejectionReason}}</p>
                            <p>
                                <b>Status: </b>
                            </p>
                        </div> -->

                        <p class="ellipsis" *ngIf="item.email"> <app-mathjax [content]='item.email'></app-mathjax></p>
                        <p class="ellipsis" *ngIf="item.mobile"> <app-mathjax [content]='item.mobile'></app-mathjax></p>
                        <nz-divider></nz-divider>
                        <div nz-row nzJustify="space-between">
                            <a *ngIf="isAddOrUpdate" nzType="primary" nzTooltipTitle="Edit"
                                nzTooltipPlacement="topCenter" nz-tooltip [routerLink]="['update', item._id]"
                                routerLinkActive="router-link-active"><i nz-icon nzType="form"
                                    nzTheme="outline"></i></a>

                            <a *ngIf="isViewDetailsOfUser" nzType="primary" nzTooltipTitle="Details"
                                nzTooltipPlacement="topCenter" nz-tooltip (click)="openUserDetails(item)"
                                routerLinkActive="router-link-active"><i nz-icon nzType="eye" nzTheme="outline"></i></a>

                            <!-- subject update pending status and status -->
                            <a *ngIf="isSubjectComponent && userRole !== 'subAdmin'" class="action-button" nz-popconfirm
                                nzPopconfirmTitle="Are you sure {{
                        item.status ? 'deactive' : 'active'
                    }} this task?" (nzOnConfirm)="updateStatus(item._id, item.status, 'subjects')"
                                nzTooltipTitle="{{ item.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                                nz-tooltip><i nz-icon [nzType]="item.status ? 'stop' : 'check'" nzTheme="outline"></i>
                            </a>

                            <a *ngIf="isSubjectComponent && userRole !== 'subAdmin' " class="action-button top-margin"
                                nz-popconfirm nzPopconfirmTitle="Are you sure Approved this Subject?"
                                (nzOnConfirm)="updatePendingStatus(item._id, item.approveStatus , 'subjects')"
                                nzTooltipTitle="{{'Approve'}}" nzTooltipPlacement="topRight" nz-tooltip><span nz-icon
                                    nzType="file-done" nzTheme="outline"></span></a>

                            <a *ngIf="isSubjectComponent && userRole !== 'subAdmin' && item.approveStatus !== 'rejected'"
                                class="action-button top-margin" nz-popconfirm
                                nzPopconfirmTitle="Are you sure Reject this Subject?"
                                (nzOnConfirm)="openRejectionReason(item._id, item.approveStatus, 'subjects')"
                                nzTooltipTitle='Reject' nzTooltipPlacement="topRight" nz-tooltip><i nz-icon
                                    [nzType]="'close-circle'" nzTheme="outline"></i></a>

                            <!-- chapter update pending status and status -->
                            <a *ngIf="isChapterComponent && userRole !== 'subAdmin'" class="action-button" nz-popconfirm
                                nzPopconfirmTitle="Are you sure {{
                        item.status ? 'deactive' : 'active'
                    }} this task?" (nzOnConfirm)="updateStatus(item._id, item.status, 'chapters')"
                                nzTooltipTitle="{{ item.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                                nz-tooltip><i nz-icon [nzType]="item.status ? 'stop' : 'check'" nzTheme="outline"></i>
                            </a>
                            <a *ngIf="isChapterComponent && userRole !== 'subAdmin' " class="action-button top-margin"
                                nz-popconfirm nzPopconfirmTitle="Are you sure Approved this Chapter?"
                                (nzOnConfirm)="updatePendingStatus(item._id, item.approveStatus , 'chapters')"
                                nzTooltipTitle="{{'Approve'}}" nzTooltipPlacement="topRight" nz-tooltip><i nz-icon
                                    nzType="file-done" nzTheme="outline"></i></a>

                            <a *ngIf="isChapterComponent && userRole !== 'subAdmin' && item.approveStatus !== 'rejected'"
                                class="action-button top-margin" nz-popconfirm
                                nzPopconfirmTitle="Are you sure Reject this Chapter?"
                                (nzOnConfirm)="openRejectionReason(item._id, item.approveStatus, 'chapters')"
                                nzTooltipTitle='Reject' nzTooltipPlacement="topRight" nz-tooltip><i nz-icon
                                    [nzType]="'close-circle'" nzTheme="outline"></i></a>

                            <!-- Topic update pending status and status -->
                            <a *ngIf="isTopicComponent && userRole !== 'subAdmin'" class="action-button" nz-popconfirm
                                nzPopconfirmTitle="Are you sure {{
                        item.status ? 'deactive' : 'active'
                    }} this task?" (nzOnConfirm)="updateStatus(item._id, item.status, 'topics')"
                                nzTooltipTitle="{{ item.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                                nz-tooltip><i nz-icon [nzType]="item.status ? 'stop' : 'check'" nzTheme="outline"></i>
                            </a>
                            <a *ngIf="isTopicComponent && userRole !== 'subAdmin' " class="action-button top-margin"
                                nz-popconfirm nzPopconfirmTitle="Are you sure Approved this Topic?"
                                (nzOnConfirm)="updatePendingStatus(item._id, item.approveStatus , 'topics')"
                                nzTooltipTitle="{{'Approve'}}" nzTooltipPlacement="topRight" nz-tooltip><i nz-icon
                                    nzType="file-done" nzTheme="outline"></i></a>

                            <a *ngIf="isTopicComponent && userRole !== 'subAdmin' && item.approveStatus !== 'rejected'"
                                class="action-button top-margin" nz-popconfirm
                                nzPopconfirmTitle="Are you sure Reject this Topic?"
                                (nzOnConfirm)="openRejectionReason(item._id, item.approveStatus, 'topics')"
                                nzTooltipTitle='Reject' nzTooltipPlacement="topRight" nz-tooltip><i nz-icon
                                    [nzType]="'close-circle'" nzTheme="outline"></i></a>

                            <!-- Exam update pending status and status -->
                            <a *ngIf="isExamComponent && userRole !== 'subAdmin'" class="action-button" nz-popconfirm
                                nzPopconfirmTitle="Are you sure {{
                        item.status ? 'deactive' : 'active'
                    }} this task?" (nzOnConfirm)="updateStatus(item._id, item.status, 'exams')"
                                nzTooltipTitle="{{ item.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                                nz-tooltip><i nz-icon [nzType]="item.status ? 'stop' : 'check'" nzTheme="outline"></i>
                            </a>
                            <a *ngIf="isExamComponent && userRole !== 'subAdmin' " class="action-button top-margin"
                                nz-popconfirm nzPopconfirmTitle="Are you sure Approved this Exam?"
                                (nzOnConfirm)="updatePendingStatus(item._id, item.approveStatus , 'exams')"
                                nzTooltipTitle="{{'Approve'}}" nzTooltipPlacement="topRight" nz-tooltip><i nz-icon
                                    nzType="file-done" nzTheme="outline"></i></a>

                            <a *ngIf="isExamComponent && userRole !== 'subAdmin' && item.approveStatus !== 'rejected'"
                                class="action-button top-margin" nz-popconfirm
                                nzPopconfirmTitle="Are you sure Reject this Exam?"
                                (nzOnConfirm)="openRejectionReason(item._id, item.approveStatus, 'exams')"
                                nzTooltipTitle='Reject' nzTooltipPlacement="topRight" nz-tooltip><i nz-icon
                                    [nzType]="'close-circle'" nzTheme="outline"></i></a>

                            <!-- Question update pending status and status -->
                            <a *ngIf="isNormalQuestionComponent ||  isParagraphQuestionComponent && userRole !== 'subAdmin'" class="action-button"
                                nz-popconfirm nzPopconfirmTitle="Are you sure {{
                        item.status ? 'deactive' : 'active'
                    }} this task?" (nzOnConfirm)="updateStatus(item._id, item.status, 'questions')"
                                nzTooltipTitle="{{ item.status ? 'Deactive' : 'Active' }}" nzTooltipPlacement="topRight"
                                nz-tooltip><i nz-icon [nzType]="item.status ? 'stop' : 'check'" nzTheme="outline"></i>
                            </a>
                            <a *ngIf="isNormalQuestionComponent ||  isParagraphQuestionComponent && userRole !== 'subAdmin' " class="action-button top-margin"
                                nz-popconfirm nzPopconfirmTitle="Are you sure Approved this Question?"
                                (nzOnConfirm)="updatePendingStatus(item._id, item.approveStatus , 'questions')"
                                nzTooltipTitle="{{'Approve'}}" nzTooltipPlacement="topRight" nz-tooltip><i nz-icon
                                    nzType="file-done" nzTheme="outline"></i></a>

                            <a *ngIf="isNormalQuestionComponent ||  isParagraphQuestionComponent && userRole !== 'subAdmin' && item.approveStatus !== 'rejected'"
                                class="action-button top-margin" nz-popconfirm
                                nzPopconfirmTitle="Are you sure Reject this Question?"
                                (nzOnConfirm)="openRejectionReason(item._id, item.approveStatus, 'questions')"
                                nzTooltipTitle='Reject' nzTooltipPlacement="topRight" nz-tooltip><i nz-icon
                                    [nzType]="'close-circle'" nzTheme="outline"></i></a>

                            <a *ngIf="isNormalQuestionComponent ||  isParagraphQuestionComponent" nzType="primary" nzTooltipTitle="Details"
                                nzTooltipPlacement="topCenter" nz-tooltip (click)="openQuestionDetails(item)"
                                routerLinkActive="router-link-active"><i nz-icon nzType="eye" nzTheme="outline"></i></a>
                        </div>
                    </nz-card>
                    <ng-template #extraTemplate>
                    </ng-template>
                </div>
            </div>
        </nz-card>
    </nz-tab>
</nz-tabset>
