import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardConfigRoutingModule } from './dashboard-config-routing.module';
import { DashboardConfigComponent } from './dashboard-config/dashboard-config.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddUpdateDashboardConfigComponent } from './add-update-dashboard-config/add-update-dashboard-config.component';


@NgModule({
  declarations: [
    DashboardConfigComponent,
    AddUpdateDashboardConfigComponent
  ],
  imports: [
    CommonModule,
    DashboardConfigRoutingModule,
    SharedModule
  ]
})
export class DashboardConfigModule { }
