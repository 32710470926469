import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Chapter } from 'src/app/core/interfaces/chapter';
import { ExamCategory } from 'src/app/core/interfaces/exam-category';
import { HttpRequestService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-update-exams-category',
  templateUrl: './add-update-exams-category.component.html',
  styleUrls: ['./add-update-exams-category.component.scss']
})
export class AddUpdateExamsCategoryComponent implements OnInit {
  addUpdateExamsCategoryForm: FormGroup;
  idForUpdate: string;
  buttonLoading:boolean = false;
  loading:boolean = false;
  allChapters: Chapter[] = [];
  allExamCategories: ExamCategory[]= [];
  checkAddPermission:boolean = false;
  checkUpdatePermission:boolean = false;
  search:string = '';
  mediaBaseUrl = environment.mediaBaseUrl;
  searchExamCategory: Subject<string> = new Subject<string>();
  mediaUploadUrl = environment.apiBaseUrl + '/api/media';
  tabIndex: number = 0
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private msg: NzMessageService,
  ) {
    this.tabIndex = this.activatedRoute.snapshot.queryParams.index;
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.addUpdateExamsCategoryForm = this.fb.group({
      categoryName: [null, [Validators.required]],
      description: [null],
      status: ['true', [Validators.required]],
      image: [null],
    })
  }

  ngOnInit(): void {
    if (this.idForUpdate) {
      this.getExamCategoryById();
    }
    this.searchExamCategory.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.search = success;
    });
  }

    //  send auth in headers //
    customRequestHeaders = () => {
      return { Authorization: `Bearer ${localStorage.getItem('token')}` };
    };
  /* Get single exam category details by Id */
  getExamCategoryById(): void {
    this.httpRequestService
      .request('get', `exam-categories/${this.idForUpdate}`)
      .subscribe(
        (result: any) => {
          const data: ExamCategory = result.data;
          this.addUpdateExamsCategoryForm.patchValue({
            categoryName: data.categoryName,
            description: data.description,
            image: data.image,
          });
        },
        (error: any) => { }
      );
  }

  /* Submit exam category form */
  submit(): void {
    if (!this.addUpdateExamsCategoryForm.valid) {
      this.markFormGroupTouched(this.addUpdateExamsCategoryForm);
    } else {
      if (this.idForUpdate) {
        this.addOrUpdateExamCategory(
          'put',
          `exam-categories/${this.idForUpdate}`,
          'Exam-category Successfully Updated'
        );
      } else {
        this.addOrUpdateExamCategory(
          'post',
          'exam-categories',
          'Exam-category Added Successfully '
        );
      }
    }
  }

  /* Add Or Edit  exam category */
  addOrUpdateExamCategory(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {
    this.buttonLoading = true;
    const finalData:ExamCategory = this.addUpdateExamsCategoryForm.value
    this.httpRequestService
      .request(requestMethod, requestURL, finalData)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          this.router.navigate(['/main/exam-category'], { queryParams: {index: this.tabIndex } });
          this.buttonLoading = false;
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          } else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }
    // image Upload
    UploadImageFile(
      info: { file: NzUploadFile },
      FormControl: string,
      index: number
    ) {
      switch (info.file.status) {
        case 'uploading':
          this.loading = true;
  
          break;
        case 'done':
          this.buttonLoading = false
            this.addUpdateExamsCategoryForm.patchValue({
              image: info.file.response.data.path,
            });
          break;
        case 'error':
          this.msg.error('Network error');
          this.loading = false;
          break;
      }
    }
  
    beforeRelatedMediaUpload = (
      file: NzUploadFile,
      fileList: NzUploadFile[]
    ): Observable<any> => {
      return new Observable((observer: Observer<boolean>) => {
        const isImage =
          file.type === 'image/jpg' ||
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/JPG' ||
          file.type === 'image/JPEG' ||
          file.type === 'image/PNG';
        if (!isImage) {
          this.msg.error('You can only upload image file!');
          observer.complete();
          return;
        }
        const isLt3M = file.size ? file.size / 1024 / 1024 < 3 : false;
        if (!isLt3M) {
          this.msg.error('Image must smaller than 3MB!');
          observer.complete();
          return;
        }
        observer.next(isImage && isLt3M);
        observer.complete();
      });
    };
    removeImage(path: string): void {
      let params: any;
      params = { path: path };
      this.addUpdateExamsCategoryForm.patchValue({
        image: null,
      });
      this.httpRequestService.request( 'post', 'media/delete-media', params ).subscribe((result: any)=>{
        if(result){
          this.notificationService.success('', 'Image Deleted Successfully');
        }
      });
    }
  
  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

  // seach  exam category
  searchConceptForDropdown(event: any): void {
    this.searchExamCategory.next(event);
  }



}
